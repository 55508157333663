import { Box, Divider, Grid, Link, styled, Tab, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import YoloLogo from "../../../assets/images/yolo_logo";
import TopLeftLink from "../../../components/button/link-top-left.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { login, register } from "../../../services/auth/auth-slice.service";
import { addToCart } from "../../../services/cart/cart-slice.service";
import { createFavourite } from "../../../services/favourite/favourites-slice.service";
import {
  guestSelector,
  resetGuestCarts,
  resetGuestFavourites,
} from "../../../services/guest/guest-slice.service";
import LoginForm from "../components/login/login-form.component";
import SignUpForm from "../components/sign_up/sign-up-form.component";

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.fonts.fontSizes.size15,
}));

function AuthScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1000"));
  const [isRemember, setIsRemember] = useState(localStorage.getItem("isRemember") || "false");
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const [optForNewsletter, setOptForNewsletter] = useState(true);
  const { guestFavouriteList, guestCartList } = useSelector(guestSelector);
  const [tabs, setTabs] = useState("login");

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const submitGuestFavourite = () => {
    if (guestFavouriteList.length > 0) {
      dispatch(createFavourite(guestFavouriteList)).then(({ meta, error }) => {
        if (meta.requestStatus === "fulfilled") {
          dispatch(resetGuestFavourites());
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const submitGuestCart = () => {
    if (guestCartList.length > 0) {
      dispatch(addToCart(guestCartList)).then(({ meta, error }) => {
        if (meta.requestStatus === "fulfilled") {
          dispatch(resetGuestCarts());
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const handleLogin = (values) => {
    setIsLoading(true);
    if (isRemember === "true") {
      localStorage.setItem("isRemember", "true");
      localStorage.setItem("userEmail", values.email);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("userEmail");
    }
    dispatch(login(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        submitGuestFavourite();
        submitGuestCart();
        if (payload.data.user.profileCompleted) {
          history.push(routes.HOME);
        } else {
          history.push(routes.COMPLETEPROFILE);
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitRegister = (values) => {
    setIsLoading(true);
    values.optForNewsletter = optForNewsletter;
    dispatch(register(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        submitGuestFavourite();
        submitGuestCart();
        history.push(routes.VERIFICATIONCODE, { email: values.email, fromRegister: true });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderSignUpForm = () => (
    <Form
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        confirmPassword: "",
      }}
      validationSchema={signUpValidationSchema}
      onSubmit={handleSubmitRegister}
    >
      <SignUpForm
        isLoading={isLoading}
        optForNewsletter={optForNewsletter}
        setOptForNewsletter={setOptForNewsletter}
        handleSubmit={handleSubmitRegister}
      />
    </Form>
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back to home" pushTo={routes.HOME} />

      <Box sx={{ position: "absolute", left: "25px", top: "15px" }}>
        <Link
          color={theme.palette.colors.brand.primary}
          underline="none"
          key="1"
          variant="body5"
          href={routes.HOME}
        >
          {"< Back to home"}
        </Link>
      </Box>
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YoloLogo />
              </Grid>
              <Spacer size="m" />
              {isMobile ? (
                <Grid item xs={12}>
                  <TabContext value={tabs}>
                    <Box
                      sx={{
                        border: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <TabList
                        textColor="secondary"
                        indicatorColor="secondary"
                        onChange={handleChange}
                        variant="fullWidth"
                      >
                        <StyledTab label="Login" value="login" />
                        <StyledTab label="Register" value="register" />
                      </TabList>
                    </Box>
                    <TabPanel value="login" sx={{ padding: "0px" }}>
                      <LoginForm
                        isLoading={isLoading}
                        handleSubmit={handleLogin}
                        isRemember={isRemember}
                        setIsRemember={setIsRemember}
                      />
                    </TabPanel>
                    <TabPanel value="register" sx={{ padding: "0px" }}>
                      {renderSignUpForm()}
                    </TabPanel>
                  </TabContext>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={5} justifyContent="end" display="flex">
                    <Grid item>
                      <LoginForm
                        isLoading={isLoading}
                        handleSubmit={handleLogin}
                        isRemember={isRemember}
                        setIsRemember={setIsRemember}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={2} justifyContent="center" display="flex">
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      sx={{ borderRightWidth: "2px" }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Grid item>{renderSignUpForm()}</Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default AuthScreen;
