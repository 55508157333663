import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const CenterItemBox = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "100%",
  width: "100%",
});

function RightBoxLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={isMobile ? 12 : 3} paddingTop={isMobile ? "30px" : 0}>
      <Grid container sx={{ height: "100%" }}>
        {!isMobile && (
          <Grid item xs={4}>
            <CenterItemBox>
              <CustomSkeleton width="30px" height="30px" />
            </CenterItemBox>
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 8}>
          <CenterItemBox>
            <Box sx={{ width: "50%" }}>
              <CustomSkeleton width="100%" height="30px" />
            </Box>
          </CenterItemBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RightBoxLoader;
