import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  lineHeight: "25px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const Body2PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
  marginTop: "15px",
  marginBottom: "10px",
}));

function GiftCard({ cardDetails }) {
  const theme = useTheme();

  const getTotalCost = () => {
    let totalCost = 0;

    totalCost = parseFloat(cardDetails.value) * parseFloat(cardDetails.quantity);

    return parseFloat(totalCost).toFixed(2);
  };

  const renderGiftCardDetail = () => (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <PrimaryColorText fontWeight="bold">Voucher Info: </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <Spacer size="s" position="top" />
        </Grid>
        <Grid item>
          <Body2PrimaryColorText>
            Credit Values: {cardDetails.quantity} x RM{cardDetails.value}
          </Body2PrimaryColorText>
        </Grid>
      </Grid>
    </Box>
  );

  const renderOrderDetails = () => (
    <SpaceBetweenRowBox>
      <Box
        sx={{
          border: "1px solid",
          paddingX: "15px",
          borderColor: theme.palette.colors.brand.primary,
        }}
      >
        <PrimaryColorText
          sx={{
            fontWeight: theme.fonts.fontWeights.bold,
            color: theme.palette.colors.brand.primary,
          }}
        >
          GIFT
        </PrimaryColorText>
      </Box>
      <PrimaryColorText>
        Total:{" "}
        <span style={{ fontWeight: theme.fonts.fontWeights.bold }}>{`RM${getTotalCost()}`}</span>{" "}
      </PrimaryColorText>
    </SpaceBetweenRowBox>
  );

  const renderRecipientInfo = () => (
    <Box>
      <Spacer />
      <Grid container>
        <Grid item xs={12}>
          <PrimaryColorText fontWeight="bold">Recipient Info: </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <Spacer size="s" position="top" />
        </Grid>
        <Grid item>
          <Body2PrimaryColorText>Email: {cardDetails.recipient.email}</Body2PrimaryColorText>
          <Body2PrimaryColorText>
            First Name: {cardDetails.recipient.firstName}
          </Body2PrimaryColorText>
          <Body2PrimaryColorText>Last Name: {cardDetails.recipient.lastName}</Body2PrimaryColorText>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <CardContainer>
      {renderGiftCardDetail()}
      <Spacer position="top" size="xs" />
      {renderRecipientInfo()}
      <SeperateLine />
      {renderOrderDetails()}
    </CardContainer>
  );
}

GiftCard.propTypes = {
  cardDetails: PropTypes.shape({
    recipient: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
    id: PropTypes.number,
    value: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
};
export default GiftCard;
