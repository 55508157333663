import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, styled, useTheme, useMediaQuery, Link } from "@mui/material";
import PropTypes from "prop-types";
import Spacer from "../../../components/utils/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { orderSelector } from "../../../services/order/order-slice.service";
import { cartSelector } from "../../../services/cart/cart-slice.service";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: isMobile ? "100%" : "95%",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const GreyTextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textDecorationColor: theme.palette.colors.text.secondary,
}));

function OrderSummary({ promoCodeRedeems }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { createOrderObj } = useSelector(orderSelector);
  const { getCartListObj } = useSelector(cartSelector);
  const totalDiscount = promoCodeRedeems.reduce((accumulator, object) => {
    if (object.discount) {
      return accumulator + parseFloat(object.discount.replace(/,/g, ""));
    }
    return accumulator;
  }, 0);
  const orderTotalPrice = promoCodeRedeems.reduce((accumulator, object) => {
    if (object.discount) {
      return accumulator - parseFloat(object.discount.replace(/,/g, ""));
    }
    return accumulator;
  }, parseFloat(getCartListObj.data.totalCost.replace(/,/g, "")));

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
      <StyledBorderBox isMobile={isMobile}>
        <PrimaryColorText variant="h5">Order Summary</PrimaryColorText>
        <SeperateLine />

        <StyledRow>
          <GreyText>Items:</GreyText>
          <GreyText>{`${getCartListObj.data.totalItems} items`}</GreyText>
        </StyledRow>
        <Spacer size="s" position="top" />
        {totalDiscount !== 0 && (
          <>
            <StyledRow>
              <GreyText>Subtotal:</GreyText>
              <GreyText>{`RM${getCartListObj.data.totalCost}`}</GreyText>
            </StyledRow>
            <Spacer size="s" position="top" />
            <StyledRow>
              <GreyText>Total Discount:</GreyText>
              <GreyText>{`-RM${totalDiscount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}`}</GreyText>
            </StyledRow>
            <Spacer size="s" position="top" />
          </>
        )}
        <StyledRow>
          <PrimaryColorText>Total Cost:</PrimaryColorText>
          <BoldPrimaryColorText>{`RM${orderTotalPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`}</BoldPrimaryColorText>
        </StyledRow>
        <Spacer size="xxxl" position="top" />
        <FormSubmitButton isLoading={createOrderObj.status === "pending"}>
          <WhiteColorText>Place Order</WhiteColorText>
        </FormSubmitButton>
        <Spacer size="m" position="top" />
        <GreyText variant="body2" sx={{ lineHeight: "17px" }}>
          {`By placing your order, you agree to our company `}
          <GreyTextLink href="http://merchantinfo.yolo.my/privacy-policy/" target="_blank">
            Privacy Policy
          </GreyTextLink>
          {` and `}
          <GreyTextLink href="http://merchantinfo.yolo.my/terms-and-conditions/" target="_blank">
            Terms & Conditions.
          </GreyTextLink>
        </GreyText>
      </StyledBorderBox>
    </Box>
  );
}

OrderSummary.propTypes = {
  promoCodeRedeems: PropTypes.arrayOf(
    PropTypes.shape({
      discount: PropTypes.string,
      total: PropTypes.string,
    }),
  ).isRequired,
};

export default OrderSummary;
