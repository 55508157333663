import React, { useContext, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";

import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  forgotPassword,
  resendVerificationCode,
  resetPassowrd,
} from "../../../services/auth/auth-slice.service";
import YoloLogo from "../../../assets/images/yolo_logo";
import EmailForm from "../components/forgot_password/enter-email-form.component";
import CreateNewPassword from "../components/forgot_password/create-new-password.component";
import ResetSuccess from "../components/forgot_password/password-reset-successful.component";
import TopLeftLink from "../../../components/button/link-top-left.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

function ForgotPassowrd() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentScreen, setCurrentScreen] = useState("Forgot Password");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmitResendCodes = () => {
    const values = {};
    values.email = userEmail;
    values.type = "forgotPassword";
    dispatch(resendVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitCreateNewPassword = (values) => {
    setIsLoading(true);
    values.email = userEmail;

    dispatch(resetPassowrd(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setCurrentScreen("Reset Successful");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitEmail = (values) => {
    setUserEmail(values.email);
    setIsLoading(true);
    dispatch(forgotPassword(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setCurrentScreen("Create New Password");
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderComponent = () => {
    if (currentScreen === "Create New Password") {
      return (
        <CreateNewPassword
          isLoading={isLoading}
          userEmail={userEmail}
          handleSubmitResendCodes={handleSubmitResendCodes}
          handleSubmit={handleSubmitCreateNewPassword}
        />
      );
    }
    if (currentScreen === "Reset Successful") {
      return <ResetSuccess />;
    }
    return <EmailForm isLoading={isLoading} handleSubmit={handleSubmitEmail} />;
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        opacity: 1,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back to login" pushTo={routes.AUTH} />
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YoloLogo />
              </Grid>
              <Spacer size="m" />
              <Grid item xs={12}>
                {renderComponent()}
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default ForgotPassowrd;
