import React, { useState } from "react";
import { Grid, useTheme, useMediaQuery, styled, Box, IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ImageFullViewModal from "./image-full-view-modal.component";

const ImagePopUpIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 30,
  bottom: 15,
  backgroundColor: "rgba(255,255,255,0.7)",
  color: theme.palette.colors.brand.secondary,
  ":hover": {
    backgroundColor: theme.palette.colors.ui.white,
  },
}));

const CarouselContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  borderTopLeftRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderTopRightRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderBottomLeftRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderBottomRightRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  overflow: "hidden",
  position: "relative",
}));

function ExperienceImageCarousel({ itemList }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showImageFullView, setShowImageFullView] = useState(false);

  return (
    <Grid item xs={12}>
      <ImageFullViewModal
        imageList={itemList}
        showModal={showImageFullView}
        setShowModal={setShowImageFullView}
      />
      <CarouselContainer isMobile={isMobile}>
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showIndicators={false}
          swipeable={true}
          autoPlay={true}
          showArrows={!isMobile}
          showStatus={false}
        >
          {itemList.map((item) => (
            <Box onClick={() => setShowImageFullView(true)} key={uuidv4()}>
              <img src={item} alt="BannerImage" key={uuidv4()} />
            </Box>
          ))}
        </Carousel>
        <ImagePopUpIconButton onClick={() => setShowImageFullView(true)}>
          <ZoomOutMapIcon />
        </ImagePopUpIconButton>
      </CarouselContainer>
    </Grid>
  );
}

ExperienceImageCarousel.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ExperienceImageCarousel;
