import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import Spacer from "../../../../components/utils/spacer.component";

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.label,
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const StyledRadion = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  "&.Mui-checked": {
    color: theme.palette.colors.text.secondary,
  },
}));

function MobileFilterPriceRules() {
  const { setFieldValue, values } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue("prule", event.target.value);
  };

  const ButtonList = [
    {
      label: "Price: Default",
      value: "",
    },
    {
      label: "Price: Low - High",
      value: "price-low-high",
    },
    {
      label: "Price: High - Low",
      value: "price-high-low",
    },
  ];

  return (
    <Box>
      <StyledText>Sort by</StyledText>
      <Spacer size="s" position="top" />
      <FormControl value="">
        <RadioGroup value={values.prule} onChange={handleChange}>
          {ButtonList.map((item) => (
            <FormControlLabel
              value={item.value}
              key={item.value}
              control={<StyledRadion />}
              label={<PlaceholderText>{item.label}</PlaceholderText>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default MobileFilterPriceRules;
