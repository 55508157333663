import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import BoxTitle from "../box-title.component";
import VerificationForm from "./verification-code-new-password-form.component";

function CreateNewPassword({ userEmail, handleSubmit, handleSubmitResendCodes, isLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[0]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <BoxTitle title="Create New Password" />
        <VerificationForm
          isLoading={isLoading}
          userEmail={userEmail}
          handleSubmit={handleSubmit}
          handleSubmitResendCodes={handleSubmitResendCodes}
        />
      </Box>
    </Box>
  );
}

CreateNewPassword.propTypes = {
  userEmail: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitResendCodes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CreateNewPassword;
