import React, { useState } from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { guestSelector } from "../../../services/guest/guest-slice.service";
import { isLogin } from "../../../infrastructure/utils";

const DetailsBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflowWrap: "anywhere",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledIconButton = styled(IconButton)({
  height: "20px",
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

function LeftBox({ itemDetails, onFavourtieClick, onDeleteClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("740"));
  const { guestFavouriteList } = useSelector(guestSelector);
  const [isFavourite, setIsFavourite] = useState(
    isLogin()
      ? itemDetails.experience.favourited
      : guestFavouriteList.some((item) => item.experienceId === itemDetails.experience.id),
  );

  const onFavouriteClick = () => {
    if (isFavourite) {
      onFavourtieClick(false);
    } else {
      onFavourtieClick(true);
    }
    setIsFavourite(!isFavourite);
  };

  const renderFavouriteIcon = () => (
    <Box display="flex" alignItems="center" height="100%">
      {isFavourite ? (
        <StyledIconButton disableRipple onClick={() => onFavouriteClick()}>
          <FavoriteIcon sx={{ color: theme.palette.colors.ui.favourite }} />
        </StyledIconButton>
      ) : (
        <StyledIconButton disableRipple onClick={() => onFavouriteClick()}>
          <FavoriteBorderIcon sx={{ color: theme.palette.colors.brand.secondary }} />
        </StyledIconButton>
      )}
    </Box>
  );

  const renderDeleteIcon = () => (
    <Box sx={{ height: "100%" }}>
      <CloseIconButton onClick={() => onDeleteClick()}>
        <CloseIcon />
      </CloseIconButton>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <img
        src={itemDetails.experience.imgPath}
        alt="exp-img"
        style={{
          width: "180px",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
        }}
      />
      <Spacer size="m" position="left" />
      <DetailsBox>
        <SpaceBetweenBox>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: routes.EXPERIENCESDETAILS,
              search: `experienceId=${itemDetails.experience.id}`,
            }}
          >
            <TitleText>{itemDetails.experience.title}</TitleText>
          </Link>
          {isMobile && renderDeleteIcon()}
        </SpaceBetweenBox>
        <GreyText>{itemDetails.experience.state}</GreyText>
        <SpaceBetweenBox>
          <StyledRatingBox>
            <ReactStars
              activeColor={theme.palette.colors.ui.star}
              size={15}
              edit={false}
              value={parseFloat(itemDetails.experience.ratings)}
              color={theme.palette.colors.ui.border}
              isHalf={true}
            />
            <Spacer size="xs" position="left" />
            <GreyText>{`${itemDetails.experience.ratings} (${itemDetails.experience.noReviews})`}</GreyText>
          </StyledRatingBox>
          {isMobile && renderFavouriteIcon()}
        </SpaceBetweenBox>
      </DetailsBox>

      {!isMobile && (
        <Grid item xs={2}>
          {renderFavouriteIcon()}
        </Grid>
      )}
    </Box>
  );
}

LeftBox.defaultProps = {
  itemDetails: {
    id: null,
    uniqueId: "",
    experience: {
      id: null,
      title: "",
      state: "",
      ratings: null,
      noReviews: null,
      imgPath: "",
      minNoPax: null,
      maxNoPax: null,
      hasFlexi: {
        status: false,
      },
    },
    isFavourite: false,
    isFlexi: false,
    quantity: null,
    pricePerItem: "",
    price: "",
    addOns: [],
  },
};

LeftBox.propTypes = {
  onFavourtieClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    uniqueId: PropTypes.string,
    id: PropTypes.number,
    experience: PropTypes.shape({
      favourited: PropTypes.bool,
      id: PropTypes.number,
      title: PropTypes.string,
      state: PropTypes.string,
      ratings: PropTypes.number,
      noReviews: PropTypes.number,
      imgPath: PropTypes.string,
      minNoPax: PropTypes.number,
      maxNoPax: PropTypes.number,
      hasFlexi: PropTypes.shape({
        status: PropTypes.bool,
      }),
    }),
    isFlexi: PropTypes.bool,
    quantity: PropTypes.number,
    pricePerItem: PropTypes.string,
    price: PropTypes.string,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default LeftBox;
