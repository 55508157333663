import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";

const FIELDHEIGHT = "35px";
const MENULIST = [
  { label: "1 Star", id: "1", value: "1" },
  { label: "2 Star", id: "2", value: "2" },
  { label: "3 Star", id: "3", value: "3" },
  { label: "4 Star", id: "4", value: "4" },
  { label: "5 Star", id: "5", value: "5" },
];
const SORTINGRULES = [
  { label: "Most Recent", id: "1", value: "recent" },
  { label: "Oldest", id: "2", value: "oldest" },
  { label: "Lowest to Highest Rating", id: "3", value: "lowtohigh" },
  { label: "Highest to Lowest Rating", id: "4", value: "hightolow" },
];

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: theme.fonts.fontSizes.size16,
        color: theme.palette.colors.text.secondary,
      },
    },
  },
  " .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const RatingFilterTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: "15px",
  paddingRight: "15px",
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

function ReviewFilterAndSort() {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const [selectedRate, setSeletedRate] = useState([]);
  const [currentRules, setCurrentRules] = useState("default");
  const { setFieldValue, values, handleSubmit } = useFormikContext();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = selectedRate.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setSeletedRate(selectedRate.filter((checkedCheckbox) => checkedCheckbox.id !== data.id));
      setFieldValue(
        "ratings",
        values.ratings.filter((id) => id !== data.id),
      );
    } else {
      setSeletedRate(selectedRate.concat(data));
      setFieldValue("ratings", values.ratings.concat(data.id));
    }

    handleSubmit();
  };

  const renderSortingRules = () => (
    <CenterRowBox>
      <GreyText>Sort by:</GreyText>
      <FormControl
        sx={{
          "& .MuiOutlinedInput-root": {
            " &.Mui-focused fieldset": {
              borderWidth: 0,
            },
          },
          "	.MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
          },
          "& .MuiSelect-iconOutlined": {
            color: theme.palette.colors.text.secondary,
          },
        }}
      >
        <Select
          value={currentRules}
          name="sortBy"
          sx={{
            marginTop: "2px",
            height: FIELDHEIGHT,
            color: theme.palette.colors.text.secondary,
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
              },
            },
          }}
          defaultValue={currentRules}
          onChange={(event) => {
            setCurrentRules(event.target.value);
            setFieldValue("sortBy", event.target.value);
            handleSubmit();
          }}
        >
          <MenuItem value="default">
            <GreyText>Default</GreyText>
          </MenuItem>
          {SORTINGRULES.map((item) => (
            <MenuItem value={item.value} key={item.id}>
              <GreyText>{item.label}</GreyText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CenterRowBox>
  );

  const renderRatingFilter = () => (
    <>
      <Button
        disableRipple
        sx={{
          textTransform: "none",
          border: `1px solid ${theme.palette.colors.ui.border}`,
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          height: FIELDHEIGHT,
          ":hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleOpenMenu}
      >
        <CenterRowBox>
          <RatingFilterTitle>Rating</RatingFilterTitle>
          {showMenu ? (
            <ArrowDropUpIcon sx={{ color: theme.palette.colors.text.secondary }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: theme.palette.colors.text.secondary }} />
          )}
        </CenterRowBox>
      </Button>
      <StyledMenu
        sx={{ mt: "40px" }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={showMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(showMenu)}
        onClose={handleCloseMenu}
        name="ratings"
      >
        <FormControl>
          {MENULIST.map((item) => (
            <MenuItem key={item.id} value={item.label} disableRipple>
              <Checkbox
                disableRipple
                onChange={() => handleCheckboxChange(item)}
                checked={selectedRate.some((checkedCheckbox) => checkedCheckbox.id === item.id)}
              />
              <ListItemText primary={<GreyText>{item.label}</GreyText>} />
            </MenuItem>
          ))}
        </FormControl>
      </StyledMenu>
    </>
  );

  return (
    <Box
      sx={{
        width: " 100%",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      }}
    >
      {renderRatingFilter()}
      {renderSortingRules()}
    </Box>
  );
}

export default ReviewFilterAndSort;
