import React from "react";
import { Box, styled, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import Spacer from "../utils/spacer.component";

const StyledBox = styled(Box)({
  paddingTop: "10px",
});

const StyledEmailIcon = styled(EmailIcon)({
  height: "18px",
});

const StyledLocalPhoneIcon = styled(LocalPhoneIcon)({
  height: "18px",
});

const WhiteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const CenterBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

function ContactUsDetails() {
  const emailAddress = "support@yolo.my";
  const phoneNumber = "+6016-2091547";

  const renderAddress = () => (
    <StyledLink
      underline="hover"
      target="_blank"
      href="https://www.waze.com/en-GB/live-map/directions/my/selangor/pj/leisure-commerce-square?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJbbuu6NdNzDERxsNZMY5WZ4o"
    >
      <WhiteText component="span">
        Unit 102, Block A4, Leisure Commerce Square, No 9, Jalan PJS 8/9, 46150, Petaling Jaya,
        Selangor.
      </WhiteText>
    </StyledLink>
  );

  const renderEmail = () => (
    <CenterBox>
      <StyledEmailIcon />
      <StyledLink underline="hover" href={`mailto: ${emailAddress}`}>
        {` ${emailAddress}`}
      </StyledLink>
    </CenterBox>
  );
  const renderPhone = () => (
    <CenterBox>
      <StyledLocalPhoneIcon />
      <StyledLink underline="hover" href={`tel: ${phoneNumber}`}>
        {` ${phoneNumber}`}
      </StyledLink>
    </CenterBox>
  );

  return (
    <StyledBox>
      {renderAddress()}
      <Spacer size="xl" position="bottom" />
      <Box display="flex" flexDirection="column">
        {renderEmail()}
        <Spacer size="s" position="bottom" />
        {renderPhone()}
      </Box>
    </StyledBox>
  );
}

export default ContactUsDetails;
