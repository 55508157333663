import React, { useEffect, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import StarRateIcon from "@mui/icons-material/StarRate";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import ReviewFilterAndSort from "./review-filter-and-sort.component";
import ReviewCard from "./review-card.component";
import {
  getExperiencesReviews,
  experienceSelector,
} from "../../../services/experiences/experience-slice.service";
import ReviewsLoader from "../loader/reviews-loader.component";
import Form from "../../../components/forms/form.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledTableCell = styled(TableCell)({
  padding: "0px",
  border: "0px",
});

const StyledHeaderTableCell = styled(TableCell)({
  backgroundColor: "transparent",
  border: "0px",
  padding: "0px",
});

const validationSchema = Yup.object().shape({
  ratings: Yup.array().of(Yup.string()).nullable().label("Ratings"),
  sortBy: Yup.string().nullable().label("sortBy"),
});

function Reviews({ itemData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { getExperiencesReviewsObj } = useSelector(experienceSelector);
  const experienceId = new URLSearchParams(location.search).get("experienceId") || itemData.id;
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const formRef = useRef();

  useEffect(() => {
    const params = "?ratings=&sortBy=";
    dispatch(getExperiencesReviews({ experienceId, params, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    const params = `?ratings=${formRef.current.values.ratings}&sortBy=${formRef.current.values.sortBy}`;
    dispatch(getExperiencesReviews({ experienceId, params, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const submitFilterAndSort = (values) => {
    setPage(1);
    const params = `?ratings=${values.ratings}&sortBy=${values.sortBy}`;

    dispatch(getExperiencesReviews({ experienceId, params, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderRatingText = () => (
    <PrimaryColorText
      variant={isMobile ? null : "h6"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {itemData.ratings} <StarRateIcon sx={{ fontSize: theme.fonts.fontSizes.size18 }} /> out of{" "}
      {itemData.noReviews} helpful reviews
    </PrimaryColorText>
  );

  const renderGuestReviews = () => {
    if (getExperiencesReviewsObj.status === "succeeded") {
      if (getExperiencesReviewsObj.data.guest.items.length > 0) {
        return (
          <>
            {getExperiencesReviewsObj.data.guest.items.map((item) => (
              <Grid item sx={{ width: isMobile ? "100%" : "95%" }} key={item.id} xs={12}>
                <ReviewCard reviewDetails={item} />
              </Grid>
            ))}
            <Grid container justifyContent="center" paddingTop="50px">
              <Pagination
                page={page}
                onChange={handlePageChange}
                count={getExperiencesReviewsObj.data.guest.pagination.totalPages}
                variant="outlined"
              />
            </Grid>
          </>
        );
      }
      return (
        <Grid item sx={{ width: isMobile ? "100%" : "95%" }} xs={12} textAlign="center">
          <PrimaryColorText>No Reviews</PrimaryColorText>
        </Grid>
      );
    }

    return <ReviewsLoader />;
  };

  const renderContent = () => {
    if (!isMobile) {
      return (
        <>
          <Table stickyHeader>
            <TableHead sx={{ position: "sticky", top: theme.dimensions.headerHeight }}>
              <TableRow>
                <StyledHeaderTableCell sx={{ width: "40%" }}>
                  <StyledTitle sx={{ height: "10px", fontSize: theme.fonts.fontSizes.size32 }}>
                    What others are saying
                  </StyledTitle>
                </StyledHeaderTableCell>
                <StyledHeaderTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ padding: "0px" }}>
                <StyledTableCell />
                <StyledTableCell>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {renderRatingText()}
                    </Grid>
                    <Grid item xs={12}>
                      <Form
                        innerRef={formRef}
                        initialValues={{ ratings: [], sortBy: "" }}
                        onSubmit={submitFilterAndSort}
                        validationSchema={validationSchema}
                      >
                        <ReviewFilterAndSort />
                      </Form>
                    </Grid>
                    {renderGuestReviews()}
                  </Grid>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Spacer size="xl" position="top" />
        </>
      );
    }

    return (
      <>
        <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size24 }}>
          What others are saying
        </StyledTitle>
        {renderRatingText()}

        <Box sx={{ width: "95%" }}>
          <Spacer size="m" position="top" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ReviewFilterAndSort />
            </Grid>
            {renderGuestReviews()}
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <Grid item xs={12} id="reviews">
      {renderContent()}
    </Grid>
  );
}

Reviews.defaultProps = {
  itemData: PropTypes.shape({
    ratings: "",
    noReviews: "",
  }),
};

Reviews.propTypes = {
  itemData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
  }),
};

export default Reviews;
