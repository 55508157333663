import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const getOrderPreview = createAsyncThunk("orders/order_preview", async () => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/preview`);
  return response;
});

export const createOrder = createAsyncThunk("orders/create_order", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders`, payload);
  return response;
});

export const getPendingPaymentList = createAsyncThunk("orders/pending_payment_list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/pending`);
  return response;
});

export const cancelOrder = createAsyncThunk("orders/cancel_order", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/${payload}/cancel`);
  return response;
});

export const billCheck = createAsyncThunk("orders/bill_check", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/bill_check`, payload);
  return response;
});

export const getOrderDetails = createAsyncThunk("orders/order_details", async (payload) => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/${payload}`);
  return response;
});

export const getOrders = createAsyncThunk("orders/order_list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders`);
  return response;
});

export const previewSecureCheckout = createAsyncThunk(
  "orders/preview_secure_checkout",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/preview_secure_checkout`,
      payload,
    );
    return response;
  },
);

export const buyNowSecureCheckout = createAsyncThunk(
  "orders/buy_now_secure_checkout",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/secure_checkout`,
      payload,
    );
    return response;
  },
);

export const previewRedeemPromoCode = createAsyncThunk(
  "orders/promoCodeRedeem",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/preview_promo_code_redeem`,
      payload,
    );
    return response;
  },
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    getOrderPreviewObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPendingPaymentListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    billCheckObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getOrderDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getOrdersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    previewSecureCheckoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    buyNowSecureCheckoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    previewRedeemPromoCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetPreviewRedeemPromoCode: (state) => {
      state.previewRedeemPromoCodeObj.status = "idle";
      state.previewRedeemPromoCodeObj.data = null;
      state.previewRedeemPromoCodeObj.successMessage = null;
      state.previewRedeemPromoCodeObj.errorMessage = null;
    },
  },
  extraReducers: {
    [getOrderPreview.pending]: (state) => {
      state.getOrderPreviewObj.status = "pending";
    },
    [getOrderPreview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getOrderPreviewObj.status = "succeeded";
      state.getOrderPreviewObj.data = data;
      state.getOrderPreviewObj.successMessage = message;
    },
    [getOrderPreview.rejected]: (state, action) => {
      const { message } = action.error;

      state.getOrderPreviewObj.status = "failed";
      state.getOrderPreviewObj.errorMessage = message;
    },
    [createOrder.pending]: (state) => {
      state.createOrderObj.status = "pending";
    },
    [createOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createOrderObj.status = "succeeded";
      state.createOrderObj.data = data;
      state.createOrderObj.successMessage = message;
    },
    [createOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.createOrderObj.status = "failed";
      state.createOrderObj.errorMessage = message;
    },
    [getPendingPaymentList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPendingPaymentListObj.status = "succeeded";
      state.getPendingPaymentListObj.data = data;
      state.getPendingPaymentListObj.successMessage = message;
    },
    [getPendingPaymentList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPendingPaymentListObj.status = "failed";
      state.getPendingPaymentListObj.errorMessage = message;
    },
    [cancelOrder.pending]: (state) => {
      state.cancelOrderObj.status = "pending";
    },
    [cancelOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelOrderObj.status = "succeeded";
      state.cancelOrderObj.data = data;
      state.cancelOrderObj.successMessage = message;
    },
    [cancelOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelOrderObj.status = "failed";
      state.cancelOrderObj.errorMessage = message;
    },
    [billCheck.pending]: (state) => {
      state.billCheckObj.status = "pending";
    },
    [billCheck.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.billCheckObj.status = "succeeded";
      state.billCheckObj.data = data;
      state.billCheckObj.successMessage = message;
    },
    [billCheck.rejected]: (state, action) => {
      const { message } = action.error;

      state.billCheckObj.status = "failed";
      state.billCheckObj.errorMessage = message;
    },
    [getOrderDetails.pending]: (state) => {
      state.getOrderDetailsObj.status = "pending";
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getOrderDetailsObj.status = "succeeded";
      state.getOrderDetailsObj.data = data;
      state.getOrderDetailsObj.successMessage = message;
    },
    [getOrderDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getOrderDetailsObj.status = "failed";
      state.getOrderDetailsObj.errorMessage = message;
    },
    [getOrders.pending]: (state) => {
      state.getOrdersObj.status = "pending";
    },
    [getOrders.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getOrdersObj.status = "succeeded";
      state.getOrdersObj.data = data;
      state.getOrdersObj.successMessage = message;
    },
    [getOrders.rejected]: (state, action) => {
      const { message } = action.error;

      state.getOrdersObj.status = "failed";
      state.getOrdersObj.errorMessage = message;
    },
    [previewSecureCheckout.pending]: (state) => {
      state.previewSecureCheckoutObj.status = "pending";
    },
    [previewSecureCheckout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewSecureCheckoutObj.status = "succeeded";
      state.previewSecureCheckoutObj.data = data;
      state.previewSecureCheckoutObj.successMessage = message;
    },
    [previewSecureCheckout.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewSecureCheckoutObj.status = "failed";
      state.previewSecureCheckoutObj.errorMessage = message;
    },
    [buyNowSecureCheckout.pending]: (state) => {
      state.buyNowSecureCheckoutObj.status = "pending";
    },
    [buyNowSecureCheckout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.buyNowSecureCheckoutObj.status = "succeeded";
      state.buyNowSecureCheckoutObj.data = data;
      state.buyNowSecureCheckoutObj.successMessage = message;
    },
    [buyNowSecureCheckout.rejected]: (state, action) => {
      const { message } = action.error;

      state.buyNowSecureCheckoutObj.status = "failed";
      state.buyNowSecureCheckoutObj.errorMessage = message;
    },
    [previewRedeemPromoCode.pending]: (state) => {
      state.previewRedeemPromoCodeObj.status = "pending";
    },
    [previewRedeemPromoCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewRedeemPromoCodeObj.status = "succeeded";
      state.previewRedeemPromoCodeObj.data = data;
      state.previewRedeemPromoCodeObj.successMessage = message;
    },
    [previewRedeemPromoCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewRedeemPromoCodeObj.status = "failed";
      state.previewRedeemPromoCodeObj.errorMessage = message;
    },
  },
});

export default orderSlice.reducer;

// state
export const orderSelector = (state) => state.order;

export const { resetPreviewRedeemPromoCode } = orderSlice.actions;
