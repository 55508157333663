import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactSimplyCarousel from "react-simply-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { marketingSelector } from "../../../../services/marketing/marketing-slice.service";
import routes from "../../../../components/navigation/routes";
import Spacer from "../../../../components/utils/spacer.component";

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
}));

const MainContainer = styled(Box)({
  width: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
});

const ViewAllButton = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: "rgba(255,255,255,0.9)",
  width: "100%",
  height: "100%",
  padding: "15px",
  textTransform: "none",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  color: theme.palette.colors.text.primary,
  ":hover": {
    backgroundColor: theme.palette.colors.brand.white,
  },
}));

const ViewAllButtonContainer = styled(Box)({
  padding: "21px",
  width: "300px",
  paddingBottom: "26px",
});

const ItemListImageBox = styled(Box)({
  padding: "20px",
  width: "300px",
});

const MaxWidthAbsoluteBox = styled(Box)(({ theme }) => ({
  maxWidth: theme.dimensions.PCMaxWidth,
  width: "100%",
  position: "absolute",
  zIndex: 1,
  display: "flex",
  flexDirection: "row",
  top: "30%",
}));

const DetailsBox = styled(Box)({
  width: "35%",
  padding: "20px",
  left: "5%",
});

const CarouselBox = styled(Box)({ width: "60%", left: "40%" });

const DotsBox = styled(Box)(({ theme }) => ({
  "&&.slick-dots li.slick-active button:before": {
    color: theme.palette.colors.brand.white,
    backgroundColor: theme.palette.colors.brand.white,
    opacity: 1,
    fontSize: "10px",
    lineHeight: "10px",
    width: "10px",
    height: "10px",
  },
  "&&.slick-dots li button::before": {
    border: `1px solid ${theme.palette.colors.brand.white}`,
    color: "transparent",
    opacity: 1,
    fontSize: "10px",
    lineHeight: "10px",
    borderRadius: "10px",
    width: "10px",
    height: "10px",
  },
  bottom: 0,
  left: "-20px",
  right: 0,
}));

const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  className: "rect__slick__slider__parent",
  appendDots: (dots) => (
    <DotsBox>
      <ul>{dots}</ul>
    </DotsBox>
  ),
};

const MobileCircleImageBox = styled(Box)({
  position: "absolute",
  top: "40%",
  zIndex: 1,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const MobileDetailBox = styled(Box)({
  position: "absolute",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  width: "100%",
  padding: "40px",
  flexDirection: "column",
});

const MobileCircleViewAllButton = styled(Button)(({ theme }) => ({
  height: "100px",
  width: "100px",
  borderRadius: "50px",
  backgroundColor: theme.palette.colors.brand.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  color: theme.palette.colors.text.primary,
  ":hover": {
    backgroundColor: theme.palette.colors.brand.white,
  },
}));

export default function ExperienceBanner() {
  const history = useHistory();
  const { getMarketingExperienceObj } = useSelector(marketingSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("780"));
  const isAutoHeight = useMediaQuery(theme.breakpoints.up("950"));
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const buttonStyle = {
    padding: "5px",
    bottom: -40,
    position: "absolute",
    color: theme.palette.colors.text.white,
    background: "transparent",
    backgroundColor: "transparent",
    border: "1px solid white",
    textAlign: "center",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const experienceList = getMarketingExperienceObj.data.concat([
    {
      imagePath: getMarketingExperienceObj.data[0].imagePath,
      description: "View all experience in experience listing page.",
      id: "viewall",
      title: "View all experiences",
    },
  ]);

  const renderPCView = () => (
    <MainContainer height={!isAutoHeight ? "auto" : "500px"}>
      <MaxWidthAbsoluteBox>
        <DetailsBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <WhiteColorText
                sx={{
                  fontSize: theme.fonts.fontSizes.size32,
                  fontWeight: theme.fonts.fontWeights.bold,
                }}
              >
                {experienceList[activeSlideIndex].title}
              </WhiteColorText>
            </Grid>
            <Grid item xs={12}>
              {experienceList[activeSlideIndex].id === "viewall" ? (
                <Link
                  style={{ textDecorationColor: theme.palette.colors.text.white }}
                  to={{
                    pathname: routes.EXPERIENCESLISTING,
                    search: `type=experience&q=`,
                  }}
                >
                  <WhiteColorText>See All</WhiteColorText>
                </Link>
              ) : (
                <Link
                  style={{ textDecorationColor: theme.palette.colors.text.white }}
                  to={{
                    pathname: routes.EXPERIENCESDETAILS,
                    search: `experienceId=${experienceList[activeSlideIndex].id}`,
                  }}
                >
                  <WhiteColorText>View Experience</WhiteColorText>
                </Link>
              )}
            </Grid>
          </Grid>
        </DetailsBox>
        <CarouselBox>
          <Box sx={{ position: "relative", width: "125%" }}>
            <ReactSimplyCarousel
              updateOnItemClick
              activeSlideIndex={activeSlideIndex}
              onRequestChange={(index) => {
                if (index < experienceList.length) {
                  setActiveSlideIndex(index);
                } else if (index > experienceList.length) {
                  setActiveSlideIndex(experienceList.length - 1);
                } else {
                  setActiveSlideIndex(0);
                }
              }}
              itemsToScroll={1}
              itemsToShow={3}
              infinite={true}
              forwardBtnProps={{
                style: {
                  left: 75,
                  opacity: activeSlideIndex === experienceList.length - 1 ? 0.7 : 1,
                  ...buttonStyle,
                },
                children: <ArrowForwardIosIcon />,
              }}
              backwardBtnProps={{
                style: {
                  left: 20,
                  opacity: activeSlideIndex === 0 ? 0.7 : 1,
                  ...buttonStyle,
                },
                children: <ArrowBackIosNewIcon />,
              }}
              speed={400}
              easing="linear"
            >
              {experienceList.map((item) =>
                item.id !== "viewall" ? (
                  <ItemListImageBox key={item.id}>
                    <img
                      src={item.imagePath}
                      alt={item}
                      style={{
                        borderRadius: theme.shape.borderRadius[0],
                        width: "100%",
                      }}
                    />
                  </ItemListImageBox>
                ) : (
                  <ViewAllButtonContainer key={item.id}>
                    <ViewAllButton
                      disableRipple
                      onClick={() =>
                        history.push({
                          pathname: routes.EXPERIENCESLISTING,
                          search: `type=experience&q=`,
                        })
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: theme.fonts.fontSizes.size18,
                          fontWeight: theme.fonts.fontWeights.bold,
                        }}
                      >
                        See all
                      </Typography>
                    </ViewAllButton>
                  </ViewAllButtonContainer>
                ),
              )}
              <ViewAllButtonContainer />
              <ViewAllButtonContainer />
            </ReactSimplyCarousel>
          </Box>
        </CarouselBox>
      </MaxWidthAbsoluteBox>
      <img
        src={experienceList[activeSlideIndex].imagePath}
        alt="expImage"
        style={{
          width: "100%",
          height: !isAutoHeight ? "auto" : "500px",
          filter: "brightness(0.3)",
          objectFit: "cover",
        }}
      />
    </MainContainer>
  );

  const renderMobileView = () => (
    <Box sx={{ width: "100%" }}>
      <Slider {...settings}>
        {experienceList.map((item) => (
          <MainContainer key={item.id}>
            <MobileDetailBox>
              <WhiteColorText
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontWeight: theme.fonts.fontWeights.bold,
                  lineHeight: "28px",
                }}
              >
                {item.title}
              </WhiteColorText>
              <Spacer size="s" position="top" />

              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {item.id === "viewall" ? (
                  <Link
                    style={{ textDecorationColor: theme.palette.colors.text.white }}
                    to={{
                      pathname: routes.EXPERIENCESLISTING,
                      search: `type=experience&q=`,
                    }}
                  >
                    <WhiteColorText sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                      See All
                    </WhiteColorText>
                  </Link>
                ) : (
                  <Link
                    style={{ textDecorationColor: theme.palette.colors.text.white }}
                    to={{
                      pathname: routes.EXPERIENCESDETAILS,
                      search: `experienceId=${item.id}`,
                    }}
                  >
                    <WhiteColorText sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                      View Experience
                    </WhiteColorText>
                  </Link>
                )}
              </Box>
            </MobileDetailBox>
            <MobileCircleImageBox>
              {item.id === "viewall" ? (
                <Link
                  style={{ textDecorationColor: theme.palette.colors.text.white }}
                  to={{
                    pathname: routes.EXPERIENCESLISTING,
                    search: `type=experience&q=`,
                  }}
                >
                  <MobileCircleViewAllButton>
                    <Typography>See All</Typography>
                  </MobileCircleViewAllButton>
                </Link>
              ) : (
                <img
                  src={item.imagePath}
                  alt="experienceCoverPhoto"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                    objectFit: "cover",
                  }}
                />
              )}
            </MobileCircleImageBox>
            <img
              src={item.imagePath}
              alt="experienceCoverPhoto"
              style={{
                width: "100%",
                height: "350px",
                objectFit: "cover",
                WebkitFilter: "brightness(30%)",
              }}
            />
          </MainContainer>
        ))}
      </Slider>
    </Box>
  );

  return isMobile ? renderMobileView() : renderPCView();
}
