import React from "react";
import PropTypes from "prop-types";
import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CarouselItemList from "../../dashboard/components/carousel-item-list.component";
import ExperiencesItem from "../../experiences-listing/component/experiences-listing/experiences-item.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

function YouMightAlsoLike({ itemList, onToggleFavouriteWithId }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledTitle
            sx={{
              fontSize: isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32,
            }}
          >
            Other Similar Options
          </StyledTitle>
        </Grid>
        <Grid item xs={12}>
          {itemList.length < 3 && !isMobile ? (
            <Grid container>
              {itemList.map((item) => (
                <Grid item xs={4} key={item.experienceId}>
                  <ExperiencesItem
                    key={item.experienceId}
                    data={item}
                    width="100%"
                    onToggleFavourite={onToggleFavouriteWithId}
                    experienceId={item.experienceId}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CarouselItemList itemToShow={isMobile ? 1.2 : 3}>
              {itemList.map((item) => (
                <ExperiencesItem
                  key={item.experienceId}
                  data={item}
                  width="100%"
                  onToggleFavourite={onToggleFavouriteWithId}
                  experienceId={item.experienceId}
                />
              ))}
            </CarouselItemList>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

YouMightAlsoLike.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onToggleFavouriteWithId: PropTypes.func.isRequired,
};

export default YouMightAlsoLike;
