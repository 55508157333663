import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";

function ModalImage({ imgPath }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <img
      src={imgPath}
      alt="modal-img"
      style={{
        width: isMobile ? "100%" : "150px",
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
      }}
    />
  );
}
ModalImage.defaultProps = {
  imgPath: "",
};

ModalImage.propTypes = {
  imgPath: PropTypes.string,
};

export default ModalImage;
