import { Box, Grid, styled, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  getProfile,
  profileSelector,
  uploadImage,
} from "../../../services/profile/profile-slice.service";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import UpcomingBookings from "../components/upcoming-bookings.component";
import {
  cancelBooking,
  getBookings,
  rateBooking,
} from "../../../services/booking/booking-slice.service";
import Bookings from "../components/bookings.component";
import CancelBookingPopUpModal from "../components/cancel-bookings-modal.component";
import FlexiUniqueModal from "../components/flexi-unique-code-modal.component";
import RateBookingModal from "../components/rate-booking-modal.component";
import Form from "../../../components/forms/form.component";
import DialogBox from "../../../components/notification/dialog-box.component";

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.secondary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const PrimaryColorText = styled(Typography)((props) => ({
  color: props.theme.palette.colors.text.primary,
}));

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: 8,
  right: 2,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.colors.text.white,
}));

const PADDINGY = 3;

const ratingValidationSchema = Yup.object().shape({
  rating: Yup.number().required().label("Rating"),
  comment: Yup.string().nullable().label("Comment"),
});

function BookingExperiences() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const [mainTab, setMainTab] = useState(0);
  const location = useLocation();
  const [currentSubTab, setCurrentSubTab] = useState("all");
  const [cancelItem, setCancelItem] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const [showMainTabCancelModal, setShowMainTabCancelModal] = useState(false);
  const [mainTabCancelItem, setMainTabCancelItem] = useState(false);
  const [showUnqiueCodeModal, setShowUniqueCodeModal] = useState(false);
  const [uniqueCode, setUniqueCode] = useState("");
  const [showRateModal, setShowRateModal] = useState(false);
  const [rateItem, setRateItem] = useState(null);
  const [showRateSubmittedDialog, setShowRateSubmittedDialog] = useState(false);
  const { profileObj } = useSelector(profileSelector);

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onBookingStatusChange = (status) => {
    dispatch(getBookings(status)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleCancelBooking = (bookingDetails) => {
    setCancelItem(bookingDetails);
    setShowCancelModal(true);
  };

  const onConfirmCancel = (bookingId) => {
    dispatch(cancelBooking(bookingId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getBookings(currentSubTab));
        dispatch(getProfile());
      }
      setShowCancelModal(false);
    });
  };

  const confirmMainTabCancel = (bookingId) => {
    let tabName = "";
    if (mainTab === 1) {
      tabName = "reschedule";
    } else if (mainTab === 2) {
      tabName = "completed";
    } else {
      tabName = "cancelled";
    }
    dispatch(cancelBooking(bookingId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowMainTabCancelModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getBookings(tabName));
        dispatch(getProfile());
      }
    });
  };

  const handleMainTabCancel = (bookingDetails) => {
    setMainTabCancelItem(bookingDetails);
    setShowMainTabCancelModal(true);
  };

  const handleChange = (event, newValue) => {
    setMainTab(newValue);
    if (newValue === 0) {
      onBookingStatusChange(currentSubTab);
    }
    if (newValue === 1) {
      onBookingStatusChange("reschedule");
    }
    if (newValue === 2) {
      onBookingStatusChange("completed");
    }
    if (newValue === 3) {
      onBookingStatusChange("cancelled");
    }
  };

  const submitRateBooking = (values) => {
    dispatch(rateBooking({ ...values, bookingId: rateItem.id })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
        if (meta.requestStatus === "fulfilled") {
          onBookingStatusChange("completed");
          setShowRateModal(false);
          setShowRateSubmittedDialog(true);
          dispatch(getProfile());
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
      },
    );
  };

  const handleRateBooking = (bookingDetails) => {
    setRateItem(bookingDetails);
    setShowRateModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfile());

    const bookingStatus = new URLSearchParams(location.search).get("status");
    if (bookingStatus) {
      switch (bookingStatus) {
        case "reschedule":
          handleChange(null, 1);
          break;
        default:
          handleChange(null, 0);
          break;
      }
    } else {
      dispatch(getBookings("all")).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, []);

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        paddingBottom: 5,
        backgroundColor: theme.palette.colors.bg.white,
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
      }}
    >
      {cancelItem && (
        <CancelBookingPopUpModal
          showModal={showCancelModal}
          setShowModal={setShowCancelModal}
          itemDetails={cancelItem}
          onConfirmDelete={onConfirmCancel}
        />
      )}
      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      <FlexiUniqueModal
        isShowModal={showUnqiueCodeModal}
        setIsShowModalModal={setShowUniqueCodeModal}
        uniqueCode={uniqueCode}
      />
      {mainTabCancelItem && (
        <CancelBookingPopUpModal
          showModal={showMainTabCancelModal}
          setShowModal={setShowMainTabCancelModal}
          itemDetails={mainTabCancelItem}
          onConfirmDelete={confirmMainTabCancel}
        />
      )}
      {showRateModal && (
        <Form
          initialValues={{ rating: 5, comment: "", photos: [] }}
          onSubmit={submitRateBooking}
          validationSchema={ratingValidationSchema}
        >
          <RateBookingModal
            showModal={showRateModal}
            setShowModal={setShowRateModal}
            itemDetails={rateItem}
          />
        </Form>
      )}
      <DialogBox
        isShow={showRateSubmittedDialog}
        setIsShowAlert={setShowRateSubmittedDialog}
        title="Review Submitted"
        message="Your review will be auto-approve after 3 days if no issues was found."
        buttonText="Confirm"
        isConfirmHandle={() => setShowRateSubmittedDialog(false)}
      />

      <Grid item xs={isMobile ? 12 : 8}>
        <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
          <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold} paddingTop={4}>
            My Experiences
          </PrimaryColorText>

          <Grid
            container
            spacing={1}
            sx={{
              paddingY: PADDINGY,
            }}
          >
            <AntTabs
              variant={isMobile ? "scrollable" : null}
              value={mainTab}
              onChange={handleChange}
            >
              <AntTab
                label={
                  <>
                    <Typography sx={{ paddingX: "10px" }}>Upcoming</Typography>
                    {profileObj.status === "succeeded" &&
                      profileObj.data.bookingAlert.upcoming > 0 && (
                        <RedDot>
                          <NotificationText>
                            {profileObj.data.bookingAlert.upcoming}
                          </NotificationText>
                        </RedDot>
                      )}
                  </>
                }
              />
              <AntTab
                label={
                  <>
                    <Typography>Reschedule</Typography>
                    {profileObj.status === "succeeded" &&
                      profileObj.data.bookingAlert.reschedule > 0 && (
                        <RedDot>
                          <NotificationText>
                            {profileObj.data.bookingAlert.reschedule}
                          </NotificationText>
                        </RedDot>
                      )}
                  </>
                }
              />
              <AntTab
                label={
                  <>
                    <Typography>Past</Typography>
                    {profileObj.status === "succeeded" &&
                      profileObj.data.bookingAlert.pendingReview > 0 && (
                        <RedDot>
                          <NotificationText>
                            {profileObj.data.bookingAlert.pendingReview}
                          </NotificationText>
                        </RedDot>
                      )}
                  </>
                }
              />
              <AntTab label={<Typography>Cancelled</Typography>} />
            </AntTabs>
          </Grid>
          {mainTab === 0 ? (
            <UpcomingBookings
              onBookingStatusChange={onBookingStatusChange}
              handleCancelBooking={handleCancelBooking}
              currentSubTab={currentSubTab}
              setCurrentSubTab={setCurrentSubTab}
              setUniqueCode={setUniqueCode}
              setShowUniqueCodeModal={setShowUniqueCodeModal}
            />
          ) : (
            <Bookings
              mainTab={mainTab}
              handleCancelBooking={handleMainTabCancel}
              handleRateBooking={handleRateBooking}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BookingExperiences;
