import React from "react";
import { Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

function BoxTitle({ title }) {
  const theme = useTheme();
  return (
    <Typography
      justifySelf="center"
      alignSelf="center"
      textAlign="center"
      variant="h5"
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        padding: 3,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {title}
    </Typography>
  );
}

BoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BoxTitle;
