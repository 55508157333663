import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Rating,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import BorderButton from "../../../components/button/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { bookingSelector } from "../../../services/booking/booking-slice.service";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "20px",
  width: isMobile ? "95%" : "60%",
  height: isMobile ? "90%" : "90%",
  outline: "none",
  overflow: "auto",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const DetailsBox = styled(Box)(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
}));

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const SuccessTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 10,
  marginBottom: 0,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "150px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.inputField,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: "5px",
  right: "7px",
  backgroundColor: theme.palette.colors.brand.white,
  width: "20px",
  height: "20px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const ratingLabels = {
  1: "Terrible",
  2: "Poor",
  3: "Fair",
  4: "Good",
  5: "Amazing",
};

function getRatingLabel(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${ratingLabels[value]}`;
}

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

function RateBookingModal({ showModal, setShowModal, itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { rateBookingObj } = useSelector(bookingSelector);
  const { setFieldValue, values } = useFormikContext();
  const createSnackBar = useContext(SnackbarContext);
  const [previewPhotos, setPreviewPhotos] = useState([]);

  const renderContent = () => (
    <Grid container>
      <Grid item xs={12} paddingTop={2}>
        <GreyText lineHeight="20px">Booking details</GreyText>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={isMobile ? 12 : 3} paddingTop={1}>
          <img
            src={itemDetails.order.item.experience.imgPath}
            alt="exp-img"
            style={{
              width: "100%",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 7} paddingTop={1}>
          <DetailsBox isMobile={isMobile}>
            <PrimaryColorText lineHeight="20px" fontSize={theme.fonts.fontSizes.size15}>
              {itemDetails.order.item.experience.title}
            </PrimaryColorText>
            <Spacer size="s" position="top" />
            {itemDetails.order.item.addOns.length > 0 && (
              <>
                <GreyColorText>Add Ons</GreyColorText>
                {itemDetails.order.item.addOns.map((addOn) => (
                  <PrimaryColorText key={addOn.id} fontSize={theme.fonts.fontSizes.size14}>
                    {addOn.quantity} x {addOn.label}
                  </PrimaryColorText>
                ))}
              </>
            )}
          </DetailsBox>
        </Grid>
      </Grid>
      <SeperateLine />
      <Grid item xs={12}>
        <Grid container paddingTop={1} alignItems="center">
          <Grid item xs={isMobile ? 2 : 1.2}>
            <PrimaryColorText>Rating:</PrimaryColorText>
          </Grid>
          <Grid item xs={10}>
            <Grid container alignItems="center">
              <Grid item paddingTop={0.5}>
                <Rating
                  getLabelText={getRatingLabel}
                  name="rating"
                  size="large"
                  value={values.rating}
                  onChange={(event, newValue) => {
                    setFieldValue("rating", newValue);
                  }}
                />
              </Grid>
              <Grid item>
                <Box sx={{ ml: 2 }}>{ratingLabels[values.rating]}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingTop={1}>
        <PrimaryColorText>Please leave a comment here:</PrimaryColorText>
        <StyledTextField
          name="comment"
          placeholder="Share some thoughts on the experience to help other buyers"
          onChange={(e) => {
            setFieldValue("comment", e.target.value);
          }}
        />
      </Grid>
      {previewPhotos.length < 6 && (
        <Grid item xs={12} paddingTop={1}>
          <Button
            component="label"
            variant="outlined"
            width="150px"
            startIcon={<AddPhotoAlternateIcon />}
            color="brandColor"
            sx={{ textTransform: "none" }}
          >
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                const { files } = e.target;
                if (files) {
                  const myFile = files[0]; // single file upload only
                  const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
                  const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
                  if (myFile && withinFileSizeLimit) {
                    const fileReader = new FileReader(); // to preview the image
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        const img = new Image();
                        img.src = URL.createObjectURL(myFile);
                        const canvas = document.createElement("canvas");
                        canvas.width = 1920;
                        canvas.height = 1080;
                        const ctx = canvas.getContext("2d");

                        img.onload = () => {
                          const scaleFactor = Math.min(
                            canvas.width / img.width,
                            canvas.height / img.height,
                          );

                          const newWidth = img.width * scaleFactor;
                          const newHeight = img.height * scaleFactor;

                          const x = canvas.width / 2 - newWidth / 2;
                          const y = canvas.height / 2 - newHeight / 2;

                          ctx.drawImage(img, x, y, newWidth, newHeight);

                          const dataURL = canvas.toDataURL("image/webp");
                          setFieldValue("photos", values.photos.concat(dataURL));
                          const newPreviewPhotos = [...previewPhotos];
                          newPreviewPhotos.push(dataURL);
                          setPreviewPhotos(newPreviewPhotos);
                        };
                      }
                    };
                    fileReader.readAsDataURL(myFile);
                  } else if (!withinFileSizeLimit) {
                    createSnackBar({
                      message: "Image file cannot be more than 5mb",
                      type: "error",
                      open: true,
                    });
                  } else if (!allowedFileType) {
                    createSnackBar({
                      message: `Only ${SUPPORTED_FORMATS} are allowed`,
                      type: "error",
                      open: true,
                    });
                  }
                }
              }}
            />
            <BrandColorText>Add photos</BrandColorText>
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container paddingTop={2}>
          {previewPhotos.map((photo, index) => (
            <Grid item xs={isMobile ? 4 : 2} paddingRight={1} key={uuidv4()} position="relative">
              <img
                src={photo}
                alt="reviewImage"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
              <StyledButtonIcon
                onClick={() => {
                  setFieldValue(
                    "photos",
                    values.photos.filter((item, i) => i !== index),
                  );
                  const newPreviewPhotos = previewPhotos.filter((item, i) => i !== index);

                  setPreviewPhotos(newPreviewPhotos);
                }}
              >
                <CloseIcon sx={{ height: "15px", width: "15px" }} />
              </StyledButtonIcon>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <SeperateLine />
    </Grid>
  );

  const renderConfirmButton = () => (
    <Grid item xs={isMobile ? 12 : 2}>
      <ButtonBox>
        <Spacer size="s" position={isMobile ? "top" : "left"} />
        <FormSubmitButton isLoading={rateBookingObj.status === "pending"}>
          <Typography>Confirm</Typography>
        </FormSubmitButton>
      </ButtonBox>
    </Grid>
  );

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <TopTitleBox>
          <SuccessTitleBox>
            <PrimaryColorText variant="h5">Rate Booking</PrimaryColorText>
          </SuccessTitleBox>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon sx={{ height: "25px", width: "25px" }} />
          </CloseIconButton>
        </TopTitleBox>

        {renderContent()}
        <Spacer size="l" postion="top" />
        <Grid container justifyContent="flex-end">
          <Grid item xs={isMobile ? 12 : 2}>
            <ButtonBox>
              <Spacer size="m" position={isMobile ? "top" : "left"} />
              <BorderButton
                handleSubmit={() => {
                  setShowModal(false);
                }}
                isLoading={rateBookingObj.status === "pending"}
              >
                <BrandColorText>Cancel</BrandColorText>
              </BorderButton>
            </ButtonBox>
          </Grid>
          <Spacer size="m" position="left" />
          {renderConfirmButton()}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

RateBookingModal.defaultProps = {
  itemDetails: {
    id: null,
    status: "",
    noOfPax: null,
    slot: {
      checkIn: {
        date: "",
        time: "",
      },
      checkOut: {
        date: "",
        time: "",
      },
      duration: "",
      isFlexi: {
        status: false,
        code: "",
      },
    },
    cancelled_at: "",
    order: {
      id: null,
      item: {
        id: null,
        price: "",
        pricePerItem: "",
        quantity: 1,
        experience: {
          id: 0,
          title: "",
          state: "",
          ratings: 0,
          noReviews: 0,
          imgPath: "",
        },
        recipient: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        addOns: [
          {
            id: "",
            label: "",
            sublabel: "",
            quantity: null,
            pricePerAddOn: "",
            price: "",
          },
        ],
        isGift: false,
        gift: { message: "" },
      },
    },
    guest: {
      name: "",
      email: "",
      phoneNumber: "",
    },
    notes: "",
  },
};

RateBookingModal.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    noOfPax: PropTypes.number,
    slot: PropTypes.shape({
      checkIn: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      checkOut: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      duration: PropTypes.string,
      isFlexi: PropTypes.shape({
        status: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
    cancelled_at: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        subtotal: PropTypes.string,
        discount: PropTypes.string,
        pricePerItem: PropTypes.string,
        quantity: PropTypes.number,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          state: PropTypes.string,
          ratings: PropTypes.number,
          noReviews: PropTypes.number,
          imgPath: PropTypes.string,
        }),
        recipient: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        addOns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            sublabel: PropTypes.string,
            quantity: PropTypes.number,
            pricePerAddOn: PropTypes.string,
            price: PropTypes.string,
          }),
        ),
        isGift: PropTypes.bool,
        gift: PropTypes.shape({ message: PropTypes.string }),
      }),
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    notes: PropTypes.string,
  }),
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default RateBookingModal;
