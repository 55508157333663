import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import PaddedView from "../../../components/utils/padded-view.component";
import { creditSelector } from "../../../services/credits/credit-slice.service";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "550px",
  outline: "none",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: 0,
  top: "-10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
  paddingBottom: "20px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

function TransactionDetails({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getCreditTransactionDetailsObj } = useSelector(creditSelector);

  const getPrice = (price) => {
    let tempPrice = "";
    if (price.includes("-")) {
      tempPrice = `- RM ${price.substring(1)}`;
    } else {
      tempPrice = `+ RM ${price}`;
    }
    return tempPrice;
  };

  const getTransactionType = () => {
    if (getCreditTransactionDetailsObj.data.type === "credit") {
      return <PrimaryColorText>Purchase Details</PrimaryColorText>;
    }
    if (getCreditTransactionDetailsObj.data.type === "point_conversion") {
      return <PrimaryColorText>Points Conversion</PrimaryColorText>;
    }
    if (getCreditTransactionDetailsObj.data.type === "top_up") {
      return <PrimaryColorText>Top Up Details</PrimaryColorText>;
    }
    return <PrimaryColorText>Refund Details</PrimaryColorText>;
  };

  const getDescriptions = () => {
    if (getCreditTransactionDetailsObj.data.experiences === null) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              Descriptions
            </PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <PrimaryColorText>{getCreditTransactionDetailsObj.data.description}</PrimaryColorText>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
            Experiences
          </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {getCreditTransactionDetailsObj.data.experiences.map((item) => (
              <Grid item xs={12} key={item.id}>
                <PrimaryColorText>{item.title}</PrimaryColorText>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (getCreditTransactionDetailsObj.status === "succeeded") {
      return (
        <ModalBox isMobile={isMobile}>
          <TopTitleBox>
            <Grid container justifyContent="center">
              <TitleText variant="h5">Transaction Details</TitleText>
            </Grid>
            <CloseIconButton
              onClick={() => {
                setShowModal(false);
              }}
            >
              <StyledCloseIcon />
            </CloseIconButton>
          </TopTitleBox>
          <SeperateLine />
          <Spacer size="l" position="top" />
          <Grid container>
            <Grid item xs={12}>
              {getTransactionType()}
            </Grid>
          </Grid>
          <Spacer size="m" position="top" />
          <Grid
            container
            sx={{ position: "relative", justifyContent: "center", alignItems: "center" }}
            spacing={2}
          >
            <Grid item xs={12}>
              <CardContainer>
                <PaddedView multiples={isMobile ? 2 : 3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryColorText variant="h4">
                      {getPrice(getCreditTransactionDetailsObj.data.transacted)}
                    </PrimaryColorText>
                  </Box>
                </PaddedView>
              </CardContainer>
              <Spacer size="m" position="top" />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CenterRowBox>
                    <PrimaryColorText>Completed Time</PrimaryColorText>
                    <PrimaryColorText>
                      {getCreditTransactionDetailsObj.data.createdAt}
                    </PrimaryColorText>
                  </CenterRowBox>
                </Grid>
                <Grid item xs={12}>
                  <CenterRowBox>
                    <PrimaryColorText>Payment Method</PrimaryColorText>
                    <PrimaryColorText sx={{ textAlign: "end", minWidth: "200px" }}>
                      {getCreditTransactionDetailsObj.data.type === "top_up"
                        ? "Online Banking / e-Wallet"
                        : "Account Credit"}
                    </PrimaryColorText>
                  </CenterRowBox>
                </Grid>
                <Grid item xs={12}>
                  <CenterRowBox>
                    <PrimaryColorText>Transaction ID</PrimaryColorText>
                    <PrimaryColorText>{getCreditTransactionDetailsObj.data.id}</PrimaryColorText>
                  </CenterRowBox>
                </Grid>
              </Grid>
              <Spacer size="l" position="top" />
              <SeperateLine />
              <Spacer size="l" position="top" />
              {getDescriptions()}
            </Grid>
          </Grid>
        </ModalBox>
      );
    }
    return (
      <ModalBox isMobile={isMobile}>
        <TopTitleBox>
          <Grid container justifyContent="center">
            <TitleText variant="h5">Transaction Details</TitleText>
          </Grid>
          <CloseIconButton
            onClick={() => {
              setShowModal(false);
            }}
          >
            <StyledCloseIcon />
          </CloseIconButton>
        </TopTitleBox>
        <SeperateLine />
        <Spacer size="l" position="top" />
        <Grid container>
          <Grid item xs={12}>
            <CustomSkeleton width="100px" height="20px" />
          </Grid>
        </Grid>
        <Spacer size="m" position="top" />
        <Grid
          container
          sx={{ position: "relative", justifyContent: "center", alignItems: "center" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CardContainer>
              <PaddedView multiples={isMobile ? 2 : 3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <CustomSkeleton width="200px" height="40px" />
                </Box>
              </PaddedView>
            </CardContainer>
            <Spacer size="m" position="top" />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CenterRowBox>
                  <CustomSkeleton width="100px" height="25px" />
                  <CustomSkeleton width="100px" height="25px" />
                </CenterRowBox>
              </Grid>
              <Grid item xs={12}>
                <CenterRowBox>
                  <CustomSkeleton width="100px" height="25px" />
                  <CustomSkeleton width="100px" height="25px" />
                </CenterRowBox>
              </Grid>
              <Grid item xs={12}>
                <CenterRowBox>
                  <CustomSkeleton width="100px" height="25px" />
                  <CustomSkeleton width="100px" height="25px" />
                </CenterRowBox>
              </Grid>
            </Grid>
            <Spacer size="l" position="top" />
            <SeperateLine />
            <Spacer size="l" position="top" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  <CustomSkeleton width="100px" height="25px" />
                </PrimaryColorText>
              </Grid>
              <Grid item xs={12}>
                <PrimaryColorText>
                  <CustomSkeleton width="100px" height="25px" />
                </PrimaryColorText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalBox>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      {renderContent()}
    </Modal>
  );
}

TransactionDetails.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default TransactionDetails;
