import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import Spacer from "../../../../components/utils/spacer.component";

const StyledButton = styled(Button)(({ theme }) => ({
  height: "40px",
  boxShadow: 0,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  border: "1px solid",
  paddingLeft: "0px",
  paddingRight: "0px",
  "&:hover": {
    boxShadow: "none",
  },
}));

const InputFieldBox = styled(Box)(({ theme }) => ({
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: theme.palette.colors.bg.white,
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.primary,
      },
    },
  },
}));
function TopUpAmountField() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const showError = touched.amount && typeof errors.amount === "string";

  return (
    <Grid container spacing={isMobile ? 1 : 2} justifyContent="space-between">
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            borderColor:
              values.amount === 50
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 50)}
        >
          <Typography
            sx={{
              color:
                values.amount === 50
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.primary,
            }}
          >
            RM50
          </Typography>
        </StyledButton>
      </Grid>
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            borderColor:
              values.amount === 100
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 100)}
        >
          <Typography
            sx={{
              color:
                values.amount === 100
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.primary,
            }}
          >
            RM100
          </Typography>
        </StyledButton>
      </Grid>
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            borderColor:
              values.amount === 150
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 150)}
        >
          <Typography
            sx={{
              color:
                values.amount === 150
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.primary,
            }}
          >
            RM150
          </Typography>
        </StyledButton>
      </Grid>
      <Spacer size="xxl" position="top" />
      <Grid item xs={12}>
        <InputFieldBox>
          <InputLabel sx={{ color: theme.palette.colors.text.primary }}>Top Up Amount</InputLabel>
          <Spacer size="s" position="top" />
          <TextField
            error={showError}
            variant="outlined"
            helperText={showError ? errors.amount : null}
            onBlur={() => setFieldTouched("amount")}
            name="amount"
            type="amount"
            placeholder="Top Up Amount"
            onChange={(e) => {
              setFieldValue("amount", e.target.value.replace(".", ""));
            }}
            value={values.amount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Spacer position="right" />
                  <Typography>RM</Typography>
                  <Spacer position="right" />
                </InputAdornment>
              ),
            }}
          />
          <Spacer size="xs" position="top" />
          <Typography variant="body2" sx={{ color: theme.palette.colors.text.placeholder }}>
            (Min reload amount RM5)
          </Typography>
        </InputFieldBox>
      </Grid>
    </Grid>
  );
}

export default TopUpAmountField;
