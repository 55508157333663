import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";

function CustomSkeleton({ width, height, borderRadius }) {
  const theme = useTheme();
  return (
    <Skeleton
      width={width}
      height={height}
      borderRadius={borderRadius}
      baseColor={theme.palette.colors.loading.imageBg}
    />
  );
}

CustomSkeleton.defaultProps = {
  width: "100%",
  height: "100%",
  borderRadius: 0,
};

CustomSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.number,
};

export default CustomSkeleton;
