import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import RowLoader from "./row-loader.component";

function UpdateFormLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={isMobile ? 11 : 6} sx={{ minHeight: "90vh" }}>
      <Grid container sx={{ paddingLeft: "35px" }}>
        <Typography variant="h4" fontWeight={theme.fonts.fontWeights.bold} paddingTop={4}>
          Profile
        </Typography>
        <RowLoader label="Name" />
        <RowLoader label="Gender" />
        <RowLoader label="Date of Birth" />
        <RowLoader label="Email" />
        <RowLoader label="Phone Number" />
        <RowLoader label="Password" />
      </Grid>
    </Grid>
  );
}

export default UpdateFormLoader;
