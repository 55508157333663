import React, { useRef, useState } from "react";
import { Box, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  guestSelector,
  guestToggleFavourite,
} from "../../../../services/guest/guest-slice.service";
import { isLogin } from "../../../../infrastructure/utils";
import Spacer from "../../../../components/utils/spacer.component";
import routes from "../../../../components/navigation/routes";
import ExperienceImageCarousel from "../../../../components/experience/experience-image-carousel.component";

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size14,
  color: theme.palette.colors.brand.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "22px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflowWrap: "anywhere",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const StyledPriceText = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.colors.brand.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledStateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
}));

const StyledBottomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const StyledExperienceDetailsBox = styled(Box)({
  width: "100%",
  "& .MuiButton-root": {
    textTransform: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: "3px",
  right: "10px",
  padding: "0px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.body,
  color: theme.palette.colors.brand.primary,
  marginTop: "-3px",
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

function ExperiencesItem({ data, onToggleFavourite, isShowFavourite, experienceId }) {
  const theme = useTheme();
  const imageHeight = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { guestFavouriteList } = useSelector(guestSelector);
  const [isFavourite, setIsFavourite] = useState(
    isLogin()
      ? data.favourited
      : guestFavouriteList.some((item) => item.experienceId === experienceId),
  );

  const onFavouriteClick = () => {
    setIsFavourite(!isFavourite);
    if (onToggleFavourite !== null) {
      if (!isLogin()) {
        dispatch(guestToggleFavourite({ experienceId, addOns: [], quantity: 1 }));
        return;
      }
      if (isFavourite) {
        onToggleFavourite({ experienceId }, false);
      } else {
        onToggleFavourite({ experienceId }, true);
      }
    }
  };

  const getNoOfPax = () => {
    if (data.maxNoPax === data.minNoPax) {
      if (data.maxNoPax === 1) {
        return "For 1 person";
      }
      return `Up to ${data.maxNoPax} persons`;
    }
    return `For ${data.minNoPax} - ${data.maxNoPax} persons`;
  };

  const renderRating = () => (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
      {data.shoutOut ? (
        <ReviewGreyText>{data.shoutOut}</ReviewGreyText>
      ) : (
        <ReviewGreyText>{getNoOfPax()}</ReviewGreyText>
      )}
      <StyledRatingBox>
        <StyledRatingStar />
        <Spacer size="xs" position="left" />
        <PrimaryColorText>{data.ratings}</PrimaryColorText>
        <Spacer size="xs" position="left" />
        <ReviewGreyText>{`(${data.noReviews})`}</ReviewGreyText>
      </StyledRatingBox>
    </Box>
  );

  const renderFavouriteIcon = () => {
    if (isFavourite) {
      return (
        <StyledIconButton onClick={() => onFavouriteClick()}>
          <FavoriteIcon sx={{ color: theme.palette.colors.brand.primary }} />
        </StyledIconButton>
      );
    }
    return (
      <StyledIconButton onClick={() => onFavouriteClick()}>
        <FavoriteBorderIcon sx={{ color: theme.palette.colors.brand.secondary }} />
      </StyledIconButton>
    );
  };

  const renderPrice = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
      <StyledStateText>From</StyledStateText>
      <Spacer size="xs" position="left" />
      <StyledPriceText>{`RM${data.price}`}</StyledPriceText>
      <Spacer size="xs" position="left" />
    </Box>
  );

  return (
    <StyledBox
      sx={{
        padding: isMobile
          ? theme.dimensions.MobileExperiencesPadding
          : theme.dimensions.PCExperiencesPadding,
      }}
    >
      <Box sx={{ position: "relative" }}>
        {isShowFavourite && renderFavouriteIcon()}
        <Link
          style={{ textDecoration: "none", width: "100%" }}
          to={{
            pathname: routes.EXPERIENCESDETAILS,
            search: `experienceId=${experienceId}`,
          }}
        >
          <Box sx={{ width: "100%" }} ref={imageHeight}>
            <ExperienceImageCarousel imageList={data.imagePath} />
          </Box>

          <StyledBottomBox>
            <StyledExperienceDetailsBox>
              {renderRating()}
              <StyledTitle>{data.title}</StyledTitle>

              {data.state !== "" && <StyledStateText>{data.state}</StyledStateText>}
            </StyledExperienceDetailsBox>
            <Spacer size="s" position="top" />
            {renderPrice()}
          </StyledBottomBox>
        </Link>
      </Box>
    </StyledBox>
  );
}

ExperiencesItem.defaultProps = {
  onToggleFavourite: null,
  experienceId: null,
  data: {
    shoutOut: "",
    minNoPax: 1,
    maxNoPax: 1,
    state: "",
    experienceId: null,
    title: "",
    price: "",
    ratings: 0,
    noReviews: 0,
    coverImage: "",
    favourited: false,
    imagePath: [],
  },
  isShowFavourite: true,
};

ExperiencesItem.propTypes = {
  experienceId: PropTypes.number,
  onToggleFavourite: PropTypes.func,
  data: PropTypes.shape({
    shoutOut: PropTypes.string,
    minNoPax: PropTypes.number,
    maxNoPax: PropTypes.number,
    state: PropTypes.string,
    experienceId: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
    coverImage: PropTypes.string,
    favourited: PropTypes.bool,
    imagePath: PropTypes.arrayOf(PropTypes.string),
  }),
  isShowFavourite: PropTypes.bool,
};

export default ExperiencesItem;
