import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { creatorLinkPreview, creatorSelector } from "../../../services/creator/creator-slice.service";
import DetailsLoader from "../../experiences-details/loader/details-loader.component";

const TitleText = styled(Typography)(({ theme }) => ({
    fontWeight: theme.fonts.fontWeights.bold,
    color: theme.palette.colors.text.primary,
    fontSize: theme.fonts.fontSizes.size24,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.colors.text.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.colors.ui.border,
}));

export default function CreatorLinkClickScreen(props) {
    const constProps = props;
    const creatorLink = constProps.match.params.id;
    const history = useHistory();
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const theme = useTheme();
    const { creatorLinkPreviewObj } = useSelector(creatorSelector);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                const ipAddress = data.ip;
                // Send the IP address to your API
                dispatch(creatorLinkPreview({ creatorLink, ip: ipAddress })).then(({ meta }) => {
                    if (meta.requestStatus === "rejected") {
                        createSnackBar({
                            message: "creator link invalid",
                            type: "error",
                            open: true,
                        });
                    }
                });
            } catch (error) {
                createSnackBar({
                    message: error,
                    type: "error",
                    open: true,
                });
            }
        };
        // Call the function on component mount
        fetchIPAddress();
    }, []);

    const renderContent = () => {
        if (creatorLinkPreviewObj.status === "succeeded") {
            return (
                <Grid container spacing={2}>
                    {history.push(`${routes.EXPERIENCESDETAILS}?experienceId=${creatorLinkPreviewObj.data.experience}`)}
                </Grid>
            );
        }
        // return <CreatorLinkPreviewLoader />;
        return <DetailsLoader />;
    };

    return (
        <Grid
            container
            overflow={isMobile ? "hidden" : null}
            paddingBottom={isMobile ? "180px" : "0px"}
        >
            <Grid item xs={12}>
                <Spacer size="xl" position="top" />
            </Grid>
            {renderContent()}
        </Grid>
    );
}
