import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
  marginTop: "15px",
  marginBottom: "10px",
}));

const SpaceBetweenRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
  flex: 1,
}));

function ExperiencesCardLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderExperiencesDetail = () => {
    if (isMobile) {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomSkeleton height="80px" width="130px" />
          <Spacer size="m" position="left" />
          <Box>
            <CustomSkeleton height="25px" width="100px" />
            <CustomSkeleton height="20px" width="100px" />
            <CustomSkeleton height="20px" width="100px" />
          </Box>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomSkeleton height="100px" width="150px" />
        <Spacer size="m" position="left" />

        <Box>
          <CustomSkeleton height="30px" width="125px" />
          <CustomSkeleton height="25px" width="125px" />
          <Spacer size="m" position="top" />
          <CustomSkeleton height="25px" width="125px" />
        </Box>
      </Box>
    );
  };

  const renderOrderDetails = () => {
    if (isMobile) {
      return (
        <SpaceBetweenRow>
          <CustomSkeleton height="20px" width="100px" />
          <CustomSkeleton height="20px" width="100px" />
        </SpaceBetweenRow>
      );
    }
    return (
      <SpaceBetweenRow
        sx={{
          alignItems: "center",
        }}
      >
        <CustomSkeleton height="25px" width="125px" />
        <CustomSkeleton height="25px" width="125px" />
      </SpaceBetweenRow>
    );
  };

  return (
    <CardContainer>
      {renderExperiencesDetail()}
      <SeperateLine />
      {renderOrderDetails()}
    </CardContainer>
  );
}

export default ExperiencesCardLoader;
