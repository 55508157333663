import React from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

function UpdateEmailForm({
  email,
  setIsEditing,
  isEditing,
  handleSubmit,
  isEmailEdit,
  setIsEmailEdit,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
  });
  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsEmailEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsEmailEdit(false);
  };

  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Email</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isEmailEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isEmailEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                email,
              }}
              onSubmit={handleFormSubmit}
            >
              <Grid container>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Email
                  </Typography>
                  <FormFieldText width="75%" name="email" placeholder="Enter your email address" />
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          ) : (
            <Typography color={theme.palette.colors.text.secondary}>
              {email === "" ? "Not Provided" : email}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdateEmailForm.propTypes = {
  email: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEmailEdit: PropTypes.bool.isRequired,
  setIsEmailEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdateEmailForm;
