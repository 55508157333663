import React, { useState } from "react";
import { Box, Grid, IconButton, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ReactStars from "react-rating-stars-component";
import routes from "../../../../components/navigation/routes";
import Spacer from "../../../../components/utils/spacer.component";
import NumberUpDownArrowField from "../../../../components/forms/quantity-field.component";
import { isLogin } from "../../../utils";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.colors.text.secondary,
  padding: "0px",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  lineHeight: "18px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflowWrap: "anywhere",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size13,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "-5px",
});

function MiniCartModalExperienceCard({ itemDetails, onToggleDelete, onQuantityUpdate }) {
  const theme = useTheme();
  const [quantity, setQuantity] = useState(
    isLogin() ? itemDetails.quantity : itemDetails.experience.quantity,
  );

  const onQuantityChange = (values) => {
    setQuantity(values);
    if (Number.isNaN(values)) {
      return;
    }

    if (!isLogin()) {
      onQuantityUpdate({
        experienceId: itemDetails.experience.id,
        quantity: values,
        isFlexi: itemDetails.experience.hasFlexi.status,
        uniqueId: `${itemDetails.experience.id}${itemDetails.experience.hasFlexi.status}`,
      });
      return;
    }
    onQuantityUpdate({ id: itemDetails.id, quantity: values, addOns: itemDetails.addOns });
  };

  const getAddOns = () => {
    const addOnsString = [];
    if (!isLogin()) {
      itemDetails.experience.addOns.map((item) =>
        addOnsString.push(
          `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${(
            parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity
          ).toFixed(2)}`,
        ),
      );
    } else {
      itemDetails.addOns.map((item) =>
        addOnsString.push(
          `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
        ),
      );
    }

    return addOnsString;
  };

  const getPrice = () => {
    if (isLogin()) {
      let price = 0;
      itemDetails.addOns.forEach((item) => {
        price += parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity;
      });
      price += parseFloat(
        Number.isNaN(quantity)
          ? parseFloat(itemDetails.pricePerItem.replace(",", ""))
          : parseFloat(quantity * itemDetails.pricePerItem.replace(",", "")).toFixed(2),
      );
      return price.toFixed(2);
    }
    let price = 0;
    itemDetails.experience.addOns.forEach((item) => {
      price += parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity;
    });
    price += parseFloat(
      Number.isNaN(quantity)
        ? parseFloat(itemDetails.experience.pricePerItem.replace(",", ""))
        : parseFloat(quantity * itemDetails.experience.pricePerItem.replace(",", "")).toFixed(2),
    );
    return price.toFixed(2);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={itemDetails.experience.imgPath}
            alt="Categories"
            style={{
              width: "130px",
              borderRadius: theme.shape.borderRadius[0],
            }}
          />
          <Spacer size="m" position="left" />
          <Box sx={{ width: "100%" }}>
            <SpaceBetweenBox>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: routes.EXPERIENCESDETAILS,
                  search: `experienceId=${itemDetails.experience.id}`,
                }}
              >
                <TitleText>{itemDetails.experience.title}</TitleText>
              </Link>
              <CloseIconButton
                onClick={() =>
                  onToggleDelete(
                    itemDetails,
                    quantity,
                    isLogin() ? itemDetails.id : itemDetails.uniqueId,
                  )
                }
              >
                <CloseIcon />
              </CloseIconButton>
            </SpaceBetweenBox>
            <GreyText>{itemDetails.experience.state}</GreyText>
            <StyledRatingBox>
              <ReactStars
                activeColor={theme.palette.colors.ui.star}
                size={13}
                edit={false}
                value={parseFloat(itemDetails.experience.ratings)}
                color={theme.palette.colors.ui.border}
                isHalf={true}
              />
              <Spacer size="xs" position="left" />
              <GreyText>{`${itemDetails.experience.ratings} (${itemDetails.experience.noReviews})`}</GreyText>
            </StyledRatingBox>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {getAddOns().length > 0 && (
          <Grid item xs={12}>
            <GreyText>Add On(s)</GreyText>
            {getAddOns().map((item) => (
              <GreyText key={item}>{item}</GreyText>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Box>
            <PrimaryColorText>Each</PrimaryColorText>
            <BoldPrimaryColorText>
              RM{isLogin() ? itemDetails.pricePerItem : itemDetails.experience.pricePerItem}
            </BoldPrimaryColorText>
          </Box>
          <Box>
            <PrimaryColorText>Qty</PrimaryColorText>
            <NumberUpDownArrowField
              maxNoPax={itemDetails.experience.maxNoPax}
              minNoPax={itemDetails.experience.minNoPax}
              value={quantity}
              setValue={onQuantityChange}
            />
          </Box>
          <Box>
            <PrimaryColorText>Total</PrimaryColorText>
            <BoldPrimaryColorText>{`RM ${getPrice()}`}</BoldPrimaryColorText>
          </Box>
        </SpaceBetweenBox>
      </Grid>
    </Grid>
  );
}

MiniCartModalExperienceCard.defaultProps = {
  itemDetails: {
    uniqueId: "",
    id: null,
    experience: {
      id: null,
      title: "",
      state: "",
      ratings: null,
      noReviews: null,
      imgPath: "",
      minNoPax: null,
      maxNoPax: null,
      pricePerItem: "",
      hasFlexi: {
        status: false,
        available: null,
        massSlotId: null,
      },
    },
    isFlexi: false,
    quantity: PropTypes.number,
    pricePerItem: "",
    price: "",
    addOns: [],
  },
};

MiniCartModalExperienceCard.propTypes = {
  onQuantityUpdate: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    uniqueId: PropTypes.string,
    id: PropTypes.number,
    experience: PropTypes.shape({
      addOns: PropTypes.arrayOf(PropTypes.shape({})),
      pricePerItem: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
      state: PropTypes.string,
      ratings: PropTypes.number,
      noReviews: PropTypes.number,
      imgPath: PropTypes.string,
      minNoPax: PropTypes.number,
      maxNoPax: PropTypes.number,
      hasFlexi: PropTypes.shape({
        status: PropTypes.bool,
        available: PropTypes.number,
        massSlotId: PropTypes.number,
      }),
      quantity: PropTypes.number,
    }),
    isFlexi: PropTypes.bool,
    quantity: PropTypes.number,
    pricePerItem: PropTypes.string,
    price: PropTypes.string,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default MiniCartModalExperienceCard;
