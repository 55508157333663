import React from "react";
import { Box, styled } from "@mui/material";
import PropTypes from "prop-types";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const StyledArrowIconBox = styled(Box)(({ theme, disabled }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "12px",
  },
  "&:hover": {
    color: disabled ? theme.palette.colors.text.primary : theme.palette.colors.brand.primary,
  },
  color: theme.palette.colors.text.primary,
  backgroundColor: theme.palette.colors.brand.white,
  opacity: disabled ? 0.5 : 1,
  borderRadius: theme.shape.borderRadius[4],
  height: "30px",
  width: "30px",
  top: "-30px",
  boxShadow: "1px 2px 3px 1px #9E9E9E",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
}));

function PrevArrow(arrowProps) {
  const { onClick, currentSlide } = arrowProps;
  return (
    <StyledArrowIconBox onClick={onClick} disabled={currentSlide === 0} sx={{ right: "55px" }}>
      <ArrowBackIosNewIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

function NextArrow(arrowProps) {
  const { onClick, className } = arrowProps;
  return (
    <StyledArrowIconBox
      onClick={onClick}
      disabled={className.includes("slick-disabled")}
      sx={{ right: "15px" }}
    >
      <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

function CarouselItemList({ children, itemToShow }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: itemToShow,
          slidesToScroll: itemToShow,
        },
      },
      {
        breakpoint: 620,
        settings: {
          arrows: false,
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
}

CarouselItemList.defaultProps = {
  itemToShow: 4,
};

CarouselItemList.propTypes = {
  itemToShow: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CarouselItemList;
