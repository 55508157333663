import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledBox = styled(Button)(({ theme }) => ({
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  height: "56px",
  background: theme.palette.colors.brand.primary,
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledInputBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: theme.palette.colors.bg.white,
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.primary,
      },
    },
  },
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function PromoCodeField() {
  const theme = useTheme();
  const { setFieldValue, values, setFieldTouched, handleSubmit, touched, errors } =
    useFormikContext();
  const showError = touched.promoCode && typeof errors.promoCode === "string";
  return (
    <StyledContainer>
      <PaddedView multiples={2}>
        <GreyText>Enter your code to redeem</GreyText>
        <Spacer position="top" size="s" />
        <StyledInputBox>
          <TextField
            variant="outlined"
            error={showError}
            helperText={showError ? errors.promoCode : null}
            onBlur={() => setFieldTouched("promoCode")}
            name="promoCode"
            onChange={(e) => {
              setFieldValue("promoCode", e.target.value.toUpperCase());
            }}
            value={values.promoCode.toUpperCase()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledBox onClick={handleSubmit}>
                    <Typography sx={{ color: theme.palette.colors.text.white }}>Redeem</Typography>
                  </StyledBox>
                </InputAdornment>
              ),
            }}
            placeholder="Enter Code"
          />
        </StyledInputBox>
        <Spacer position="top" size="m" />
      </PaddedView>
    </StyledContainer>
  );
}

export default PromoCodeField;
