import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

function ExperienceBannerLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: "100%" }}>
      <CustomSkeleton
        width="100%"
        height={isMobile ? "350px" : "500px"}
        borderRadius={theme.shape.borderRadius[0]}
      />
      ;
    </Box>
  );
}

export default ExperienceBannerLoader;
