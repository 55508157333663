import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import routes from "../../../components/navigation/routes";
import ExperiencesItemList from "../component/experiences-listing/experiences-item-list.component";
import ExperiencesItemListLoader from "../component/experiences-listing/experiences-item-list-loader.component";
import FilterBar from "../component/filter-bar/filter-bar.component";
import MobileFilterBar from "../component/mobile-filter-bar/mobile-filter-bar.component";
import {
  experienceSelector,
  getSearchExperiences,
} from "../../../services/experiences/experience-slice.service";
import {
  createFavourite,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import BestSeller from "../../dashboard/components/best-seller/best-seller.component";
import {
  getBestSeller,
  marketingSelector,
} from "../../../services/marketing/marketing-slice.service";
import Form from "../../../components/forms/form.component";
import CarouselExperienceLoader from "../../dashboard/components/loader/carousel-experience-loader.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const BestSellerBox = styled(Box)({
  marginTop: "50px",
  marginBottom: "50px",
  width: "100%",
});

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const validationSchema = Yup.object().shape({
  prule: Yup.string().nullable(),
  pmin: Yup.string().nullable(),
  pmax: Yup.string().nullable(),
  ratings: Yup.array().of(Yup.string()).nullable(),
});

function ExploreScreen() {
  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { experiencesListObj } = useSelector(experienceSelector);
  const { bestSellerObj } = useSelector(marketingSelector);
  const [page, setPage] = useState(1);
  const createSnackBar = useContext(SnackbarContext);

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(getSearchExperiences({ params: location.search, page: value })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get data! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleFilter = (value) => {
    setPage(1);
    history.push({ pathname: routes.EXPERIENCES_EXPLORE, search: value });
  };

  const onToggleFavourite = (values, isAddFavourite) => {
    if (isAddFavourite) {
      dispatch(createFavourite([values])).then(({ meta, error }) => {
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite(values)).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSearchExperiences({ params: location.search, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getBestSeller()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get best seller data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  }, [location.search]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("pmin", "");
      formRef.current.setFieldValue("pmax", "");
      formRef.current.setFieldValue("prule", "");
      formRef.current.setFieldValue("ratings", []);
    }
  }, [new URLSearchParams(location.search).get("type")]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const renderBestSeller = () => {
    if (bestSellerObj.status === "succeeded") {
      return (
        <BestSellerBox>
          <BestSeller itemList={bestSellerObj.data} onToggleFavourite={onToggleFavourite} />
        </BestSellerBox>
      );
    }
    return (
      <BestSellerBox>
        <CarouselExperienceLoader />
      </BestSellerBox>
    );
  };

  const onSearch = (values) => {
    let addedParams = "";
    const type = new URLSearchParams(location.search).get("type");

    if (values.prule !== "") {
      addedParams += `&prule=${values.prule}`;
    }

    if (values.pmax !== "" && values.pmin !== "") {
      addedParams += `&pmin=${values.pmin}&pmax=${values.pmax}`;
    }

    if (values.ratings.length > 0) {
      addedParams += `&ratings=${values.ratings.toString()}`;
    }

    setPage(1);
    history.push({
      pathname: routes.EXPERIENCES_EXPLORE,
      search: `type=${type}${addedParams}`,
    });
  };

  const renderContent = () => {
    if (experiencesListObj.status === "succeeded") {
      return (
        <Form
          innerRef={formRef}
          initialValues={{
            prule: new URLSearchParams(location.search).get("prule") || "",
            pmin: new URLSearchParams(location.search).get("pmin") || "",
            pmax: new URLSearchParams(location.search).get("pmax") || "",
            ratings: new URLSearchParams(location.search).get("ratings")?.split(",") || [],
          }}
          onSubmit={onSearch}
          validationSchema={validationSchema}
        >
          {experiencesListObj.data.explore.label && (
            <Grid
              item
              xs={12}
              paddingX={
                isMobile
                  ? theme.dimensions.MobileExperiencesPadding
                  : theme.dimensions.PCExperiencesPadding
              }
            >
              <BoldPrimaryColorText sx={{ fontSize: theme.fonts.fontSizes.size32 }}>
                {experiencesListObj.data.explore.label}
              </BoldPrimaryColorText>
            </Grid>
          )}

          {isMobile ? <MobileFilterBar handleFilter={handleFilter} /> : <FilterBar />}

          <ExperiencesItemList
            page={page}
            handlePageChange={handlePageChange}
            onToggleFavourite={onToggleFavourite}
          />
          <Grid item xs={12}>
            {renderBestSeller()}
          </Grid>
        </Form>
      );
    }
    return (
      <>
        <Grid
          item
          xs={12}
          sx={{
            marginLeft: isMobile
              ? theme.dimensions.MobileExperiencesPadding
              : theme.dimensions.PCExperiencesPadding,
          }}
        >
          <CustomSkeleton width="230px" height="55px" />
        </Grid>
        <ExperiencesItemListLoader />
      </>
    );
  };

  return (
    <Grid
      container
      paddingX={isMobile ? theme.dimensions.ScreenPaddingX : "0px"}
      paddingTop={3}
      rowSpacing={3}
    >
      {renderContent()}
    </Grid>
  );
}

export default ExploreScreen;
