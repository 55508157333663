import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loader from "react-js-loader";
import { useHistory } from "react-router-dom";
import { experienceSelector } from "../../../../services/experiences/experience-slice.service";
import { isLogin } from "../../../utils";
import MiniCartModalExperienceCard from "./mini-cart-modal-experience-card.component";
import { guestSelector } from "../../../../services/guest/guest-slice.service";
import { cartSelector } from "../../../../services/cart/cart-slice.service";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import routes from "../../../../components/navigation/routes";
import BackgroundButton from "../../../../components/button/background-button.component";
import BorderButton from "../../../../components/button/border-button.component";

const LoaderContainer = styled(Box)(({ theme }) => ({
  width: "430px",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.colors.ui.border,
}));

const EmptyCartContainer = styled(Box)(({ theme }) => ({
  width: "430px",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.colors.bg.white,
}));

const ExperiencePaddedContainer = styled(Box)({
  maxHeight: "240px",
  overflowY: "auto",
  paddingTop: "30px",
  paddingBottom: "30px",
  paddingLeft: "30px",
  paddingRight: "30px",
});

const SummaryPaddedContainer = styled(Box)({
  paddingTop: "10px",
  paddingBottom: "30px",
  paddingLeft: "30px",
  paddingRight: "30px",
});

const BorderLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  background: theme.palette.colors.ui.border,
}));

const SecondaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

function MiniCartModalExperienceList({ deleteCartItems, onQuantityUpdate, isValueUpdating }) {
  const { latestCartDetailsObj } = useSelector(experienceSelector);
  const { guestCartList, guestCartQuantity } = useSelector(guestSelector);
  const { getCartListObj } = useSelector(cartSelector);
  const { profileObj } = useSelector(profileSelector);
  const history = useHistory();
  const theme = useTheme();

  const getTotalCost = () => {
    let totalCost = 0;
    latestCartDetailsObj.data.map((item) =>
      guestCartList.map((item2) => {
        if (item.uniqueId === item2.uniqueId) {
          item.experience.addOns.forEach((addOns) => {
            totalCost +=
              parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
          });
          totalCost =
            parseFloat(totalCost) +
            parseFloat(item.experience.pricePerItem) * parseFloat(item2.quantity);
        }
        return totalCost;
      }),
    );
    return parseFloat(totalCost).toFixed(2);
  };

  const pushToCheckOut = () => {
    if (profileObj.data.firstName) {
      if (profileObj.data.emailVerified) {
        history.push(routes.SECURECHECKOUT);
      } else {
        history.push(routes.VERIFICATIONCODE, {
          email: profileObj.data.email,
        });
      }
    } else {
      history.push(routes.COMPLETEPROFILE);
    }
  };

  if (isLogin() && getCartListObj.status === "succeeded") {
    if (getCartListObj.data.items.length <= 0) {
      return (
        <EmptyCartContainer>
          <PrimaryColorText>No Items in Cart</PrimaryColorText>
        </EmptyCartContainer>
      );
    }
    return (
      <Box>
        <ExperiencePaddedContainer>
          <Grid container spacing={2}>
            {getCartListObj.data.items.map((item, index) => (
              <Grid item xs={12} key={item.id}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MiniCartModalExperienceCard
                      itemDetails={item}
                      onToggleDelete={deleteCartItems}
                      onQuantityUpdate={onQuantityUpdate}
                    />
                  </Grid>
                  {index !== getCartListObj.data.items.length - 1 && (
                    <Grid item xs={12}>
                      <BorderLine />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </ExperiencePaddedContainer>
        <SummaryPaddedContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BorderLine />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box>
                  <SecondaryColorText>Cart Quantity:</SecondaryColorText>
                  <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                    {isValueUpdating ? (
                      <CustomSkeleton width="30px" />
                    ) : (
                      getCartListObj.data.totalItems
                    )}
                  </PrimaryColorText>
                </Box>
                <Box>
                  <SecondaryColorText sx={{ textAlign: "right" }}>
                    Cart Subtotal:
                  </SecondaryColorText>
                  <PrimaryColorText
                    sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "right" }}
                  >
                    {isValueUpdating ? (
                      <CustomSkeleton width="80px" />
                    ) : (
                      `RM${getCartListObj.data.totalCost}`
                    )}
                  </PrimaryColorText>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <BorderButton
                handleSubmit={() => history.push(routes.MYCART)}
                isLoading={
                  !(
                    getCartListObj.status === "succeeded" &&
                    !isValueUpdating &&
                    profileObj.status === "succeeded"
                  )
                }
              >
                <BrandColorText>View Cart</BrandColorText>
              </BorderButton>
            </Grid>
            <Grid item xs={6}>
              <BackgroundButton
                isLoading={
                  !(
                    getCartListObj.status === "succeeded" &&
                    !isValueUpdating &&
                    profileObj.status === "succeeded"
                  )
                }
                handleSubmit={pushToCheckOut}
              >
                <WhiteColorText>Secure Checkout</WhiteColorText>
              </BackgroundButton>
            </Grid>
          </Grid>
        </SummaryPaddedContainer>
      </Box>
    );
  }

  if (!isLogin() && latestCartDetailsObj.status === "succeeded") {
    if (guestCartQuantity <= 0) {
      return (
        <EmptyCartContainer>
          <PrimaryColorText>No Items in Cart</PrimaryColorText>
        </EmptyCartContainer>
      );
    }
    return (
      <Box>
        <ExperiencePaddedContainer>
          <Grid container spacing={2}>
            {latestCartDetailsObj.data.map((item, index) => (
              <Grid item xs={12} key={item.uniqueId}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MiniCartModalExperienceCard
                      itemDetails={item}
                      onToggleDelete={deleteCartItems}
                      onQuantityUpdate={onQuantityUpdate}
                    />
                  </Grid>
                  {index !== latestCartDetailsObj.data.length - 1 && (
                    <Grid item xs={12}>
                      <BorderLine />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </ExperiencePaddedContainer>
        <SummaryPaddedContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BorderLine />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box>
                  <SecondaryColorText>Cart Quantity:</SecondaryColorText>
                  <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                    {guestCartQuantity}
                  </PrimaryColorText>
                </Box>
                <Box>
                  <SecondaryColorText>Cart Subtotal:</SecondaryColorText>
                  <PrimaryColorText
                    sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "right" }}
                  >
                    {`RM${getTotalCost()}`}
                  </PrimaryColorText>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <BorderButton handleSubmit={() => history.push(routes.MYCART)}>
                <BrandColorText>View Cart</BrandColorText>
              </BorderButton>
            </Grid>
            <Grid item xs={6}>
              <BackgroundButton handleSubmit={() => history.push(routes.AUTH)}>
                <WhiteColorText>Secure Checkout</WhiteColorText>
              </BackgroundButton>
            </Grid>
          </Grid>
        </SummaryPaddedContainer>
      </Box>
    );
  }

  return (
    <LoaderContainer>
      <Loader type="bubble-top" size={40} />
    </LoaderContainer>
  );
}

MiniCartModalExperienceList.propTypes = {
  deleteCartItems: PropTypes.func.isRequired,
  onQuantityUpdate: PropTypes.func.isRequired,
  isValueUpdating: PropTypes.bool.isRequired,
};

export default MiniCartModalExperienceList;
