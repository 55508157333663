import React, { useState } from "react";
import { useFormikContext } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import { bookingSelector } from "../../../services/booking/booking-slice.service";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";

const DateField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.white,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: theme.fonts.fontWeights.regular,
  color: theme.palette.colors.text.primary,
  marginTop: 50,
}));

const TimeField = styled(Select)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.bg.white,
}));

const FormBox = styled(Box)((props) => ({
  justifyContent: "space-around",
  display: "flex",
  width: props.width,
  flexDirection: "column",
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const MainContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

const ColumnBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const FlexEndBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
});

function FormDateTime() {
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { getBookingDetailsObj } = useSelector(bookingSelector);
  const dateArray = [];
  const [menuList, setMenuList] = useState([]);
  const showTimeSlotError = touched.slotId && typeof errors.slotId === "string";
  const showDateError = touched.date && typeof errors.date === "string";
  const [openPicker, setOpenPicker] = useState(false);

  const handleDateChange = (newValue) => {
    newValue.setHours(0, 0, 0);
    setFieldValue("slotId", "");
    setFieldValue("date", newValue);
    setMenuList(getBookingDetailsObj.data.availableSlots[format(newValue, "yyyy-MM-dd")]);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleSlotChange = (event) => {
    setFieldValue("slotId", event.target.value);
  };

  const renderButton = () => {
    if (getBookingDetailsObj.status === "succeeded") {
      if (isMobile) {
        return (
          <ColumnBox>
            <BorderButton handleSubmit={() => history.push(routes.BOOKINGEXPERIENCES)}>
              <BrandColorText sx={{ padding: "5px" }}>Cancel</BrandColorText>
            </BorderButton>
            <Spacer size="m" position="top" />
            <BackgroundButton handleSubmit={handleSubmit}>
              <WhiteColorText sx={{ padding: "5px" }}>Confirm</WhiteColorText>
            </BackgroundButton>
          </ColumnBox>
        );
      }
      return (
        <FlexEndBox>
          <BorderButton width="120px" handleSubmit={() => history.push(routes.BOOKINGEXPERIENCES)}>
            <BrandColorText>Cancel</BrandColorText>
          </BorderButton>
          <Spacer size="l" position="left" />
          <BackgroundButton width="120px" handleSubmit={handleSubmit}>
            <WhiteColorText>Confirm</WhiteColorText>
          </BackgroundButton>
        </FlexEndBox>
      );
    }
    if (isMobile) {
      return (
        <ColumnBox>
          <BorderButton handleSubmit={() => history.push(routes.BOOKINGEXPERIENCES)}>
            <BrandColorText sx={{ padding: "5px" }}>Cancel</BrandColorText>
          </BorderButton>
        </ColumnBox>
      );
    }
    return (
      <FlexEndBox>
        <BorderButton width="120px" handleSubmit={() => history.push(routes.BOOKINGEXPERIENCES)}>
          <BrandColorText>Cancel</BrandColorText>
        </BorderButton>
      </FlexEndBox>
    );
  };

  const renderForm = () => {
    if (getBookingDetailsObj.status === "succeeded") {
      Object.keys(getBookingDetailsObj.data.availableSlots).map((key) =>
        dateArray.push(`${key}T00:00:00`),
      );
      return (
        <FormBox width={isMobile ? "100%" : "60%"}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: isMobile ? "100%" : "45%" }}>
              {!isMobile ? (
                <DesktopDatePicker
                  open={openPicker}
                  onOpen={() => setOpenPicker(true)}
                  onClose={() => setOpenPicker(false)}
                  label="Date"
                  minDate={new Date()}
                  shouldDisableDate={(date) =>
                    !dateArray.map((item) => new Date(item).getTime()).includes(date.getTime())
                  }
                  disableHighlightToday={true}
                  inputFormat="dd/MM/yyyy"
                  value={values.date}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    error: true,
                  }}
                  InputProps={{
                    error: showDateError,
                    style: { borderRadius: theme.shape.borderRadius[0] },
                  }}
                  renderInput={(params) => (
                    <DateField
                      onKeyDown={onKeyDown}
                      onClick={() => setOpenPicker(true)}
                      InputLabelProps={{ error: showDateError }}
                      inputProps={{ readOnly: true }}
                      onBlur={() => setFieldTouched("date")}
                      placeholder="Select Date"
                      {...params}
                    />
                  )}
                />
              ) : (
                <MobileDatePicker
                  minDate={new Date()}
                  shouldDisableDate={(date) =>
                    !dateArray.map((item) => new Date(item).getTime()).includes(date.getTime())
                  }
                  disableHighlightToday={true}
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  value={values.date}
                  onChange={handleDateChange}
                  InputProps={{ error: showDateError }}
                  renderInput={(params) => (
                    <DateField
                      InputLabelProps={{ error: showDateError }}
                      onBlur={() => setFieldTouched("date")}
                      {...params}
                    />
                  )}
                />
              )}
              {showDateError && errors.date && (
                <Typography sx={{ color: theme.palette.darkColors.text.error }}>
                  {errors.date}
                </Typography>
              )}
            </Box>
            <Spacer position="top" size="l" />
            <FormControl sx={{ width: isMobile ? "100%" : "45%" }}>
              <InputLabel error={showTimeSlotError}>Time</InputLabel>
              <TimeField
                error={showTimeSlotError}
                onBlur={() => setFieldTouched("slotId")}
                variant="outlined"
                name="slotId"
                value={values.slotId}
                label="Time"
                onChange={handleSlotChange}
              >
                {menuList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.time}
                  </MenuItem>
                ))}
                {menuList.length === 0 && (
                  <MenuItem value="">
                    <em>No Availabe Time Slot</em>
                  </MenuItem>
                )}
              </TimeField>
              {showTimeSlotError && errors.slotId && (
                <Typography sx={{ color: theme.palette.darkColors.text.error }}>
                  {errors.slotId}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Spacer position="top" size="xxl" />

          <PrimaryColorText>Message Merchant:</PrimaryColorText>
          <Spacer position="top" size="m" />
          <FormFieldTextMultiline
            width="100%"
            name="notes"
            placeholder="Leave a message..."
            isShowBgColor={false}
            height="100px"
          />
          <Spacer position="top" size="xxl" />
          {renderButton()}
        </FormBox>
      );
    }
    return (
      <FormBox width={isMobile ? "100%" : "60%"} flexDirection={isMobile ? "column" : "row"}>
        <Box sx={{ width: isMobile ? "100%" : "45%" }}>
          <Skeleton height="50px" />
        </Box>

        <Box sx={{ width: isMobile ? "100%" : "45%" }}>
          <Skeleton height="50px" />
        </Box>
      </FormBox>
    );
  };

  return (
    <MainContainer>
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Select the date and time</StyledTitle>
      <Spacer position="top" size="xxl" />
      <LocalizationProvider dateAdapter={AdapterDateFns}>{renderForm()}</LocalizationProvider>
    </MainContainer>
  );
}

export default FormDateTime;
