import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CarouselItemList from "../carousel-item-list.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

const StyledBox = styled(Box)({
  width: "100%",
  flexDirection: "row",
  display: "flex",
});

function CarouselExperienceLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const arr = Array.apply("", Array(3));
  return (
    <Grid item xs={12} paddingY={7}>
      <StyledBox
        paddingX={
          isMobile
            ? theme.dimensions.MobileExperiencesPadding
            : theme.dimensions.PCExperiencesPadding
        }
      >
        <CustomSkeleton width="300px" height="50px" borderRadius={theme.shape.borderRadius[0]} />
      </StyledBox>
      <Box
        sx={{
          paddingLeft: isMobile ? "15px" : 0,
          paddingTop: "15px",
        }}
      >
        <CarouselItemList itemToShow={3}>
          {arr.map(() => (
            <Box key={uuidv4()} sx={{ padding: "15px" }}>
              <CustomSkeleton width="100%" height="300px" />
            </Box>
          ))}
        </CarouselItemList>
      </Box>
    </Grid>
  );
}

export default CarouselExperienceLoader;
