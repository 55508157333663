import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";

const StyledButton = styled(Button)(({ theme }) => ({
  height: "40px",
  boxShadow: 0,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  border: "1px solid",
  paddingLeft: "0px",
  paddingRight: "0px",
  "&:hover": {
    boxShadow: "none",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  fontSize: theme.fonts.fontSizes.size14,
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const InputFieldBox = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "150px",

  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.white,
    height: "40px",
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.primary,
    },
  },
}));

const DATALIST = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
];

function AmountField() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const showError = touched.amount && typeof errors.amount === "string";
  const { getCreditGiftListObj } = useSelector(voucherSelector);

  const handleChange = (event) => {
    setFieldValue("quantity", event.target.value);
  };

  const amountButton = (amount) => (
    <StyledButton
      sx={{
        minWidth: isMobile ? "90px" : "130px",
        padding: "10px",
        borderColor:
          values.amountObj?.id === amount.id
            ? theme.palette.colors.brand.primary
            : theme.palette.colors.ui.border,
        backgroundColor:
          values.amountObj?.id === amount.id ? theme.palette.colors.brand.primary : "transparent",
        ":hover": {
          backgroundColor:
            values.amountObj?.id === amount.id ? theme.palette.colors.brand.primary : "transparent",
        },
      }}
      onClick={() => {
        setFieldValue("amountObj", amount);
        setFieldValue("amount", amount.value / 100);
      }}
    >
      <Typography
        sx={{
          color:
            values.amountObj?.id === amount.id
              ? theme.palette.colors.text.white
              : theme.palette.colors.text.primary,
        }}
      >
        {amount.label}
      </Typography>
    </StyledButton>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
          {getCreditGiftListObj.data.map((item) => (
            <Grid item key={item.id}>
              {amountButton(item)}
            </Grid>
          ))}
          {showError &&
            (values.amountObj?.label.toUpperCase() !== "OTHER" ||
              values.amountObj?.label.toUpperCase() !== "OTHERS") && (
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <FormHelperText error>{errors.amount}</FormHelperText>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
          {(values.amountObj?.label.toUpperCase() === "OTHER" ||
            values.amountObj?.label.toUpperCase() === "OTHERS") && (
            <Grid item>
              <InputFieldBox
                error={showError}
                helperText={showError ? errors.amount : null}
                variant="outlined"
                onBlur={() => setFieldTouched("amount")}
                name="amount"
                placeholder="Amount"
                onChange={(e) => {
                  setFieldValue("amount", e.target.value);
                }}
                value={values.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Spacer position="right" />
                      <Typography>RM</Typography>
                      <Spacer position="right" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item>
            <FormControl>
              <StyledSelect
                variant="outlined"
                value={values.quantity}
                onChange={handleChange}
                autoWidth
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      color: theme.palette.colors.ui.border,
                      fontSize: theme.fonts.fontSizes.size14,
                    }}
                  >
                    Select Quantity
                  </Typography>
                </MenuItem>
                {DATALIST.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AmountField;
