import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";

import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormLabel from "../../../../components/forms/form-label.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import BoxTitle from "../box-title.component";
import MiniCheckBox from "./login-mini-checkbox.component";

function LoginForm({ handleSubmit, isLoading, setIsRemember, isRemember }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(4).label("Password"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[0]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          overflow: "hidden",
        }}
      >
        <BoxTitle title="Log In" />
        <Grid item textAlign="center" paddingTop="20px">
          <Typography variant="h5" fontWeight="600">
            Welcome Back!
          </Typography>
          <Typography>Let&#39;s find a new adventure!</Typography>
        </Grid>
        <Form
          initialValues={{
            email: localStorage.getItem("userEmail") || "",
            password: localStorage.getItem("userPassword") || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <PaddedView multiples={isMobile ? 3 : 4}>
            <FormLabel>Email</FormLabel>
            <Spacer size="s" />
            <FormFieldText name="email" placeholder="Enter your email address" />

            <Spacer size="m" />
            <FormLabel>Password</FormLabel>
            <Spacer size="s" />
            <FormFieldText name="password" placeholder="Password" />

            <Grid container justifyContent="space-between" sx={{ paddingTop: 1 }}>
              <Grid sx={{ width: 150 }}>
                <MiniCheckBox
                  setIsRemember={setIsRemember}
                  isRemember={isRemember}
                  label="Remember Me"
                />
              </Grid>
              <Grid alignItems="center" justifyContent="center">
                <Link
                  sx={{
                    paddingLeft: 1,
                    color: theme.palette.colors.brand.primary,
                  }}
                  underline="none"
                  key="1"
                  variant="body2"
                  href={routes.FORGOTPASSWORD}
                >
                  Forgot Password
                </Link>
              </Grid>
            </Grid>

            <Spacer size="m" />

            <Spacer size="s" />
            <FormSubmitButton isLoading={isLoading}>
              <Typography>Continue</Typography>
            </FormSubmitButton>
          </PaddedView>
        </Form>
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setIsRemember: PropTypes.func.isRequired,
  isRemember: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
