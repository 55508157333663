import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ListingItemsLoader from "../../../../components/listing-items/listing-items-loader.component";

function ExperiencesItemListLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("860"));

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  return (
    <Grid
      item
      xs={12}
      paddingX={
        isMobile ? theme.dimensions.MobileExperiencesPadding : theme.dimensions.PCExperiencesPadding
      }
    >
      <Grid container sx={{ justifyContent: "flex-start" }} spacing={3}>
        <Grid item xs={getItemToShow()}>
          <ListingItemsLoader width="100%" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <ListingItemsLoader width="100%" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <ListingItemsLoader width="100%" />
        </Grid>
        {isShowTwoItems && (
          <Grid item xs={getItemToShow()}>
            <ListingItemsLoader width="100%" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default ExperiencesItemListLoader;
