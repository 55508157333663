import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";
import { isLogin } from "../../infrastructure/utils";

export const getExplore = createAsyncThunk("marketings/explore", async () => {
  const response = await getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/marketings/explores`,
  );
  return response;
});

export const getBestSeller = createAsyncThunk("marketings/best_sellers", async () => {
  const response = await getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/marketings/best_sellers`,
  );
  return response;
});

export const getFestival = createAsyncThunk("marketings/festivals", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/marketings/festivals`);
  return response;
});

export const getMarketingBanner = createAsyncThunk("marketings/banners", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/marketings/banners`);
  return response;
});

export const getMarketingExperience = createAsyncThunk("marketings/experiences", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/marketings/experiences`);
  return response;
});

const marketingSlice = createSlice({
  name: "marketing",
  initialState: {
    exploreObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    bestSellerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFestivalObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMarketingBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMarketingExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBestSeller.pending]: (state) => {
      state.bestSellerObj.status = "pending";
    },
    [getBestSeller.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.bestSellerObj.status = "succeeded";
      state.bestSellerObj.data = data;
      state.bestSellerObj.successMessage = message;
    },
    [getBestSeller.rejected]: (state, action) => {
      const { message } = action.error;

      state.bestSellerObj.status = "failed";
      state.bestSellerObj.errorMessage = message;
    },
    [getExplore.pending]: (state) => {
      state.exploreObj.status = "pending";
    },
    [getExplore.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.exploreObj.status = "succeeded";
      state.exploreObj.data = data;
      state.exploreObj.successMessage = message;
    },
    [getExplore.rejected]: (state, action) => {
      const { message } = action.error;

      state.exploreObj.status = "failed";
      state.exploreObj.errorMessage = message;
    },
    [getFestival.pending]: (state) => {
      state.getFestivalObj.status = "pending";
    },
    [getFestival.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFestivalObj.status = "succeeded";
      state.getFestivalObj.data = data;
      state.getFestivalObj.successMessage = message;
    },
    [getFestival.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFestivalObj.status = "failed";
      state.getFestivalObj.errorMessage = message;
    },
    [getMarketingBanner.pending]: (state) => {
      state.getMarketingBannerObj.status = "pending";
    },
    [getMarketingBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMarketingBannerObj.status = "succeeded";
      state.getMarketingBannerObj.data = data;
      state.getMarketingBannerObj.successMessage = message;
    },
    [getMarketingBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMarketingBannerObj.status = "failed";
      state.getMarketingBannerObj.errorMessage = message;
    },
    [getMarketingExperience.pending]: (state) => {
      state.getMarketingExperienceObj.status = "pending";
    },
    [getMarketingExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMarketingExperienceObj.status = "succeeded";
      state.getMarketingExperienceObj.data = data;
      state.getMarketingExperienceObj.successMessage = message;
    },
    [getMarketingExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMarketingExperienceObj.status = "failed";
      state.getMarketingExperienceObj.errorMessage = message;
    },
  },
});

export default marketingSlice.reducer;

// state
export const marketingSelector = (state) => state.marketing;
