import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import { bookingSelector } from "../../../services/booking/booking-slice.service";
import CancelledBookingCard from "./cancelled-booking-card.component";
import CompletedBookingCard from "./completed-booking-card.component";
import RescheduleBookingCard from "./reschedule-booking-card.component";

function Bookings({ handleCancelBooking, mainTab, handleRateBooking }) {
  const theme = useTheme();
  const { getBookingsObj } = useSelector(bookingSelector);

  const renderEmptyList = () => (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "250px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.colors.brand.secondary,
          fontWeight: theme.fonts.fontWeights.regular,
        }}
      >
        No item to show.
      </Typography>
    </Grid>
  );

  const renderContent = () => {
    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      mainTab === 3
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return (
        <Grid container>
          <Grid container spacing={4}>
            {getBookingsObj.data.map((item) => (
              <CancelledBookingCard
                key={item.id}
                cardDetails={item}
                handleCancelBooking={handleCancelBooking}
              />
            ))}
          </Grid>
        </Grid>
      );
    }

    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      mainTab === 2
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return (
        <Grid container>
          <Grid container spacing={4}>
            {getBookingsObj.data.map((item) => (
              <CompletedBookingCard
                key={item.id}
                cardDetails={item}
                handleRateBooking={handleRateBooking}
              />
            ))}
          </Grid>
        </Grid>
      );
    }

    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      mainTab === 1
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return (
        <Grid container>
          <Grid container spacing={4}>
            {getBookingsObj.data.map((item) => (
              <RescheduleBookingCard
                key={item.id}
                cardDetails={item}
                handleCancelBooking={handleCancelBooking}
              />
            ))}
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "250px",
        }}
      >
        <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
      </Grid>
    );
  };

  return <Grid container>{renderContent()}</Grid>;
}

Bookings.propTypes = {
  handleCancelBooking: PropTypes.func.isRequired,
  handleRateBooking: PropTypes.func.isRequired,
  mainTab: PropTypes.number.isRequired,
};

export default Bookings;
