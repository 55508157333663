import React from "react";
import PropTypes from "prop-types";
import { FormControl, Grid, MenuItem, Select, Typography, useTheme } from "@mui/material";

const POINTMENULIST = [
  {
    label: "All Transactions",
    value: "all",
  },
  {
    label: "Deducts",
    value: "deducts",
  },
  {
    label: "Refunds",
    value: "refunds",
  },
  {
    label: "Converts",
    value: "converts",
  },
];

const CREDITMENULIST = [
  {
    label: "All Transactions",
    value: "all",
  },
  {
    label: "Purchases",
    value: "purchases",
  },
  {
    label: "Top-ups",
    value: "top_ups",
  },
  {
    label: "Refunds",
    value: "refunds",
  },
  {
    label: "Converts",
    value: "converts",
  },
];
function TransactionsFilter({ currentFilter, setCurrentFilter, onChangeFilter, currentTab }) {
  const theme = useTheme();
  const onChangeCurrentFilter = (filter) => {
    onChangeFilter(filter);
    setCurrentFilter(filter);
  };

  return (
    <Grid
      container
      sx={{
        "& .MuiOutlinedInput-root": {
          " &.Mui-focused fieldset": {
            borderWidth: 0,
          },
        },
        "	.MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
        "& .MuiSelect-iconOutlined": {
          color: theme.palette.colors.brand.secondary,
        },
      }}
    >
      <FormControl>
        <Select
          variant="outlined"
          value={currentFilter}
          sx={{
            minWidth: "150px",
            borderRadius: theme.shape.borderRadius[0],
            height: "40px",
            border: "0px soild white",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: "5px",
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
              },
            },
          }}
          defaultValue={currentFilter}
        >
          {currentTab === "points" &&
            POINTMENULIST.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                onClick={() => onChangeCurrentFilter(item.value)}
              >
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}

          {currentTab === "credits" &&
            CREDITMENULIST.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                onClick={() => onChangeCurrentFilter(item.value)}
              >
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

TransactionsFilter.propTypes = {
  currentFilter: PropTypes.string.isRequired,
  setCurrentFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default TransactionsFilter;
