import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Menu,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import Spacer from "../../../../components/utils/spacer.component";

const SubHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: theme.dimensions.PCMaxWidth,
  width: "100%",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingTop: "5px",
  paddingBottom: "5px",
}));

const StyledTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
}));

const StyledGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
}));

const ColumnButton = styled(Button)({
  display: "flex",
  flexDirection: "column",
  textTransform: "none",
  alignItems: "flex-start",
  ":hover": { backgroundColor: "transparent" },
});

const MobileColumnButton = styled(Button)({
  textTransform: "none",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const CenterMobileRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  paddingLeft: "5px",
  paddingRight: "5px",
});

const DescriptionBox = styled(Box)({
  width: "300px",
  padding: "10px",
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.shape.borderRadius[0],
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "none",
      },
    },
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

function HeaderPromotions() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 200,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEMLIST = [
    {
      icon: <ChangeCircleOutlinedIcon sx={{ color: theme.palette.colors.brand.primary }} />,
      title: "Free exchanges",
      label: "On all voucher purchases",
      description:
        "We would want you to have only the best experience possible, so if this means swapping your sky diving experience for a dining experience then we've got you covered. Simply cancel your ticket to recieve a full refund in credits to choose your new experience.",
    },
    {
      icon: <MonetizationOnOutlinedIcon sx={{ color: theme.palette.colors.brand.primary }} />,
      title: "Price matching",
      label: "If you find it cheaper direct",
      description:
        "We not only handpick the top experiences, but we also promise that we have the best price. If you see the same experience listed at a lower standard price by the same provider on the same day, just show us a snapshot of the experience that was offered at the lower price and let us know within 30 days of your purchase and we'll match it.",
    },
    {
      icon: <StarBorderRoundedIcon sx={{ color: theme.palette.colors.brand.primary }} />,
      title: "4.5 star rating",
      label: "From 145+ customer reviews",
      description:
        "As we only handpick the best experiences, we assure you that you'll have an enjoyable one! With over 4.5 stars average rating across 145+ customer reviews, we're certain that there will be an experiece for everyone.",
    },
  ];

  if (isMobile) {
    return (
      <Collapse in={true}>
        <Box sx={{ width: "100%" }}>
          <SubHeaderBox>
            <Box sx={{ overflow: "hidden" }}>
              <Slider {...settings}>
                {ITEMLIST.map((item, index) => (
                  <MobileColumnButton
                    key={item.title}
                    disableRipple
                    onClick={(i) => {
                      handleClick(i);
                      setCurrentIndex(index);
                    }}
                  >
                    <CenterMobileRowBox>
                      {item.icon}
                      <Spacer size="s" position="left" />
                      <Box>
                        <StyledTitleText>{item.title}</StyledTitleText>
                        <StyledGreyText>{item.label}</StyledGreyText>
                      </Box>
                    </CenterMobileRowBox>
                  </MobileColumnButton>
                ))}
              </Slider>
            </Box>
          </SubHeaderBox>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              onMouseLeave: handleClose,
            }}
          >
            <DescriptionBox>
              <StyledTitleText>{ITEMLIST[currentIndex].title}</StyledTitleText>
              <Spacer size="m" position="top" />
              <StyledGreyText>{ITEMLIST[currentIndex].description}</StyledGreyText>
            </DescriptionBox>
          </StyledMenu>
          <SeperateLine />
        </Box>
      </Collapse>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <SeperateLine />
      <Collapse in={true}>
        <CenterBox>
          <SubHeaderBox>
            <Grid container>
              {ITEMLIST.map((item) => (
                <Grid
                  key={item.title}
                  item
                  xs={4}
                  sx={{
                    borderRight:
                      item.title !== "4.5 star rating" &&
                      `1px solid ${theme.palette.colors.ui.border}`,
                  }}
                >
                  <Tooltip
                    PopperProps={{
                      sx: {
                        whiteSpace: "pre-line",
                        "& .MuiTooltip-arrow": {
                          top: "-10px",
                          "&::before": {
                            backgroundColor: "white",
                            boxShadow:
                              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                          },
                        },
                        "& .MuiTooltip-tooltip": {
                          borderRadius: "2px",
                          backgroundColor: "white",
                          boxShadow:
                            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",

                          width: "300px",
                          padding: "10px",
                        },
                      },
                    }}
                    arrow
                    title={
                      <Box>
                        <StyledTitleText>{item.title}</StyledTitleText>
                        <Spacer size="m" position="top" />
                        <StyledGreyText variant="body2">{item.description}</StyledGreyText>
                      </Box>
                    }
                  >
                    <CenterMobileRowBox>
                      {item.icon}
                      <ColumnButton disableRipple>
                        <StyledTitleText>{item.title}</StyledTitleText>
                        <StyledGreyText>{item.label}</StyledGreyText>
                      </ColumnButton>
                    </CenterMobileRowBox>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </SubHeaderBox>
          <SeperateLine />
        </CenterBox>
      </Collapse>
    </Box>
  );
}
export default HeaderPromotions;
