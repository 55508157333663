import React from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PropTypes from "prop-types";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: "10px",
  top: "10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const SpaceBetweenRowBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledAccountBalanceWalletIcon = styled(AccountBalanceWalletIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontSize: "18px",
}));

export default function CreditVoucherPreviewLoader({ setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <CloseIconButton onClick={() => setShowModal(false)}>
        <StyledCloseIcon />
      </CloseIconButton>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <TitleText variant="h5">Credit Gift Voucher</TitleText>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PrimaryColorText>Items</PrimaryColorText>
            </Grid>
            <Grid item xs={12}>
              <SpaceBetweenRowBox>
                <CustomSkeleton width="125px" height="30px" />
                <CustomSkeleton width="125px" height="30px" />
              </SpaceBetweenRowBox>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <PrimaryColorText>Recipient Info</PrimaryColorText>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <CustomSkeleton width="100%" height="200px" />
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <CustomSkeleton width="100%" height="30px" />
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <CustomSkeleton width="100%" height="30px" />
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <CustomSkeleton width="100%" height="30px" />
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <CustomSkeleton width="100%" height="30px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <CenterRowBox>
            <StyledAccountBalanceWalletIcon />
            <Spacer size="xs" position="left" />
            <PrimaryColorText>Payment</PrimaryColorText>
          </CenterRowBox>
        </Grid>
        <Grid item xs={12}>
          <CustomSkeleton width="125px" height="30px" />
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <SpaceBetweenRowBox>
            <PrimaryColorText variant="h6" sx={{ fontWeight: theme.fonts.fontWeights.regular }}>
              Total Payment
            </PrimaryColorText>
            <CustomSkeleton width="125px" height="30px" />
          </SpaceBetweenRowBox>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </>
  );
}

CreditVoucherPreviewLoader.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
