import React, { useState } from "react";
import { Box, Button, Collapse, Grid, Icon, styled, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  paddingTop: 2,
  fontSize: theme.fonts.fontSizes.size14,
  color: theme.palette.colors.text.primary,
}));

const StyledButton = styled(Button)({
  justifyContent: "flex-start",
});

const StyledBox = styled(Box)({
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

function DrawerMenuItem({ label, itemList, type, handleSearch, setOpenDrawer }) {
  const theme = useTheme();
  const [collapse, setCollapse] = useState(false);
  const [categoryCollapse, setCategoryCollapse] = useState(Array(itemList?.length).fill(false));

  const handleItemSelected = (values, isSubcategory = false) => {
    if (isSubcategory) {
      handleSearch(values.target.textContent, "subcategory");
    } else {
      handleSearch(values.target.textContent, type);
    }
    setOpenDrawer(false);
    setCollapse(null);
  };

  const handleAll = () => {
    handleSearch("", type === "category" ? "experience" : type);
    setOpenDrawer(false);
    setCollapse(null);
  };

  const renderSubcategoryList = (index) => (
    <Collapse in={categoryCollapse[index]} unmountOnExit timeout="auto">
      <StyledBox>
        {itemList[index].subcategories.map((item) => (
          <Grid key={uuidv4()} item xs={12}>
            <StyledButton
              onClick={(value) => handleItemSelected(value, true)}
              disableRipple
              fullWidth
            >
              <StyledTypography sx={{ fontSize: theme.fonts.fontSizes.size12 }}>
                {item}
              </StyledTypography>
            </StyledButton>
          </Grid>
        ))}
      </StyledBox>
    </Collapse>
  );

  const renderCategoryList = () =>
    itemList.map((category, index) => (
      <Grid item xs={12} key={uuidv4()}>
        <Icon
          aria-label="expand row"
          onClick={() => {
            const newCategoryCollapse = [...categoryCollapse];
            newCategoryCollapse[index] = !newCategoryCollapse[index];
            setCategoryCollapse(newCategoryCollapse);
          }}
          sx={{ paddingTop: "4px" }}
        >
          {categoryCollapse[index] ? (
            <KeyboardArrowUpIcon sx={{ width: 20, height: 20 }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ width: 20, height: 20 }} />
          )}
        </Icon>
        <StyledButton onClick={handleItemSelected} disableRipple>
          <StyledTypography>{category.label}</StyledTypography>
        </StyledButton>
        {renderSubcategoryList(index)}
      </Grid>
    ));

  const renderContentList = () => {
    if (itemList) {
      return (
        <Collapse in={collapse} unmountOnExit timeout="auto">
          <StyledBox>
            <Grid key={uuidv4()} item xs={12}>
              <StyledButton onClick={handleAll} disableRipple fullWidth>
                <StyledTypography>All</StyledTypography>
              </StyledButton>
            </Grid>
            {type === "category"
              ? renderCategoryList()
              : itemList.map((item) => (
                  <Grid key={uuidv4()} item xs={12}>
                    <StyledButton onClick={handleItemSelected} disableRipple fullWidth>
                      <StyledTypography>{item}</StyledTypography>
                    </StyledButton>
                  </Grid>
                ))}
          </StyledBox>
        </Collapse>
      );
    }
    return (
      <Collapse in={collapse} unmountOnExit timeout="auto">
        <CustomSkeleton width="100%" height="30px" />
        <CustomSkeleton width="100%" height="30px" />
        <CustomSkeleton width="100%" height="30px" />
      </Collapse>
    );
  };
  return (
    <Grid item xs={12}>
      <Typography
        onClick={() => {
          setCollapse(!collapse);
        }}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          color: theme.palette.colors.text.primary,
        }}
      >
        {label}
        <Icon aria-label="expand row">
          {collapse ? (
            <KeyboardArrowUpIcon sx={{ width: 20, height: 20 }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ width: 20, height: 20 }} />
          )}
        </Icon>
      </Typography>
      {renderContentList()}
    </Grid>
  );
}

DrawerMenuItem.defaultProps = {
  itemList: null,
};

DrawerMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        subcategories: PropTypes.arrayOf(PropTypes.string),
      }),
    ]),
  ),
  type: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
};

export default DrawerMenuItem;
