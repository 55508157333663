import React, { useState } from "react";
import { Box, Button, Grid, Menu, styled, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Spacer from "../../../../components/utils/spacer.component";
import FilterPriceButton from "./filter-price-button.component";
import FilterRatingsButton from "./filter-ratings-button.component";

const StyledMenu = styled((props) => <Menu {...props} />)({
  marginTop: "45px",
  marginLeft: "-10px",
  "& .MuiPaper-root": {
    maxWidth: "300px",
    minWidth: "200px",
    borderRadius: 1,
    marginTop: "5px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "10px 0",
    },
  },
});

const PlaceHolderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const GreyMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size20,
}));

const StyledBox = styled(Box)({
  padding: "10px",
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledFilterButton = styled(Button)({
  textTransform: "none",
  alignItems: "center",
  display: "flex",
  ":hover": { backgroundColor: "transparent" },
});

const StyledButton = styled(Button)({
  padding: "2px",
  width: "30px",
  textTransform: "none",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
});

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "5px",
  paddingLeft: "10px",
  paddingRight: "10px",
});

function FilterBarMenu() {
  const { handleSubmit, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    handleSubmit();
    setOpenMenu(null);
  };

  const onClearHandle = () => {
    setFieldValue("pmin", "");
    setFieldValue("pmax", "");
    setFieldValue("ratings", []);
    handleSubmit();
    setOpenMenu(null);
  };

  const renderPriceRange = () => (
    <StyledBox sx={{ padding: "10px" }}>
      <PlaceHolderText>Price Range</PlaceHolderText>
      <Spacer size="s" position="top" />
      <Grid container spacing={1}>
        <Grid item>
          <FilterPriceButton min="0" max="50" label="RM0-RM50" />
        </Grid>
        <Grid item>
          <FilterPriceButton min="50" max="150" label="RM50-RM150" />
        </Grid>
        <Grid item>
          <FilterPriceButton min="150" max="300" label="RM150-RM300" />
        </Grid>
        <Grid item>
          <FilterPriceButton min="300" max="1000000" label="> RM300" />
        </Grid>
      </Grid>
    </StyledBox>
  );

  const renderRatings = () => (
    <StyledBox>
      <PlaceHolderText>Ratings</PlaceHolderText>
      <Spacer size="s" position="top" />
      <Grid container spacing={1}>
        <Grid item>
          <FilterRatingsButton id="1" label="1 Star" />
        </Grid>
        <Grid item>
          <FilterRatingsButton id="2" label="2 Star" />
        </Grid>
        <Grid item>
          <FilterRatingsButton id="3" label="3 Star" />
        </Grid>
        <Grid item>
          <FilterRatingsButton id="4" label="4 Star" />
        </Grid>
        <Grid item>
          <FilterRatingsButton id="5" label="5 Star" />
        </Grid>
      </Grid>
    </StyledBox>
  );

  return (
    <>
      <StyledFilterButton onClick={handleOpenMenu} disableRipple>
        <PlaceHolderText>Filter</PlaceHolderText>
        <GreyMoreVertIcon />
      </StyledFilterButton>
      <StyledMenu
        elevation={0}
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        {renderPriceRange()}
        {renderRatings()}

        <SpaceBetweenRowBox>
          <StyledButton disableRipple onClick={onClearHandle}>
            <Typography
              sx={{
                fontSize: theme.fonts.fontSizes.size14,
                color: theme.palette.colors.text.primary,
              }}
            >
              Clear
            </Typography>
          </StyledButton>

          <StyledButton disableRipple onClick={handleCloseMenu} sx={{ justifyContent: "flex-end" }}>
            <Typography
              sx={{
                fontSize: theme.fonts.fontSizes.size14,
                color: theme.palette.colors.brand.primary,
              }}
            >
              Save
            </Typography>
          </StyledButton>
        </SpaceBetweenRowBox>
      </StyledMenu>
    </>
  );
}

export default FilterBarMenu;
