import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useSelector } from "react-redux";
import PaddedView from "../../../components/utils/padded-view.component";
import { profileSelector } from "../../../services/profile/profile-slice.service";
import Spacer from "../../../components/utils/spacer.component";

const CenterIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  width: isMobile ? "100%" : "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledSwapHorizIcon = styled(SwapHorizIcon)(({ theme }) => ({
  fontSize: "25px",
  strokeWidth: 1.5,
  stroke: theme.palette.colors.brand.white,
  color: theme.palette.colors.brand.white,
}));

const StyledSwapVertIcon = styled(SwapVertIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 1.5,
  stroke: theme.palette.colors.brand.white,
  color: theme.palette.colors.brand.white,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
  width: "50px",
  borderRadius: "25px",
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
}));

const CardBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "44%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `0.5px 1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "normal",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: "normal",
}));

function CurrentBalance({ setShowPointConvertModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { profileObj } = useSelector(profileSelector);

  if (isMobile) {
    return (
      <Grid item xs={12}>
        <Grid container sx={{ flexDirection: "column", justifyContent: "space-between" }}>
          <CardBox isMobile={isMobile}>
            <PaddedView multiples={3}>
              <GreyText variant="h6">Available Credits</GreyText>

              <PrimaryColorText variant="h6">{`RM${profileObj.data.credits}`}</PrimaryColorText>
            </PaddedView>
          </CardBox>
          {/* <Spacer size="s" position="top" />
          <CenterIconBox isMobile={isMobile}>
            <StyledIconButton onClick={() => setShowPointConvertModal(true)}>
              <StyledSwapVertIcon />
            </StyledIconButton>
          </CenterIconBox>
          <Spacer size="s" position="top" />
          <CardBox isMobile={isMobile}>
            <PaddedView multiples={3}>
              <GreyText variant="h6">Available Points</GreyText>

              <PrimaryColorText variant="h6">{profileObj.data.points}</PrimaryColorText>
            </PaddedView>
          </CardBox> */}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container sx={{ flexDirection: "row", justifyContent: "space-between" }}>
        <CardBox isMobile={isMobile}>
          <PaddedView multiples={2}>
            <GreyText variant="h6">Available Credits</GreyText>
            <PrimaryColorText variant="h6">{`RM${profileObj.data.credits}`}</PrimaryColorText>
          </PaddedView>
        </CardBox>
        {/* <CenterIconBox isMobile={isMobile}>
          <StyledIconButton onClick={() => setShowPointConvertModal(true)}>
            <StyledSwapHorizIcon />
          </StyledIconButton>
        </CenterIconBox>
        <CardBox isMobile={isMobile}>
          <PaddedView multiples={2}>
            <GreyText variant="h6">Available Points</GreyText>
            <PrimaryColorText variant="h6">{profileObj.data.points}</PrimaryColorText>
          </PaddedView>
        </CardBox> */}
      </Grid>
    </Grid>
  );
}

CurrentBalance.propTypes = {
  setShowPointConvertModal: PropTypes.func.isRequired,
};

export default CurrentBalance;
