import React from "react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropTypes from "prop-types";

function RowLoader({ label }) {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={12}>
          <Typography variant="label">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="50%" />
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

RowLoader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default RowLoader;
