export const colors = {
  brand: {
    primary: "#7439c2",
    lightPrimary: "#b17bff",
    secondary: "#25273C",
    white: "#FFFFFF",
    linear: ["#F65DB0", "#7439c2"],
  },
  ui: {
    primary: "#262626",
    secondary: "#6B6B6B",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    white: "#FFFFFF",
    border: "#C2C2C2",
    lightBorder: "#dfdfdf",
    indicator: "#AAAAAA",
    star: "#FFCC00",
    favourite: "#FF7777",
    notification: "#FF2525",
  },
  bg: {
    primary: "#F7F7F7",
    secondary: "#F0F3F9",
    white: "#FFFFFF",
    recommendationsBg: "#EFEFF3",
  },
  timeSlot: {
    selected: "#E60013",
    unselected: "#AAAAAA",
  },
  tab: {
    selected: "#E60013",
    unselected: "#AAAAAA",
    bg: "#FAF8F8",
  },
  loading: {
    backgroundColor: "#FFFFFF",
    foregroundColor: "#AAAAAA",
    imageBg: "#AAAAAA",
    dot1: "#262626",
    dot2: "#6B6B6B",
    dot3: "#FFFFFF",
  },
  text: {
    primary: "#25273C",
    secondary: "#6B6B6B",
    tertiary: "#707070",
    placeholder: "#AAAAAA",
    white: "#FFFFFF",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    inputField: "#F6F6F9",
  },
  button: {
    switch: "#26BC50",
    openBooking: "#55BF98",
    pendingBooking: "#265086",
    confirmedBooking: "#FFB71C",
    cancelledBooking: "#FF2525",
    rescheduleBooking: "#046D46",
  },
};
