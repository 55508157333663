import React from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";

function UpdatePhoneForm({
  phoneNumber,
  setIsEditing,
  isEditing,
  handleSubmit,
  isPhoneEdit,
  setIsPhoneEdit,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .nullable()
      .notRequired()
      .when({
        is: (value) => value?.length,
        then: (rule) => rule.min(10),
      })
      .label("Phone Number"),
  });

  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsPhoneEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsPhoneEdit(false);
  };

  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Phone Number</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isPhoneEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isPhoneEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                phoneNumber,
              }}
              onSubmit={handleFormSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <PhoneNumberTextField defaultValue={phoneNumber} name="phoneNumber" />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          ) : (
            <Typography color={theme.palette.colors.text.secondary}>
              {phoneNumber === "" ? "Not Provided" : phoneNumber}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdatePhoneForm.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isPhoneEdit: PropTypes.bool.isRequired,
  setIsPhoneEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdatePhoneForm;
