import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Spacer from "../../../components/utils/spacer.component";
import NumberUpDownArrowField from "../../../components/forms/quantity-field.component";

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "40px",
}));

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
});

function MiddleBox({ setQuantity, quantity, itemData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("840"));

  return (
    <Grid item xs={isMobile ? 12 : 4.5} paddingTop={isMobile ? "30px" : 0}>
      <MainBox>
        <DetailsBox>
          <GreyText textAlign="start">Per Ticket</GreyText>
          <Spacer size="s" position="top" />
          <PrimaryColorText>{`RM${itemData.pricePerItem}`}</PrimaryColorText>
        </DetailsBox>
        <DetailsBox>
          <GreyText textAlign="center">Qty</GreyText>
          <Spacer size="s" position="top" />
          <NumberUpDownArrowField
            maxNoPax={itemData.maxNoPax}
            minNoPax={itemData.minNoPax}
            setValue={setQuantity}
            value={quantity}
          />
        </DetailsBox>
        <DetailsBox sx={{ width: "100px" }}>
          <GreyText textAlign="end">Total</GreyText>
          <Spacer size="s" position="top" />
          <PrimaryColorText textAlign="end">{`RM${
            Number.isNaN(parseFloat(quantity))
              ? "0.00"
              : (parseFloat(itemData.pricePerItem.replace(",", "")) * parseFloat(quantity)).toFixed(
                  2,
                )
          }`}</PrimaryColorText>
        </DetailsBox>
      </MainBox>
    </Grid>
  );
}

MiddleBox.defaultProps = {
  quantity: 1,
};

MiddleBox.propTypes = {
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  itemData: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    favourited: PropTypes.bool,
    hasFlexi: PropTypes.shape({
      status: PropTypes.bool,
    }),
    id: PropTypes.number,
    imgPath: PropTypes.string,
    importantInfo: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    maxNoPax: PropTypes.number,
    minNoPax: PropTypes.number,
    noReviews: PropTypes.number,
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
    ratings: PropTypes.number,
    state: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default MiddleBox;
