import React, { useState, useContext } from "react";
import {
  styled,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getAutoCompleteSuggestion,
  experienceSelector,
} from "../../../services/experiences/experience-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "30px",
  background: theme.palette.colors.brand.secondary,
  borderRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}));

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.palette.colors.brand.white};
`;

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    "& .MuiOutlinedInput-root": {
      padding: 0,
      height: "30px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],

      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.secondary,
      },
      " &.MuiInputBase-root": {
        padding: "6px",
        paddingRight: "0px",
      },
    },
  },
}));

function MiniSearchBar({ handleSearch, setCollapse }) {
  const theme = useTheme();
  const isMobileAppBar = useMediaQuery(theme.breakpoints.down("920"));
  const [searchKeyword, setSearchKeyword] = useState("");
  const createSnackBar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { getAutoCompleteSuggestionObj } = useSelector(experienceSelector);

  const handleSubmit = (value = null) => {
    if (value) {
      handleSearch(value.title || value, "experience");
    } else {
      handleSearch(searchKeyword, "experience");
    }
    if (setCollapse !== null) {
      setCollapse(false);
    }
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleAutoComplete = (e) => {
    setSearchKeyword(e.target.value);
    const params = new URLSearchParams();
    params.append("q", e.target.value);
    dispatch(getAutoCompleteSuggestion({ params: params.toString() })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get auto complete suggestion! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledBox
      sx={{
        minWidth: isMobileAppBar ? "130px" : "300px",
        marginTop: isMobileAppBar ? "5px" : null,
      }}
    >
      <Autocomplete
        sx={{ minWidth: isMobileAppBar ? "130px" : "300px" }}
        filterOptions={(x) => x}
        freeSolo
        id="free-solo-1"
        onChange={(event, value) => value && handleSubmit(value)}
        options={
          searchKeyword && getAutoCompleteSuggestionObj.status === "succeeded"
            ? getAutoCompleteSuggestionObj.data
            : []
        }
        getOptionLabel={(option) => (option.title ? option.title : option)}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0, borderRadius: "3px" } }}
            {...props}
          >
            <img loading="lazy" width="60px" src={option.imagePath} alt="" />
            {option.title}
          </Box>
        )}
        componentsProps={{
          paper: {
            sx: {
              minWidth: "280px",
              width: "fit-content",
              position: "absolute",
              right: 0,
            },
          },
        }}
        renderInput={(params) => (
          <DebounceInput
            debounceTimeout={200}
            {...params}
            size="small"
            onChange={(e) => handleAutoComplete(e)}
            element={TextField}
            value={searchKeyword}
            onKeyDown={keyPress}
            InputProps={{
              ref: params.InputProps.ref,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton
                    sx={{ "&:hover": { backgroundColor: "#25273c" } }}
                    onClick={() => handleSubmit()}
                  >
                    <StyledSearchIcon />
                  </StyledIconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Search ..."
          />
        )}
      />
    </StyledBox>
  );
}

MiniSearchBar.defaultProps = {
  setCollapse: null,
};

MiniSearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setCollapse: PropTypes.func,
};

export default MiniSearchBar;
