import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import VoucherRedemptionBg from "../../../assets/images/voucher_redemption_bg.png";
import VoucherForm from "../components/voucher-form.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { giftVoucherPreview } from "../../../services/voucher/voucher-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import routes from "../../../components/navigation/routes";

const BackgroundImage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  backgroundImage: `url(${VoucherRedemptionBg})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: isMobile ? "300px" : "400px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const validationSchema = Yup.object().shape({
  giftVoucher: Yup.string().required().label("Voucher Code"),
});

const CenterBox = styled(Box)({
  justifyContent: "center",
  display: "flex",
});

function VoucherRedemptionScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitGiftVoucher = (values) => {
    setIsLoading(true);
    dispatch(giftVoucherPreview({ voucherCode: values.giftVoucher })).then(
      ({ meta, payload, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.type === "credit") {
            history.push(routes.GIFT_VOUCHER_PREVIEW, values);
            return;
          }
          history.push(routes.VOUCHERDETAILS, values);
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{ giftVoucher: "" }}
      onSubmit={submitGiftVoucher}
    >
      <Loading isLoading={isLoading} />
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <BackgroundImage />
          </Grid>

          <Grid item xs={12}>
            <CenterBox>
              <Grid
                container
                sx={{ justifyContent: "center", maxWidth: theme.dimensions.PCMaxWidth }}
                spacing={2}
                paddingX={isMobile ? 2 : 10}
              >
                <Grid item xs={12}>
                  <PrimaryColorText variant="h3" sx={{ textAlign: "center" }}>
                    Redeem
                  </PrimaryColorText>
                </Grid>
                <Grid item xs={12}>
                  <GreyColorText sx={{ textAlign: "center" }}>
                    Use the Redemption Code in your email here!
                  </GreyColorText>
                  <GreyColorText sx={{ textAlign: "center" }}>
                    You&apos;ll be able to redeem or exchange it upon submission.
                  </GreyColorText>
                </Grid>
                <Grid item xs={isMobile ? 12 : 8}>
                  <VoucherForm name="giftVoucher" placeholder="Enter voucher code here" />
                </Grid>
              </Grid>
            </CenterBox>
          </Grid>

          <Grid item xs={12} />
        </Grid>
      </Box>
    </Form>
  );
}

export default VoucherRedemptionScreen;
