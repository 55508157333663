import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import BackgroundButton from "../../../components/button/background-button.component";
import PendingPaymentItemList from "../components/pending-payment/pending-payment-item-list.component";
import PendingPaymentItemLoader from "../loader/pending-payment-items-loader.component";
import {
  cancelOrder,
  getPendingPaymentList,
  orderSelector,
} from "../../../services/order/order-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  marginTop: 50,
}));

const StyledIcon = styled(PendingActionsIcon)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  height: "75px",
  width: "75px",
}));

const StyledGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const EmptyListBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

function PendingPaymentScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { getPendingPaymentListObj } = useSelector(orderSelector);
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const loadDataFromNet = () => {
    dispatch(getPendingPaymentList()).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPendingPaymentList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handleCancelOrder = (orderId) => {
    setIsLoading(true);
    dispatch(cancelOrder(orderId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getProfile());
        loadDataFromNet();
      }
    });
  };

  const renderContent = () => {
    if (getPendingPaymentListObj.status === "succeeded") {
      if (getPendingPaymentListObj.data.length === 0) {
        return (
          <>
            <SeperateLine />
            <EmptyListBox>
              <StyledIcon />
              <Spacer size="m" position="top" />
              <StyledGreyText variant="h6">No Pending Payment</StyledGreyText>
              <Spacer size="l" position="top" />
              <BackgroundButton handleSubmit={() => history.push(routes.HOME)} width="300px">
                Continue Shopping
              </BackgroundButton>
              <Spacer size="xxl" position="top" />
            </EmptyListBox>
          </>
        );
      }
      return (
        <>
          <Box sx={{ marginTop: "50px", width: "100%" }} />
          {getPendingPaymentListObj.data.map((item, index) => (
            <PendingPaymentItemList
              itemData={item}
              key={item.id}
              handleCancelOrder={handleCancelOrder}
              index={index}
            />
          ))}
        </>
      );
    }
    return (
      <>
        <SeperateLine />
        <PendingPaymentItemLoader />
        <SeperateLine />
      </>
    );
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Grid
        container
        paddingBottom={isMobile ? "10px" : "0px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <StyledTitle variant={isMobile ? "h4" : "h3"}>Pending Payment</StyledTitle>
          </Grid>
        </Grid>

        {renderContent()}
      </Grid>
      <Spacer size="xxl" position="top" />
    </>
  );
}

export default PendingPaymentScreen;
