import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const getConvertiblePoints = createAsyncThunk("points/convertible_points", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/points/convertible`);
  return response;
});

export const convertPoints = createAsyncThunk("points/convert_points", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/points/convert`, payload);
  return response;
});

export const getPointsTransactions = createAsyncThunk("points/transactions", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/points/transactions?type=${payload}`,
    payload,
  );
  return response;
});

const pointSlice = createSlice({
  name: "points",
  initialState: {
    getConvertiblePointsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    convertPointsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPointsTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getConvertiblePoints.pending]: (state) => {
      state.getConvertiblePointsObj.status = "pending";
    },
    [getConvertiblePoints.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getConvertiblePointsObj.status = "succeeded";
      state.getConvertiblePointsObj.data = data;
      state.getConvertiblePointsObj.successMessage = message;
    },
    [getConvertiblePoints.rejected]: (state, action) => {
      const { message } = action.error;

      state.getConvertiblePointsObj.status = "failed";
      state.getConvertiblePointsObj.errorMessage = message;
    },
    [convertPoints.pending]: (state) => {
      state.convertPointsObj.status = "pending";
    },
    [convertPoints.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.convertPointsObj.status = "succeeded";
      state.convertPointsObj.data = data;
      state.convertPointsObj.successMessage = message;
    },
    [convertPoints.rejected]: (state, action) => {
      const { message } = action.error;

      state.convertPointsObj.status = "failed";
      state.convertPointsObj.errorMessage = message;
    },
    [getPointsTransactions.pending]: (state) => {
      state.getPointsTransactionsObj.status = "pending";
    },
    [getPointsTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPointsTransactionsObj.status = "succeeded";
      state.getPointsTransactionsObj.data = data;
      state.getPointsTransactionsObj.successMessage = message;
    },
    [getPointsTransactions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPointsTransactionsObj.status = "failed";
      state.getPointsTransactionsObj.errorMessage = message;
    },
  },
});

export default pointSlice.reducer;

// state
export const pointSelector = (state) => state.points;
