import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const DetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  width: "100%",
}));

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const RowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "100%",
  flexDirection: "row",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

function AddToCartModalLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1000"));

  if (isMobile) {
    return (
      <>
        <Grid item xs={12}>
          <CustomSkeleton height="170px" width="100%" />
        </Grid>
        <Grid item xs={12}>
          <DetailsBox isMobile={isMobile}>
            <CustomSkeleton height="25px" width="100%" />
            <CustomSkeleton height="20px" width="100%" />
            <CustomSkeleton height="20px" width="100%" />
          </DetailsBox>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12}>
              <ButtonBox>
                <CustomSkeleton height="30px" width="100%" borderRadius={5} />
                <CustomSkeleton height="30px" width="100%" borderRadius={5} />
              </ButtonBox>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item width="20%">
          <CustomSkeleton height="100px" width="100%" />
        </Grid>
        <Grid item width="80%">
          <DetailsBox>
            <SpaceBetweenRowBox>
              <Box>
                <CustomSkeleton height="25px" width="200px" />
                <CustomSkeleton height="20px" width="200px" />
                <CustomSkeleton height="20px" width="200px" />
              </Box>
              <RowBox>
                <Spacer size="m" position="left" />
                <CustomSkeleton height="35px" width="125px" borderRadius={5} />
                <Spacer size="m" position="left" />
                <CustomSkeleton height="35px" width="125px" borderRadius={5} />
              </RowBox>
            </SpaceBetweenRowBox>
          </DetailsBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddToCartModalLoader;
