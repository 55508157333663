import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { pointSelector } from "../../../../services/point/points-slice.service";

function CreditField({ name }) {
  const theme = useTheme();
  const { getConvertiblePointsObj } = useSelector(pointSelector);
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit, setFieldError } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const onChangeText = (e) => {
    if (e.target.value * 100 > getConvertiblePointsObj.data.maxConvertibleCreditCents) {
      setFieldError(
        "credit",
        `Your max convertible credits is ${getConvertiblePointsObj.data.maxConvertibleCredit.replace(
          ".00",
          "",
        )}`,
      );
      return;
    }
    setFieldValue("credit", e.target.value);
    setFieldValue("points", e.target.value / 10);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.white,
            " &.Mui-focused fieldset": {
              borderColor: theme.palette.colors.brand.primary,
            },
          },
        },
      }}
    >
      <TextField
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        onBlur={() => setFieldTouched(name)}
        onFocus={() => setFieldTouched(name)}
        name={name}
        onChange={onChangeText}
        inputProps={{ style: { textAlign: "center" } }}
        value={values[name]}
      />
    </Box>
  );
}

CreditField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CreditField;
