import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import BoxTitle from "../box-title.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

function EmailForm({ handleSubmit, isLoading }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.white,
        borderRadius: `${theme.shape.borderRadius[0]}px`,
        flex: 1,
        maxWidth: 500,
        minWidth: isMobile ? null : 450,
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 2,
      }}
    >
      <BoxTitle title="Forgot Password?" />
      <Form
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <PaddedView multiples={isMobile ? 3 : 4}>
          <Typography varian="h2" textAlign="center">
            Enter your email address to receive verification code.
          </Typography>
          <Spacer size="l" />

          <FormFieldText name="email" placeholder="Enter your email address" />

          <Spacer size="xxxl" />

          <FormSubmitButton isLoading={isLoading}>
            <Typography>Confirm</Typography>
          </FormSubmitButton>
        </PaddedView>
      </Form>
    </Box>
  );
}

EmailForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
