import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Form from "../../../components/forms/form.component";
import PromoCodeField from "../components/promo-code-field.component";
import Spacer from "../../../components/utils/spacer.component";
import ConfirmationModal from "../components/confirmation-modal.component";
import {
  buyNowSecureCheckout,
  previewRedeemPromoCode,
} from "../../../services/order/order-slice.service";
import { getProfile, profileSelector } from "../../../services/profile/profile-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import BuyNowExperience from "../components/buy-now-experience.component";
import SecureCheckoutLoader from "../loader/secure-checkout-loader.component";
import PaymentMethodCheckbox from "../components/payment-method-checkbox.component";
import BuyNowOrderSummary from "../components/buy-now-order-summary.component";

const BackButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  width: "100%",
  color: theme.palette.colors.text.secondary,
  marginTop: 50,
  marginBottom: 50,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().label("Payment Method"),
  experienceId: Yup.string().required(),
  quantity: Yup.number().required(),
  massSlotId: Yup.number().nullable(),
  isFlexi: Yup.boolean(),
  purchasedAsGift: Yup.boolean(),
  gift: Yup.object().shape({
    message: Yup.string().label("Message"),
    recipient: Yup.object().shape({
      purchasedAsGift: Yup.boolean(),
      lastName: Yup.string().label("Last Name").when("purchasedAsGift", {
        is: true,
        then: Yup.string().required(),
      }),
      firstName: Yup.string().label("First Name").when("purchasedAsGift", {
        is: true,
        then: Yup.string().required(),
      }),
      email: Yup.string().email().label("Email").when("purchasedAsGift", {
        is: true,
        then: Yup.string().required(),
      }),
      phone: Yup.string()
        .when({
          is: (value) => value?.length,
          then: (rule) => rule.min(10),
        })
        .when("purchasedAsGift", {
          is: true,
          then: Yup.string().required(),
        })
        .nullable()
        .label("Phone Number"),
    }),
  }),
});

function BuyNowSecureCheckoutScreen(props) {
  const locationProps = props;
  const { experience, massSlotId } = locationProps.location.state;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("940"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileObj } = useSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoRedeem, setPromoRedeem] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [submitValue, setSubmitValue] = useState(null);
  const createSnackBar = useContext(SnackbarContext);

  const accountCreditPurchase = () => {
    setShowConfirmModal(!showConfirmModal);
    setIsLoading(true);
    dispatch(buyNowSecureCheckout(submitValue)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        history.push(routes.BILLSUCCESS);
      }
    });
  };

  const pushToTopUp = () => {
    history.push(routes.ACCOUNTCREDITS);
  };

  const getTotalCost = () => {
    const result =
      parseFloat(experience.totalCost.replace(",", "")) -
      parseFloat(promoRedeem.discount.replace(",", ""));
    return result.toFixed(2);
  };

  const formSubmitHandle = (values) => {
    const submitValues = { promoCode, ...values };

    setSubmitValue(submitValues);
    if (values.paymentMethod === "credits") {
      const totalCost = promoCode === "" ? experience.totalCost.replace(/,/g, "") : getTotalCost();
      if (parseFloat(profileObj.data.credits.replace(/,/g, "")) < parseFloat(totalCost)) {
        setShowTopUpModal(true);
        return;
      }
      setShowConfirmModal(true);
      return;
    }

    setIsLoading(true);
    dispatch(buyNowSecureCheckout(submitValues)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      }
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        if (promoRedeem && promoRedeem.total === "0.00") {
          history.push(routes.BILLSUCCESS);
        } else {
          window.open(payload.data.orderBill.remote_url, "_self");
        }
      }
    });
  };

  const redeemHandle = (values) => {
    setPromoCode(values.promoCode);
    setIsLoading(true);
    dispatch(
      previewRedeemPromoCode({
        ...values,
        fromCartItem: false,
        experienceId: experience.experience.id,
        totalCost: parseFloat(experience.price.replace(/,/g, "")),
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        setPromoCode("");
        setPromoRedeem(null);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setPromoRedeem(payload.data);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (profileObj.status === "succeeded") {
      return (
        <Form
          initialValues={{
            experience: {
              id: experience.experience.id,
              addOns: experience.addOns,
            },
            paymentMethod: "others",
            experienceId: experience.experience.id,
            quantity: experience.quantity,
            massSlotId,
            isFlexi: experience.isFlexi,
            purchasedAsGift: false,
            gift: {
              message: "",
              recipient: {
                purchasedAsGift: false,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
              },
            },
          }}
          onSubmit={formSubmitHandle}
          validationSchema={validationSchema}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <BackButton
                to={{
                  pathname: routes.MYCART,
                }}
              >
                {"< Back"}
              </BackButton>
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 12 : 9}>
            <StyledBox>
              <Grid item xs={12}>
                <StyledTitle variant="h5">Order Details</StyledTitle>
                <Box sx={{ paddingBottom: "50px" }}>
                  <BuyNowExperience
                    itemData={experience}
                    key={experience.experience.id}
                    promoRedeem={promoRedeem}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} paddingBottom="50px">
                <Form
                  initialValues={{ promoCode: "" }}
                  validationSchema={Yup.object().shape({
                    promoCode: Yup.string().label("Promo"),
                  })}
                  onSubmit={redeemHandle}
                >
                  <StyledTitle variant="h5">Promos & Vouchers</StyledTitle>
                  <Spacer size="m" position="top" />
                  <PromoCodeField />
                </Form>
              </Grid>
              <Grid item xs={12} paddingBottom="50px">
                <StyledTitle variant="h5">Payment Method</StyledTitle>
                <Spacer size="m" position="top" />
                <PaymentMethodCheckbox itemData={experience} />
              </Grid>
            </StyledBox>
          </Grid>
          {isMobile && <SeperateLine />}
          <Grid item xs={isMobile ? 12 : 3}>
            <BuyNowOrderSummary itemData={experience} promoRedeem={promoRedeem} />
          </Grid>
        </Form>
      );
    }
    return <SecureCheckoutLoader />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Grid
        container
        paddingBottom={isMobile ? "30px" : "100px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        {renderContent()}
        <ConfirmationModal
          setShowModal={setShowConfirmModal}
          showModal={showConfirmModal}
          onSubmit={accountCreditPurchase}
          message="Confirm to proceed with the Account Credits? Total amount will be deducted from your Account Credits."
          buttonText="Confirm"
        />
        <ConfirmationModal
          setShowModal={setShowTopUpModal}
          showModal={showTopUpModal}
          onSubmit={pushToTopUp}
          message="Sorry, you do not have enough balance in your account credits for this payment. Please choose another payment method or top up your credits to continue."
          buttonText="Top Up"
        />
        <Spacer size="xxl" position="top" />
      </Grid>
    </>
  );
}

export default BuyNowSecureCheckoutScreen;
