import React from "react";
import { styled, Box, Typography, useMediaQuery, useTheme, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../../../components/navigation/routes";
import { marketingSelector } from "../../../../services/marketing/marketing-slice.service";
import CarouselItemList from "../carousel-item-list.component";

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  textAlign: "start",
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[0],
}));

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  padding: isMobile
    ? theme.dimensions.MobileExperiencesPadding
    : theme.dimensions.PCExperiencesPadding,
}));

const LabelContainer = styled(Box)(({ theme }) => ({
  borderBottom: `0.1px solid ${theme.palette.colors.ui.lightBorder}`,
  borderLeft: `1px solid ${theme.palette.colors.ui.lightBorder}`,
  borderRight: `1px solid ${theme.palette.colors.ui.lightBorder}`,
  padding: "24px 16px",
  minHeight: "97px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

function FestivalItems() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getFestivalObj } = useSelector(marketingSelector);

  const getUrlLink = (link) => {
    if (!link.includes("https://")) {
      return `https://${link}`;
    }
    return link;
  };

  const renderURLFestival = (item) => (
    <a
      key={item.id}
      href={
        typeof item.query.value === "string"
          ? getUrlLink(item.query.value)
          : getUrlLink(item.query.value[0])
      }
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <CardContainer isMobile={isMobile}>
        <img
          src={item.imagePath}
          alt="festivalCover"
          style={{ borderRadius: theme.shape.borderRadius[0], width: "100%" }}
        />
        <LabelContainer>
          <StyledLabel>{item.label}</StyledLabel>
        </LabelContainer>
      </CardContainer>
    </a>
  );

  const renderTagFestival = (item) => (
    <Link
      style={{ textDecoration: "none" }}
      target="_blank"
      key={item.id}
      to={{
        pathname: routes.EXPERIENCESLISTING,
        search: `type=${item.query.type}&q=${encodeURIComponent(item.query.value.toString())}`,
      }}
    >
      <CardContainer isMobile={isMobile}>
        <img
          src={item.imagePath}
          alt="festivalCover"
          style={{ borderRadius: theme.shape.borderRadius[0], width: "100%" }}
        />
        <LabelContainer>
          <StyledLabel>{item.label}</StyledLabel>
        </LabelContainer>
      </CardContainer>
    </Link>
  );

  if (!isMobile && getFestivalObj.data.categories.length < 4) {
    return (
      <Grid container justifyContent="flex-start" spacing={2}>
        {getFestivalObj.data.categories.map((item) => (
          <Grid item xs={isMobile ? 6 : 3} key={item.id}>
            <ImageContainer>
              {item.query.type === "url" ? renderURLFestival(item) : renderTagFestival(item)}
            </ImageContainer>
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <CarouselItemList>
      {getFestivalObj.data.categories.map((item) => (
        <ImageContainer key={item.id}>
          {item.query.type === "url" ? renderURLFestival(item) : renderTagFestival(item)}
        </ImageContainer>
      ))}
    </CarouselItemList>
  );
}

export default FestivalItems;
