import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import CustomSkeleton from "../utils/skeleton.component";

function ListingItemsLoader({ width }) {
  return (
    <Box>
      <CustomSkeleton width={width} height="350px" />
    </Box>
  );
}

ListingItemsLoader.defaultProps = {
  width: "350px",
};

ListingItemsLoader.propTypes = {
  width: PropTypes.string,
};

export default ListingItemsLoader;
