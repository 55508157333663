import React, { useState } from "react";
import { Box, Drawer, Grid, IconButton, styled, Typography, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormikContext } from "formik";
import MobileFilterPriceRules from "./mobile-filter-price-rules.component";
import Spacer from "../../../../components/utils/spacer.component";
import MobileFilterPriceRange from "./mobile-filter-price-range.component";
import MobileFilterRatings from "./mobile-filter-ratings.component";
import MobileFilterBottomNavBar from "./mobile-filter-bottom-nav-bar.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: "30px",
  marginBottom: "30px",
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
}));

const FilterButtonBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: theme.shape.borderRadius[0],
}));

const StyledIconButton = styled(IconButton)({
  paddingTop: "3px",
  paddingBottom: "3px",
});

const StyledMoreVertIcon = styled(MoreVertIcon)({
  width: "20px",
  height: "20px",
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.colors.bg.white,
  },
}));

const StyledTopNavBar = styled(Box)(({ theme }) => ({
  height: "10%",
  background: theme.palette.colors.bg.white,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  position: "fixed",
  width: "100%",
  top: 0,
  zIndex: 1,
}));

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  left: "20px",
}));

const StyledMainBox = styled(Box)({
  paddingLeft: "20px",
  paddingRight: "20px",
});

function MobileFilterBar() {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { handleSubmit, setFieldValue } = useFormikContext();

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSearchPressed = () => {
    handleSubmit();
    handleDrawerToggle();
  };

  const onClearPress = () => {
    setFieldValue("pmin", "");
    setFieldValue("pmax", "");
    setFieldValue("prule", "");
    setFieldValue("ratings", []);
    handleSubmit();
    handleDrawerToggle();
  };

  return (
    <Grid item xs={12} sx={{ padding: theme.dimensions.MobileExperiencesPadding }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <StyledText variant="h4">Experiences</StyledText>
        <FilterButtonBox>
          <StyledIconButton onClick={() => setOpenDrawer(true)}>
            <StyledMoreVertIcon />
            <Typography>Filters</Typography>
            <ArrowDropDownIcon />
          </StyledIconButton>
        </FilterButtonBox>
      </Grid>
      <StyledDrawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerToggle}
      >
        <StyledTopNavBar sx={{ boxShadow: 3 }}>
          <StyledBackButton onClick={handleDrawerToggle}>
            <ArrowBackIosIcon />
          </StyledBackButton>
          <Typography variant="h5">Filters</Typography>
        </StyledTopNavBar>
        <Spacer size="xxxl" position="top" />
        <StyledMainBox>
          <MobileFilterPriceRules />
          <SeperateLine />
          <MobileFilterPriceRange />
          <SeperateLine />
          <MobileFilterRatings />
        </StyledMainBox>
        <Spacer size="xxxl" position="top" />
        <MobileFilterBottomNavBar onClearPress={onClearPress} onGoPress={onSearchPressed} />
      </StyledDrawer>
    </Grid>
  );
}

export default MobileFilterBar;
