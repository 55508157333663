import React, { useContext } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  createCreditGiftVoucherBill,
  voucherSelector,
} from "../../../services/voucher/voucher-slice.service";
import PreviewItems from "./preview-items.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import CreditVoucherPreviewLoader from "../loader/credit-voucher-preview-loader.component";

const SpaceBetweenRowBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: "90%",
  maxWidth: "600px",
  outline: "none",
  height: "95%",
  overflowY: "auto",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: "10px",
  top: "10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledAccountBalanceWalletIcon = styled(AccountBalanceWalletIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontSize: "18px",
}));

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      recipient: Yup.object().shape({
        lastName: Yup.string().label("Last Name").required(),
        firstName: Yup.string().label("First Name").required(),
        email: Yup.string().email().label("Email").required(),
        phone: Yup.string()
          .when({
            is: (value) => value?.length,
            then: (rule) => rule.min(10),
          })
          .required()
          .nullable()
          .label("Phone Number"),
      }),
    }),
  ),
});

function CreditVoucherPreviewModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { creditGiftPreviewObj, createCreditGiftVoucherBillObj } = useSelector(voucherSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmit = (values) => {
    dispatch(
      createCreditGiftVoucherBill({
        items: [
          {
            label: values.items[0].title,
            value:
              parseFloat(creditGiftPreviewObj.data.items[0].pricePerItem.replace(",", "")) * 100,
            quantity: creditGiftPreviewObj.data.summary.noItems,
            message: values.items[0].message,
            recipient: values.items[0].recipient,
          },
        ],
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        window.open(payload.data.bill.remoteUrl, "_self");
      }
    });
  };

  const processedItemList = () => {
    const items = [];
    creditGiftPreviewObj.data.items.map((voucher) =>
      items.push({
        ...voucher,
        message: "",
        recipient: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      }),
    );
    return items;
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        {creditGiftPreviewObj.status === "succeeded" ? (
          <Form
            validationSchema={validationSchema}
            initialValues={{ items: processedItemList() }}
            onSubmit={handleSubmit}
          >
            <CloseIconButton onClick={() => setShowModal(false)}>
              <StyledCloseIcon />
            </CloseIconButton>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <TitleText variant="h5">Credit Gift Voucher</TitleText>
              </Grid>
              <Grid item xs={12}>
                <SeperateLine />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PrimaryColorText>Items</PrimaryColorText>
                  </Grid>
                  {creditGiftPreviewObj.data.items.map((item, index) => (
                    <PreviewItems
                      voucher={item}
                      index={index}
                      key={`${item.title}${item.pricePerItem}`}
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <SeperateLine />
              </Grid>
              <Grid item xs={12}>
                <CenterRowBox>
                  <StyledAccountBalanceWalletIcon />
                  <Spacer size="xs" position="left" />
                  <PrimaryColorText>Payment</PrimaryColorText>
                </CenterRowBox>
              </Grid>
              <Grid item xs={12}>
                <CenterRowBox>
                  <GreyText>Online Banking / e-Wallet</GreyText>
                </CenterRowBox>
              </Grid>
              <Grid item xs={12}>
                <SeperateLine />
              </Grid>
              <Grid item xs={12}>
                <SpaceBetweenRowBox>
                  <PrimaryColorText
                    variant="h6"
                    sx={{ fontWeight: theme.fonts.fontWeights.regular }}
                  >
                    Total Payment
                  </PrimaryColorText>

                  <PrimaryColorText variant="h6">{`RM${creditGiftPreviewObj.data.summary.subTotal}`}</PrimaryColorText>
                </SpaceBetweenRowBox>
              </Grid>
              <Grid item xs={12}>
                <FormSubmitButton isLoading={createCreditGiftVoucherBillObj.status === "pending"}>
                  <Typography>Checkout</Typography>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <CreditVoucherPreviewLoader setShowModal={setShowModal} />
        )}
      </ModalBox>
    </Modal>
  );
}

CreditVoucherPreviewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CreditVoucherPreviewModal;
