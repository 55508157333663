import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const ButtonText = styled(Typography)((props) => ({
  color:
    props.isselected === "true"
      ? props.theme.palette.colors.text.white
      : props.theme.palette.colors.text.secondary,
  padding: "5px",
  fontSize: props.theme.fonts.fontSizes.size14,
}));

const ButtonBox = styled(Box)((props) => ({
  borderTopLeftRadius: props.theme.shape.borderRadius[0],
  borderTopRightRadius: props.theme.shape.borderRadius[0],
  borderBottomLeftRadius: props.theme.shape.borderRadius[0],
  borderBottomRightRadius: props.theme.shape.borderRadius[0],
  justifyContent: "center",
  display: "flex",
  border: "1px solid",
  borderColor:
    props.isselected === "true"
      ? props.theme.palette.colors.brand.primary
      : props.theme.palette.colors.ui.border,
  backgroundColor:
    props.isselected === "true" ? props.theme.palette.colors.brand.primary : "transparent",
}));

function FilterRatingsButton({ id, label }) {
  const { values, setFieldValue } = useFormikContext();
  const name = "ratings";

  const handleOnClick = () => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox === id);
    if (isChecked) {
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox !== id),
      );
    } else {
      setFieldValue(name, values[name].concat(id));
    }
  };

  return (
    <ButtonBox
      isselected={values[name].some((checked) => checked === id).toString()}
      onClick={() => {
        handleOnClick();
      }}
    >
      <Button disableRipple sx={{ padding: 0 }}>
        <ButtonText isselected={values[name].some((checked) => checked === id).toString()}>
          {label}
        </ButtonText>
      </Button>
    </ButtonBox>
  );
}

FilterRatingsButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FilterRatingsButton;
