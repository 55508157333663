import React from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const StyledMainBox = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  "&:disabled": { color: theme.palette.colors.brand.secondary },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function ExperienceDetailLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));

  return (
    <Grid xs={isMobile ? 12 : 8.5} item>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <CustomSkeleton height={isMobile ? "240px" : "480px"} width="100%" />
          <Spacer size="m" position="top" height="30px" />
          <CustomSkeleton width="300px" height="30px" />
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <StyledMainBox>
            <StyledIconButton disabled>
              <VisibilityIcon />
            </StyledIconButton>
            <StyledTitle variant="h5">Highlights</StyledTitle>
          </StyledMainBox>

          <CustomSkeleton width="350px" height="30px" />
          <CustomSkeleton width="350px" height="30px" />
          <CustomSkeleton width="350px" height="30px" />
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <StyledMainBox>
            <StyledIconButton disabled>
              <InfoIcon />
            </StyledIconButton>
            <StyledTitle variant="h5">Overview</StyledTitle>
          </StyledMainBox>
          <Spacer size="xl" position="top" />
          <CustomSkeleton width="350px" height="30px" />
          <CustomSkeleton width="350px" height="30px" />
          <CustomSkeleton width="350px" height="30px" />
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <StyledMainBox>
            <StyledIconButton disabled>
              <LocationOnIcon />
            </StyledIconButton>
            <StyledTitle variant="h5">Location</StyledTitle>
          </StyledMainBox>
          <Spacer size="xl" position="top" />
          <Box sx={{ width: isMobile ? "100%" : "90%" }}>
            <CustomSkeleton height={isMobile ? "200px" : "400px"} />
            <Spacer size="m" position="top" />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExperienceDetailLoader;
