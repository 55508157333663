import React, { useState } from "react";
import { useFormikContext } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import PropTypes from "prop-types";
import Spacer from "../../../components/utils/spacer.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const BottomBar = styled(Box)(({ theme }) => ({
  background: theme.palette.colors.bg.white,
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

const DateField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.white,
  },
}));

const TimeField = styled(Select)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.bg.white,
}));

const FormBox = styled(Box)((props) => ({
  justifyContent: "space-around",
  display: "flex",
  width: props.width,
  flexDirection: props.flexDirection,
}));

function FormDateTime({ setSelectedTime }) {
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { giftVoucherPreviewObj } = useSelector(voucherSelector);
  const dateArray = [];
  const [menuList, setMenuList] = useState([]);
  const showTimeSlotError = touched.slotId && typeof errors.slotId === "string";
  const showDateError = touched.date && typeof errors.date === "string";

  const handleDateChange = (newValue) => {
    newValue.setHours(0, 0, 0);
    setFieldValue("slotId", "");
    setFieldValue("date", newValue);
    setMenuList(
      giftVoucherPreviewObj.data.experience.availableSlots[format(newValue, "yyyy-MM-dd")],
    );
  };

  const handleSlotChange = (event) => {
    menuList.map((item) => event.target.value === item.id && setSelectedTime(item.time));

    setFieldValue("slotId", event.target.value);
  };

  const renderForm = () => {
    Object.keys(giftVoucherPreviewObj.data.experience.availableSlots).map((key) =>
      dateArray.push(`${key}T00:00:00`),
    );
    return (
      <FormBox width="100%" flexDirection="column" paddingX={isMobile ? 2 : null}>
        <Box sx={{ width: "100%" }}>
          {!isMobile ? (
            <DesktopDatePicker
              label="Date"
              minDate={new Date()}
              shouldDisableDate={(date) =>
                !dateArray.map((item) => new Date(item).getTime()).includes(date.getTime())
              }
              disableHighlightToday={true}
              inputFormat="dd/MM/yyyy"
              value={values.date}
              onChange={handleDateChange}
              InputLabelProps={{
                error: true,
              }}
              InputProps={{
                error: showDateError,
                style: { borderRadius: theme.shape.borderRadius[0] },
              }}
              renderInput={(params) => (
                <DateField
                  InputLabelProps={{ error: showDateError }}
                  inputProps={{ readOnly: true }}
                  onBlur={() => setFieldTouched("date")}
                  placeholder="Select Date"
                  {...params}
                />
              )}
            />
          ) : (
            <MobileDatePicker
              minDate={new Date()}
              shouldDisableDate={(date) =>
                !dateArray.map((item) => new Date(item).getTime()).includes(date.getTime())
              }
              disableHighlightToday={true}
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={values.date}
              onChange={handleDateChange}
              InputProps={{ error: showDateError }}
              renderInput={(params) => (
                <DateField
                  InputLabelProps={{ error: showDateError }}
                  onBlur={() => setFieldTouched("date")}
                  {...params}
                />
              )}
            />
          )}
          {showDateError && errors.date && (
            <Typography sx={{ color: theme.palette.darkColors.text.error }}>
              {errors.date}
            </Typography>
          )}
        </Box>
        <Spacer position="top" size="m" />
        <FormControl sx={{ width: "100%" }}>
          <InputLabel error={showTimeSlotError}>Time</InputLabel>
          <TimeField
            error={showTimeSlotError}
            onBlur={() => setFieldTouched("slotId")}
            variant="outlined"
            name="slotId"
            value={values.slotId}
            label="Time"
            onChange={handleSlotChange}
          >
            {menuList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.time}
              </MenuItem>
            ))}
            {menuList.length === 0 && (
              <MenuItem value="">
                <em>No Availabe Time Slot</em>
              </MenuItem>
            )}
          </TimeField>
          {showTimeSlotError && errors.slotId && (
            <Typography sx={{ color: theme.palette.darkColors.text.error }}>
              {errors.slotId}
            </Typography>
          )}
        </FormControl>
      </FormBox>
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>{renderForm()}</LocalizationProvider>
      <Spacer position="top" size="m" />
      {!isMobile ? (
        <FormSubmitButton>
          <Typography>Next</Typography>
        </FormSubmitButton>
      ) : (
        <BottomBar sx={{ boxShadow: 3 }}>
          <FormSubmitButton>
            <Typography>Next</Typography>
          </FormSubmitButton>
        </BottomBar>
      )}
    </>
  );
}

FormDateTime.propTypes = {
  setSelectedTime: PropTypes.func.isRequired,
};

export default FormDateTime;
