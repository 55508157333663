import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

function PublicRoute({ component: Component, ...otherProps }) {
  return <Route {...otherProps} render={(props) => <Component {...props} />} />;
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
