import React from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import logo from "./yolo_logo.png";
import routes from "../../components/navigation/routes";

function YoloLogo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <Link to={{ pathname: routes.HOME }}>
        <Button disableRipple>
          <img
            width={isMobile ? 75 : "auto"}
            height={isMobile ? "auto" : 49}
            src={logo}
            alt="logo"
          />
        </Button>
      </Link>
    </Box>
  );
}

export default YoloLogo;
