import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Icon,
  Paper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { guestSelector } from "../../../../services/guest/guest-slice.service";
import routes from "../../../../components/navigation/routes";
import { isLogin } from "../../../utils";

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: -3,
  right: -7,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const RelativeBox = styled(Box)({
  position: "relative",
});

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.colors.text.white,
}));

function MobileBottomNavBar({ userData }) {
  const theme = useTheme();
  const history = useHistory();
  const { guestFavouriteList } = useSelector(guestSelector);

  const handleBtmNavBar = (target) => {
    if (target === 0) {
      history.push(routes.HOME);
    }
    if (target === 1) {
      history.push(routes.MYFAVOURITES);
    }
    if (target === 2) {
      history.push(routes.BOOKINGEXPERIENCES);
    }
    if (target === 3) {
      history.push(routes.PENDINGPAYMENT);
    }
    if (target === 4) {
      history.push(routes.USERPROFILEMENU);
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        paddingBottom: 2,
      }}
      elevation={3}
    >
      <BottomNavigation onChange={(event, newValue) => handleBtmNavBar(newValue)} showLabels>
        <BottomNavigationAction
          label={
            <Typography
              sx={{
                color: theme.palette.colors.brand.secondary,
              }}
              variant="label"
            >
              Home
            </Typography>
          }
          icon={
            <HomeIcon
              sx={{
                color: theme.palette.colors.brand.secondary,
              }}
            />
          }
        />
        <BottomNavigationAction
          label={
            <Typography
              sx={{
                color: theme.palette.colors.brand.secondary,
              }}
              variant="label"
            >
              Favorites
            </Typography>
          }
          icon={
            <RelativeBox>
              <Icon>
                {userData && userData.totalFavourite > 0 && (
                  <RedDot>
                    <NotificationText>{userData.totalFavourite}</NotificationText>
                  </RedDot>
                )}
                {!isLogin() && guestFavouriteList.length > 0 && (
                  <RedDot>
                    <NotificationText>{guestFavouriteList.length}</NotificationText>
                  </RedDot>
                )}
                <FavoriteBorderIcon
                  sx={{
                    color: theme.palette.colors.brand.secondary,
                  }}
                />
              </Icon>
            </RelativeBox>
          }
        />
        {isLogin() && (
          <BottomNavigationAction
            label={
              <Typography
                sx={{
                  color: theme.palette.colors.brand.secondary,
                }}
                variant="label"
              >
                Bookings
              </Typography>
            }
            icon={
              <RelativeBox>
                <Icon>
                  {userData && userData.bookingAlert.total > 0 && (
                    <RedDot>
                      <NotificationText>{userData.bookingAlert.total}</NotificationText>
                    </RedDot>
                  )}
                  <BookOnlineOutlinedIcon
                    sx={{
                      color: theme.palette.colors.brand.secondary,
                    }}
                  />
                </Icon>
              </RelativeBox>
            }
          />
        )}
        {isLogin() && (
          <BottomNavigationAction
            label={
              <Typography
                sx={{
                  color: theme.palette.colors.brand.secondary,
                }}
                variant="label"
              >
                Payment
              </Typography>
            }
            icon={
              <RelativeBox>
                <Icon>
                  {userData && userData.totalPendingOrder > 0 && (
                    <RedDot>
                      <NotificationText>{userData.totalPendingOrder}</NotificationText>
                    </RedDot>
                  )}
                  <PendingActionsIcon
                    sx={{
                      color: theme.palette.colors.brand.secondary,
                    }}
                  />
                </Icon>
              </RelativeBox>
            }
          />
        )}

        <BottomNavigationAction
          label={
            <Typography
              sx={{
                color: theme.palette.colors.brand.secondary,
              }}
              variant="label"
            >
              Profile
            </Typography>
          }
          icon={
            <PersonIcon
              sx={{
                color: theme.palette.colors.brand.secondary,
              }}
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
}

MobileBottomNavBar.defaultProps = {
  userData: PropTypes.shape({
    totalFavourite: 0,
    totalCartItem: 0,
    totalPendingOrder: 0,
    bookingAlert: PropTypes.shape({
      total: 0,
    }),
  }),
};

MobileBottomNavBar.propTypes = {
  userData: PropTypes.shape({
    totalFavourite: PropTypes.number,
    totalCartItem: PropTypes.number,
    totalPendingOrder: PropTypes.number,
    bookingAlert: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
};

export default MobileBottomNavBar;
