import StarRateIcon from "@mui/icons-material/StarRate";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
  fontWeight: theme.fonts.fontWeights.bold,
  overflowWrap: "anywhere",
  lineheight: "20px",
}));

const BoldGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size20,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: "25px",
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size16,
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.text.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

function ExperiencesDetailsComponent({ itemData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));

  const renderRating = () => (
    <StyledRatingBox disableRipple>
      <StyledRatingStar />
      <Spacer size="xs" position="left" />
      <PrimaryColorText>{itemData.ratings}</PrimaryColorText>

      <Spacer size="m" position="left" />
      <ReviewGreyText>{`${itemData.noReviews} reviews`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const getNoOfPax = () => {
    if (itemData.maxNoPax === itemData.minNoPax) {
      if (itemData.maxNoPax === 1) {
        return "For 1 person";
      }
      return `Up to ${itemData.maxNoPax} persons`;
    }
    return `For ${itemData.minNoPax} - ${itemData.maxNoPax} persons`;
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Box>
            <StyledTitle>{itemData.title}</StyledTitle>
            <BoldGreyText>{`In ${itemData.state}`}</BoldGreyText>
            {itemData.shoutOut ? (
              <GreyText>{itemData.shoutOut}</GreyText>
            ) : (
              <GreyText>{getNoOfPax()}</GreyText>
            )}
          </Box>
        </Box>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          {renderRating()}
        </Grid>
      )}
    </Grid>
  );
}

ExperiencesDetailsComponent.defaultProps = {
  itemData: PropTypes.shape({
    title: "",
    state: "",
    ratings: 0,
    noReviews: 0,
    favourited: false,
    experienceId: null,
    shoutOut: "",
    maxNoPax: 0,
  }),
};

ExperiencesDetailsComponent.propTypes = {
  itemData: PropTypes.shape({
    minNoPax: PropTypes.number,
    maxNoPax: PropTypes.number,
    title: PropTypes.string,
    state: PropTypes.string,
    shoutOut: PropTypes.string,
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
    favourited: PropTypes.bool,
    experienceId: PropTypes.number,
  }),
};

export default ExperiencesDetailsComponent;
