import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, styled, useTheme, useMediaQuery, Link } from "@mui/material";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { orderSelector } from "../../../services/order/order-slice.service";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: isMobile ? "100%" : "95%",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const GreyTextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textDecorationColor: theme.palette.colors.text.secondary,
}));

function BuyNowOrderSummary({ itemData, promoRedeem }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { buyNowSecureCheckoutObj } = useSelector(orderSelector);
  const getTotalCost = () => {
    const result =
      parseFloat(itemData.totalCost.replace(",", "")) -
      parseFloat(promoRedeem.discount.replace(",", ""));
    return result.toFixed(2);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
      <StyledBorderBox isMobile={isMobile}>
        <PrimaryColorText variant="h5">Order Summary</PrimaryColorText>
        <SeperateLine />

        <StyledRow>
          <GreyText>Items:</GreyText>
          <GreyText>{`${itemData.quantity} items`}</GreyText>
        </StyledRow>
        <Spacer size="s" position="top" />
        {promoRedeem && (
          <>
            <StyledRow>
              <GreyText>Subtotal:</GreyText>
              <GreyText>{`RM${itemData.totalCost}`}</GreyText>
            </StyledRow>
            <Spacer size="s" position="top" />
            <StyledRow>
              <GreyText>Discount:</GreyText>
              <GreyText>{`-RM${promoRedeem.discount}`}</GreyText>
            </StyledRow>
            <Spacer size="s" position="top" />
          </>
        )}
        <StyledRow>
          <PrimaryColorText>Total Cost:</PrimaryColorText>
          {promoRedeem ? (
            <BoldPrimaryColorText>{`RM${getTotalCost()}`}</BoldPrimaryColorText>
          ) : (
            <BoldPrimaryColorText>{`RM${itemData.totalCost}`}</BoldPrimaryColorText>
          )}
        </StyledRow>
        <Spacer size="xxxl" position="top" />
        <FormSubmitButton isLoading={buyNowSecureCheckoutObj.status === "pending"}>
          <WhiteColorText>Place Order</WhiteColorText>
        </FormSubmitButton>
        <Spacer size="m" position="top" />
        <GreyText variant="body2" sx={{ lineHeight: "17px" }}>
          {`By placing your order, you agree to our company `}
          <GreyTextLink href="http://merchantinfo.yolo.my/privacy-policy/" target="_blank">
            Privacy Policy
          </GreyTextLink>
          {` and `}
          <GreyTextLink href="http://merchantinfo.yolo.my/terms-and-conditions/" target="_blank">
            Terms & Conditions.
          </GreyTextLink>
        </GreyText>
      </StyledBorderBox>
    </Box>
  );
}

BuyNowOrderSummary.defaultProps = {
  itemData: {
    experience: {
      area: "",
      id: null,
      imgPath: "",
      price: "",
      title: "",
    },
    isFlexi: false,
    quantity: "",
    subtotalPrice: "",
  },
  promoRedeem: {
    subtotal: null,
    discount: null,
    total: null,
    creditPurchasable: null,
  },
};

BuyNowOrderSummary.propTypes = {
  itemData: PropTypes.shape({
    experience: PropTypes.shape({
      area: PropTypes.string,
      id: PropTypes.number,
      imgPath: PropTypes.string,
      price: PropTypes.string,
      title: PropTypes.string,
    }),
    isFlexi: PropTypes.bool,
    quantity: PropTypes.string,
    totalCost: PropTypes.string,
  }),
  promoRedeem: PropTypes.shape({
    subtotal: PropTypes.string,
    discount: PropTypes.string,
    total: PropTypes.string,
    creditPurchasable: PropTypes.bool,
  }),
};
export default BuyNowOrderSummary;
