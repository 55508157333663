import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.services";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/login`, payload);
  return response;
});

export const register = createAsyncThunk("auth/register", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/register`, payload);
  return response;
});

export const emailCheck = createAsyncThunk("auth/email_check", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/check_email`, payload);
  return response;
});

export const emailVerification = createAsyncThunk("auth/email_verification", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/email_verification`,
    payload,
  );
  return response;
});

export const resendVerificationCode = createAsyncThunk(
  "auth/resend_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const forgotPassword = createAsyncThunk("auth/forgot_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/forgot_password`,
    payload,
  );
  return response;
});

export const resetPassowrd = createAsyncThunk("auth/reset_password", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/reset_password`, payload);
  return response;
});

export const logout = createAsyncThunk("auth/logout", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/logout`, payload);
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    registerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    emailCheckObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    emailVerificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resetPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("yoloUserAuthToken");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.data = data;
      state.user = data;
      localStorage.setItem("yoloUserAuthToken", data.authToken);
      setAxiosToken(data.authToken);
      state.loginObj.successMessage = message;
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("yoloUserAuthToken");
      state.loginObj.status = "failed";
      state.loginObj.errorMessage = message;
    },
    [register.pending]: (state) => {
      state.registerObj.status = "pending";
    },
    [register.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      localStorage.setItem("yoloUserAuthToken", data.authToken);
      setAxiosToken(data.authToken);
      state.registerObj.status = "succeeded";
      state.registerObj.data = data;
      state.registerObj.successMessage = message;
    },
    [register.rejected]: (state, action) => {
      const { message } = action.error;

      state.registerObj.status = "failed";
      state.registerObj.errorMessage = message;
    },
    [emailCheck.pending]: (state) => {
      state.emailCheckObj.status = "pending";
    },
    [emailCheck.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.emailCheckObj.status = "succeeded";
      state.emailCheckObj.data = data;
      state.emailCheckObj.successMessage = message;
    },
    [emailCheck.rejected]: (state, action) => {
      const { message } = action.error;

      state.emailCheckObj.status = "failed";
      state.emailCheckObj.errorMessage = message;
    },
    [emailVerification.pending]: (state) => {
      state.emailVerificationObj.status = "pending";
    },
    [emailVerification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.emailVerificationObj.status = "succeeded";
      state.emailVerificationObj.data = data;
      state.emailVerificationObj.successMessage = message;
    },
    [emailVerification.rejected]: (state, action) => {
      const { message } = action.error;

      state.emailVerificationObj.status = "failed";
      state.emailVerificationObj.errorMessage = message;
    },
    [resendVerificationCode.pending]: (state) => {
      state.resendVerificationCodeObj.status = "pending";
    },
    [resendVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendVerificationCodeObj.status = "succeeded";
      state.resendVerificationCodeObj.data = data;
      state.resendVerificationCodeObj.successMessage = message;
    },
    [resendVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendVerificationCodeObj.status = "failed";
      state.resendVerificationCodeObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.data = data;
      state.forgotPasswordObj.successMessage = message;
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [resetPassowrd.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassowrd.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.data = data;
      state.resetPasswordObj.successMessage = message;
    },
    [resetPassowrd.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("yoloUserAuthToken");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;
