import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  experienceSelector,
  getExperienceDetails,
  getExperiencesSuggestions,
} from "../../../services/experiences/experience-slice.service";
import ExperiencesDetailsComponent from "../components/experiences-details.component";
import PurchaseBox from "../components/purchase-box.component";
import Highlights from "../components/highlights.component";
import Overview from "../components/overview.component";
import LocationMap from "../components/location-map.component";
import Reviews from "../components/reviews.component";
import DetailsLoader from "../loader/details-loader.component";
import AddToCartPopUpModal from "../components/add-to-cart-pop-up-modal.component";
import { addToCart, getCartList } from "../../../services/cart/cart-slice.service";
import { isLogin } from "../../../infrastructure/utils";
import { getProfile, profileSelector } from "../../../services/profile/profile-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { guestAddToCart } from "../../../services/guest/guest-slice.service";
import Form from "../../../components/forms/form.component";
import { previewSecureCheckout } from "../../../services/order/order-slice.service";
import routes from "../../../components/navigation/routes";
import YouMightAlsoLike from "../components/you-might-also-like.component";
import ExperienceImageGrid from "../components/experience-image-grid.component";
import ExperienceImageCarousel from "../components/experience-image-carousel.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  createFavourite,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";
import AddOnsPopUpModal from "../components/add-ons-pop-up-modal.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const validationSchema = Yup.object().shape({
  quantity: Yup.string().label("Quantity").required(),
});

function ExperiencesDetails() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("990"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { experienceDetailsObj, experiencesSuggestionsObj } = useSelector(experienceSelector);
  const [showModal, setShowModal] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const [cartItemAdded, setCartItemAdded] = useState({});
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const [showAddOnModal, setShowAddOnModal] = useState(false);
  const [isResetAddOns, setIsResetAddOns] = useState(false);

  const { profileObj } = useSelector(profileSelector);

  const onToggleFavourite = (isAddFavourite) => {
    if (isAddFavourite) {
      dispatch(createFavourite([{ experienceId }])).then(({ meta, error }) => {
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite({ experienceId })).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const scrollToReview = () => {
    const top = document.getElementById("reviews").offsetTop - 80 + window.scrollY;

    window.scrollTo({ top, behavior: "smooth" });
  };

  const onToggleFavouriteWithId = (experience, isAddFavourite) => {
    if (isAddFavourite) {
      dispatch(createFavourite([{ experienceId: experience.experienceId }])).then(
        ({ meta, error }) => {
          dispatch(getProfile());
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
              open: true,
            });
          }
        },
      );
      return;
    }
    dispatch(removeFavourite({ experienceId: experience.experienceId })).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExperienceDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getExperiencesSuggestions(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    if (isLogin()) {
      dispatch(getCartList()).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, [location]);

  const handleAddCart = (values, { resetForm }) => {
    if (!isLogin()) {
      setShowModal(true);
      dispatch(
        guestAddToCart({
          experienceId,
          quantity: parseInt(values.quantity, 10),
          uniqueId: `${experienceId}${values.isFlexi}`,
          isFlexi: values.isFlexi,
          massSlotId: values.massSlotId,
          addOns: values.addOns,
        }),
      );
      setCartItemAdded({
        experience: {
          imgPath: experienceDetailsObj.data.experience.banners[0],
          ...experienceDetailsObj.data.experience,
        },
        addOns: values.addOns,
        quantity: parseInt(values.quantity, 10),
        isFlexi: values.isFlexi,
        massSlotId: values.massSlotId,
      });
      setIsResetAddOns(true);
      resetForm();
      return;
    }
    if (isLogin() && values.purchaseType === "buy now") {
      if (profileObj.status === "succeeded") {
        if (profileObj.data.firstName) {
          if (!profileObj.data.emailVerified) {
            history.push(routes.VERIFICATIONCODE, { email: profileObj.data.email });
            return;
          }
        } else {
          history.push(routes.COMPLETEPROFILE);
          return;
        }
      }

      dispatch(
        previewSecureCheckout({
          experience: { id: values.experienceId, addOns: values.addOns },
          ...values,
        }),
      ).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          history.push(routes.BUYNOWSECURECHECKOUT, {
            experience: payload.data,
            massSlotId: values.massSlotId,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get data! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      });
      setIsResetAddOns(true);
      resetForm();
      return;
    }
    dispatch(addToCart([values])).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getCartList());
        dispatch(getProfile());
        setIsResetAddOns(true);
        resetForm();
        setShowModal(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (
      experienceDetailsObj.status === "succeeded" &&
      experiencesSuggestionsObj.status === "succeeded"
    ) {
      return (
        <>
          <AddToCartPopUpModal
            showModal={showModal}
            setShowModal={setShowModal}
            cartItemAdded={cartItemAdded}
          />
          <Form
            onSubmit={handleAddCart}
            initialValues={{
              quantity: "",
              isFlexi: experienceDetailsObj.data.experience.hasFlexi.status,
              massSlotId:
                (experienceDetailsObj.data.experience.hasFlexi.status &&
                  experienceDetailsObj.data.experience.hasFlexi.massSlotId) ||
                "",
              experienceId,
              addOns: [],
            }}
            validationSchema={validationSchema}
          >
            <AddOnsPopUpModal
              showModal={showAddOnModal}
              setShowModal={setShowAddOnModal}
              setIsResetAddOns={setIsResetAddOns}
              isResetAddOns={isResetAddOns}
            />
            <Grid container padding={2} rowSpacing={5} columnSpacing={3}>
              {isMobile && (
                <Grid item xs={12}>
                  <ExperiencesDetailsComponent
                    onToggleFavourite={onToggleFavourite}
                    itemData={{
                      experienceId: parseInt(experienceId, 10),
                      imgPath: experienceDetailsObj.data.experience.banners[0],
                      ...experienceDetailsObj.data.experience,
                    }}
                  />
                </Grid>
              )}
              <Grid sx={{ width: isTablet ? "100%" : null, flex: 1 }} item>
                {isMobile ? (
                  <ExperienceImageCarousel
                    itemList={experienceDetailsObj.data.experience.banners}
                  />
                ) : (
                  <>
                    <ExperiencesDetailsComponent
                      onToggleFavourite={onToggleFavourite}
                      itemData={{
                        experienceId: parseInt(experienceId, 10),
                        imgPath: experienceDetailsObj.data.experience.banners[0],
                        ...experienceDetailsObj.data.experience,
                      }}
                    />
                    <ExperienceImageGrid itemList={experienceDetailsObj.data.experience.banners} />
                  </>
                )}
              </Grid>

              <Grid sx={{ width: isTablet ? "100%" : "380px" }} item>
                <PurchaseBox
                  setShowAddOnModal={setShowAddOnModal}
                  scrollToReview={scrollToReview}
                  onToggleFavourite={onToggleFavourite}
                  experienceId={parseInt(experienceId, 10)}
                />
              </Grid>

              <Grid item xs={12}>
                <SeperateLine />
              </Grid>
              <Highlights itemData={experienceDetailsObj.data.experience} />
              <Overview itemData={experienceDetailsObj.data.experience.importantInfo} />
              <LocationMap itemData={experienceDetailsObj.data.experience} />
              <Reviews itemData={experienceDetailsObj.data.experience} />
              {experiencesSuggestionsObj.data.length !== 0 && (
                <YouMightAlsoLike
                  itemList={experiencesSuggestionsObj.data}
                  onToggleFavouriteWithId={onToggleFavouriteWithId}
                />
              )}
            </Grid>
          </Form>
        </>
      );
    }
    return <DetailsLoader />;
  };

  return (
    <Grid
      container
      overflow={isMobile ? "hidden" : null}
      paddingBottom={isMobile ? "180px" : "0px"}
    >
      <Grid item xs={12}>
        <Spacer size="xl" position="top" />
      </Grid>
      {renderContent()}
    </Grid>
  );
}

export default ExperiencesDetails;
