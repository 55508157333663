import React, { useContext } from "react";
import { Box, Typography, useMediaQuery, useTheme, styled, Link, IconButton } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import NewsRegisterationField from "./newsletter-form-field.component";
import Form from "../forms/form.component";
import { subscribeNewsletter } from "../../services/marketing/newsletter/newsletter-slice.service";
import { SnackbarContext } from "../notification/snackbar.context";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.white,
  textAlign: "center",
  fontSize: theme.fonts.size24,
}));

const StyledHint = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size14,
}));

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.brand.secondary,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile, theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  flexDirection: isMobile ? "column" : "row",
}));

const StyledFacebookIcon = styled(FacebookIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  height: "30px",
}));

const StyledIconButton = styled(IconButton)({
  paddingLeft: "0px",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const StyledInstagramIcon = styled(InstagramIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  height: "30px",
}));

const SpacebetweenBox = styled(Box)({
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});
function NewsletterSignUp() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().label("Email").required(),
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const facebookLink = "https://www.facebook.com/YOLO.Experiences.MY";
  const instagramLink = "https://www.instagram.com/yolo.experiences/";
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values, { resetForm }) => {
    dispatch(subscribeNewsletter(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <MainContainer sx={{ paddingY: 3 }}>
      <Container isMobile={isMobile} sx={{ paddingX: theme.dimensions.ScreenPaddingX }}>
        <StyledBox>
          <StyledTitle>Become a VIP & get RM5 off</StyledTitle>
          <StyledHint textAlign="start">
            Subscribe to our newsletter and save on your next purchase. T&Cs apply.
          </StyledHint>
        </StyledBox>
        <StyledBox paddingTop="10px">
          <Box width="100%">
            <Form
              initialValues={{ email: "" }}
              onSubmit={onHandleSubmit}
              validationSchema={validationSchema}
            >
              <NewsRegisterationField name="email" />
            </Form>
            <SpacebetweenBox>
              <Box>
                <StyledIconButton href={facebookLink} target="_blank">
                  <StyledFacebookIcon />
                </StyledIconButton>
                <StyledIconButton href={instagramLink} target="_blank">
                  <StyledInstagramIcon />
                </StyledIconButton>
              </Box>
              <StyledHint textAlign="end">
                <StyledLink
                  href="http://merchantinfo.yolo.my/terms-and-conditions/"
                  target="_blank"
                  underline="hover"
                >
                  Terms
                </StyledLink>
                {" · "}
                <StyledLink
                  href="http://merchantinfo.yolo.my/privacy-policy/"
                  target="_blank"
                  underline="hover"
                >
                  Privacy Policy
                </StyledLink>
              </StyledHint>
            </SpacebetweenBox>
          </Box>
        </StyledBox>
      </Container>
    </MainContainer>
  );
}

export default NewsletterSignUp;
