import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import OrderSummaryLoader from "./order-summary-loader.component";
import Spacer from "../../../components/utils/spacer.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const BackButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  width: "100%",
  color: theme.palette.colors.text.secondary,
  marginTop: 50,
  marginBottom: 50,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

function SecureCheckoutLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <BackButton
            to={{
              pathname: routes.MYCART,
            }}
          >
            {"< Back"}
          </BackButton>
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 9}>
        <StyledBox>
          <Grid item xs={12}>
            <StyledTitle variant={isMobile ? "h5" : "h4"}>Order Details</StyledTitle>
            <Box sx={{ paddingBottom: "50px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs={isMobile ? 6 : 3.5}>
                      <CustomSkeleton width="90%" height={isMobile ? "100px" : "170px"} />
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 8.5}>
                      <DetailsBox>
                        <CustomSkeleton width="50%" height="30px" />

                        <Spacer position="top" size="m" />
                        <CustomSkeleton width="50%" height="20px" />
                      </DetailsBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} paddingBottom="50px">
            <StyledTitle variant={isMobile ? "h5" : "h4"}>Promos & Vouchers</StyledTitle>
            <Spacer size="l" position="top" />
            <CustomSkeleton height="50px" width="300px" />
          </Grid>
          <Grid item xs={12} paddingBottom="50px">
            <StyledTitle variant={isMobile ? "h5" : "h4"}>Payment Method</StyledTitle>
            <Spacer size="l" position="top" />
            <CustomSkeleton height="50px" width="300px" />
            <CustomSkeleton height="50px" width="300px" />
          </Grid>
        </StyledBox>
      </Grid>
      {isMobile && <SeperateLine />}
      <Grid item xs={isMobile ? 12 : 3}>
        <OrderSummaryLoader />
      </Grid>
    </>
  );
}

export default SecureCheckoutLoader;
