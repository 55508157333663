import React from "react";
import { Typography, useTheme, Box, styled, Tooltip } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.brand.secondary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  marginBottom: "16px"
});

function FlexiCheckBox() {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();
  const { experienceDetailsObj } = useSelector(experienceSelector);

  const handleOnChange = (event) => {
    if (event.target.checked) {
      setFieldValue("isFlexi", true);
      setFieldValue("massSlotId", experienceDetailsObj.data.experience.hasFlexi.massSlotId);
    } else {
      setFieldValue("isFlexi", false);
      setFieldValue("massSlotId", "");
    }
  };

  return (
    <FlexStartRowBox>
      <CheckBox
        disabled={
          experienceDetailsObj.data.bookingTypes.flexi &&
          !experienceDetailsObj.data.bookingTypes.single &&
          !experienceDetailsObj.data.bookingTypes.multiple
        }
        checked={values.isFlexi}
        onChange={handleOnChange}
        size="small"
        sx={{
          padding: "0px",
          color: theme.palette.colors.brand.primary,
          ":hover": { backgroundColor: "transparent" },
          "&.Mui-checked": {
            color: theme.palette.colors.brand.primary,
          },
        }}
      />
      <Spacer size="s" position="left" />
      <Box>
        <CenterRowBox>
          <Typography sx={{ fontSize: theme.fonts.fontSizes.size14 }}>Flexi Booking</Typography>
          <Spacer size="s" position="left" />
          <Tooltip
            title="Simply key in the unique code of the redemption ticket when presented for it to be claimed."
            arrow
          >
            <StyledInfoOutlinedIcon />
          </Tooltip>
        </CenterRowBox>
      </Box>
    </FlexStartRowBox>
  );
}

export default FlexiCheckBox;
