import React from "react";
import { Box, FormControl, FormControlLabel, Radio, styled, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import Spacer from "../../../../components/utils/spacer.component";

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.label,
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const StyledRadion = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  "&.Mui-checked": {
    color: theme.palette.colors.text.secondary,
  },
}));

const RATINGLIST = [
  { label: "1 Star", value: "1" },
  { label: "2 Star", value: "2" },
  { label: "3 Star", value: "3" },
  { label: "4 Star", value: "4" },
  { label: "5 Star", value: "5" },
];

function MobileFilterRatings() {
  const { values, setFieldValue } = useFormikContext();
  const name = "ratings";

  const handleOnClick = (e) => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox === e.target.value);
    if (e.target.value === undefined) {
      return;
    }
    if (isChecked) {
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox !== e.target.value),
      );
    } else {
      setFieldValue(name, values[name].concat(e.target.value));
    }
  };

  return (
    <Box>
      <StyledText>Ratings</StyledText>
      <Spacer size="s" position="top" />
      <FormControl>
        {RATINGLIST.map((item) => (
          <FormControlLabel
            checked={values[name].some((checked) => checked === item.value)}
            key={item.value}
            value={item.value}
            control={<StyledRadion />}
            label={<PlaceholderText>{item.label}</PlaceholderText>}
            onClick={(e) => handleOnClick(e)}
          />
        ))}
      </FormControl>
    </Box>
  );
}

export default MobileFilterRatings;
