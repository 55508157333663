import React from "react";
import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/utils/spacer.component";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

export default function AddOnsPopUpBottomBar() {
  const { values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getTotalPrice = () => {
    let price = 0;
    values.addOns.forEach((item) => {
      price += item.quantity * parseFloat(item.price.replace(",", ""));
    });
    return price;
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: isMobile ? null : "center",
      }}
    >
      <Box>
        <GreyText variant="body2">{`Total: RM ${getTotalPrice().toFixed(2)}`}</GreyText>
        <GreyText variant="body2">{`${values.addOns.length} add-on(s) selected`}</GreyText>
      </Box>
      {isMobile && <Spacer size="m" position="top" />}
      <FormSubmitButton>
        <Typography sx={{ paddingX: "5px" }}>Select Add-On(s)</Typography>
      </FormSubmitButton>
    </Box>
  );
}
