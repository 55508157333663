import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "react-js-loader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { creditSelector } from "../../../services/credits/credit-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import { pointSelector } from "../../../services/point/points-slice.service";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: "18px",
}));

function TransactionsList({ currentTab, openTransactionDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getCreditTransactionsObj } = useSelector(creditSelector);
  const { getPointsTransactionsObj } = useSelector(pointSelector);

  const getPrice = (price) => {
    let tempPrice = "";
    if (price.includes("-")) {
      tempPrice = `-RM${price.substring(1)}`;
    } else {
      tempPrice = `+RM${price}`;
    }
    return tempPrice;
  };

  const getPoints = (points) => {
    let tempPoints = "";
    if (!points.toString().includes("-")) {
      tempPoints = `+${points}`;
    }
    return tempPoints;
  };

  const renderEmptyList = () => (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.colors.brand.secondary,
          fontWeight: theme.fonts.fontWeights.regular,
        }}
      >
        No item to show.
      </Typography>
    </Grid>
  );

  const renderLoader = () => (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
      }}
    >
      <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
    </Grid>
  );

  const renderCreditsTransaction = () => {
    if (getCreditTransactionsObj.status === "succeeded") {
      if (getCreditTransactionsObj.data.length === 0) {
        return renderEmptyList();
      }
      return (
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: 3,
          }}
        >
          {getCreditTransactionsObj.data.map((item, index) => (
            <Grid item xs={12} key={item.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& .MuiButton-root": {
                    width: "100%",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              >
                <Button disableRipple onClick={() => openTransactionDetails(item.id)}>
                  <Box sx={{ width: "100%" }}>
                    <SpaceBetweenRowBox>
                      <GreyText sx={{ width: isMobile ? "60%" : null, textAlign: "start" }}>
                        {item.description}
                      </GreyText>
                      <GreyText sx={{ textAlign: "end" }}>{getPrice(item.transacted)}</GreyText>
                    </SpaceBetweenRowBox>
                    <Box>
                      <GreyText textAlign="start">{item.createdAt}</GreyText>
                    </Box>
                  </Box>
                  <Spacer size="s" position="left" />
                  <StyledIcon />
                </Button>
              </Box>
              <Spacer size="m" position="top" />
              {index !== getCreditTransactionsObj.data.length - 1 && <SeperateLine />}
            </Grid>
          ))}
        </Grid>
      );
    }
    return renderLoader();
  };

  const renderPointsTransaction = () => {
    if (getPointsTransactionsObj.status === "succeeded") {
      if (getPointsTransactionsObj.data.length === 0) {
        return renderEmptyList();
      }
      return (
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: 3,
          }}
        >
          {getPointsTransactionsObj.data.map((item, index) => (
            <Grid item xs={12} key={item.id}>
              <SpaceBetweenRowBox>
                <GreyText sx={{ width: isMobile ? "60%" : null }}>{item.description}</GreyText>
                <GreyText>{getPoints(item.point)}</GreyText>
              </SpaceBetweenRowBox>
              <Box>
                <GreyText>{item.createdAt}</GreyText>
              </Box>
              <Spacer size="m" position="top" />
              {index !== getPointsTransactionsObj.data.length - 1 && <SeperateLine />}
            </Grid>
          ))}
        </Grid>
      );
    }
    return renderLoader();
  };

  return currentTab === "credits" ? renderCreditsTransaction() : renderPointsTransaction();
}

TransactionsList.propTypes = {
  currentTab: PropTypes.string.isRequired,
  openTransactionDetails: PropTypes.func.isRequired,
};

export default TransactionsList;
