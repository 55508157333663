import PaidIcon from "@mui/icons-material/Paid";
import { Avatar, Box, Grid, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import { isLogin } from "../../../utils";
import TextButton from "./text-button.component";

const StyledGuestBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginLeft: "-10px",
});

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "25%",
  minHeight: "160px",
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
  alignItems: "center",
}));

const CenteredRowBox = styled(Box)({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 75,
  height: 75,
  border: `2px solid ${theme.palette.colors.brand.white}`,
}));

function DrawerAvater({ handleDrawerToggle }) {
  const { profileObj } = useSelector(profileSelector);
  const theme = useTheme();
  const history = useHistory();

  const pushToLogIn = () => {
    history.push(routes.AUTH);
  };

  const pushToSignUp = () => {
    history.push(routes.AUTH);
  };

  const renderLoggedInData = () => {
    if (profileObj.status === "succeeded") {
      return (
        <>
          <Grid
            item
            xs={12}
            onClick={() => {
              history.push(routes.USERPROFILE);
              handleDrawerToggle();
            }}
          >
            <CenteredRowBox>
              <StyledAvatar src={profileObj.data.imagePath} />
              <Spacer position="left" size="m" />
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.colors.text.white,
                  textAlign: "start",
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {profileObj.data.firstName || "there"}!
              </Typography>
            </CenteredRowBox>
          </Grid>
          <Grid
            item
            xs={12}
            onClick={() => {
              history.push(routes.ACCOUNTCREDITS);
              handleDrawerToggle();
            }}
          >
            <CenteredRowBox>
              <PaidIcon sx={{ color: theme.palette.colors.brand.white }} />
              <Spacer size="xs" position="left" />
              <Typography
                color={theme.palette.colors.text.white}
              >{`RM${profileObj.data.credits}`}</Typography>
            </CenteredRowBox>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <CenteredRowBox>
            <StyledAvatar />
            <Spacer position="left" size="m" />
            <CustomSkeleton width="100px" height="30px" />
          </CenteredRowBox>
        </Grid>
        <Grid item xs={12}>
          <CenteredRowBox>
            <PaidIcon sx={{ color: theme.palette.colors.brand.white }} />
            <Spacer size="xs" position="left" />
            <CustomSkeleton width="80px" />
          </CenteredRowBox>
        </Grid>
      </>
    );
  };

  const renderGuestData = () => (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          paddingLeft: "30px",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: theme.fonts.fontWeights.bold,
            color: theme.palette.colors.text.white,
            paddingBottom: "10px",
          }}
        >
          Welcome.
        </Typography>
        <StyledGuestBox>
          <TextButton label="Log In" handleSubmit={pushToLogIn} />
          <Typography
            sx={{
              color: theme.palette.colors.text.white,
              paddingRight: "2px",
              paddingTop: "2px",
            }}
          >
            |
          </Typography>
          <TextButton label="Sign Up" handleSubmit={pushToSignUp} />
        </StyledGuestBox>
      </Grid>
    </Grid>
  );

  return (
    <StyledBox>
      <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
        {isLogin() ? renderLoggedInData() : renderGuestData()}
      </Grid>
    </StyledBox>
  );
}

DrawerAvater.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default DrawerAvater;
