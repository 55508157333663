import React from "react";
import { Grid, styled, Box, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector } from "react-redux";
import routes from "../../../../components/navigation/routes";
import { marketingSelector } from "../../../../services/marketing/marketing-slice.service";

const StyledFowardButton = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius[4],
  height: "40px",
  width: "40px",
  padding: "10px",
  right: 34,
  position: "absolute",
  "&:hover": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  "&:active": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

const StyledArrowBackIosNewIcon = styled(ArrowBackIosNewIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius[4],
  height: "40px",
  width: "40px",
  padding: "10px",
  left: 35,
  position: "absolute",
  "&:hover": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  "&:active": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

function SlickArrow(arrowProps) {
  const { children, currentSlide, slideCount, ...props } = arrowProps;
  return (
    <StyledButtonBox {...props} type="button">
      {children}
    </StyledButtonBox>
  );
}

const StyledButtonBox = styled(Box)({
  zIndex: 1,
  marginTop: "-25px",
});

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
      },
    },
  ],
  nextArrow: (
    <SlickArrow>
      <StyledFowardButton />
    </SlickArrow>
  ),
  prevArrow: (
    <SlickArrow>
      <StyledArrowBackIosNewIcon />
    </SlickArrow>
  ),
};

function MarketingBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getMarketingBannerObj } = useSelector(marketingSelector);
  const getUrlLink = (link) => {
    if (!link.includes("https://")) {
      return `https://${link}`;
    }
    return link;
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: isMobile
          ? theme.dimensions.MobileExperiencesPadding
          : theme.dimensions.PCExperiencesPadding,
      }}
    >
      <Slider {...settings}>
        {getMarketingBannerObj.data.map((item) =>
          item.query.type === "url" ? (
            <a
              key={item.id}
              href={
                typeof item.query.value === "string"
                  ? getUrlLink(item.query.value)
                  : getUrlLink(item.query.value[0])
              }
              target="_blank"
              rel="noreferrer"
              style={{ textDeocation: "none" }}
            >
              <img
                src={item.imagePath}
                alt="primary-banner"
                style={{ borderRadius: theme.shape.borderRadius[0], width: "100%" }}
              />
            </a>
          ) : (
            <Link
              key={item.id}
              target="_blank"
              to={{
                pathname: routes.EXPERIENCESLISTING,
                search: `type=${item.query.type}&q=${encodeURIComponent(
                  item.query.value.toString(),
                )}`,
              }}
            >
              <img
                src={item.imagePath}
                alt="primary-banner"
                style={{ borderRadius: theme.shape.borderRadius[0], width: "100%" }}
              />
            </Link>
          ),
        )}
      </Slider>
    </Grid>
  );
}

export default MarketingBanner;
