import React from "react";
import { Box } from "@mui/material";

import logo from "./fpx_logo.png";

function FPXLogo() {
  return (
    <Box>
      <img width="60px" height="auto" src={logo} alt="logo" />
    </Box>
  );
}

export default FPXLogo;
