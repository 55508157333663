import { styled, Rating, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textDecorationColor: theme.palette.colors.text.secondary,
  ":hover": {
    color: theme.palette.colors.text.primary,
    textDecorationColor: theme.palette.colors.text.primary,
  },
}));

const CenteredRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

const ReviewRatingBox = styled(Box)(({ isMobile, theme }) => ({
  width: isMobile ? "100%" : "450px",
  border: `1px solid${theme.palette.colors.ui.lightBorder}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  display: "flex",
  flexDirection: "row",
}));

const RatingBox = styled(Box)(({ theme }) => ({
  width: "35%",
  padding: "16px",
  borderRight: `1px solid ${theme.palette.colors.ui.lightBorder}`,
}));

const SecondaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const StyledArrowIconBox = styled(Box)(({ theme, disabled }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "12px",
  },
  "&:hover": {
    color: disabled ? theme.palette.colors.text.primary : theme.palette.colors.brand.primary,
  },
  color: theme.palette.colors.text.primary,
  backgroundColor: theme.palette.colors.brand.white,
  opacity: disabled ? 0.5 : 1,
  borderRadius: theme.shape.borderRadius[4],
  height: "25px",
  width: "25px",
  top: "-35px",
  boxShadow: "1px 2px 3px 1px #9E9E9E",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
}));

function PrevArrow(arrowProps) {
  const { onClick, currentSlide } = arrowProps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledArrowIconBox
      onClick={onClick}
      disabled={currentSlide === 0}
      sx={{ right: isMobile ? "23px" : "45px" }}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

function NextArrow(arrowProps) {
  const { onClick, currentSlide, slideCount } = arrowProps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledArrowIconBox
      onClick={onClick}
      disabled={currentSlide === slideCount - 1}
      sx={{ right: isMobile ? "-10px" : "5px" }}
    >
      <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

export default function CategoryReview({ reviews }) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("920"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <ReviewRatingBox isMobile={isTablet}>
      <RatingBox>
        <CenteredRowBox>
          <BoldPrimaryColorText sx={{ fontSize: theme.fonts.fontSizes.label }}>
            {reviews.ratings}
          </BoldPrimaryColorText>
          <Spacer size="xs" position="left" />
          <PrimaryColorText>/ 5</PrimaryColorText>
        </CenteredRowBox>
        <Spacer size="xs" position="top" />
        <SecondaryColorText>{reviews.noReviews} reviews</SecondaryColorText>
        <Rating
          value={reviews.ratings}
          readOnly
          style={{
            color: theme.palette.colors.brand.primary,
            fontSize: isMobile ? theme.fonts.fontSizes.size16 : theme.fonts.fontSizes.size22,
          }}
        />
      </RatingBox>

      <Box sx={{ padding: "16px", width: "65%" }}>
        <BoldPrimaryColorText>What Guests Say</BoldPrimaryColorText>
        <Spacer size="s" position="top" />
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <Slider {...settings}>
            {reviews.guest.map((item) => (
              <Box sx={{ width: "100%" }} key={item.id}>
                <PrimaryColorText
                  sx={{
                    fontSize: theme.fonts.fontSizes.size14,
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {item.comment}
                </PrimaryColorText>
                <Spacer size="s" position="top" />
                <PrimaryColorText sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                  {item.userName}
                </PrimaryColorText>
                <SecondaryColorText
                  sx={{
                    fontSize: theme.fonts.fontSizes.size14,
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  Booked{" "}
                  <StyledLink
                    to={{
                      pathname: routes.EXPERIENCESDETAILS,
                      search: `experienceId=${item.experience.id}`,
                    }}
                  >
                    {item.experience.title}
                  </StyledLink>
                </SecondaryColorText>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </ReviewRatingBox>
  );
}

CategoryReview.defaultProps = {
  reviews: PropTypes.shape({
    ratings: 0,
    noReviews: 0,
    guest: [],
  }),
};

CategoryReview.propTypes = {
  reviews: PropTypes.shape({
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
    guest: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
        comment: PropTypes.string,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
        }),
      }),
    ),
  }),
};
