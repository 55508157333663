import React from "react";
import { Box, Grid, Pagination, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { experienceSelector } from "../../../../services/experiences/experience-slice.service";
import ExperiencesItem from "./experiences-item.component";
import Spacer from "../../../../components/utils/spacer.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size24,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.text.primary,
  textAlign: "start",
}));

const CenterBox = styled(Box)({
  display: "flex",
  paddingTop: "40px",
  paddingBottom: "40px",
  flexDirection: "column",
  maxWidth: "550px",
});

function ExperiencesItemList({ page, handlePageChange, onToggleFavourite }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("860"));
  const { experiencesListObj } = useSelector(experienceSelector);
  const location = useLocation();
  const mobileSorryText = "We're sorry, there were no results found for: ";
  const sorryTextLine1 = "We're sorry.";
  const sorryTextLine2 = "We can't seem to find any products that match your search for";

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  const renderNoDataView = () => {
    if (isMobile) {
      return (
        <Grid container>
          <CenterBox>
            <PrimaryColorText>{mobileSorryText}</PrimaryColorText>
            <PrimaryColorText variant="h6">{`"${new URLSearchParams(location.search).get(
              "q",
            )}"`}</PrimaryColorText>
            <Spacer size="m" position="top" />
            <PrimaryColorText>Try again using these search tips:</PrimaryColorText>
            <PrimaryColorText sx={{ marginLeft: "30px", fontSize: theme.fonts.fontSizes.size16 }}>
              Double check the spelling
            </PrimaryColorText>
            <PrimaryColorText sx={{ marginLeft: "30px", fontSize: theme.fonts.fontSizes.size16 }}>
              Reduce your search to just one or two words
            </PrimaryColorText>
            <PrimaryColorText sx={{ marginLeft: "30px", fontSize: theme.fonts.fontSizes.size16 }}>
              Try a more general term, it might help you find similar results
            </PrimaryColorText>
          </CenterBox>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="center" alignItems="center">
        <CenterBox>
          <StyledTitle>{sorryTextLine1}</StyledTitle>
          <Spacer size="m" position="top" />
          <PrimaryColorText>{sorryTextLine2}</PrimaryColorText>
          <PrimaryColorText variant="h6" sx={{ textAlign: "center" }}>{`"${new URLSearchParams(
            location.search,
          ).get("q")}"`}</PrimaryColorText>
          <Spacer size="m" position="top" />
          <PrimaryColorText>Try again using these search tips:</PrimaryColorText>
          <PrimaryColorText sx={{ marginLeft: "30px" }}>Double check the spelling</PrimaryColorText>
          <PrimaryColorText sx={{ marginLeft: "30px" }}>
            Reduce your search to just one or two words
          </PrimaryColorText>
          <PrimaryColorText sx={{ marginLeft: "30px" }}>
            Try to reset filter and search again
          </PrimaryColorText>
          <PrimaryColorText sx={{ marginLeft: "30px" }}>
            Try a more general term, it might help you find similar results
          </PrimaryColorText>
        </CenterBox>
      </Grid>
    );
  };
  return (
    <Grid item xs={12} sx={{ marginTop: "-15px" }}>
      {experiencesListObj.data.experiences.items.length > 0 ? (
        <>
          <Grid container sx={{ justifyContent: "flex-start" }} rowSpacing={1}>
            {experiencesListObj.data.experiences.items.map((item) => (
              <Grid key={item.id} item xs={getItemToShow()}>
                <ExperiencesItem
                  onToggleFavourite={onToggleFavourite}
                  data={item}
                  width="100%"
                  experienceId={item.id}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" paddingTop="50px">
            <Pagination
              page={page}
              onChange={handlePageChange}
              count={experiencesListObj.data.experiences.pagination.totalPages}
              variant="outlined"
            />
          </Grid>
        </>
      ) : (
        renderNoDataView()
      )}
    </Grid>
  );
}

ExperiencesItemList.propTypes = {
  onToggleFavourite: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};
export default ExperiencesItemList;
