import React from "react";
import PropTypes from "prop-types";
import { styled, Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Spacer from "../../../components/utils/spacer.component";
import RecipientForm from "./recipient-form.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const SecondaryolorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  padding: "5px",
}));

const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.colors.ui.notification,
}));

const SpaceBetweenRowBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

export default function PreviewItems({ voucher, index }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderMessageBox = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" flexDirection="column">
          <SecondaryolorText>Message</SecondaryolorText>
          <RecipientForm
            isMessageField
            showErrorName="message"
            name={`items[${index}].message`}
            index={index}
            placeholder="Message"
          />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid container justifyContent="center" flexDirection="column">
          <SecondaryolorText>First Name</SecondaryolorText>
          <RecipientForm
            showErrorName="firstName"
            name={`items[${index}].recipient.firstName`}
            index={index}
            placeholder="First Name"
          />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid container justifyContent="center" flexDirection="column">
          <SecondaryolorText>Last Name</SecondaryolorText>
          <RecipientForm
            showErrorName="lastName"
            name={`items[${index}].recipient.lastName`}
            index={index}
            placeholder="Last Name"
          />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid container justifyContent="center" flexDirection="column">
          <SecondaryolorText>Phone Number</SecondaryolorText>
          <RecipientForm
            showErrorName="phone"
            name={`items[${index}].recipient.phone`}
            index={index}
            placeholder="Phone Number"
          />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid container justifyContent="center" flexDirection="column">
          <SecondaryolorText>Email</SecondaryolorText>
          <RecipientForm
            showErrorName="email"
            name={`items[${index}].recipient.email`}
            index={index}
            placeholder="Email Address"
          />
          <Spacer position="top" size="xs" />
          <RowBox>
            <WarningAmberRoundedIcon />
            <Spacer position="left" size="xs" />
            <Typography variant="body2">Please ensure the email address is correct.</Typography>
          </RowBox>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Grid item xs={12}>
        <SpaceBetweenRowBox>
          <PrimaryColorText>{`Credit Voucher: RM${voucher.pricePerItem}`}</PrimaryColorText>
          <PrimaryColorText>{`x ${voucher.quantity}`}</PrimaryColorText>
        </SpaceBetweenRowBox>
      </Grid>
      <Grid item xs={12}>
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <PrimaryColorText>Recipient Info</PrimaryColorText>
      </Grid>
      <Grid item xs={12}>
        {renderMessageBox()}
      </Grid>
    </>
  );
}

PreviewItems.propTypes = {
  index: PropTypes.number.isRequired,
  voucher: PropTypes.shape({
    title: PropTypes.string,
    quantity: PropTypes.number,
    pricePerItem: PropTypes.string,
    total: PropTypes.string,
  }).isRequired,
};
