import React from "react";
import { styled, Grid, Typography, useTheme, Box, useMediaQuery } from "@mui/material";
import ReactStars from "react-rating-stars-component";
import Spacer from "../../../../components/utils/spacer.component";
import Carousel from "./carousel.component";
import BackgroundButton from "../../../../components/button/background-button.component";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size14,
}));

const ReviewCardBox = styled(Box)({
  width: "100%",
  height: "200px",
  padding: "20px",
});

const REVIEWLIST = [
  {
    id: 1,
    user: "Robert Lee",
    rating: 5,
    description: "The entire experience was very professional and easy to navigate.",
  },
  {
    id: 2,
    user: "John T.",
    rating: 5,
    description: "Very easy to order and pay . Enjoyed our day out which was well overdue.",
  },
  {
    id: 3,
    user: "Jack Ronald",
    rating: 5,
    description: "great options, great variety and seamless transaction",
  },
  {
    id: 4,
    user: "Ana Kim",
    rating: 4.5,
    description:
      "I really enjoyed my shopping here. I had a discount code and it definitely altered my experience even more positive! Thank you!",
  },
  {
    id: 5,
    user: "Ronald Tee.",
    rating: 5,
    description:
      "I have purchased many gifts from YOLO and during Lockdown family and friends still get to have a happy and excited experience of a present.",
  },
  {
    id: 6,
    user: "Zachery Tan",
    rating: 4.5,
    description:
      "Fairly priced! Would shop here again. Did the story bridge climb with my friend. Highly recommend it to everyone.",
  },
];

function Reviews() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title sx={{ textAlign: "center" }}>What Our Customers Say</Title>
        </Grid>
        <Grid item xs={12}>
          <Carousel>
            {REVIEWLIST.map((item) => (
              <Box
                key={item.id}
                sx={{
                  padding: isMobile
                    ? theme.dimensions.MobileExperiencesPadding
                    : theme.dimensions.PCExperiencesPadding,
                }}
              >
                <ReviewCardBox sx={{ boxShadow: 3 }}>
                  <Title sx={{ fontSize: theme.fonts.fontSizes.size14 }}>{item.user}</Title>
                  <ReactStars
                    activeColor={theme.palette.colors.ui.star}
                    size={14}
                    edit={false}
                    value={parseFloat(item.rating)}
                    color={theme.palette.colors.ui.border}
                    isHalf={true}
                  />
                  <Spacer size="m" position="top" />
                  <Title
                    sx={{
                      fontSize: theme.fonts.fontSizes.size14,
                      fontWeight: "regular",
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 4,
                    }}
                  >
                    {item.description}
                  </Title>
                </ReviewCardBox>
              </Box>
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={12}>
          <Spacer position="top" size="m" />
          <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
            <BackgroundButton width="200px">
              <ButtonText>View All Reviews</ButtonText>
            </BackgroundButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Reviews;
