import React from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";

function UpdateDOBForm({
  dateOfBirth,
  setIsEditing,
  isEditing,
  handleSubmit,
  isDOBEdit,
  setIsDOBEdit,
  isLoading,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.string().notRequired().nullable(),
  });

  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsDOBEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsDOBEdit(false);
  };

  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  const dateConverter = (dob) => {
    const newDate = new Date(Date.parse(dob));
    const date = newDate.getDate();
    const month = months[newDate.getMonth()];
    const year = newDate.getFullYear();
    return `${month} ${date}, ${year}`;
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Date of Birth</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isDOBEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isDOBEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                dateOfBirth,
              }}
              onSubmit={handleFormSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <FormDatePicker name="dateOfBirth" width="75%" />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          ) : (
            <Typography color={theme.palette.colors.text.secondary}>
              {dateOfBirth === "" ? "Not Provided" : dateConverter(dateOfBirth)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdateDOBForm.propTypes = {
  dateOfBirth: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDOBEdit: PropTypes.bool.isRequired,
  setIsDOBEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdateDOBForm;
