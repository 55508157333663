import React from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const SpaceBetweenRowView = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

function TopUpPaymentSumamry() {
  const theme = useTheme();
  const { values } = useFormikContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <SpaceBetweenRowView>
          <PrimaryColorText variant="h6" sx={{ fontWeight: theme.fonts.fontWeights.regular }}>
            Total Payment
          </PrimaryColorText>

          <PrimaryColorText variant="h6">{`RM${values.amount}.00`}</PrimaryColorText>
        </SpaceBetweenRowView>
      </Grid>
    </Grid>
  );
}

export default TopUpPaymentSumamry;
