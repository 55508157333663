import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isLogin } from "../../infrastructure/utils";
import { getReq, postReq, putReq } from "../api.services";

export const getLocationCategoryList = createAsyncThunk("homeScreen/locations", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/experiences/locations`);
  return response;
});

export const getExperiencesCategoryList = createAsyncThunk("homeScreen/experiences", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/experiences/categories`);
  return response;
});

export const getLatestFavouriteDetails = createAsyncThunk(
  "experiences/latest_favourite",
  async (payload) => {
    const processedData = [];
    payload.map((item) => processedData.push({ id: parseInt(item.experienceId, 10), ...item }));
    const response = postReq(
      `${process.env.REACT_APP_API_PUBLIC_V1}/experiences/multiple_details`,
      { experiences: processedData },
    );
    return response;
  },
);

export const getLatestCartDetails = createAsyncThunk("experiences/latest_cart", async (payload) => {
  const processedData = [];
  payload.map((item) => processedData.push({ id: parseInt(item.experienceId, 10), ...item }));
  const response = postReq(`${process.env.REACT_APP_API_PUBLIC_V1}/experiences/multiple_details`, {
    experiences: processedData,
  });
  return response;
});

export const getExperienceDetails = createAsyncThunk("experiences/", async (payload) => {
  const response = getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/experiences/${payload}`,
  );
  return response;
});

export const getSearchExperiences = createAsyncThunk("experiences/search", async (payload) => {
  const response = await getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/experiences/search${payload.params}&page=${payload.page}`,
  );
  return response;
});

export const getExperiencesSuggestions = createAsyncThunk(
  "experiences/suggestions",
  async (payload) => {
    const response = await getReq(
      `${
        isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
      }/experiences/${payload}/suggestions`,
    );
    return response;
  },
);

export const getAutoCompleteSuggestion = createAsyncThunk(
  "experiences/autoComplete/suggestions",
  async (payload) => {
    const response = await getReq(
      `${
        isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
      }/experiences/auto_complete/?${payload.params}`,
    );
    return response;
  },
);

export const getExperiencesReviews = createAsyncThunk("experiences/reviews", async (payload) => {
  const response = await getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/experiences/${payload.experienceId}/reviews${payload.params}&page=${payload.page}`,
  );
  return response;
});

export const likeExperienceReview = createAsyncThunk(
  "experiences/reviews/likes",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/experiences/${payload.experienceId}/reviews/${payload.reviewId}`,
      payload,
    );
    return response;
  },
);

const experiencesSlice = createSlice({
  name: "experiences",
  initialState: {
    locationCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experiencesCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    latestFavouriteDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    latestCartDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experienceDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    searchExperiencesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experiencesListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experiencesSuggestionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExperiencesReviewsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    likeExperienceReviewObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getLocationCategoryList.pending]: (state) => {
      state.locationCategoryListObj.status = "pending";
    },
    [getLocationCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.locationCategoryListObj.status = "succeeded";
      state.locationCategoryListObj.data = data;
      state.locationCategoryListObj.successMessage = message;
    },
    [getLocationCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.locationCategoryListObj.status = "failed";
      state.locationCategoryListObj.errorMessage = message;
    },
    [getExperiencesCategoryList.pending]: (state) => {
      state.experiencesCategoryListObj.status = "pending";
    },
    [getExperiencesCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experiencesCategoryListObj.status = "succeeded";
      state.experiencesCategoryListObj.data = data;
      state.experiencesCategoryListObj.successMessage = message;
    },
    [getExperiencesCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.experiencesCategoryListObj.status = "failed";
      state.experiencesCategoryListObj.errorMessage = message;
    },
    [getLatestFavouriteDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.latestFavouriteDetailsObj.status = "succeeded";
      state.latestFavouriteDetailsObj.data = data;
      state.latestFavouriteDetailsObj.successMessage = message;
    },
    [getLatestFavouriteDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.latestFavouriteDetailsObj.status = "failed";
      state.latestFavouriteDetailsObj.errorMessage = message;
    },
    [getLatestCartDetails.pending]: (state) => {
      state.latestCartDetailsObj.status = "pending";
    },
    [getLatestCartDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.latestCartDetailsObj.status = "succeeded";
      state.latestCartDetailsObj.data = data;
      state.latestCartDetailsObj.successMessage = message;
    },
    [getLatestCartDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.latestCartDetailsObj.status = "failed";
      state.latestCartDetailsObj.errorMessage = message;
    },
    [getExperienceDetails.pending]: (state) => {
      state.experienceDetailsObj.status = "pending";
    },
    [getExperienceDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experienceDetailsObj.status = "succeeded";
      state.experienceDetailsObj.data = data;
      state.experienceDetailsObj.successMessage = message;
    },
    [getExperienceDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.experienceDetailsObj.status = "failed";
      state.experienceDetailsObj.errorMessage = message;
    },
    [getSearchExperiences.pending]: (state) => {
      state.searchExperiencesObj.status = "pending";
    },
    [getSearchExperiences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchExperiencesObj.status = "succeeded";
      state.searchExperiencesObj.data = data;
      state.searchExperiencesObj.successMessage = message;
      state.experiencesListObj.status = "succeeded";
      state.experiencesListObj.data = data;
    },
    [getSearchExperiences.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchExperiencesObj.status = "failed";
      state.searchExperiencesObj.errorMessage = message;
      state.experiencesListObj.status = "failed";
      state.experiencesListObj.data = message;
    },
    [getExperiencesSuggestions.pending]: (state) => {
      state.experiencesSuggestionsObj.status = "pending";
    },
    [getExperiencesSuggestions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experiencesSuggestionsObj.status = "succeeded";
      state.experiencesSuggestionsObj.data = data;
      state.experiencesSuggestionsObj.successMessage = message;
    },
    [getExperiencesSuggestions.rejected]: (state, action) => {
      const { message } = action.error;

      state.experiencesSuggestionsObj.status = "failed";
      state.experiencesSuggestionsObj.errorMessage = message;
    },
    [getAutoCompleteSuggestion.pending]: (state) => {
      state.getAutoCompleteSuggestionObj.status = "pending";
    },
    [getAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAutoCompleteSuggestionObj.status = "succeeded";
      state.getAutoCompleteSuggestionObj.data = data;
      state.getAutoCompleteSuggestionObj.successMessage = message;
    },
    [getAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAutoCompleteSuggestionObj.status = "failed";
      state.getAutoCompleteSuggestionObj.errorMessage = message;
    },
    [getExperiencesReviews.pending]: (state) => {
      state.getExperiencesReviewsObj.status = "pending";
    },
    [getExperiencesReviews.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperiencesReviewsObj.status = "succeeded";
      state.getExperiencesReviewsObj.data = data;
      state.getExperiencesReviewsObj.successMessage = message;
    },
    [getExperiencesReviews.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperiencesReviewsObj.status = "failed";
      state.getExperiencesReviewsObj.errorMessage = message;
    },
    [likeExperienceReview.pending]: (state) => {
      state.likeExperienceReviewObj.status = "pending";
    },
    [likeExperienceReview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.likeExperienceReviewObj.status = "succeeded";
      state.likeExperienceReviewObj.data = data;
      state.likeExperienceReviewObj.successMessage = message;
    },
    [likeExperienceReview.rejected]: (state, action) => {
      const { message } = action.error;

      state.likeExperienceReviewObj.status = "failed";
      state.likeExperienceReviewObj.errorMessage = message;
    },
  },
});

export default experiencesSlice.reducer;

// state
export const experienceSelector = (state) => state.experience;
