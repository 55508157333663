import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const ButtonText = styled(Typography)((props) => ({
  color:
    props.isselected === "true"
      ? props.theme.palette.colors.text.white
      : props.theme.palette.colors.text.secondary,
  padding: "5px",
  fontSize: props.theme.fonts.fontSizes.size14,
}));

const ButtonBox = styled(Box)((props) => ({
  borderTopLeftRadius: props.theme.shape.borderRadius[0],
  borderTopRightRadius: props.theme.shape.borderRadius[0],
  borderBottomLeftRadius: props.theme.shape.borderRadius[0],
  borderBottomRightRadius: props.theme.shape.borderRadius[0],
  justifyContent: "center",
  display: "flex",
  border: "1px solid",
  borderColor:
    props.isselected === "true"
      ? props.theme.palette.colors.brand.primary
      : props.theme.palette.colors.ui.border,
  backgroundColor:
    props.isselected === "true" ? props.theme.palette.colors.brand.primary : "transparent",
}));

function FilterPriceRuleButton({ label, id }) {
  const { values, setFieldValue } = useFormikContext();
  const name = "prule";

  const isSelected = values[name] === id;

  const handleOnClick = () => {
    if (isSelected) {
      setFieldValue(name, "");

      return;
    }
    setFieldValue(name, id);
  };

  return (
    <ButtonBox
      isselected={isSelected.toString()}
      onClick={() => {
        handleOnClick();
      }}
    >
      <Button disableRipple sx={{ padding: 0 }}>
        <ButtonText isselected={isSelected.toString()}>{label}</ButtonText>
      </Button>
    </ButtonBox>
  );
}

FilterPriceRuleButton.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default FilterPriceRuleButton;
