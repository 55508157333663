import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import YouMightAlsoLikeLoader from "./you-might-also-like-loader.component";
import PurchaseBoxLoader from "./purchase-box-loader.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.h5,
  fontWeight: theme.fonts.fontWeights.bold,
}));

function DetailsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = "Where You'll Meet";

  const titleLoader = () => (
    <Grid xs={12} item>
      <CustomSkeleton width="300px" height="30px" />
      <CustomSkeleton width="300px" height="20px" />
      <Spacer size="m" position="top" />
    </Grid>
  );

  const bannerLoader = () => (
    <Grid xs={12} item>
      <CustomSkeleton height={isMobile ? "240px" : "480px"} width="100%" />
    </Grid>
  );

  const highlightsLoader = () => (
    <Grid item xs={12}>
      <RowBox>
        <Box sx={{ width: "30%" }}>
          <StyledTitle>Highlights</StyledTitle>
        </Box>
        <Box sx={{ width: "70%" }}>
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
        </Box>
      </RowBox>
    </Grid>
  );

  const overviewLoader = () => (
    <Grid item xs={12}>
      <RowBox>
        <Box sx={{ width: "30%" }}>
          <StyledTitle>Overview</StyledTitle>
        </Box>
        <Box sx={{ width: "70%" }}>
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
        </Box>
      </RowBox>
    </Grid>
  );

  const locationLoader = () => (
    <Grid item xs={12}>
      <RowBox>
        <Box sx={{ width: "30%" }}>
          <StyledTitle>{title}</StyledTitle>
        </Box>
        <Box sx={{ width: "70%" }}>
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
        </Box>
      </RowBox>
    </Grid>
  );

  const reviewsLoader = () => (
    <Grid item xs={12}>
      <RowBox>
        <Box sx={{ width: "30%" }}>
          <StyledTitle>What others are saying</StyledTitle>
        </Box>
        <Box sx={{ width: "70%" }}>
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
          <RowBox>
            <CustomSkeleton height="100px" width="100px" />
            <Spacer size="s" position="left" />
            <CustomSkeleton height="100px" width="100px" />
          </RowBox>
          <Spacer size="m" position="top" />
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton height="100px" width="100px" />
        </Box>
      </RowBox>
    </Grid>
  );

  return (
    <Grid item xs={12} paddingY={8} paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}>
      {titleLoader()}

      <Grid container spacing={5}>
        <Grid item xs={isMobile ? 12 : 8}>
          {bannerLoader()}
        </Grid>
        {!isMobile && (
          <Grid item xs={4} paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}>
            <PurchaseBoxLoader />
          </Grid>
        )}

        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        {highlightsLoader()}

        {overviewLoader()}

        {locationLoader()}

        {reviewsLoader()}

        {isMobile && <YouMightAlsoLikeLoader />}
      </Grid>
    </Grid>
  );
}
export default DetailsLoader;
