import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import {
  experienceSelector,
  getExperienceDetails,
} from "../../../services/experiences/experience-slice.service";
import CartAddOnsItem from "./cart-add-ons-item.component";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: "90%",
  maxWidth: "750px",
  outline: "none",
  maxHeight: "90%",
  overflowY: "auto",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: "15px",
  top: "15px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function CartAddOnsModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  const { experienceDetailsObj } = useSelector(experienceSelector);

  useEffect(() => {
    if (values.experienceId !== "") {
      dispatch(getExperienceDetails(values.experienceId));
    }
  }, []);

  const getTotalPrice = () => {
    let price = 0;
    values.addOns.forEach((item) => {
      price +=
        item.quantity *
        parseFloat(item.price ? item.price.replace(",", "") : item.pricePerAddOn.replace(",", ""));
    });
    return price;
  };

  const processedItemList = () => {
    const items = [];
    experienceDetailsObj.data.experience.addOns.map((item) =>
      items.push({
        quantity: 1,
        ...item,
      }),
    );
    return items;
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <CloseIconButton onClick={() => setShowModal(false)}>
          <StyledCloseIcon />
        </CloseIconButton>

        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <TitleText variant="h5" sx={{ textAlign: "start" }}>
              Select Add-On(s)
            </TitleText>
            <Spacer position="top" size="xs" />
            <GreyText>Subject to availability. Add-ons will be refunded if unavailable.</GreyText>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>

          {experienceDetailsObj.status === "succeeded" &&
            (processedItemList().length <= 0 ? (
              <Grid item xs={12}>
                <Spacer position="top" size="l" />
                <TitleText>No Add-On(s) available</TitleText>
                <Spacer position="top" size="l" />
              </Grid>
            ) : (
              processedItemList().map((item, index) => (
                <Grid item xs={12} key={item.id}>
                  <CartAddOnsItem item={item} index={index} />
                </Grid>
              ))
            ))}

          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "center" : "space-between",
                alignItems: isMobile ? null : "center",
              }}
            >
              <Box>
                <GreyText variant="body2">{`Total: RM ${getTotalPrice().toFixed(2)}`}</GreyText>
                <GreyText variant="body2">{`${values.addOns.length} add-on(s) selected`}</GreyText>
              </Box>
              {isMobile && <Spacer size="m" position="top" />}
              <FormSubmitButton>
                <Typography sx={{ paddingX: "5px" }}>Select Add-On(s)</Typography>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CartAddOnsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CartAddOnsModal;
