import React from "react";
import { Box, styled, TextField } from "@mui/material";
import PropTypes from "prop-types";

const QuantityBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: "transparent",
  padding: "0px",
  "& .MuiTextField-root": {
    padding: "0px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "70px",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],

      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.secondary,
      },
    },
  },
}));

function NumberUpDownArrowField({ value, setValue, maxNoPax, minNoPax }) {
  const onBlurHandle = (event) => {
    if (event.target.value >= maxNoPax) {
      setValue(maxNoPax);
    }
    if (event.target.value <= minNoPax) {
      setValue(minNoPax);
    }
    if (event.target.value === "") {
      setValue(1);
    }
  };

  return (
    <QuantityBox>
      <TextField
        onBlur={onBlurHandle}
        variant="outlined"
        type="number"
        InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        placeholder="Qty"
        onChange={(e) => {
          if (e.target.value >= maxNoPax) {
            setValue(maxNoPax);
            return;
          }
          if (e.target.value <= 0 && e.target.value !== "") {
            setValue(1);
            return;
          }
          setValue(parseInt(e.target.value, 10));
        }}
        value={value}
      />
    </QuantityBox>
  );
}

NumberUpDownArrowField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  maxNoPax: PropTypes.number,
  minNoPax: PropTypes.number,
};

NumberUpDownArrowField.defaultProps = {
  maxNoPax: 99,
  minNoPax: 1,
};

export default NumberUpDownArrowField;
