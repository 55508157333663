import React from "react";
import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import SideMenuItem from "./side-menu-item.component";

function SideMenuLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item sx={{ paddingBottom: 5 }} xs={4}>
      <Grid justifyContent="center" container sx={{ width: "100%", paddingTop: 5 }}>
        <Grid
          container
          sx={{
            height: "500px",
            width: "60%",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            boxShadow: 3,
            minWidth: "250px",
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: isMobile
                ? theme.palette.colors.brand.white
                : theme.palette.colors.text.inputField,
              height: "30%",
            }}
          >
            <Grid item sx={{ width: "35%" }}>
              <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                <Grid item>
                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      sx={{
                        width: 75,
                        height: 75,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">Hi, User !</Typography>
            </Grid>
          </Grid>
          <SideMenuItem />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SideMenuLoader;
