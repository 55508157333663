import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, styled, Tooltip, Typography, useTheme } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.brand.secondary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  marginBottom: "16px",
});

function FlexiCheckBox({ itemDetails, isFlexi, setIsFlexi, setMassSlotId }) {
  const theme = useTheme();

  const handleOnChange = (event) => {
    if (event.target.checked) {
      setIsFlexi(true);
      setMassSlotId(itemDetails.hasFlexi.massSlotId);
    } else {
      setIsFlexi(false);
      setMassSlotId("");
    }
  };

  return (
    <FlexStartRowBox>
      <CheckBox
        disabled={itemDetails.bookingTypes.flexi && !itemDetails.bookingTypes.multiple}
        checked={isFlexi}
        onChange={handleOnChange}
        size="small"
        sx={{
          padding: "0px",
          color: theme.palette.colors.brand.primary,
          ":hover": { backgroundColor: "transparent" },
          "&.Mui-checked": {
            color: theme.palette.colors.brand.primary,
          },
        }}
      />
      <Spacer size="s" position="left" />
      <Box>
        <CenterRowBox>
          <Typography sx={{ fontSize: theme.fonts.fontSizes.size14 }}>Flexi Booking</Typography>
          <Spacer size="s" position="left" />
          <Tooltip
            title="Simply key in the unique code of the redemption ticket when presented for it to be claimed."
            arrow
          >
            <StyledInfoOutlinedIcon />
          </Tooltip>
        </CenterRowBox>
      </Box>
    </FlexStartRowBox>
  );
}

FlexiCheckBox.defaultProps = {
  itemDetails: {
    addOns: [],
    description: "",
    favourited: false,
    hasFlexi: {
      status: false,
    },
    id: null,
    imgPath: "",
    importantInfo: "",
    location: {
      lat: "",
      lng: "",
    },
    maxNoPax: null,
    minNoPax: null,
    noReviews: null,
    pricePerItem: "",
    quantity: null,
    ratings: null,
    state: "",
    title: "",
  },
};

FlexiCheckBox.propTypes = {
  isFlexi: PropTypes.bool.isRequired,
  setIsFlexi: PropTypes.func.isRequired,
  setMassSlotId: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    favourited: PropTypes.bool,
    hasFlexi: PropTypes.shape({
      massSlotId: PropTypes.number,
      status: PropTypes.bool,
    }),
    bookingTypes: PropTypes.shape({
      flexi: PropTypes.bool,
      multiple: PropTypes.bool,
    }),
    id: PropTypes.number,
    imgPath: PropTypes.string,
    importantInfo: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    maxNoPax: PropTypes.number,
    minNoPax: PropTypes.number,
    noReviews: PropTypes.number,
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
    ratings: PropTypes.number,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default FlexiCheckBox;
