import React from "react";
import { Grid, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SideMenuAvatar from "./side-menu-avatar.component";
import SideMenuItem from "./side-menu-item.component";
import SideMenuLoader from "./side-menu-loader.component";
import { profileSelector } from "../../services/profile/profile-slice.service";

function SideMenu({ handleUploadImage }) {
  const { profileImgObj } = useSelector(profileSelector);
  const theme = useTheme();
  const renderContent = () => {
    if (profileImgObj.status === "succeeded") {
      return (
        <Grid item sx={{ paddingBottom: 5 }} xs={4}>
          <Grid justifyContent="flex-start" container sx={{ width: "100%", paddingTop: 5 }}>
            <Grid
              container
              sx={{
                width: "95%",
                boxShadow: 3,
                minWidth: "250px",
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
              }}
            >
              <Grid item xs={12}>
                <SideMenuAvatar
                  handleUploadImage={handleUploadImage}
                  userData={profileImgObj.data}
                />
              </Grid>
              <Grid item xs={12}>
                <SideMenuItem />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return <SideMenuLoader />;
  };
  return renderContent();
}

SideMenu.defaultProps = {
  handleUploadImage: null,
};

SideMenu.propTypes = {
  handleUploadImage: PropTypes.func,
};

export default SideMenu;
