import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, styled, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import Spacer from "../../../../components/utils/spacer.component";
import BackgroundButton from "../../../../components/button/background-button.component";
import BorderButton from "../../../../components/button/border-button.component";
import routes from "../../../../components/navigation/routes";
import { bookingSelector } from "../../../../services/booking/booking-slice.service";
import { experienceSelector } from "../../../../services/experiences/experience-slice.service";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.label,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box)({
  width: "100%",
  paddingLeft: "5px",
  paddingRight: "5px",
  paddingTop: "10px",
  paddingBottom: "20px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: "25px",
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const StyledRatingBox = styled(Button)({
  padding: "0px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  textTransform: "none",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size16,
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.text.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const SpacebetweenBox = styled(Box)({
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

function BookingBox({ orderId, handleCancelBooking, scrollToReview }) {
  const history = useHistory();
  const { cancelBookingObj, getBookingDetailsObj } = useSelector(bookingSelector);
  const { experienceDetailsObj } = useSelector(experienceSelector);

  const renderRating = () => (
    <StyledRatingBox disableRipple onClick={scrollToReview}>
      <StyledRatingStar />
      <Spacer size="xs" position="left" />
      <PrimaryColorText>{experienceDetailsObj.data.experience.ratings}</PrimaryColorText>

      <Spacer size="m" position="left" />
      <ReviewGreyText>{`${experienceDetailsObj.data.experience.noReviews} reviews`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const getSubtotalPrice = () => {
    let total = 0;

    total += parseFloat(getBookingDetailsObj.data.order.item.subtotal.replace(",", ""));

    getBookingDetailsObj.data.order.item.addOns.forEach((item) => {
      total += parseFloat(item.quantity) * parseFloat(item.pricePerAddOn.replace(",", ""));
    });

    return total.toFixed(2);
  };

  const getTotalPrice = () => {
    let total = 0;

    total += parseFloat(getBookingDetailsObj.data.order.item.price.replace(",", ""));

    getBookingDetailsObj.data.order.item.addOns.forEach((item) => {
      total += parseFloat(item.quantity) * parseFloat(item.pricePerAddOn.replace(",", ""));
    });

    return total.toFixed(2);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <SpacebetweenBox>
        <StyledTitle>Select slot</StyledTitle>
        {renderRating()}
      </SpacebetweenBox>
      <SeperateLine />
      <StyledBorderBox>
        <StyledRow sx={{ justifyContent: "space-between" }}>
          <GreyText>Experience</GreyText>
          <GreyText>
            {`${
              getBookingDetailsObj.data.order.item.quantity
            } x RM${getBookingDetailsObj.data.order.item.pricePerItem.replace(",", "")}`}
          </GreyText>
        </StyledRow>
        {getBookingDetailsObj.data.order.item.addOns.map((item) => (
          <StyledRow sx={{ justifyContent: "space-between" }} key={item.id}>
            <GreyText>{item.label}</GreyText>
            <GreyText>{`${item.quantity} x RM${item.pricePerAddOn} `}</GreyText>
          </StyledRow>
        ))}

        <Spacer size="s" position="top" />
        <SeperateLine />
        <Spacer size="s" position="top" />
        <StyledRow sx={{ justifyContent: "space-between" }}>
          <StyledTitle>Subtotal</StyledTitle>
          <StyledTitle>{`RM${getSubtotalPrice()}`}</StyledTitle>
        </StyledRow>
        <StyledRow sx={{ justifyContent: "space-between" }}>
          <StyledTitle>Discount</StyledTitle>
          <StyledTitle>{`-RM${getBookingDetailsObj.data.order.item.discount}`}</StyledTitle>
        </StyledRow>
        <StyledRow sx={{ justifyContent: "space-between" }}>
          <StyledTitle>Total Cost</StyledTitle>
          <StyledTitle>{`RM${getTotalPrice()}`}</StyledTitle>
        </StyledRow>

        <Spacer size="xl" position="top" />
        {getBookingDetailsObj.data.status !== "pending" && (
          <BackgroundButton
            width="100%"
            handleSubmit={() => history.push(routes.DATETIMESELECT + orderId)}
          >
            <WhiteColorText>Select Date & Time</WhiteColorText>
          </BackgroundButton>
        )}
        <Spacer size="s" position="top" />
        <BorderButton
          width="100%"
          handleSubmit={handleCancelBooking}
          isLoading={cancelBookingObj.status === "pending"}
        >
          <PrimaryColorText>Refund</PrimaryColorText>
        </BorderButton>
      </StyledBorderBox>
      <SeperateLine />
    </Box>
  );
}

BookingBox.propTypes = {
  scrollToReview: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  handleCancelBooking: PropTypes.func.isRequired,
};

export default BookingBox;
