import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Spacer from "../../../components/utils/spacer.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const BorderLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const TitleFont = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.regular,
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size14,
  color: theme.palette.colors.text.secondary,
}));

export default function FlexiUniqueModal({ isShowModal, setIsShowModalModal, uniqueCode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setIsShowModalModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Box />
              <TitleFont variant="h5">Unique Code</TitleFont>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BorderLine />
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                paddingY: "70px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TitleFont variant="h6" xs={{ textAlign: "center" }}>
                Your Unique Code:
              </TitleFont>

              <TitleFont variant="h5">{uniqueCode}</TitleFont>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <GreyText sx={{ textAlign: "center" }}>
              Please show this code to the merchant to verify your booking.
            </GreyText>
            <Spacer size="xl" />
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

FlexiUniqueModal.defaultProps = {
  uniqueCode: "",
};

FlexiUniqueModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModalModal: PropTypes.func.isRequired,
  uniqueCode: PropTypes.string,
};
