import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import React from "react";
import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WorkIcon from "@mui/icons-material/Work";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHistory, useLocation } from "react-router-dom";
import SideMenuButton from "./side-menu-button.component";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";

const StyledText = styled(Typography)(({ theme }) => ({
  paddingLeft: "5px",
  fontSize: theme.fonts.fontSizes.label,
}));

const StyledIcon = styled(Typography)(({ theme, isSelected }) => ({
  paddingTop: "5px",
  height: "100%",
  color: isSelected ? theme.palette.colors.text.primary : theme.palette.colors.text.secondary,
}));

function SideMenuItem() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const history = useHistory();
  const location = useLocation();

  const pushToUserProfile = () => {
    history.push(routes.USERPROFILE);
  };

  const pushToHome = () => {
    history.push(routes.HOME);
  };

  const pustToBookingExperiences = () => {
    history.push(routes.BOOKINGEXPERIENCES);
  };

  const pushToAccountCredits = () => {
    history.push(routes.ACCOUNTCREDITS);
  };

  const pushToMyOrders = () => {
    history.push(routes.MYORDERS);
  };

  const pushToGiftCardOrders = () => {
    history.push(routes.GIFTCARDORDERS);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} />
      <SideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile ||
                location.pathname === routes.USERPROFILE ||
                location.pathname === routes.USERPROFILEMENU
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Profile
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname === routes.USERPROFILE || routes.USERPROFILEMENU
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <PersonIcon />
          </StyledIcon>
        }
        handleSubmit={pushToUserProfile}
      />
      <SideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname === routes.ACCOUNTCREDITS
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Account Credits
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname === routes.ACCOUNTCREDITS
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <AccountBalanceWalletIcon />
          </StyledIcon>
        }
        handleSubmit={pushToAccountCredits}
      />
      <SideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname.includes(routes.MYORDERS)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Purchase History
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname.includes(routes.MYORDERS)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <AssignmentIcon />
          </StyledIcon>
        }
        handleSubmit={pushToMyOrders}
      />

      <SideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname === routes.BOOKINGEXPERIENCES
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            My Experiences
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname === routes.BOOKINGEXPERIENCES
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <WorkIcon />
          </StyledIcon>
        }
        handleSubmit={pustToBookingExperiences}
      />
      {/* <SideMenuButton
        label={
          <StyledText
            sx={{
              color: isMobile
                ? theme.palette.colors.text.primary
                : theme.palette.colors.text.secondary,
            }}
          >
            Contest
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color: isMobile
                ? theme.palette.colors.text.primary
                : theme.palette.colors.text.secondary,
            }}
          >
            <EmojiEventsIcon />
          </StyledIcon>
        }
        handleSubmit={pushToHome}
      /> */}
      <SideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname.includes(routes.GIFTCARDORDERS)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Gift Card Orders
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname.includes(routes.GIFTCARDORDERS)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <CardGiftcardOutlinedIcon />
          </StyledIcon>
        }
        handleSubmit={pushToGiftCardOrders}
      />
      <Grid item xs={12}>
        <Spacer position="top" size="xxl" />
      </Grid>
    </Grid>
  );
}

export default SideMenuItem;
