import React from "react";
import PropTypes from "prop-types";
import HTMLRenderer from "react-html-renderer";
import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledTableCell = styled(TableCell)({
  padding: "0px",
  border: "0px",
});

const StyledHeaderTableCell = styled(TableCell)({
  backgroundColor: "transparent",
  border: "0px",
  padding: "0px",
});

function Highlights({ itemData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));

  if (!isMobile) {
    return (
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: theme.dimensions.headerHeight }}>
            <TableRow>
              <StyledHeaderTableCell sx={{ width: "40%" }}>
                <StyledTitle sx={{ height: "10px", fontSize: theme.fonts.fontSizes.size32 }}>
                  Highlights
                </StyledTitle>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: "0px" }}>
              <StyledTableCell />
              <StyledTableCell>
                <HTMLRenderer html={itemData.description} />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Spacer size="xl" position="top" />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size24 }}>Highlights</StyledTitle>
      <Spacer size="m" position="top" />
      <Box sx={{ width: "95%" }}>
        <HTMLRenderer html={itemData.description} />
      </Box>
    </Grid>
  );
}

Highlights.defaultProps = {
  itemData: PropTypes.shape({ description: "", imgPath: "" }),
};

Highlights.propTypes = {
  itemData: PropTypes.shape({
    description: PropTypes.string,
    imgPath: PropTypes.string,
  }),
};

export default Highlights;
