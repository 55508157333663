import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

function FormLabel({ children }) {
  return <Typography>{children}</Typography>;
}

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormLabel;
