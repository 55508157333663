import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const StyledRowCenterBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  marginLeft: "-5px",
}));

const PrimaryColorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size15,
}));

const PaddingGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size15,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

function ItemDetailsBox({ itemDetails, promoCodeRedeems }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getAddOns = () => {
    const addOnsString = [];
    itemDetails.addOns.map((item) =>
      addOnsString.push(
        `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
      ),
    );

    return addOnsString;
  };

  const getSubtotalCost = () => {
    let totalCost = 0;

    itemDetails.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(itemDetails.price.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  const getTotalCost = () => {
    let result = getSubtotalCost();
    result -= parseFloat(promoCodeRedeems.discount.replace(",", ""));

    return parseFloat(result).toFixed(2);
  };

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: isMobile ? "100px" : "180px" }}>
          <img
            src={itemDetails.experience.imgPath}
            alt="ExperienceImage"
            style={{
              width: isMobile ? "100px" : "180px",
              borderRadius: theme.shape.borderRadius[0],
            }}
          />
        </Box>
        <Spacer size="m" position="left" />
        <DetailsBox>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: routes.EXPERIENCESDETAILS,
              search: `experienceId=${itemDetails.experience.id}`,
            }}
          >
            <PrimaryColorTitle
              sx={{
                fontSize: theme.fonts.fontSizes.size16,
                textAlign: "start",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflowWrap: "anywhere",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {itemDetails.experience.title}
            </PrimaryColorTitle>
          </Link>

          <SpaceBetweenBox>
            <PaddingGreyText>{itemDetails.experience.state}</PaddingGreyText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PrimaryColorText>{`${itemDetails.quantity}x`}</PrimaryColorText>
              <Spacer position="left" size="xs" />
              <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                {`RM${itemDetails.pricePerItem}`}
              </PrimaryColorText>
            </Box>
          </SpaceBetweenBox>

          <StyledRowCenterBox>
            <StyledPersonIcon />
            <Spacer size="xs" position="left" />
            <PrimaryColorText>{`group of ${itemDetails.quantity}`}</PrimaryColorText>
          </StyledRowCenterBox>
        </DetailsBox>
      </Box>

      {getAddOns().length > 0 && (
        <>
          <Spacer position="top" size="xs" />
          <GreyText variant="body2">Add On(s)</GreyText>
          {getAddOns().map((item) => (
            <GreyText variant="body2" key={item}>
              {item}
            </GreyText>
          ))}
        </>
      )}
      <Spacer position="top" size="m" />
      <SpaceBetweenBox>
        <PrimaryColorText>Item Subtotal :</PrimaryColorText>
        <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
          {`RM${getSubtotalCost()}`}
        </PrimaryColorText>
      </SpaceBetweenBox>
      {promoCodeRedeems.discount && (
        <>
          <SpaceBetweenBox>
            <PrimaryColorText>Item Discount :</PrimaryColorText>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              {`- RM${promoCodeRedeems.discount}`}
            </PrimaryColorText>
          </SpaceBetweenBox>
          <SpaceBetweenBox>
            <PrimaryColorText>Item Total Price :</PrimaryColorText>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              {`RM${getTotalCost()}`}
            </PrimaryColorText>
          </SpaceBetweenBox>
        </>
      )}
    </Grid>
  );
}

ItemDetailsBox.defaultProps = {
  promoCodeRedeems: PropTypes.shape({
    discount: null,
    total: null,
  }),
  itemDetails: PropTypes.shape({
    id: null,
    quantity: null,
    subtotalPrice: "",
    isFlexi: false,
    experience: PropTypes.shape({
      imgPath: "",
      id: null,
      title: "",
      area: "",
      hasFlexi: {
        available: null,
        massSlotId: "",
        status: false,
      },
    }),
    price: "",
    pricePerItem: "",
  }),
};

ItemDetailsBox.propTypes = {
  promoCodeRedeems: PropTypes.shape({
    discount: PropTypes.string,
    total: PropTypes.string,
  }),
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    quantity: PropTypes.number,
    subtotalPrice: PropTypes.string,
    isFlexi: PropTypes.bool,
    experience: PropTypes.shape({
      imgPath: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
      state: PropTypes.string,
      hasFlexi: PropTypes.shape({
        available: PropTypes.number,
        massSlotId: PropTypes.number,
        status: PropTypes.bool,
      }),
    }),
    price: PropTypes.string,
    pricePerItem: PropTypes.string,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ItemDetailsBox;
