import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, subYears } from "date-fns";

function FormDatePicker({ name, width }) {
  const { setFieldValue, values } = useFormikContext();
  const minDate = subYears(new Date(), 99); // Max age is 60
  const maxDate = subYears(new Date(), 1); // Min age is 15
  const [currentDOB, setCurrentDOB] = useState("");
  const theme = useTheme();
  const [openPicker, setOpenPicker] = useState(false);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleChange = (eventName, newDOB) => {
    setCurrentDOB(newDOB);
    setFieldValue(eventName, format(newDOB, "yyyy-MM-dd"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date of Birth"
        value={values[name] ? new Date(values[name]) : currentDOB}
        onChange={(newDOB) => {
          handleChange(name, newDOB);
        }}
        open={openPicker}
        onOpen={() => setOpenPicker(true)}
        onClose={() => setOpenPicker(false)}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={onKeyDown}
            onClick={() => setOpenPicker(true)}
            error={false}
            sx={{
              width,
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                backgroundColor: theme.palette.colors.bg.secondary,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

FormDatePicker.defaultProps = {
  width: "100%",
};

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default FormDatePicker;
