import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import BookingCardExperienceImage from "../../bookings/components/booking-card-experience-image.component";

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  marginLeft: "-5px",
  fontSize: theme.fonts.fontSizes.size16,
}));

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  lineHeight: "25px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const Body2PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const Body2GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const PackageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
  marginTop: "15px",
  marginBottom: "10px",
}));

const FlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function ExperiencesCard({ cardDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getTotalCost = () => {
    let totalCost = 0;

    cardDetails.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(cardDetails.price.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };
  const renderExperiencesDetail = () => {
    if (isMobile) {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BookingCardExperienceImage imagePath={cardDetails.experience.imgPath} />
          <Spacer size="m" position="left" />
          <Box>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: routes.EXPERIENCESDETAILS,
                search: `experienceId=${cardDetails.experience.id}`,
              }}
            >
              <TitleText
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {cardDetails.experience.title}
              </TitleText>
            </Link>
            <GreyText>{cardDetails.experience.state}</GreyText>

            <RowBox>
              <StyledPersonIcon />
              <Spacer size="xs" position="left" />
              <PackageText>{`group of ${cardDetails.quantity}`}</PackageText>
            </RowBox>
          </Box>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BookingCardExperienceImage imagePath={cardDetails.experience.imgPath} />
        <Spacer size="m" position="left" />
        <Box>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: routes.EXPERIENCESDETAILS,
              search: `experienceId=${cardDetails.experience.id}`,
            }}
          >
            <TitleText
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {cardDetails.experience.title}
            </TitleText>
          </Link>
          <GreyText>{cardDetails.experience.state}</GreyText>

          <RowBox>
            <StyledPersonIcon />
            <Spacer size="xs" position="left" />
            <PackageText>{`group of ${cardDetails.quantity}`}</PackageText>
          </RowBox>
        </Box>
      </Box>
    );
  };

  const renderOrderDetails = () => {
    if (isMobile) {
      return cardDetails.isGift ? (
        <SpaceBetweenRowBox>
          <Box
            sx={{
              border: "1px solid",
              paddingX: "15px",
              borderColor: theme.palette.colors.brand.primary,
            }}
          >
            <PrimaryColorText
              sx={{
                fontWeight: theme.fonts.fontWeights.bold,
                color: theme.palette.colors.brand.primary,
              }}
            >
              GIFT
            </PrimaryColorText>
          </Box>
          <PrimaryColorText>
            Total:{" "}
            <span
              style={{ fontWeight: theme.fonts.fontWeights.bold }}
            >{`RM${getTotalCost()}`}</span>{" "}
          </PrimaryColorText>
        </SpaceBetweenRowBox>
      ) : (
        <FlexEndBox>
          <RowBox>
            <PrimaryColorText>Total:</PrimaryColorText>
            <Spacer size="xs" position="left" />
            <PrimaryColorText
              sx={{ fontWeight: theme.fonts.fontWeights.bold }}
            >{`RM${getTotalCost()}`}</PrimaryColorText>
          </RowBox>
        </FlexEndBox>
      );
    }
    return cardDetails.isGift ? (
      <SpaceBetweenRowBox>
        <Box
          sx={{
            border: "1px solid",
            paddingX: "15px",
            borderColor: theme.palette.colors.brand.primary,
          }}
        >
          <PrimaryColorText
            sx={{
              fontWeight: theme.fonts.fontWeights.bold,
              color: theme.palette.colors.brand.primary,
            }}
          >
            GIFT
          </PrimaryColorText>
        </Box>
        <PrimaryColorText>
          Total:{" "}
          <span style={{ fontWeight: theme.fonts.fontWeights.bold }}>{`RM${getTotalCost()}`}</span>{" "}
        </PrimaryColorText>
      </SpaceBetweenRowBox>
    ) : (
      <FlexEndBox
        sx={{
          alignItems: "center",
        }}
      >
        <RowBox>
          <PrimaryColorText>Total:</PrimaryColorText>
          <Spacer size="s" position="left" />
          <PrimaryColorText
            sx={{ fontWeight: theme.fonts.fontWeights.bold }}
          >{`RM${getTotalCost()}`}</PrimaryColorText>
        </RowBox>
      </FlexEndBox>
    );
  };

  const renderAddOns = () => (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <SpaceBetweenRowBox>
            <Body2PrimaryColorText>Experience: </Body2PrimaryColorText>
            <Body2PrimaryColorText>{`${cardDetails.quantity} x RM ${cardDetails.pricePerItem}`}</Body2PrimaryColorText>
          </SpaceBetweenRowBox>
        </Grid>
        <Grid item xs={12}>
          <Spacer size="s" position="top" />
        </Grid>
        {cardDetails.addOns.length > 0 && (
          <>
            <Grid item xs={12}>
              <Body2PrimaryColorText>Add On(s)</Body2PrimaryColorText>
            </Grid>
            <Grid item xs={12}>
              {cardDetails.addOns.map((item) => (
                <SpaceBetweenRowBox key={item.id}>
                  <Body2GreyColorText>{item.label}</Body2GreyColorText>
                  <Body2PrimaryColorText>{`${item.quantity} x RM ${item.pricePerAddOn}`}</Body2PrimaryColorText>
                </SpaceBetweenRowBox>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );

  const renderRecipientInfo = () => (
    <Box>
      <Spacer />
      <Grid container>
        <Grid item xs={12}>
          <PrimaryColorText fontWeight="bold">Recipient Info: </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <Spacer size="s" position="top" />
        </Grid>
        <Grid item>
          <Body2PrimaryColorText>Email: {cardDetails.recipient.email}</Body2PrimaryColorText>
          <Body2PrimaryColorText>
            First Name: {cardDetails.recipient.firstName}
          </Body2PrimaryColorText>
          <Body2PrimaryColorText>Last Name: {cardDetails.recipient.lastName}</Body2PrimaryColorText>
        </Grid>
      </Grid>
    </Box>
  );

  const renderDiscount = () => (
    <Grid item xs={12}>
      <SpaceBetweenRowBox>
        <Body2PrimaryColorText>Discount: </Body2PrimaryColorText>
        <Body2PrimaryColorText>{`-RM ${cardDetails.discount}`}</Body2PrimaryColorText>
      </SpaceBetweenRowBox>
    </Grid>
  );

  return (
    <CardContainer>
      {renderExperiencesDetail()}
      <Spacer position="top" size="xs" />
      {renderAddOns()}
      {renderRecipientInfo()}
      {cardDetails.discount && (
        <>
          <Spacer position="top" size="s" />
          {renderDiscount()}
        </>
      )}
      <SeperateLine />
      {renderOrderDetails()}
    </CardContainer>
  );
}

ExperiencesCard.propTypes = {
  cardDetails: PropTypes.shape({
    addOns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        price: PropTypes.string,
        pricePerAddOn: PropTypes.string,
        quantity: PropTypes.number,
        sublabel: PropTypes.string,
      }),
    ),
    recipient: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
    isGift: PropTypes.bool,
    id: PropTypes.number,
    price: PropTypes.string,
    discount: PropTypes.string,
    quantity: PropTypes.number,
    pricePerItem: PropTypes.string,
    experience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      state: PropTypes.string,
      ratings: PropTypes.number,
      noReviews: PropTypes.number,
      imgPath: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};
export default ExperiencesCard;
