export default Object.freeze({
  AUTH: "/auth",
  FORGOTPASSWORD: "/forgot_password",
  VERIFICATIONCODE: "/verification_code",
  COMPLETEPROFILE: "/complete_profile",

  USERPROFILE: "/user_profile",
  USERPROFILEMENU: "/user_profile_menu",
  MYFAVOURITES: "/my_favourites",
  MYCART: "/my_cart",

  NEWSLETTER: "/newsletter",
  HOME: "/",
  EXPERIENCESLISTING: "/experiences_listing",
  EXPERIENCESDETAILS: "/experiences/details",
  EXPERIENCES_BEST_SELLER: "/experiences/best_seller",
  EXPERIENCES_EXPLORE: "/experiences/explore",

  BUYNOWSECURECHECKOUT: "/buy_now/secure_checkout/",
  SECURECHECKOUT: "/secure_checkout",
  BILLCHECK: "/bill_check",
  BILLSUCCESS: "/bill_successful",
  BILLFAILED: "/bill_failed",
  PENDINGPAYMENT: "/pending_payment",
  ORDER_DETAILS: "/order_details",

  BOOKINGEXPERIENCES: "/booking_experiences",
  BOOKINGEXPERIENCESDETAILS: "/booking_experiences/details/",
  BOOKINGWAITINGCONFIRMATION: "/pending_confirmation",
  DATETIMESELECT: "/select_datetime/",

  MYORDERS: "/my_orders",
  MY_ORDER_DETAILS: "/my_orders/details",

  GIFTCARDORDERS: "/gift_card_orders",
  GIFTCARDORDERDETAILS: "/gift_card_orders/details",

  ACCOUNTCREDITS: "/account_credits",
  TOPUPBILLPAYMENTCHECK: "/top_up_bill_check",
  TOPUPBILLPAYMENTSUCCESS: "/top_up_bill_success/",

  VOUCHERREDEMPTION: "/voucher/redemption",
  VOUCHERDETAILS: "/voucher/details",
  VOUCHERREDEEMED: "/voucher/redeemed",
  GIFT_VOUCHER: "/gift_voucher",
  GIFT_VOUCHER_PREVIEW: "/gift_voucher/preview",
  GIFT_VOUCHER_REDEEMED: "/gift_voucher/redeemed",
  GIFT_VOUCHER_BILL_CHECK: "/gift_order_bill_check",
  GIFT_VOUCHER_BILL_SUCCESS: "/gift_order_bill_success",
});
