import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

import Spacer from "../../../../components/utils/spacer.component";
import ModalImage from "./modal-image.component";
import { cartSelector } from "../../../../services/cart/cart-slice.service";
import RemoveItemModalLoader from "../../loader/remove-item-modal-loader.component";
import BackgroundButton from "../../../../components/button/background-button.component";
import BorderButton from "../../../../components/button/border-button.component";
import { isLogin } from "../../../../infrastructure/utils";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "650px",
  outline: "none",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const PaddingPrimaryColorTextBold = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size13,
}));

const PaddingPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const DetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
}));

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const Minorbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "row" : "row",
  alignItems: isMobile ? "center" : "flex-start",
  justifyContent: isMobile ? "space-between" : "space-between",
}));

const SuccessTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: "15px",
  marginBottom: "15px",
}));

function RemoveItemPopUpModal({ showModal, setShowModal, onConfirmDelete, removeCartItemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getCartItemDetailsObj } = useSelector(cartSelector);

  const renderContent = () => {
    if (isLogin()) {
      if (getCartItemDetailsObj.status === "succeeded") {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GreyText lineHeight="20px">
                Are you sure you want to remove the following product from the cart?
              </GreyText>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 3}>
                  <ModalImage imgPath={getCartItemDetailsObj.data.experience.imgPath} />
                </Grid>

                <Grid item xs={isMobile ? 12 : 9}>
                  <DetailsBox isMobile={isMobile}>
                    <TitleText>{getCartItemDetailsObj.data.experience.title}</TitleText>
                    <Spacer size="s" position="top" />
                    <Minorbox isMobile={isMobile}>
                      <PaddingPrimaryColorText>{`Qty: ${getCartItemDetailsObj.data.quantity}`}</PaddingPrimaryColorText>
                      <PaddingPrimaryColorTextBold>{`RM${getCartItemDetailsObj.data.price}`}</PaddingPrimaryColorTextBold>
                    </Minorbox>
                  </DetailsBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      return <RemoveItemModalLoader />;
    }

    if (!isLogin() && removeCartItemDetails.experience) {
      return (
        <Grid container>
          <Grid item xs={12} paddingTop={3}>
            <GreyText lineHeight="20px">
              Are you sure you want to remove the following product from the cart?
            </GreyText>
          </Grid>

          <Grid container>
            <Grid item xs={isMobile ? 12 : 5} paddingTop={3}>
              <ModalImage imgPath={removeCartItemDetails.experience.imgPath} />
            </Grid>

            <Grid item xs={isMobile ? 12 : 7} paddingTop={3}>
              <DetailsBox isMobile={isMobile}>
                <TitleText>{removeCartItemDetails.experience.title}</TitleText>
                <Spacer size="s" position="top" />
                <Minorbox isMobile={isMobile}>
                  <PaddingPrimaryColorText>{`Qty: ${removeCartItemDetails.quantity}`}</PaddingPrimaryColorText>
                  <PaddingPrimaryColorTextBold>{`RM${
                    Number.isNaN(parseFloat(removeCartItemDetails.quantity))
                      ? "0.00"
                      : (
                          parseFloat(
                            removeCartItemDetails.experience.pricePerItem.replace(",", ""),
                          ) * parseFloat(removeCartItemDetails.quantity)
                        ).toFixed(2)
                  }`}</PaddingPrimaryColorTextBold>
                </Minorbox>
              </DetailsBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderConfirmButton = () => {
    if (isLogin()) {
      if (getCartItemDetailsObj.status === "succeeded") {
        return (
          <Grid item xs={isMobile ? 12 : 2}>
            <ButtonBox>
              <Spacer size="s" position={isMobile ? "top" : "left"} />
              <BackgroundButton handleSubmit={() => onConfirmDelete(getCartItemDetailsObj.data.id)}>
                <WhiteColorText>Yes</WhiteColorText>
              </BackgroundButton>
            </ButtonBox>
          </Grid>
        );
      }
      return null;
    }
    return (
      <Grid item xs={isMobile ? 12 : 2}>
        <ButtonBox>
          <Spacer size="s" position={isMobile ? "top" : "left"} />
          <BackgroundButton
            handleSubmit={() => onConfirmDelete(removeCartItemDetails.experience.id)}
          >
            <WhiteColorText>Yes</WhiteColorText>
          </BackgroundButton>
        </ButtonBox>
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <TopTitleBox>
          <SuccessTitleBox>
            <PrimaryColorText variant="h5">Remove Product?</PrimaryColorText>
          </SuccessTitleBox>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon sx={{ height: "25px", width: "25px" }} />
          </CloseIconButton>
        </TopTitleBox>

        {renderContent()}
        <SeperateLine />
        <Grid container justifyContent="flex-end">
          <Grid item xs={isMobile ? 12 : 2}>
            <ButtonBox>
              <Spacer size="m" position={isMobile ? "top" : "left"} />
              <BorderButton
                handleSubmit={() => {
                  setShowModal(false);
                }}
              >
                <BrandColorText>Cancel</BrandColorText>
              </BorderButton>
            </ButtonBox>
          </Grid>
          <Spacer size="m" position="left" />
          {renderConfirmButton()}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

RemoveItemPopUpModal.defaultProps = {
  removeCartItemDetails: {
    experience: {
      addOns: [],
      description: "",
      favourited: false,
      hasFlexi: {
        status: false,
      },
      id: null,
      imgPath: "",
      importantInfo: "",
      location: {
        lat: "",
        lng: "",
      },
      maxNoPax: null,
      minNoPax: null,
      noReviews: null,
      pricePerItem: "",
      quantity: null,
      ratings: null,
      state: "",
      title: "",
    },
    quantity: 1,
  },
};

RemoveItemPopUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  removeCartItemDetails: PropTypes.shape({
    experience: PropTypes.shape({
      addOns: PropTypes.arrayOf(PropTypes.shape({})),
      description: PropTypes.string,
      favourited: PropTypes.bool,
      hasFlexi: PropTypes.shape({
        status: PropTypes.bool,
      }),
      id: PropTypes.number,
      imgPath: PropTypes.string,
      importantInfo: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
      }),
      maxNoPax: PropTypes.number,
      minNoPax: PropTypes.number,
      noReviews: PropTypes.number,
      pricePerItem: PropTypes.string,
      quantity: PropTypes.number,
      ratings: PropTypes.number,
      state: PropTypes.string,
      title: PropTypes.string,
    }),
    quantity: PropTypes.number,
  }),
};

export default RemoveItemPopUpModal;
