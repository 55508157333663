import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MobileMaintenanceImage from "./assets/images/maintenance/yolo_maintenance_mobile.jpg";
import PCMaintenanceImage from "./assets/images/maintenance/yolo_maintenance_pc.jpg";
import Routes from "./components/navigation/index";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <img
          src={isMobile ? MobileMaintenanceImage : PCMaintenanceImage}
          alt="Maintenance"
          width="100%"
        />
      </Box>
    );
  }
  return <Routes />;
}

export default App;
