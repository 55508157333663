import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import { bookingSelector } from "../../../services/booking/booking-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "700px",
  outline: "none",
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const PaddingPrimaryColorTextBold = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
  paddingTop: "3px",
}));

const DetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
}));

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const Minorbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "row" : "row",
  alignItems: isMobile ? "center" : "flex-start",
  justifyContent: isMobile ? "space-between" : "space-between",
}));

const SuccessTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 10,
  marginBottom: 0,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

function CancelBookingPopUpModal({ showModal, setShowModal, onConfirmDelete, itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { cancelBookingObj } = useSelector(bookingSelector);

  const getAddOns = () => {
    const addOnsString = [];
    itemDetails.order.item.addOns.map((item) =>
      addOnsString.push(
        `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
      ),
    );
    return addOnsString;
  };

  const getSubtotal = () => {
    let totalCost = 0;

    itemDetails.order.item.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost =
      parseFloat(totalCost) + parseFloat(itemDetails.order.item.subtotal.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  const getTotalRefundAmount = () => {
    let totalCost = 0;

    itemDetails.order.item.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(itemDetails.order.item.price.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  const renderContent = () => (
    <Grid container>
      <Grid item xs={12} paddingTop={3}>
        <GreyText lineHeight="20px">
          Are you sure you want to cancel the following booking?
        </GreyText>
      </Grid>

      <Grid container>
        <Grid item xs={isMobile ? 12 : 5} paddingTop={3}>
          <img
            src={itemDetails.order.item.experience.imgPath}
            alt="exp-img"
            style={{
              width: "100%",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 7} paddingTop={3}>
          <DetailsBox isMobile={isMobile}>
            <PrimaryColorText lineHeight="20px" variant="h6">
              {itemDetails.order.item.experience.title}
            </PrimaryColorText>
            <Spacer size="s" position="top" />
            <Minorbox isMobile={isMobile}>
              <PaddingPrimaryColorTextBold>{`${itemDetails.order.item.quantity} x RM${itemDetails.order.item.pricePerItem}`}</PaddingPrimaryColorTextBold>
            </Minorbox>
            {getAddOns().length > 0 && (
              <Grid item xs={12}>
                <Spacer position="top" size="xs" />
                <PrimaryColorText variant="body2">Add On(s)</PrimaryColorText>
                {getAddOns().map((item) => (
                  <GreyText variant="body2" key={item}>
                    {item}
                  </GreyText>
                ))}
              </Grid>
            )}
          </DetailsBox>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRefundPrices = () => (
    <Grid item xs={12}>
      <Spacer position="top" size="m" />
      <SpaceBetweenBox>
        <PrimaryColorText>Subtotal :</PrimaryColorText>
        <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
          {`RM${getSubtotal()}`}
        </PrimaryColorText>
      </SpaceBetweenBox>
      {itemDetails.order.item.discount && (
        <SpaceBetweenBox>
          <PrimaryColorText>Discount :</PrimaryColorText>
          <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
            {`- RM${itemDetails.order.item.discount}`}
          </PrimaryColorText>
        </SpaceBetweenBox>
      )}
      <SpaceBetweenBox>
        <PrimaryColorText>Total Refund Amount :</PrimaryColorText>
        <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
          {`RM${getTotalRefundAmount()}`}
        </PrimaryColorText>
      </SpaceBetweenBox>
    </Grid>
  );

  const renderConfirmButton = () => (
    <Grid item xs={isMobile ? 12 : 2}>
      <ButtonBox>
        <Spacer size="s" position={isMobile ? "top" : "left"} />
        <BackgroundButton
          isLoading={cancelBookingObj.status === "pending"}
          handleSubmit={() => onConfirmDelete(itemDetails.id)}
        >
          <WhiteColorText>Yes</WhiteColorText>
        </BackgroundButton>
      </ButtonBox>
    </Grid>
  );

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <TopTitleBox>
          <SuccessTitleBox>
            <PrimaryColorText variant="h5">Cancel Booking?</PrimaryColorText>
          </SuccessTitleBox>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon sx={{ height: "25px", width: "25px" }} />
          </CloseIconButton>
        </TopTitleBox>

        {renderContent()}
        <SeperateLine />
        {renderRefundPrices()}
        <SeperateLine />
        <Spacer size="l" postion="top" />
        <Grid container justifyContent="flex-end">
          <Grid item xs={isMobile ? 12 : 2}>
            <ButtonBox>
              <Spacer size="m" position={isMobile ? "top" : "left"} />
              <BorderButton
                handleSubmit={() => {
                  setShowModal(false);
                }}
              >
                <BrandColorText>Cancel</BrandColorText>
              </BorderButton>
            </ButtonBox>
          </Grid>
          <Spacer size="m" position="left" />
          {renderConfirmButton()}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CancelBookingPopUpModal.defaultProps = {
  itemDetails: {
    id: null,
    status: "",
    noOfPax: null,
    slot: {
      checkIn: {
        date: "",
        time: "",
      },
      checkOut: {
        date: "",
        time: "",
      },
      duration: "",
      isFlexi: {
        status: false,
        code: "",
      },
    },
    cancelled_at: "",
    order: {
      id: null,
      item: {
        id: null,
        price: "",
        pricePerItem: "",
        quantity: 1,
        experience: {
          id: 0,
          title: "",
          state: "",
          ratings: 0,
          noReviews: 0,
          imgPath: "",
        },
        recipient: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        addOns: [
          {
            id: "",
            label: "",
            sublabel: "",
            quantity: null,
            pricePerAddOn: "",
            price: "",
          },
        ],
        isGift: false,
        gift: { message: "" },
      },
    },
    guest: {
      name: "",
      email: "",
      phoneNumber: "",
    },
    notes: "",
  },
};

CancelBookingPopUpModal.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    noOfPax: PropTypes.number,
    slot: PropTypes.shape({
      checkIn: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      checkOut: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      duration: PropTypes.string,
      isFlexi: PropTypes.shape({
        status: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
    cancelled_at: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        subtotal: PropTypes.string,
        discount: PropTypes.string,
        pricePerItem: PropTypes.string,
        quantity: PropTypes.number,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          state: PropTypes.string,
          ratings: PropTypes.number,
          noReviews: PropTypes.number,
          imgPath: PropTypes.string,
        }),
        recipient: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        addOns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            sublabel: PropTypes.string,
            quantity: PropTypes.number,
            pricePerAddOn: PropTypes.string,
            price: PropTypes.string,
          }),
        ),
        isGift: PropTypes.bool,
        gift: PropTypes.shape({ message: PropTypes.string }),
      }),
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    notes: PropTypes.string,
  }),
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
};

export default CancelBookingPopUpModal;
