import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import BorderButton from "../../../../components/profile-side-menu/border-button.component";
import Spacer from "../../../../components/utils/spacer.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import { isLogin } from "../../../utils";
import MiniSearchBar from "../mini-search-bar.component";
import DrawerAvater from "./mobile-drawer-avatar.component";
import DrawerMenuItem from "./mobile-drawer-menu-item.component";
import { guestSelector } from "../../../../services/guest/guest-slice.service";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: "100%",
  color: theme.palette.colors.brand.secondary,
  size: "small",
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const ButtonFlexEndBox = styled(Box)({ height: "30px", display: "flex", alignItems: "flex-end" });

const FlexBox = styled(Box)({
  display: "flex",
  flex: 1,
  height: "100%",
  alignItems: "flex-end",
  justifyContent: "center",
  padding: "30px",
});

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: 6,
  right: 5,
}));

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.colors.text.white,
}));

function MobileDrawerAppBar({
  handleLogout,
  isLoading,
  handleSearch,
  locationList,
  experienceList,
}) {
  const theme = useTheme();
  const { profileObj } = useSelector(profileSelector);
  const { guestCartQuantity } = useSelector(guestSelector);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("900") && theme.breakpoints.up("sm"));
  const [collapse, setCollapse] = useState(false);
  const history = useHistory();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const renderContestButton = () => (
    <Grid item xs={12}>
      <ButtonFlexEndBox>
        <Typography
          onClick={() => {}}
          sx={{
            fontWeight: theme.fonts.fontWeights.bold,
            color: theme.palette.colors.text.primary,
          }}
        >
          Contest
        </Typography>
      </ButtonFlexEndBox>
    </Grid>
  );

  const renderPurchaseGiftVoucher = () => (
    <Grid item xs={12}>
      <ButtonFlexEndBox>
        <Typography
          onClick={() => {
            handleDrawerToggle();
            history.push(routes.GIFT_VOUCHER);
          }}
          sx={{
            fontWeight: theme.fonts.fontWeights.bold,
            color: theme.palette.colors.text.primary,
          }}
        >
          Gift Vouchers
        </Typography>
      </ButtonFlexEndBox>
    </Grid>
  );

  const renderSearchBar = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ marginTop: "-3px", marginRight: "-5px" }}>
        <Collapse orientation="horizontal" in={collapse} unmountOnExit timeout="auto">
          <MiniSearchBar handleSearch={handleSearch} setCollapse={setCollapse} />
        </Collapse>
      </Box>
      {!collapse ? (
        <StyledIconButton
          disableRipple
          sx={{ padding: "0px" }}
          onClick={() => {
            setCollapse(true);
          }}
        >
          <SearchIcon />
        </StyledIconButton>
      ) : (
        <StyledIconButton disableRipple sx={{ padding: "0px" }} onClick={() => setCollapse(false)}>
          <CloseIcon />
        </StyledIconButton>
      )}
    </Box>
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      {renderSearchBar()}

      <StyledIconButton
        onClick={() => {
          history.push(routes.MYCART);
        }}
        size="large"
      >
        {profileObj.data && profileObj.data.totalCartItem > 0 && (
          <RedDot>
            <NotificationText>{profileObj.data.totalCartItem}</NotificationText>
          </RedDot>
        )}
        {!isLogin() && guestCartQuantity > 0 && (
          <RedDot>
            <NotificationText>{guestCartQuantity}</NotificationText>
          </RedDot>
        )}
        <ShoppingCartIcon />
      </StyledIconButton>

      <StyledIconButton
        onClick={() => {
          handleOpenDrawer();
        }}
        size="large"
      >
        <MenuIcon />
      </StyledIconButton>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: isTablet ? "35%" : "80%",
            minWidth: "300px",
            backgroundColor: theme.palette.colors.bg.white,
          },
        }}
      >
        <DrawerAvater handleDrawerToggle={handleDrawerToggle} />
        <Spacer position="top" size="m" />
        <Grid container paddingX="20px" spacing="10px">
          <DrawerMenuItem
            setOpenDrawer={setOpenDrawer}
            label="Experiences"
            itemList={experienceList}
            type="category"
            handleSearch={handleSearch}
          />
          <DrawerMenuItem
            setOpenDrawer={setOpenDrawer}
            label="Locations"
            itemList={locationList}
            type="location"
            handleSearch={handleSearch}
          />
          {/* {renderContestButton()} */}
          {renderPurchaseGiftVoucher()}
          <Grid item xs={12}>
            <ButtonFlexEndBox>
              <Typography
                onClick={() => history.push(routes.VOUCHERREDEMPTION)}
                sx={{
                  fontWeight: theme.fonts.fontWeights.bold,
                  color: theme.palette.colors.text.primary,
                }}
              >
                Use A Voucher
              </Typography>
            </ButtonFlexEndBox>
          </Grid>
        </Grid>

        {isLogin() && (
          <FlexBox>
            <Box sx={{ width: "100%" }}>
              <BorderButton
                isLoading={isLoading}
                handleSubmit={handleLogout}
                width="100%"
                height="35px"
              >
                <Typography sx={{ color: theme.palette.colors.text.primary }}>Logout</Typography>
              </BorderButton>
            </Box>
          </FlexBox>
        )}
      </Drawer>
    </Box>
  );
}
MobileDrawerAppBar.defaultProps = {
  userData: null,
  locationList: null,
  experienceList: null,
};

MobileDrawerAppBar.propTypes = {
  userData: PropTypes.shape({}),
  locationList: PropTypes.node,
  experienceList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      subcategories: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  handleLogout: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MobileDrawerAppBar;
