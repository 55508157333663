import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import { newsletterSelector } from "../../../services/marketing/newsletter/newsletter-slice.service";

function NewsletterForm() {
  const theme = useTheme();
  const { subscribeNewsletterObj } = useSelector(newsletterSelector);
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const showError = touched.email && typeof errors.email === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <TextField
        fullWidth
        onKeyDown={keyPress}
        error={showError}
        variant="standard"
        helperText={showError ? errors.email : null}
        onBlur={() => setFieldTouched("email")}
        name="email"
        placeholder="someone@email.com"
        onChange={(e) => {
          setFieldValue("email", e.target.value);
        }}
        value={values.email}
      />
      <Spacer />
      <Button
        fullWidth
        disabled={subscribeNewsletterObj.status === "pending"}
        variant="contained"
        sx={{
          borderRadius: `${theme.shape.borderRadius[3]}px`,
          backgroundColor: theme.palette.colors.brand.lightPrimary,
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
        onClick={handleSubmit}
      >
        {subscribeNewsletterObj.status === "pending" ? (
          <Box
            sx={{ height: "25px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.white} size={30} />
          </Box>
        ) : (
          <Typography textTransform="none" fontWeight="bold">
            Discover your escape
          </Typography>
        )}
      </Button>
    </>
  );
}

export default NewsletterForm;
