import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const CardBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "44%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `0.5px 0.5px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const CenterIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  width: isMobile ? "100%" : "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "normal",
}));

function CurrencyBalanceLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderContent = () => {
    if (isMobile) {
      return (
        <Grid item xs={12}>
          <Grid container sx={{ flexDirection: "column", justifyContent: "space-between" }}>
            <CardBox isMobile={isMobile}>
              <PaddedView multiples={3}>
                <GreyText variant="h6">Available Credits</GreyText>
                <CustomSkeleton height="30px" />
              </PaddedView>
            </CardBox>
            {/* <Spacer size="s" position="top" />
            <CenterIconBox isMobile={isMobile}>
              <CustomSkeleton height="50px" width="50px" borderRadius={4} />
            </CenterIconBox>
            <Spacer size="s" position="top" />
            <CardBox isMobile={isMobile}>
              <PaddedView multiples={3}>
                <GreyText variant="h6">Available Points</GreyText>
                <CustomSkeleton height="30px" />
              </PaddedView>
            </CardBox> */}
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Grid container sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <CardBox isMobile={isMobile}>
            <PaddedView multiples={3}>
              <GreyText variant="h5">Available Credits</GreyText>

              <CustomSkeleton height="35px" />
            </PaddedView>
          </CardBox>
          {/* <CenterIconBox isMobile={isMobile}>
            <CustomSkeleton height="50px" width="50px" borderRadius={4} />
          </CenterIconBox>
          <CardBox isMobile={isMobile}>
            <PaddedView multiples={3}>
              <GreyText variant="h5">Available Points</GreyText>

              <CustomSkeleton height="35px" />
            </PaddedView>
          </CardBox> */}
        </Grid>
      </Grid>
    );
  };

  return renderContent();
}

export default CurrencyBalanceLoader;
