import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import routes from "../../../components/navigation/routes";
import {
  giftVoucherPreview,
  redeemGiftVoucher,
  voucherSelector,
} from "../../../services/voucher/voucher-slice.service";
import BannerImage from "../../../assets/images/gift_voucher_banner.png";
import PaddedView from "../../../components/utils/padded-view.component";
import GiftVoucherPreviewLoader from "../loader/gift-voucher-preview-loader.component";
import BackgroundButton from "../../../components/button/background-button.component";
import Spacer from "../../../components/utils/spacer.component";
import ConfirmationModal from "../../purchasing/components/confirmation-modal.component";
import Loading from "../../../components/notification/backdrop-loading.component";
import { getProfile } from "../../../services/profile/profile-slice.service";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

export default function GiftVoucherPreviewScreen(props) {
  const newProps = props;
  const { giftVoucher } = newProps.history.location.state;
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { giftVoucherPreviewObj } = useSelector(voucherSelector);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (giftVoucher === "" || giftVoucher === undefined) {
      history.push(routes.VOUCHERREDEMPTION);
    }
    dispatch(giftVoucherPreview({ voucherCode: giftVoucher })).then(({ meta }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Gift voucher code invalid",
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onConfirmRedeemVoucher = () => {
    setIsLoading(true);
    setShowModal(false);
    dispatch(
      redeemGiftVoucher({ type: "credit", voucherCode: giftVoucherPreviewObj.data.voucherCode }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getProfile());
        history.push(routes.GIFT_VOUCHER_REDEEMED);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderDetails = () => {
    if (giftVoucherPreviewObj.status === "succeeded") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PrimaryColorText
              sx={{
                fontSize: theme.fonts.fontSizes.size20,
              }}
            >
              Voucher Details
            </PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <GreyText>Voucher Code</GreyText>
            <PrimaryColorText>{giftVoucherPreviewObj.data.voucherCode}</PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <GreyText>Amount</GreyText>
            <PrimaryColorText>{`RM ${giftVoucherPreviewObj.data.amount}`}</PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <GreyText>Purchased Date</GreyText>
            <PrimaryColorText>{giftVoucherPreviewObj.data.purchaseDate}</PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <GreyText>Purchased By</GreyText>
            <PrimaryColorText>{`${giftVoucherPreviewObj.data.purchasedBy}`}</PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" />
          </Grid>
          <Grid item xs={12}>
            <BackgroundButton handleSubmit={() => setShowModal(true)}>
              <Typography>Redeem Now</Typography>
            </BackgroundButton>
          </Grid>
        </Grid>
      );
    }
    return <GiftVoucherPreviewLoader />;
  };

  return (
    <Grid
      container
      overflow={isMobile ? "hidden" : null}
      paddingBottom={isMobile ? "180px" : "0px"}
      padding={2}
      paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      spacing={5}
    >
      <Loading isLoading={isLoading} />
      <ConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmit={onConfirmRedeemVoucher}
        message="Confirm to redeem this voucher?"
        buttonText="Confirm"
      />
      <Grid item sx={{ display: "flex", flex: 1 }}>
        <img src={BannerImage} alt="Voucher" width="100%" />
      </Grid>
      <Grid item sx={{ width: isMobile ? "100%" : "350px" }}>
        <Box sx={{ boxShadow: 3 }}>
          <PaddedView multiples={3}>{renderDetails()}</PaddedView>
        </Box>
      </Grid>
    </Grid>
  );
}
