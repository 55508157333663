import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, useTheme } from "@mui/material";

function SideMenuButton({ icon, label, handleSubmit }) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            "& .MuiButton-root": {
              backgroundColor: "transparent",
              textTransform: "none",
              boxShadow: 0,
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none",
              },
            },
          }}
        >
          <Button disableRipple onClick={handleSubmit}>
            {icon}
            {label}
          </Button>
        </Box>
        <Box
          sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "90%" }}
        />
      </Grid>
    </Grid>
  );
}

SideMenuButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.shape({}).isRequired,
};

export default SideMenuButton;
