import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const createTopUpBill = createAsyncThunk("credits/create_top_up", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/credits/top_up`, payload);
  return response;
});

export const checkTopUpBill = createAsyncThunk("credits/check_bill", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/credits/bill_check`, payload);
  return response;
});

export const getCreditTransactions = createAsyncThunk("credits/transactions", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/credits/transactions?type=${payload}`,
    payload,
  );
  return response;
});

export const getCreditTransactionDetails = createAsyncThunk(
  "credits/transactions_details",
  async (payload) => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/credits/transactions/${payload}`,
      payload,
    );
    return response;
  },
);

const creditSlice = createSlice({
  name: "credits",
  initialState: {
    createTopUpBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkTopUpBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCreditTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCreditTransactionDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetCreditTransactionDetail: (state) => {
      state.getCreditTransactionDetailsObj.status = "idle";
      state.getCreditTransactionDetailsObj.data = null;
      state.getCreditTransactionDetailsObj.successMessage = null;
      state.getCreditTransactionDetailsObj.errorMessage = null;
    },
  },
  extraReducers: {
    [createTopUpBill.pending]: (state) => {
      state.createTopUpBillObj.status = "pending";
    },
    [createTopUpBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createTopUpBillObj.status = "succeeded";
      state.createTopUpBillObj.data = data;
      state.createTopUpBillObj.successMessage = message;
    },
    [createTopUpBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.createTopUpBillObj.status = "failed";
      state.createTopUpBillObj.errorMessage = message;
    },
    [checkTopUpBill.pending]: (state) => {
      state.checkTopUpBillObj.status = "pending";
    },
    [checkTopUpBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkTopUpBillObj.status = "succeeded";
      state.checkTopUpBillObj.data = data;
      state.checkTopUpBillObj.successMessage = message;
    },
    [checkTopUpBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkTopUpBillObj.status = "failed";
      state.checkTopUpBillObj.errorMessage = message;
    },
    [getCreditTransactions.pending]: (state) => {
      state.getCreditTransactionsObj.status = "pending";
    },
    [getCreditTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCreditTransactionsObj.status = "succeeded";
      state.getCreditTransactionsObj.data = data;
      state.getCreditTransactionsObj.successMessage = message;
    },
    [getCreditTransactionDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCreditTransactionDetailsObj.status = "failed";
      state.getCreditTransactionDetailsObj.errorMessage = message;
    },
    [getCreditTransactionDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCreditTransactionDetailsObj.status = "succeeded";
      state.getCreditTransactionDetailsObj.data = data;
      state.getCreditTransactionDetailsObj.successMessage = message;
    },
    [getCreditTransactionDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCreditTransactionDetailsObj.status = "failed";
      state.getCreditTransactionDetailsObj.errorMessage = message;
    },
  },
});

export default creditSlice.reducer;

// state
export const creditSelector = (state) => state.credits;

export const { resetCreditTransactionDetail } = creditSlice.actions;
