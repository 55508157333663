import React, { useContext, useEffect } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Festival from "../components/festival/festival.component";
import FestivalLoader from "../components/festival/festival-loader.component";
import CarouselComponent from "../components/primary-marketing-banner/primary-marketing-banner.component";
import CarouselLoader from "../components/primary-marketing-banner/primary-marketing-banner-loader.component";
import BestSeller from "../components/best-seller/best-seller.component";
import ExploreMore from "../components/explore-more/explore-more.component";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Reviews from "../components/reviews/reviews.component";
import ReviewsLoader from "../components/reviews/reviews-loader.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getBestSeller,
  getExplore,
  getFestival,
  getMarketingBanner,
  getMarketingExperience,
  marketingSelector,
} from "../../../services/marketing/marketing-slice.service";
import ExperienceBanner from "../components/experience-banner/experience-banner.component";
import ExperienceBannerLoader from "../components/experience-banner/experience-banner-loader.component";
import HeaderPromotions from "../../../infrastructure/layout/components/pc-view-app-bar-components/header-promotions.component";
import CarouselExperienceLoader from "../components/loader/carousel-experience-loader.component";
import {
  createFavourite,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

function HomeScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const {
    exploreObj,
    bestSellerObj,
    getFestivalObj,
    getMarketingBannerObj,
    getMarketingExperienceObj,
  } = useSelector(marketingSelector);
  const createSnackBar = useContext(SnackbarContext);

  const onToggleFavourite = (values, isAddFavourite) => {
    if (isAddFavourite) {
      dispatch(createFavourite([values])).then(({ meta, error }) => {
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite(values)).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getMarketingBanner()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get marketing banner data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getFestival()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get festival data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getBestSeller()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get best seller data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getExplore()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get explore more data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getMarketingExperience()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get experience banner data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderPrimaryBanner = () => {
    if (getMarketingBannerObj.status === "succeeded") {
      return <CarouselComponent />;
    }
    return <CarouselLoader />;
  };

  const renderFestivalComponent = () => {
    if (getFestivalObj.status === "succeeded") {
      return getFestivalObj.data.categories.length > 0 && <Festival />;
    }
    return <FestivalLoader />;
  };

  const renderBestSeller = () => {
    if (bestSellerObj.status === "succeeded") {
      return (
        bestSellerObj.data.length > 0 && (
          <BestSeller itemList={bestSellerObj.data} onToggleFavourite={onToggleFavourite} />
        )
      );
    }
    return <CarouselExperienceLoader />;
  };

  const renderExplore = () => {
    if (exploreObj.status === "succeeded") {
      return (
        exploreObj.data.experiences.length > 0 && (
          <ExploreMore onToggleFavourite={onToggleFavourite} />
        )
      );
    }
    return <CarouselExperienceLoader />;
  };

  const renderReviews = () => {
    if (exploreObj.status === "succeeded") {
      return <Reviews />;
    }
    return <ReviewsLoader />;
  };

  const renderExperienceCarousel = () => {
    if (getMarketingExperienceObj.status === "succeeded") {
      if (getMarketingExperienceObj.data.length > 0) {
        return (
          <>
            <ExperienceBanner />
            <HeaderPromotions />
          </>
        );
      }
      return null;
    }
    return <ExperienceBannerLoader />;
  };

  return (
    <MainContainer>
      {renderExperienceCarousel()}
      <Spacer size="xl" position="top" />
      <Grid
        container
        sx={{
          paddingY: "30px",
          maxWidth: theme.dimensions.PCMaxWidth,
          paddingX: isMobile ? theme.dimensions.ScreenPaddingX : "0px",
        }}
        rowSpacing={5}
      >
        {renderBestSeller()}
        {renderExplore()}
        {renderPrimaryBanner()}
        {/* <HowItWorks /> */}
        {renderFestivalComponent()}
        {/* {renderReviews()} */}
      </Grid>
    </MainContainer>
  );
}

export default HomeScreen;
