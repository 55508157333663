import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";
import CircleTick from "../../../assets/images/circle_tick";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

const ButtonContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  width: isMobile ? null : "450px",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: isMobile ? null : "space-between",
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

export default function PendingConfirmation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xxxl" />
      <CircleTick />
      <Spacer size="xl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>
        Your booking is waiting for a confirmation!
      </StyledTitle>
      <Spacer size="m" />
      <GreyText>You will received a confirmation email once confirmed.</GreyText>
      <Spacer size="xxxl" />
      <ButtonContainer isMobile={isMobile}>
        <BorderButton handleSubmit={() => history.push(routes.HOME)} width="220px">
          <BrandColorText>Continue shopping</BrandColorText>
        </BorderButton>
        <Spacer size="m" />
        <BackgroundButton
          handleSubmit={() => history.push(routes.BOOKINGEXPERIENCES)}
          width="220px"
        >
          <Typography>View Other Bookings</Typography>
        </BackgroundButton>
      </ButtonContainer>
    </StyledContainer>
  );
}
