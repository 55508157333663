import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import Spacer from "../../../../components/utils/spacer.component";
import { orderSelector } from "../../../../services/order/order-slice.service";
import BackgroundButton from "../../../../components/button/background-button.component";
import BorderButton from "../../../../components/button/border-button.component";

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: isMobile ? "100%" : "95%",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

function AwaitingPaymentSummary({ setIsLoading, handleCancelOrder }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { getOrderDetailsObj } = useSelector(orderSelector);
  const discount =
    parseFloat(getOrderDetailsObj.data.subtotal.replace(/,/g, "")) -
    parseFloat(getOrderDetailsObj.data.totalCost.replace(/,/g, ""));

  const openBill = () => {
    setIsLoading(true);
    window.open(getOrderDetailsObj.data.orderBill.remote_url, "_self");
  };

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
      <StyledBorderBox isMobile={isMobile}>
        <BrandColorText variant="h5">Awaiting Payment</BrandColorText>
        <SeperateLine />

        <StyledRow>
          <GreyText>Items:</GreyText>
          <GreyText>{`${getOrderDetailsObj.data.totalItems} items`}</GreyText>
        </StyledRow>
        <Spacer size="s" position="top" />
        <StyledRow>
          <GreyText>Subtotal:</GreyText>
          <GreyText>{`RM${getOrderDetailsObj.data.subtotal}`}</GreyText>
        </StyledRow>
        <Spacer size="s" position="top" />
        {discount !== 0 && (
          <StyledRow>
            <GreyText>Discount:</GreyText>
            <GreyText>{`-RM${discount.toFixed(2)}`}</GreyText>
          </StyledRow>
        )}
        <Spacer size="s" position="top" />
        <StyledRow>
          <PrimaryColorText>Total Cost:</PrimaryColorText>

          <BoldPrimaryColorText>{`RM${getOrderDetailsObj.data.totalCost}`}</BoldPrimaryColorText>
        </StyledRow>
        <Spacer size="xxxl" position="top" />
        <BackgroundButton handleSubmit={openBill}>
          <WhiteColorText>Continue Payment</WhiteColorText>
        </BackgroundButton>
        <Spacer size="m" position="top" />
        <BorderButton handleSubmit={() => handleCancelOrder(getOrderDetailsObj.data.id)}>
          <BrandColorText>Cancel Order</BrandColorText>
        </BorderButton>
      </StyledBorderBox>
    </Box>
  );
}

AwaitingPaymentSummary.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  handleCancelOrder: PropTypes.func.isRequired,
};
export default AwaitingPaymentSummary;
