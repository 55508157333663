import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import ResendButton from "./resend-button.component";

function VerificationForm({ userEmail, handleSubmit, handleSubmitResendCodes, isLoading }) {
  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required().label("Verification Code"),
    password: Yup.string().required().min(4).label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
      .label("Password confirmation"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Form
      initialValues={{ verificationCode: "", password: "", confirmPassword: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <PaddedView multiples={isMobile ? 3 : 4}>
        <Typography varian="h2" textAlign="center">
          A verification code has been sent to {userEmail}
        </Typography>
        <Spacer size="s" />

        <FormFieldText name="verificationCode" placeholder="Enter Verification Code" />
        <Grid container justifyContent="center">
          <ResendButton handleSubmit={handleSubmitResendCodes} />
        </Grid>
        <Spacer size="xl" />

        <FormFieldText name="password" placeholder="New Password" />
        <Spacer size="s" />
        <FormFieldText type="password" name="confirmPassword" placeholder="Confirm New Password" />
        <Spacer size="xl" />
        <FormSubmitButton isLoading={isLoading}>
          <Typography>Confirm</Typography>
        </FormSubmitButton>
      </PaddedView>
    </Form>
  );
}

VerificationForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitResendCodes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default VerificationForm;
