import React from "react";
import { Box, Button, useTheme, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Loader from "react-js-loader";

function BorderButton({ children, handleSubmit, isLoading, width, height }) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .MuiButton-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: "transparent",
            textTransform: "none",
            borderColor: theme.palette.colors.brand.secondary,
            boxShadow: 0,
            width,
            height,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        {isLoading ? (
          <Button
            variant="contained"
            disabled
            sx={{ backgroundColor: theme.palette.colors.brand.primary }}
          >
            <Box
              sx={{
                height: "25px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.secondary} size={30} />
            </Box>
          </Button>
        ) : (
          <Button
            sx={{ border: 1, padding: 1, paddingY: 1 }}
            variant="contained"
            onClick={handleSubmit}
          >
            {children}
          </Button>
        )}
      </Box>
    </Grid>
  );
}

BorderButton.defaultProps = {
  width: "100%",
  height: null,
};

BorderButton.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default BorderButton;
