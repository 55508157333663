import React, { useContext, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getProfile,
  profileSelector,
  uploadImage,
} from "../../../services/profile/profile-slice.service";
import UserDetails from "./user-details.layout";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

function ProifleScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const { profileObj } = useSelector(profileSelector);
  const createSnackBar = useContext(SnackbarContext);

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfile()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        backgroundColor: theme.palette.colors.bg.white,
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
      }}
    >
      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      <UserDetails userData={profileObj.data} />
    </Grid>
  );
}

export default ProifleScreen;
