import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function OutlinedSelectTextField({ label, name, selections, selected, width }) {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();
  const [currSelected, setCurrSelected] = useState(selected);

  const handleChange = (e) => {
    setCurrSelected(e.target.value);
    setFieldValue(name, e.target.value);
  };

  return (
    <FormControl
      sx={{
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: theme.palette.colors.bg.secondary,
          width,
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={currSelected} onChange={handleChange}>
        {selections.map((selection) => (
          <MenuItem
            label={label}
            value={selection.value}
            key={`${selection.type}-${selection.value}`}
          >
            {selection.type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

OutlinedSelectTextField.defaultProps = {
  width: "100%",
};

OutlinedSelectTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  width: PropTypes.string,
};

export default OutlinedSelectTextField;
