import React from "react";
import { Box, styled } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const StyledBox = styled(Box)`
  padding: 10px;
  padding-top: 15px;
  text-transform: none;
  &:hover {
    background: none;
  }
`;

const StyledBottomBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
  height: "50%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  backgroundColor: theme.palette.colors.brand.white,
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledExperienceDetailsBox = styled(Box)({
  width: "100%",
  "& .MuiButton-root": {
    textTransform: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

function YouMightAlsoLikeCartCardLoader() {
  return (
    <StyledBox disableRipple>
      <Box sx={{ width: "100%", height: "250px" }}>
        <CustomSkeleton height="50%" width="100%" />
        <StyledBottomBox sx={{ boxShadow: 3 }}>
          <StyledExperienceDetailsBox>
            <CustomSkeleton height="25px" width="200px" />
            <CustomSkeleton height="20px" width="200px" />
            <CustomSkeleton height="20px" width="200px" />
          </StyledExperienceDetailsBox>
          <CustomSkeleton height="25px" width="100px" />
        </StyledBottomBox>
      </Box>
    </StyledBox>
  );
}

export default YouMightAlsoLikeCartCardLoader;
