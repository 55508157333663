import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box, Typography, FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-input-2";

function ArrayFormFieldText({ name, placeholder, width, index, showErrorName, isMessageField }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, handleSubmit, values } =
    useFormikContext();
  const BORDERRADIUS = theme.shape.borderRadius[0];
  const showError =
    touched.orders &&
    touched.orders[index] &&
    touched.orders[index].recipient &&
    touched.orders[index].recipient[showErrorName] &&
    errors.orders &&
    errors.orders[index] &&
    errors.orders[index].recipient &&
    typeof errors.orders[index].recipient[showErrorName] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  if (name.toString().toUpperCase().includes("PHONE")) {
    return (
      <Box
        sx={{
          height: "100%",
          "& .MuiTextField-root": {
            borderTopLeftRadius: BORDERRADIUS,
            borderTopRightRadius: BORDERRADIUS,
            borderBottomLeftRadius: BORDERRADIUS,
            borderBottomRightRadius: BORDERRADIUS,
            width,
            "& .MuiOutlinedInput-root": {
              minHeight: "200px",
              borderTopLeftRadius: BORDERRADIUS,
              borderTopRightRadius: BORDERRADIUS,
              borderBottomLeftRadius: BORDERRADIUS,
              borderBottomRightRadius: BORDERRADIUS,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          },
        }}
      >
        <PhoneInput
          value={values[name]}
          isValid={() => !showError}
          specialLabel=""
          variant="outlined"
          country="my"
          onBlur={() => setFieldTouched(name)}
          onChange={(e) => setFieldValue(name, e)}
          inputStyle={{
            width,
            borderRadius: theme.shape.borderRadius[0],
            borderColor: showError && theme.palette.colors.ui.error,
          }}
        />
        {showError && (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.error,
              margin: "3px 14px 0px 14px",
            }}
          >
            {errors.orders[index].recipient[showErrorName]}
          </FormHelperText>
        )}
      </Box>
    );
  }

  if (isMessageField) {
    return (
      <Box
        sx={{
          height: "100%",
          "& .MuiTextField-root": {
            borderTopLeftRadius: BORDERRADIUS,
            borderTopRightRadius: BORDERRADIUS,
            borderBottomLeftRadius: BORDERRADIUS,
            borderBottomRightRadius: BORDERRADIUS,
            width,
            "& .MuiOutlinedInput-root": {
              minHeight: "200px",
              borderTopLeftRadius: BORDERRADIUS,
              borderTopRightRadius: BORDERRADIUS,
              borderBottomLeftRadius: BORDERRADIUS,
              borderBottomRightRadius: BORDERRADIUS,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          },
        }}
      >
        <TextField
          multiline
          onKeyDown={keyPress}
          error={showError}
          variant="outlined"
          helperText={showError ? errors.orders[index].recipient[showErrorName] : null}
          onBlur={() => setFieldTouched(name)}
          name={name}
          type={name}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          inputProps={{ maxLength: 300 }}
        />
        <Typography sx={{ fontSize: "13px", color: theme.palette.colors.text.secondary }}>{`${
          (values.orders[index].recipient.message &&
            values.orders[index].recipient.message.length) ||
          0
        }/300 characters`}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: BORDERRADIUS,
          borderTopRightRadius: BORDERRADIUS,
          borderBottomLeftRadius: BORDERRADIUS,
          borderBottomRightRadius: BORDERRADIUS,
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: BORDERRADIUS,
            borderTopRightRadius: BORDERRADIUS,
            borderBottomLeftRadius: BORDERRADIUS,
            borderBottomRightRadius: BORDERRADIUS,
          },
        },
      }}
    >
      <TextField
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        helperText={showError ? errors.orders[index].recipient[showErrorName] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      />
    </Box>
  );
}

ArrayFormFieldText.defaultProps = {
  width: "100%",
  showErrorName: "",
  isMessageField: false,
};

ArrayFormFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  index: PropTypes.number.isRequired,
  showErrorName: PropTypes.string,
  isMessageField: PropTypes.bool,
};

export default ArrayFormFieldText;
