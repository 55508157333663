import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import YoloLogo from "../../../../assets/images/yolo_logo";
import routes from "../../../../components/navigation/routes";
import Spacer from "../../../../components/utils/spacer.component";
import { guestSelector } from "../../../../services/guest/guest-slice.service";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import { isLogin } from "../../../utils";
import MiniSearchBar from "../mini-search-bar.component";
import AvatarMenu from "./avatar-with-menu.component";
import LeftButtonView from "./left-button-view.component";
import MiniCartModal from "./mini-cart-modal.component";
import AppBarMenuItem from "./pc-app-bar-menu-item.component";

const NegativeMarginTop = "-30px";

const AppBarContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingTop: "10px",
}));

const StyledIconButton = styled(IconButton)`
  height: 100%;
  color: ${({ theme }) => theme.palette.colors.brand.secondary};
`;

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: 8,
  right: 2,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const RelativeBox = styled(Box)({
  position: "relative",
});

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.colors.text.white,
}));

const LeftBox = styled(Box)({
  width: "40%",
  justifyContent: "flex-start",
  display: "flex",
  marginTop: NegativeMarginTop,
});

const MiddleBox = styled(Box)({ width: "20%", justifyContent: "center", display: "flex" });

const RightBox = styled(Box)({
  width: "40%",
  justifyContent: "flex-end",
  display: "flex",
  marginTop: NegativeMarginTop,
});

const SubHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: theme.dimensions.PCMaxWidth,
  width: "100%",
  paddingBottom: "5px",
  marginTop: NegativeMarginTop,
}));

const VoucherSubHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "6px 0px",
  backgroundColor: "transparent",
  boxShadow: "none",
  height: "33px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
  },
}));

function PCViewAppBar({ handleLogout, handleSearch, locationList, experienceList }) {
  const theme = useTheme();
  const SmallScreen = useMediaQuery(theme.breakpoints.down("1110"));
  const history = useHistory();
  const { profileObj } = useSelector(profileSelector);
  const location = useLocation();
  const voucherSubHeaderText =
    location.pathname === routes.VOUCHERDETAILS
      ? "You're booking this experience."
      : "You've redeemed this voucher.";
  const { guestFavouriteList } = useSelector(guestSelector);

  const pushToMyFavourites = () => {
    history.push(routes.MYFAVOURITES);
  };

  const pushToPendingPayment = () => {
    history.push(routes.PENDINGPAYMENT);
  };

  const pushToMyBookings = () => {
    history.push(routes.BOOKINGEXPERIENCES);
  };

  // const renderContestButton = () => (
  //   <StyledButton variant="contained" onClick={() => {}}>
  //     <Typography
  //       textAlign="center"
  //       sx={{
  //         fontSize: theme.fonts.fontSizes.size14,
  //         color: theme.palette.colors.text.primary,
  //       }}
  //     >
  //       Contest
  //     </Typography>
  //   </StyledButton>
  // );

  const renderPurchaseGiftVoucher = () => (
    <StyledButton variant="contained" onClick={() => history.push(routes.GIFT_VOUCHER)}>
      <Typography
        textAlign="center"
        sx={{
          fontSize: theme.fonts.fontSizes.size14,
          color: theme.palette.colors.text.primary,
        }}
      >
        Gift Vouchers
      </Typography>
    </StyledButton>
  );

  const renderSubHeader = () => (
    <SubHeaderBox sx={{ justifyContent: "space-between" }}>
      <Grid container spacing={3} sx={{ paddingX: theme.dimensions.ScreenPaddingX }}>
        <Grid item>
          <AppBarMenuItem
            itemList={experienceList}
            type="category"
            label="Experiences"
            handleSearch={handleSearch}
          />
        </Grid>
        <Grid item>
          <AppBarMenuItem
            itemList={locationList}
            type="location"
            label="Locations"
            handleSearch={handleSearch}
          />
        </Grid>
        {/* <Grid item>{renderContestButton()}</Grid> */}
        <Grid item>{renderPurchaseGiftVoucher()}</Grid>
      </Grid>
      <MiniSearchBar handleSearch={handleSearch} />
    </SubHeaderBox>
  );

  const voucherSubHeader = () => (
    <Box
      sx={{
        width: "100%",

        backgroundColor: theme.palette.colors.brand.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SubHeaderBox sx={{ justifyContent: "flex-start", marginTop: "0px", padding: "15px" }}>
        <VoucherSubHeaderText>{voucherSubHeaderText}</VoucherSubHeaderText>
      </SubHeaderBox>
    </Box>
  );

  if (location.pathname.includes("voucher/")) {
    return (
      <>
        <AppBarContainer
          sx={{
            maxWidth:
              location.pathname === routes.VOUCHERREDEMPTION ||
              location.pathname === routes.VOUCHERREDEEMED
                ? "1800px"
                : theme.dimensions.PCMaxWidth,
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: "10px" }}
          >
            <YoloLogo />
          </Box>
        </AppBarContainer>
        {(location.pathname === routes.VOUCHERDETAILS ||
          location.pathname === routes.GIFT_VOUCHER_PREVIEW) &&
          voucherSubHeader()}
      </>
    );
  }

  if (profileObj.status === "succeeded" && isLogin()) {
    return (
      <>
        <AppBarContainer
          paddingX={theme.dimensions.ScreenPaddingX}
          sx={{
            maxWidth:
              location.pathname === routes.VOUCHERREDEMPTION ||
              location.pathname === routes.VOUCHERREDEEMED
                ? "1800px"
                : theme.dimensions.PCMaxWidth,
          }}
        >
          <LeftBox>
            <LeftButtonView amount={profileObj.data.credits} />
          </LeftBox>
          <MiddleBox>
            <YoloLogo />
          </MiddleBox>
          <RightBox>
            <Tooltip title="Your Bookings">
              <RelativeBox>
                <StyledIconButton onClick={pushToMyBookings}>
                  {profileObj.data.bookingAlert.total > 0 && (
                    <RedDot>
                      <NotificationText>{profileObj.data.bookingAlert.total}</NotificationText>
                    </RedDot>
                  )}
                  <BookOnlineOutlinedIcon />
                </StyledIconButton>
              </RelativeBox>
            </Tooltip>
            <Spacer size={SmallScreen ? "s" : "m"} position="left" />
            <Tooltip title="Pending Payment">
              <RelativeBox>
                <StyledIconButton onClick={pushToPendingPayment}>
                  {profileObj.data.totalPendingOrder > 0 && (
                    <RedDot>
                      <NotificationText>{profileObj.data.totalPendingOrder}</NotificationText>
                    </RedDot>
                  )}
                  <PendingActionsIcon />
                </StyledIconButton>
              </RelativeBox>
            </Tooltip>
            <Spacer size={SmallScreen ? "s" : "m"} position="left" />
            <RelativeBox>
              <Tooltip title="Favourites">
                <StyledIconButton onClick={pushToMyFavourites}>
                  {profileObj.data.totalFavourite > 0 && (
                    <RedDot>
                      <NotificationText>{profileObj.data.totalFavourite}</NotificationText>
                    </RedDot>
                  )}
                  <FavoriteBorderIcon />
                </StyledIconButton>
              </Tooltip>
            </RelativeBox>
            <Spacer size={SmallScreen ? "s" : "m"} position="left" />
            <RelativeBox>
              <MiniCartModal />
            </RelativeBox>

            <Spacer size={SmallScreen ? "s" : "m"} position="left" />
            <AvatarMenu handleLogout={handleLogout} imagePath={profileObj.data.imagePath} />
          </RightBox>
        </AppBarContainer>
        {renderSubHeader()}
      </>
    );
  }

  return (
    <>
      <AppBarContainer
        paddingX={theme.dimensions.ScreenPaddingX}
        sx={{
          maxWidth:
            location.pathname === routes.VOUCHERREDEMPTION ||
            location.pathname === routes.VOUCHERREDEEMED
              ? "1800px"
              : theme.dimensions.PCMaxWidth,
        }}
      >
        <LeftBox>
          <LeftButtonView />
        </LeftBox>
        <MiddleBox>
          <YoloLogo />
        </MiddleBox>
        <RightBox>
          <Spacer size={SmallScreen ? "s" : "m"} position="left" />
          <Tooltip title="Favourites">
            <StyledIconButton onClick={pushToMyFavourites}>
              <FavoriteBorderIcon />
              {guestFavouriteList.length > 0 && (
                <RedDot>
                  <NotificationText>{guestFavouriteList.length}</NotificationText>
                </RedDot>
              )}
            </StyledIconButton>
          </Tooltip>
          <Spacer size={SmallScreen ? "s" : "m"} position="left" />
          <MiniCartModal />
          <Spacer size={SmallScreen ? "s" : "m"} position="left" />
          <AvatarMenu handleLogout={handleLogout} />
        </RightBox>
      </AppBarContainer>
      {renderSubHeader()}
    </>
  );
}

PCViewAppBar.defaultProps = {
  locationList: null,
  experienceList: null,
};

PCViewAppBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  locationList: PropTypes.node,
  experienceList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      subcategories: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default PCViewAppBar;
