import React, { useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "../../../components/forms/form.component";
import FormDateTime from "../components/form-date-time.component";
import {
  getBookingDetails,
  scheduleBooking,
} from "../../../services/booking/booking-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const validationSchema = Yup.object().shape({
  date: Yup.string().required().label("Date").nullable(),
  slotId: Yup.string().required().label("Time"),
});

function DateTimeSelectScreen(props) {
  const constProps = props;
  const orderId = constProps.match.params.id;
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBookingDetails(orderId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onSubmit = (slotId) => {
    setIsLoading(true);
    dispatch(scheduleBooking({ orderId, slotId })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.BOOKINGWAITINGCONFIRMATION);
      }
    });
  };

  return (
    <Form
      initialValues={{ date: null, slotId: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Loading isLoading={isLoading} />
      <Grid container paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX} height="89vh">
        <FormDateTime />
      </Grid>
    </Form>
  );
}

export default DateTimeSelectScreen;
