import React, { useState } from "react";
import {
  styled,
  Menu,
  Button,
  MenuItem,
  Typography,
  useTheme,
  Tooltip,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import { v4 as uuidv4 } from "uuid";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.shape.borderRadius[0],
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "none",
      },
    },
  },
}));

const PopperProps = {
  sx: {
    whiteSpace: "pre-line",
    "& .MuiTooltip-arrow": {
      top: "-10px",
      "&::before": {
        backgroundColor: "white",
        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      },
    },
    "& .MuiTooltip-tooltip": {
      borderRadius: "2px",
      backgroundColor: "white",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",

      maxWidth: "350px",
      width: "fit-content",
      padding: "0px",
    },
  },
};
function AppBarMenuItem({ label, type, itemList, handleSearch }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const SmallScreen = useMediaQuery(theme.breakpoints.down("1110"));
  const arr = Array.apply("", Array(3));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (values, isSubcategory = false) => {
    if (isSubcategory) {
      handleSearch(values, "subcategory");
    } else {
      handleSearch(values, type);
    }
    setAnchorEl(null);
  };

  const renderSubcategory = (index) =>
    itemList[index].subcategories.map((item) => (
      <MenuItem
        key={uuidv4()}
        onClick={() => handleItemSelected(item, true)}
        style={{ whiteSpace: "normal" }}
      >
        <Typography
          sx={{
            color: theme.palette.colors.text.primary,
            fontSize: theme.fonts.fontSizes.size14,
          }}
        >
          {item}
        </Typography>
      </MenuItem>
    ));

  const renderContent = () => {
    if (itemList) {
      if (type === "category") {
        return itemList.map((category, index) => (
          <Tooltip
            key={uuidv4()}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-2, -14],
                  },
                },
              ],
              ...PopperProps,
              onMouseDown: (e) => {
                handleItemSelected(e.target.innerText, true);
                e.stopPropagation();
              },
            }}
            title={renderSubcategory(index)}
            placement="right-start"
          >
            <MenuItem
              key={uuidv4()}
              onClick={() => handleItemSelected(category.label)}
              style={{ whiteSpace: "normal" }}
            >
              <Typography
                sx={{
                  color: theme.palette.colors.text.primary,
                  fontSize: theme.fonts.fontSizes.size14,
                  paddingRight: type === "category" ? "25px" : "0",
                }}
              >
                {category.label}
              </Typography>
              {category.subcategories.length !== 0 && (
                <ListItemIcon sx={{ position: "absolute", right: "0" }}>
                  <ChevronRightIcon sx={{ color: theme.palette.colors.brand.secondary }} />
                </ListItemIcon>
              )}
            </MenuItem>
          </Tooltip>
        ));
      }
      return itemList.map((item) => (
        <MenuItem
          key={uuidv4()}
          onClick={() => handleItemSelected(item)}
          style={{ whiteSpace: "normal" }}
        >
          <Typography
            sx={{
              color: theme.palette.colors.text.primary,
              fontSize: theme.fonts.fontSizes.size14,
            }}
          >
            {item}
          </Typography>
        </MenuItem>
      ));
    }
    return arr.map(() => (
      <MenuItem key={uuidv4()}>
        <CustomSkeleton width="150px" height="20px" borderRadius={theme.shape.borderRadius[0]} />
      </MenuItem>
    ));
  };

  return (
    <Tooltip
      arrow
      PopperProps={{
        ...PopperProps,
        onMouseDown: (e) => {
          handleItemSelected(e.target.innerText);
        },
      }}
      title={renderContent()}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          disableRipple
          onClick={() => handleSearch("", type === "category" ? "experience" : type)}
          sx={{
            textTransform: "none",
            paddingX: "0px",
            ":hover": { backgroundColor: "transparent" },
          }}
        >
          <Typography
            sx={{
              color: theme.palette.colors.text.primary,
              fontSize: theme.fonts.fontSizes.size14,
            }}
          >
            {label}
          </Typography>
        </Button>
        <IconButton
          disableRipple
          id="basic-button"
          onClick={(i) => (SmallScreen ? handleClick(i) : null)}
          sx={{ padding: "0px" }}
        >
          {anchorEl !== null ? (
            <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          )}
        </IconButton>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            onMouseLeave: handleClose,
          }}
        >
          {renderContent()}
        </StyledMenu>
      </Box>
    </Tooltip>
  );
}

AppBarMenuItem.defaultProps = {
  itemList: null,
  type: "",
};

AppBarMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        subcategories: PropTypes.arrayOf(PropTypes.string),
      }),
    ]),
  ),
  type: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
};

export default AppBarMenuItem;
