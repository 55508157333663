import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Spacer from "../../../components/utils/spacer.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export default function BookingDetails({ cardDetails }) {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <SpaceBetweenRowBox>
            <PrimaryColorText>Experience: </PrimaryColorText>
            <PrimaryColorText>{`${cardDetails.noOfPax} x RM ${cardDetails.order.item.pricePerItem}`}</PrimaryColorText>
          </SpaceBetweenRowBox>
        </Grid>
        <Grid item xs={12}>
          <Spacer size="s" position="top" />
        </Grid>
        {cardDetails.order.item.addOns.length > 0 && (
          <>
            <Grid item xs={12}>
              <PrimaryColorText>Add On(s)</PrimaryColorText>
            </Grid>
            <Grid item xs={12}>
              {cardDetails.order.item.addOns.map((item) => (
                <SpaceBetweenRowBox key={item.id}>
                  <GreyColorText>{item.label}</GreyColorText>
                  <PrimaryColorText>{`${item.quantity} x RM ${item.pricePerAddOn}`}</PrimaryColorText>
                </SpaceBetweenRowBox>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

BookingDetails.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    noOfPax: PropTypes.number,
    slot: PropTypes.shape({
      checkIn: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      checkOut: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      duration: PropTypes.string,
      isFlexi: PropTypes.shape({
        status: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
    cancelled_at: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        pricePerItem: PropTypes.string,
        quantity: PropTypes.number,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          state: PropTypes.string,
          ratings: PropTypes.number,
          noReviews: PropTypes.number,
          imgPath: PropTypes.string,
        }),
        recipient: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        addOns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            sublabel: PropTypes.string,
            quantity: PropTypes.number,
            pricePerAddOn: PropTypes.string,
            price: PropTypes.string,
          }),
        ),
        isGift: PropTypes.bool,
        gift: PropTypes.shape({ message: PropTypes.string }),
      }),
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    notes: PropTypes.string,
  }).isRequired,
};
