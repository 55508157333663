import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme, Link } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import BoxTitle from "../box-title.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import ResendButton from "./resend-button.component";
import TextButton from "../../../../components/button/text-button.component";
import routes from "../../../../components/navigation/routes";

function VerificationForm({
  handleSubmit,
  email,
  handleSubmitResendCodes,
  handleLogout,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required().label("Verification Code"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <BoxTitle title="Enter Verification Code" />
        <Form
          initialValues={{ verificationCode: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <PaddedView multiples={isMobile ? 3 : 4}>
            <Typography varian="h2" textAlign="center">
              A verification code has been sent to {email}
            </Typography>
            <Spacer size="l" />
            <Grid container justifyContent="center">
              <Box sx={{ width: "70%" }}>
                <FormFieldText name="verificationCode" placeholder="Enter Verification Code" />
              </Box>
            </Grid>
            <Spacer size="m" />
            <Grid container justifyContent="center">
              <ResendButton handleSubmit={handleSubmitResendCodes} />
            </Grid>

            <Box sx={{ width: 50, height: 50 }} />
            <FormSubmitButton isLoading={isLoading}>
              <Typography>Verify</Typography>
            </FormSubmitButton>
            <Spacer size="xl" />
            <Grid container justifyContent="space-between">
              <TextButton label="Logout" handleSubmit={handleLogout} />
              <Link color={theme.palette.colors.brand.primary} underline="none" href={routes.HOME}>
                Skip
              </Link>
            </Grid>
          </PaddedView>
        </Form>
      </Box>
    </Box>
  );
}

VerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleSubmitResendCodes: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default VerificationForm;
