import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box, styled, Typography, Button } from "@mui/material";

const StyledBox = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  height: "56px",
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
}));

function VoucherForm({ name, placeholder, width }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            padding: "0px",
          },
        },
      }}
    >
      <TextField
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledBox onClick={handleSubmit}>
                <Typography sx={{ color: theme.palette.colors.text.white }}>Next</Typography>
              </StyledBox>
            </InputAdornment>
          ),
        }}
        value={values[name]}
      />
    </Box>
  );
}

VoucherForm.defaultProps = {
  width: "100%",
};

VoucherForm.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default VoucherForm;
