import React, { useState } from "react";
import { Box, Typography, useTheme, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout, resetUser } from "../../../services/auth/auth-slice.service";
import routes from "../../../components/navigation/routes";
import SideMenuItem from "../../../components/profile-side-menu/side-menu-item.component";
import BorderButton from "../../../components/profile-side-menu/border-button.component";
import { resetProfile } from "../../../services/profile/profile-slice.service";
import Spacer from "../../../components/utils/spacer.component";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.brand.white,
  opacity: 1,
  width: "100%",
  paddingBottom: 5,
  height: "100%",
}));

function UserProfileMenu() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logout()).then(({ meta }) => {
      setIsLoading(false);
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.AUTH);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        history.push(routes.AUTH);
      }
    });
  };

  return (
    <StyledBox>
      <Spacer size="m" position="top" />
      <SideMenuItem />
      <Spacer size="xxxl" position="top" />
      <BorderButton isLoading={isLoading} handleSubmit={handleLogout} width="90%">
        <Typography
          variant="label"
          sx={{
            color: theme.palette.colors.text.primary,
          }}
        >
          Logout
        </Typography>
      </BorderButton>
    </StyledBox>
  );
}

export default UserProfileMenu;
