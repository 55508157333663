import React, { useContext } from "react";
import {
  Box,
  Grid,
  styled,
  Typography,
  useTheme,
  Switch,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ItemDetailsBox from "./item-details-box.component";
import Spacer from "../../../components/utils/spacer.component";
import ArrayFormFieldText from "./form/array-form-field-text.component";
import GiftArrayFormFieldText from "./form/gift-array-form-field-text.component";
import Form from "../../../components/forms/form.component";
import PromoCodeField from "./promo-code-field.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { previewRedeemPromoCode } from "../../../services/order/order-slice.service";

const GiftSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.colors.text.white,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.colors.button.switch,
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.colors.ui.notification,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

function ItemList({ itemData, index, setIsLoading, promoCodeRedeems, setPromoCodeRedeems }) {
  const { setFieldValue, values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      setFieldValue(`orders[${index}].purchasedAsGift`, true);
      setFieldValue(`orders[${index}].recipient.buyAsGift`, true);
      setFieldValue(`orders[${index}].recipient.firstName`, "");
      setFieldValue(`orders[${index}].recipient.lastName`, "");
      setFieldValue(`orders[${index}].recipient.email`, "");
      setFieldValue(`orders[${index}].recipient.phone`, "");
      setFieldValue(`orders[${index}].giftDetails.from`, "");
      setFieldValue(`orders[${index}].giftDetails.to`, "");
      setFieldValue(`orders[${index}].giftDetails.message`, "");
    } else {
      setFieldValue(`orders[${index}].purchasedAsGift`, false);
      setFieldValue(`orders[${index}].recipient.buyAsGift`, false);
    }
  };

  const redeemHandle = (value) => {
    setFieldValue(`orders[${index}].promoCode`, value.promoCode);
    setIsLoading(true);
    dispatch(
      previewRedeemPromoCode({
        ...value,
        promoCodeRedeems,
        fromCartItem: true,
        experienceId: itemData.experience.id,
        totalCost: parseFloat(itemData.price.replace(",", "")),
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        setFieldValue(`orders[${index}].promoCode`, "");
        const newPromoCodeRedeems = [...promoCodeRedeems];
        newPromoCodeRedeems[index] = {};
        setPromoCodeRedeems(newPromoCodeRedeems);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        const newPromoCodeRedeems = [...promoCodeRedeems];
        newPromoCodeRedeems[index] = payload.data;
        setPromoCodeRedeems(newPromoCodeRedeems);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
    });
  };

  const renderMessageBox = () => (
    <Grid item xs={12}>
      <Spacer size="l" position="top" />
      <Grid container sx={{ alignItems: "center" }} spacing={2}>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <PrimaryColorText>Purchase as a gift</PrimaryColorText>
            <GiftSwitch onChange={handleSwitchChange} />
          </SpaceBetweenBox>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={values.orders[index].purchasedAsGift} unmountOnExit timeout="auto">
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    First Name
                  </Typography>
                  <ArrayFormFieldText
                    showErrorName="firstName"
                    name={`orders[${index}].recipient.firstName`}
                    index={index}
                    placeholder="First Name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Last Name
                  </Typography>
                  <ArrayFormFieldText
                    showErrorName="lastName"
                    name={`orders[${index}].recipient.lastName`}
                    index={index}
                    placeholder="Last Name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Email
                  </Typography>
                  <ArrayFormFieldText
                    showErrorName="email"
                    name={`orders[${index}].recipient.email`}
                    index={index}
                    placeholder="Email Address"
                  />
                  <Spacer position="top" size="xs" />
                  <RowBox>
                    <WarningAmberRoundedIcon />
                    <Spacer position="left" size="xs" />
                    <Typography variant="body2">
                      Please ensure the email address is correct.
                    </Typography>
                  </RowBox>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Phone Number
                  </Typography>
                  <ArrayFormFieldText
                    showErrorName="phone"
                    name={`orders[${index}].recipient.phone`}
                    index={index}
                    placeholder="Phone Number"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Spacer position="top" size="m" />
                <Typography>Add a gift message?</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Message (Optional)
                  </Typography>
                  <GiftArrayFormFieldText
                    showErrorName="message"
                    isMessageField={true}
                    name={`orders[${index}].giftDetails.message`}
                    index={index}
                    placeholder="Message"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Spacer size="l" position="top" />
            <SeperateLine />
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Spacer position="bottom" size="l" />
      <Grid container>
        {promoCodeRedeems[index] && (
          <ItemDetailsBox itemDetails={itemData} promoCodeRedeems={promoCodeRedeems[index]} />
        )}
        <Grid item xs={12} paddingTop="30px">
          <Form
            initialValues={{ promoCode: "" }}
            validationSchema={Yup.object().shape({
              promoCode: Yup.string().label("Promo"),
            })}
            onSubmit={redeemHandle}
          >
            <StyledTitle variant="h5">Promos & Vouchers</StyledTitle>
            <Spacer size="m" position="top" />
            <PromoCodeField />
          </Form>
        </Grid>
        {renderMessageBox()}
      </Grid>
    </>
  );
}

ItemList.defaultProps = {
  itemData: {
    experienceId: 1,
    price: "",
    quantity: 1,
    message: "",
    firstName: "",
    lastName: "",
    email: "",
  },
  promoCodeRedeems: [
    {
      discount: null,
      total: null,
    },
  ],
};

ItemList.propTypes = {
  promoCodeRedeems: PropTypes.arrayOf(
    PropTypes.shape({
      discount: PropTypes.string,
      total: PropTypes.string,
    }),
  ),
  setPromoCodeRedeems: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  itemData: PropTypes.shape({
    cartItemId: PropTypes.number,
    experienceId: PropTypes.number,
    experience: PropTypes.shape({
      id: PropTypes.number,
    }),
    price: PropTypes.string,
    quantity: PropTypes.number,
    message: PropTypes.string,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ItemList;
