import React, { useEffect } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReviewForm from "../components/review-form.component";
import Form from "../../../components/forms/form.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import VoucherRedemptionBg from "../../../assets/images/voucher_redemption_bg.png";
import BannerImage from "../../../assets/images/gift_voucher_banner.png";
import { profileSelector } from "../../../services/profile/profile-slice.service";

const BackgroundImage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  backgroundImage: `url(${VoucherRedemptionBg})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: isMobile ? "300px" : "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const validationSchema = Yup.object().shape({
  starRating: Yup.number().label("Star Rating"),
  descriptions: Yup.string().label("Descriptions"),
});

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  width: "100%",
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  padding: isMobile ? "25px" : "50px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

function GiftVoucherRedeemedScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { redeemGiftVoucherObj } = useSelector(voucherSelector);
  const history = useHistory();
  const { profileObj } = useSelector(profileSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitReview = () => {};

  if (redeemGiftVoucherObj.status !== "succeeded") {
    history.push(routes.VOUCHERREDEMPTION);
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <BackgroundImage />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container paddingX={isMobile ? 2 : 10} spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BoldPrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
                    {`Hi ${profileObj.data?.firstName} ${profileObj.data?.lastName},`}
                  </BoldPrimaryColorText>
                  <BoldPrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
                    Your gift voucher has been credited to your account.
                  </BoldPrimaryColorText>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex", flexDirection: "column" }}
            >
              <CardContainer isMobile={isMobile}>
                <img
                  alt="banner-img"
                  src={BannerImage}
                  style={{
                    width: isMobile ? "100%" : "230px",
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                  }}
                />
                <Spacer size={isMobile ? "m" : "xl"} position={isMobile ? "top" : "left"} />
                <Box>
                  <BoldPrimaryColorText variant="h6">
                    {`Gift Voucher: RM ${redeemGiftVoucherObj.data.amount}`}
                  </BoldPrimaryColorText>
                  <Spacer size="l" position="top" />
                  <PrimaryColorText>
                    {`Purchased by ${redeemGiftVoucherObj.data.purchasedBy}`}
                  </PrimaryColorText>
                  <Spacer size="s" position="top" />
                  <PrimaryColorText>
                    {`Purchased at ${redeemGiftVoucherObj.data.purchaseDate}`}
                  </PrimaryColorText>
                </Box>
              </CardContainer>
            </Grid>

            <Grid item xs={12}>
              <Form
                onSubmit={submitReview}
                validationSchema={validationSchema}
                initialValues={{
                  starRating: 5,
                  descriptions: "",
                }}
              >
                <ReviewForm />
              </Form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GiftVoucherRedeemedScreen;
