import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const getFavouriteList = createAsyncThunk("favourite/favourite_list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/favourites`);
  return response;
});

export const createFavourite = createAsyncThunk("favourite/add", async (payload) => {
  const processedData = [];
  payload.map((item) => processedData.push(parseInt(item.experienceId, 10)));
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/favourites`, {
    experiencesId: processedData,
  });
  return response;
});

export const removeFavourite = createAsyncThunk("favourite/remove", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/favourites/remove_experience`,
    payload,
  );
  return response;
});

const favouriteSlice = createSlice({
  name: "favourite",
  initialState: {
    getFavouriteListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    favouriteListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFavouriteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeFavouriteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getFavouriteList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFavouriteListObj.status = "succeeded";
      state.getFavouriteListObj.data = data;
      state.getFavouriteListObj.successMessage = message;
    },
    [getFavouriteList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFavouriteListObj.status = "failed";
      state.getFavouriteListObj.errorMessage = message;
    },
    [createFavourite.pending]: (state) => {
      state.createFavouriteObj.status = "pending";
    },
    [createFavourite.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFavouriteObj.status = "succeeded";
      state.createFavouriteObj.data = data;
      state.createFavouriteObj.successMessage = message;
    },
    [createFavourite.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFavouriteObj.status = "failed";
      state.createFavouriteObj.errorMessage = message;
    },
    [removeFavourite.pending]: (state) => {
      state.removeFavouriteObj.status = "pending";
    },
    [removeFavourite.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeFavouriteObj.status = "succeeded";
      state.removeFavouriteObj.data = data;
      state.removeFavouriteObj.successMessage = message;
    },
    [removeFavourite.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeFavouriteObj.status = "failed";
      state.removeFavouriteObj.errorMessage = message;
    },
  },
});

export default favouriteSlice.reducer;

// state
export const favouriteSelector = (state) => state.favourite;
