import React from "react";
import PropTypes from "prop-types";
import { Button, Box, styled, Typography, useTheme } from "@mui/material";

const StyledButtonBox = styled(Box)(({ theme, tabColor, isSelected }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: isSelected ? tabColor : "transparent",
    border: "1px solid",
    borderColor: tabColor,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": {
      backgroundColor: isSelected ? tabColor : "transparent",
      boxShadow: "none",
    },
  },
}));

function BorderButtonWithBg({ label, handleSubmit, tabColor, isSelected }) {
  const theme = useTheme();
  return (
    <StyledButtonBox tabColor={tabColor} isSelected={isSelected}>
      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{ border: 1, width: "110px", boxShadow: 0 }}
      >
        <Typography sx={{ color: isSelected ? theme.palette.colors.text.white : tabColor }}>
          {label}
        </Typography>
      </Button>
    </StyledButtonBox>
  );
}

BorderButtonWithBg.defaultProps = {
  handleSubmit: null,
  tabColor: "red",
};

BorderButtonWithBg.propTypes = {
  tabColor: PropTypes.string,
  handleSubmit: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default BorderButtonWithBg;
