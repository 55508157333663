import React from "react";
import PropTypes from "prop-types";
import { Button, Box, useTheme, styled } from "@mui/material";
import Loader from "react-js-loader";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
    },
  },
}));

function BackgroundButton({ children, width, isLoading, handleSubmit }) {
  const theme = useTheme();

  return (
    <StyledButtonBox>
      {isLoading ? (
        <Button
          variant="contained"
          disabled
          sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
        >
          <Box
            sx={{ height: "25px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.white} size={30} />
          </Box>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
        >
          {children}
        </Button>
      )}
    </StyledButtonBox>
  );
}

BackgroundButton.defaultProps = {
  width: "100%",
  isLoading: false,
  handleSubmit: null,
};

BackgroundButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default BackgroundButton;
