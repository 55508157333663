import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq } from "../../api.services";

export const subscribeNewsletter = createAsyncThunk(
  "marketings/newsletters/subscribe",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PUBLIC_V1}/marketings/newsletters/subscribe`,
      payload,
    );
    return response;
  },
);

const newsletterSlice = createSlice({
  name: "order",
  initialState: {
    subscribeNewsletterObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [subscribeNewsletter.pending]: (state) => {
      state.subscribeNewsletterObj.status = "pending";
    },
    [subscribeNewsletter.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.subscribeNewsletterObj.status = "succeeded";
      state.subscribeNewsletterObj.data = data;
      state.subscribeNewsletterObj.successMessage = message;
    },
    [subscribeNewsletter.rejected]: (state, action) => {
      const { message } = action.error;

      state.subscribeNewsletterObj.status = "failed";
      state.subscribeNewsletterObj.errorMessage = message;
    },
  },
});

export default newsletterSlice.reducer;

// state
export const newsletterSelector = (state) => state.newsletter;
