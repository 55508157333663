import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useSelector } from "react-redux";
import Spacer from "../../../../components/utils/spacer.component";
import Form from "../../../../components/forms/form.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import TopUpAmountField from "./top-up-amount-field.component";
import TopUpPaymentSumamry from "./top-up-payment-summary.component";
import { creditSelector } from "../../../../services/credits/credit-slice.service";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "550px",
  outline: "none",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: 0,
  top: "-10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
  paddingBottom: "20px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledAccountBalanceWalletIcon = styled(AccountBalanceWalletIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontSize: "18px",
}));

const validationSchema = Yup.object().shape({
  amount: Yup.number().required().min(5).label("Top Up Amount"),
  paymentMethod: Yup.string().required(),
});

function TopUpModal({ showModal, setShowModal, onConfirmTopUp }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { createTopUpBillObj } = useSelector(creditSelector);

  const renderConfirmButton = () => (
    <Grid item xs={12}>
      <FormSubmitButton isLoading={createTopUpBillObj.status === "pending"}>
        <WhiteColorText variant="h6">Continue</WhiteColorText>
      </FormSubmitButton>
    </Grid>
  );

  const handleSubmit = (values) => {
    const amountCents = values.amount * 100;
    onConfirmTopUp({ amountCents, paymentMethod: values.paymentMethod });
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Form
          validationSchema={validationSchema}
          initialValues={{ amount: 50, paymentMethod: "revenue_monster" }}
          onSubmit={handleSubmit}
        >
          <TopTitleBox>
            <Grid container justifyContent="center">
              <TitleText variant="h5">Top-up</TitleText>
            </Grid>
            <CloseIconButton onClick={() => setShowModal(false)}>
              <StyledCloseIcon />
            </CloseIconButton>
          </TopTitleBox>
          <SeperateLine />
          <Spacer size="xl" position="top" />
          <TopUpAmountField />
          <Spacer size="xl" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          <Grid container>
            <Grid item xs={12}>
              <CenterRowBox>
                <StyledAccountBalanceWalletIcon />
                <Spacer size="xs" position="left" />
                <PrimaryColorText>Payment</PrimaryColorText>
              </CenterRowBox>
              <CenterRowBox>
                <GreyText>Online Banking / e-Wallet</GreyText>
              </CenterRowBox>
            </Grid>
          </Grid>
          <Spacer size="m" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          <TopUpPaymentSumamry />
          <Spacer size="xl" position="top" />
          <Grid container>{renderConfirmButton()}</Grid>
        </Form>
      </ModalBox>
    </Modal>
  );
}

TopUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onConfirmTopUp: PropTypes.func.isRequired,
};

export default TopUpModal;
