import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import routes from "../../../../components/navigation/routes";
import {
  guestSelector,
  onCartAmountChanged,
  onRemoveCartItems,
} from "../../../../services/guest/guest-slice.service";
import { isLogin } from "../../../utils";
import {
  getCartItemDetails,
  getCartList,
  removeCartItem,
  updateCartItemQty,
} from "../../../../services/cart/cart-slice.service";
import { getLatestCartDetails } from "../../../../services/experiences/experience-slice.service";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import { getProfile, profileSelector } from "../../../../services/profile/profile-slice.service";
import RemoveItemPopUpModal from "../../../../features/cart/components/remove-item-modal/remove-item-pop-up-modal.component";
import MiniCartModalExperienceList from "./mini-cart-modal-experience-list.component";

const StyledIconButton = styled(IconButton)`
  height: 100%;
  color: ${({ theme }) => theme.palette.colors.brand.secondary};
`;

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.colors.text.white,
}));

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: 8,
  right: 2,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

function MiniCartModal() {
  const { guestCartList, guestCartQuantity } = useSelector(guestSelector);
  const { profileObj } = useSelector(profileSelector);
  const history = useHistory();

  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [removeCartItemDetails, setRemoveCartItemDetails] = useState({});
  let tempGuestCartList = guestCartList || [];
  const [countDown, setCountDown] = useState(0);
  const [updateValues, setUpdateValues] = useState();
  const [isValueUpdating, setIsValueUpdating] = useState(false);

  const loadCartList = () => {
    dispatch(getCartList()).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsValueUpdating(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const deleteCartItems = (experience, quantity, uniqueId) => {
    if (!isLogin()) {
      setRemoveCartItemDetails({ ...experience, quantity, uniqueId });
      setShowDeleteModal(true);
      return;
    }

    dispatch(getCartItemDetails(uniqueId)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onConfirmDelete = (values) => {
    if (!isLogin()) {
      dispatch(
        onRemoveCartItems({
          experienceId: values,
          uniqueId: removeCartItemDetails.uniqueId,
        }),
      );
      tempGuestCartList = tempGuestCartList.filter(
        (item) => item.uniqueId !== removeCartItemDetails.uniqueId,
      );
      setShowDeleteModal(false);
      dispatch(getLatestCartDetails(tempGuestCartList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeCartItem(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        loadCartList();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getProfile());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const pushToMyCart = () => {
    history.push(routes.MYCART);
  };

  const handleOpenUserMenu = () => {
    if (isLogin()) {
      loadCartList();
    }
    if (!isLogin()) {
      dispatch(getLatestCartDetails(guestCartList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const renderRedDot = () => {
    if (isLogin() && profileObj.status === "succeeded") {
      return (
        profileObj.data.totalCartItem > 0 && (
          <RedDot>
            <NotificationText>{profileObj.data.totalCartItem}</NotificationText>
          </RedDot>
        )
      );
    }
    return (
      guestCartQuantity > 0 && (
        <RedDot>
          <NotificationText>{guestCartQuantity}</NotificationText>
        </RedDot>
      )
    );
  };

  const updateItemQuantity = () => {
    dispatch(updateCartItemQty(updateValues)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        loadCartList();
        dispatch(getProfile());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onQuantityUpdate = (values) => {
    if (!isLogin()) {
      dispatch(onCartAmountChanged(values));
      return;
    }
    setCountDown(3);
    setIsValueUpdating(true);
    setUpdateValues(values);
  };

  useEffect(() => {
    let interval = 0;
    let timer = 0;
    if (countDown > 0) {
      timer = setTimeout(() => {
        interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 1) {
              updateItemQuantity();
              clearInterval(interval);
            }
            return prev - 1;
          });
        });
      }, 300);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countDown]);

  return (
    <>
      <Tooltip
        leaveDelay={250}
        onMouseEnter={handleOpenUserMenu}
        placement="bottom-start"
        PopperProps={{
          sx: {
            whiteSpace: "pre-line",
            "& .MuiTooltip-arrow": {
              top: "-10px",
              "&::before": {
                backgroundColor: "white",
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              },
            },
            "& .MuiTooltip-tooltip": {
              borderRadius: "2px",
              backgroundColor: "white",
              boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",

              maxWidth: "450px",
            },
          },
        }}
        arrow
        title={
          <MiniCartModalExperienceList
            deleteCartItems={deleteCartItems}
            onQuantityUpdate={onQuantityUpdate}
            isValueUpdating={isValueUpdating}
          />
        }
      >
        <StyledIconButton onClick={pushToMyCart}>
          <ShoppingCartIcon />
          {renderRedDot()}
        </StyledIconButton>
      </Tooltip>

      <RemoveItemPopUpModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirmDelete={onConfirmDelete}
        removeCartItemDetails={removeCartItemDetails}
      />
    </>
  );
}

export default MiniCartModal;
