import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
// import ReactStars from "react-rating-stars-component";
// import { useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
// import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
// import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";

// const PrimaryColorText = styled(Typography)(({ theme }) => ({
//   color: theme.palette.colors.text.primary,
// }));

// const GreyColorText = styled(Typography)(({ theme }) => ({
//   color: theme.palette.colors.text.secondary,
// }));

// const WhiteColorText = styled(Typography)(({ theme }) => ({
//   color: theme.palette.colors.text.white,
// }));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

function ReviewForm() {
  const theme = useTheme();
  // const { setFieldValue, values } = useFormikContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  // const onChangeRating = (rating) => {
  //   setFieldValue("starRating", rating);
  // };

  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={12}>
        <PrimaryColorText>Help us do better</PrimaryColorText>
      </Grid>
      <Grid item xs={12}>
        <GreyColorText>
          How likely are you to recommend this website to someone you know?
        </GreyColorText>
      </Grid>
      <Grid item xs={12}>
        <ReactStars
          onChange={onChangeRating}
          activeColor={theme.palette.colors.ui.star}
          size={30}
          edit={true}
          color={theme.palette.colors.ui.border}
          isHalf={true}
          value={parseFloat(values.starRating)}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={isMobile ? 12 : 7}>
          <FormFieldTextMultiline name="descriptions" placeholder="Tell us more..." />
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <FormSubmitButton width={isMobile ? null : "200px"}>
            <WhiteColorText>Submit</WhiteColorText>
          </FormSubmitButton> */}
          <BorderButton
            width={isMobile ? null : "200px"}
            handleSubmit={() => history.push(routes.HOME)}
          >
            <BrandColorText>Back To Home</BrandColorText>
          </BorderButton>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ReviewForm;
