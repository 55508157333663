import React from "react";
import {
  TextField,
  InputAdornment,
  Box,
  useTheme,
  Typography,
  styled,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const StyledBox = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  height: "56px",
  background: theme.palette.colors.brand.primary,
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledViewBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    "& .MuiOutlinedInput-root": {
      minWidth: "325px",
      padding: 0,
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: theme.palette.colors.bg.white,
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.primary,
      },
    },
  },
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function NewsletterFormField({ name }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <StyledViewBox>
      <TextField
        variant="outlined"
        error={showError}
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type="email"
        onKeyDown={keyPress}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        value={values[name]}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledBox onClick={handleSubmit} disabled={false}>
                <Typography
                  sx={{
                    color: theme.palette.colors.text.white,
                  }}
                >
                  Sign Up
                </Typography>
              </StyledBox>
            </InputAdornment>
          ),
        }}
        placeholder="Enter Your Email Address"
      />
    </StyledViewBox>
  );
}

NewsletterFormField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NewsletterFormField;
