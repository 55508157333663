import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormLabel from "../../../components/forms/form-label.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { completeProfile, profileSelector } from "../../../services/profile/profile-slice.service";
import BoxTitle from "../../auth/components/box-title.component";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

function CompleteProfileForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { completeProfileObj } = useSelector(profileSelector);
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmit = (values) => {
    dispatch(completeProfile(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.VERIFICATIONCODE, { email: payload.data.email });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box>
      <Form
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.colors.bg.white,
            borderRadius: `${theme.shape.borderRadius[0]}px`,
            flex: 1,
            maxWidth: 500,
            minWidth: isMobile ? null : 450,
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 2,
          }}
        >
          <BoxTitle title="Complete Your Profile" />

          <PaddedView multiples={isMobile ? 3 : 4}>
            <FormLabel>First Name</FormLabel>
            <Spacer size="s" />
            <FormFieldText name="firstName" placeholder="Enter Your First Name" />

            <Spacer size="m" />
            <FormLabel>Last Name</FormLabel>
            <Spacer size="s" />
            <FormFieldText name="lastName" placeholder="Enter Your Last Name" />

            <Spacer size="m" />
            <FormLabel>New Password</FormLabel>
            <Spacer size="s" />
            <FormFieldText name="password" placeholder="Enter Your Password" />

            <Spacer size="m" />
            <FormLabel>Confirm New Password</FormLabel>
            <Spacer size="s" />
            <FormFieldText
              type="password"
              name="confirmPassword"
              placeholder="Confirm Your Password"
            />

            <Spacer size="l" />
            <FormSubmitButton isLoading={completeProfileObj.status === "pending"}>
              <Typography>Continue</Typography>
            </FormSubmitButton>
          </PaddedView>
        </Box>
      </Form>
    </Box>
  );
}

export default CompleteProfileForm;
