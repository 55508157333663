import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import Spacer from "../../../../components/utils/spacer.component";

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.label,
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const StyledRadion = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  "&.Mui-checked": {
    color: theme.palette.colors.text.secondary,
  },
}));

function MobileFilterPriceRange() {
  const { values, setFieldValue } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue("pmin", JSON.parse(event.target.value).pmin);
    setFieldValue("pmax", JSON.parse(event.target.value).pmax);
  };

  const ButtonList = [
    {
      label: "All",
      value: { pmin: "", pmax: "" },
    },
    {
      label: "RM0 - RM50",
      value: { pmin: "0", pmax: "50" },
    },
    {
      label: "RM50 - RM150",
      value: { pmin: "50", pmax: "150" },
    },
    {
      label: "RM150 - RM300",
      value: { pmin: "150", pmax: "300" },
    },
    {
      label: "> RM300",
      value: { pmin: "300", pmax: "1000000" },
    },
  ];

  return (
    <Box>
      <StyledText>Price Range</StyledText>
      <Spacer size="s" position="top" />
      <FormControl>
        <RadioGroup
          value={JSON.stringify({ pmin: values.pmin, pmax: values.pmax })}
          onChange={handleChange}
        >
          {ButtonList.map((item) => (
            <FormControlLabel
              key={item.label}
              value={JSON.stringify(item.value)}
              control={<StyledRadion />}
              label={<PlaceholderText>{item.label}</PlaceholderText>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default MobileFilterPriceRange;
