import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../infrastructure/utils/index";
import routes from "./routes";

function RestrictedRoute({ component: Component, ...otherProps }) {
  return (
    <Route
      {...otherProps}
      render={(props) => (isLogin() ? <Redirect to={routes.HOME} /> : <Component {...props} />)}
    />
  );
}

RestrictedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default RestrictedRoute;
