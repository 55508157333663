import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import Spacer from "../../../../components/utils/spacer.component";
import BackgroundButton from "../../../../components/button/background-button.component";
import routes from "../../../../components/navigation/routes";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const DetailsBox = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
});

const StyledMoreHorizIcon = styled(MoreHorizIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  width: "30px",
  height: "30px",
}));

const StyledCancelIconBox = styled(Box)({
  width: "100%",
  height: "15px",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
});

function PendingPaymentItemList({ itemData, handleCancelOrder, index }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCancel, setShowCancel] = useState(null);

  const handleOpenUserMenu = (event) => {
    setShowCancel(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setShowCancel(null);
  };

  const renderCancel = () => (
    <StyledMenu
      sx={{ mt: "15px" }}
      id="menu-appbar"
      anchorEl={showCancel}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(showCancel)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem
        key={1}
        onClick={() => {
          handleCloseUserMenu();
          handleCancelOrder(itemData.id);
        }}
      >
        <Typography textAlign="center">Cancel Booking</Typography>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        {index === 0 && <SeperateLine />}
      </Grid>

      <Grid item xs={12}>
        <StyledCancelIconBox>
          <IconButton sx={{ padding: 0, marginTop: "-12px" }} onClick={handleOpenUserMenu}>
            <StyledMoreHorizIcon />
          </IconButton>
          {renderCancel()}
        </StyledCancelIconBox>
        <MainBox>
          <img
            alt="img-exp"
            style={{
              width: "180px",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
            src={itemData.orderItems[0].experience.imgPath}
          />

          <Spacer size="m" position="left" />
          <DetailsBox>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: routes.ORDER_DETAILS,
                search: `orderId=${itemData.id}`,
              }}
            >
              <StyledTitle>{`Ref ID: ${itemData.id}`}</StyledTitle>
            </Link>
            <Spacer size="xs" position="top" />
            <GreyText>Awaiting Payment</GreyText>
          </DetailsBox>
          {!isMobile && (
            <>
              <DetailsBox>
                <GreyText textAlign="start">Items</GreyText>
                <Spacer size="xs" position="top" />
                <PrimaryColorText>{itemData.totalItems}</PrimaryColorText>
              </DetailsBox>
              <DetailsBox>
                <GreyText textAlign="start">To Pay</GreyText>
                <Spacer size="xs" position="top" />
                <PrimaryColorText
                  sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                >{`RM${itemData.totalCost}`}</PrimaryColorText>
              </DetailsBox>
              <DetailsBox>
                <Link
                  to={{
                    pathname: routes.ORDER_DETAILS,
                    search: `orderId=${itemData.id}`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <BackgroundButton>
                    <WhiteColorText>See Booking</WhiteColorText>
                  </BackgroundButton>
                </Link>
              </DetailsBox>
            </>
          )}
        </MainBox>
      </Grid>
      {isMobile ? (
        <>
          <Grid item xs={12}>
            <Spacer size="m" position="top" />
            <MainBox>
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <PrimaryColorText>Items:</PrimaryColorText>
                <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  {itemData.totalItems}
                </PrimaryColorText>
              </Box>
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <PrimaryColorText>Total Cost:</PrimaryColorText>
                <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  {` RM${itemData.totalCost}`}
                </PrimaryColorText>
              </Box>
            </MainBox>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="m" position="top" />
            <Link
              to={{
                pathname: routes.ORDER_DETAILS,
                search: `orderId=${itemData.id}`,
              }}
              style={{ textDecoration: "none" }}
            >
              <BackgroundButton>
                <WhiteColorText>See Booking</WhiteColorText>
              </BackgroundButton>
            </Link>
          </Grid>
          <SeperateLine />
        </>
      ) : (
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
      )}
    </Grid>
  );
}

PendingPaymentItemList.propTypes = {
  itemData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    totalItems: PropTypes.number.isRequired,
    totalCost: PropTypes.string.isRequired,
    orderItems: PropTypes.arrayOf(
      PropTypes.shape({
        experience: PropTypes.shape({
          imgPath: PropTypes.string.isRequired,
        }),
      }),
    ),
  }).isRequired,
  handleCancelOrder: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default PendingPaymentItemList;
