import { createSlice } from "@reduxjs/toolkit";

const guestSlice = createSlice({
  name: "guest",
  initialState: {
    guestFavouriteList: JSON.parse(localStorage.getItem("yoloGuestFavourites")) || [],
    guestCartList: JSON.parse(localStorage.getItem("yoloGuestCartList")) || [],
    guestCartQuantity: localStorage.getItem("yoloGuestCartQuantity") || 0,
  },
  reducers: {
    guestToggleFavourite: (state, data) => {
      const isChecked = state.guestFavouriteList.some(
        (item) => item.experienceId === data.payload.experienceId,
      );
      if (isChecked) {
        state.guestFavouriteList = state.guestFavouriteList.filter(
          (item) => item.experienceId !== data.payload.experienceId,
        );
      } else {
        const newArr = [
          { isFlexi: false, uniqueId: `${data.payload.experienceId}false`, ...data.payload },
        ];
        state.guestFavouriteList = newArr.concat(state.guestFavouriteList);
      }
      localStorage.setItem("yoloGuestFavourites", JSON.stringify(state.guestFavouriteList));
    },
    guestAddToCart: (state, data) => {
      const isInCart = state.guestCartList.some(
        (item) =>
          parseInt(item.experienceId, 10) === parseInt(data.payload.experienceId, 10) &&
          item.isFlexi === data.payload.isFlexi,
      );
      if (isInCart) {
        state.guestCartList.map((item) => {
          if (
            parseInt(item.experienceId, 10) === parseInt(data.payload.experienceId, 10) &&
            item.isFlexi === data.payload.isFlexi
          ) {
            state.guestCartQuantity =
              parseInt(state.guestCartQuantity, 10) + data.payload.quantity - item.quantity;
            item.quantity = data.payload.quantity;
            item.addOns = data.payload.addOns;
          }
          return state.guestCartList;
        });
      } else {
        state.guestCartList = state.guestCartList.concat(data.payload);
        state.guestCartQuantity = parseInt(state.guestCartQuantity, 10) + data.payload.quantity;
      }

      localStorage.setItem("yoloGuestCartList", JSON.stringify(state.guestCartList));
      localStorage.setItem("yoloGuestCartQuantity", JSON.stringify(state.guestCartQuantity));
    },
    onCartAmountChanged: (state, data) => {
      state.guestCartList.map((item) => {
        if (
          parseInt(item.experienceId, 10) === parseInt(data.payload.experienceId, 10) &&
          item.isFlexi === data.payload.isFlexi
        ) {
          item.quantity = data.payload.quantity;
        }
        return state.guestCartList;
      });
      let cartItemQuantity = 0;

      state.guestCartList.map((item) => {
        cartItemQuantity += parseInt(item.quantity, 10);
        return cartItemQuantity;
      });

      state.guestCartQuantity = cartItemQuantity;
      localStorage.setItem("yoloGuestCartList", JSON.stringify(state.guestCartList));
      localStorage.setItem("yoloGuestCartQuantity", JSON.stringify(state.guestCartQuantity));
    },
    onRemoveCartItems: (state, data) => {
      state.guestCartList = state.guestCartList.filter(
        (item) => item.uniqueId !== data.payload.uniqueId,
      );
      let cartItemQuantity = 0;
      state.guestCartList.map((item) => {
        cartItemQuantity += parseInt(item.quantity, 10);
        return cartItemQuantity;
      });
      state.guestCartQuantity = cartItemQuantity;
      localStorage.setItem("yoloGuestCartList", JSON.stringify(state.guestCartList));
      localStorage.setItem("yoloGuestCartQuantity", JSON.stringify(state.guestCartQuantity));
    },
    resetGuestFavourites: (state) => {
      state.guestFavouriteList = [];
      localStorage.removeItem("yoloGuestFavourites");
    },
    resetGuestCarts: (state) => {
      state.guestCartQuantity = 0;
      state.guestCartList = [];
      localStorage.removeItem("yoloGuestCartList");
      localStorage.removeItem("yoloGuestCartQuantity");
    },
  },
});

export default guestSlice.reducer;

// state
export const guestSelector = (state) => state.guest;

export const {
  resetUser,
  guestToggleFavourite,
  guestAddToCart,
  onCartAmountChanged,
  onRemoveCartItems,
  resetGuestFavourites,
  resetGuestCarts,
} = guestSlice.actions;
