import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Avatar, Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../components/utils/spacer.component";
import ImageFullViewModal from "./image-full-view-modal.component";
import { likeExperienceReview } from "../../../services/experiences/experience-slice.service";
import { isLogin } from "../../../infrastructure/utils";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size12,
}));

const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

function ReviewCard({ reviewDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const dispatch = useDispatch();
  const [showImageFullView, setShowImageFullView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [likeByUser, setLikeByUser] = useState(reviewDetails.likeByCurrentUser);
  const [helpfulCount, setHelpfulCount] = useState(reviewDetails.helpfulCount);

  const handleLikeAction = (from) => {
    if (from === "true") {
      setLikeByUser(false);
      setHelpfulCount(helpfulCount - 1);
    } else {
      setLikeByUser(true);
      setHelpfulCount(helpfulCount + 1);
    }

    const helpful = from === "false";
    if (isLogin()) {
      dispatch(likeExperienceReview({ experienceId, reviewId: reviewDetails.id, helpful }));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CenterRowBox>
          <Avatar sx={{ width: 45, height: 45 }} src={reviewDetails.profileImg} />
          <Spacer size="s" position="left" />
          <Box>
            <GreyText>{`${reviewDetails.username} · ${reviewDetails.createdAt}`}</GreyText>
            <ReactStars
              activeColor={theme.palette.colors.ui.star}
              size={15}
              edit={false}
              value={parseFloat(reviewDetails.rating)}
              color={theme.palette.colors.ui.border}
              isHalf={true}
            />
          </Box>
        </CenterRowBox>
      </Grid>
      <Grid item xs={12}>
        <PrimaryText>{reviewDetails.comment}</PrimaryText>
      </Grid>
      {reviewDetails.images.length > 0 && (
        <Grid item xs={12}>
          <ImageFullViewModal
            imageList={reviewDetails.images}
            showModal={showImageFullView}
            setShowModal={setShowImageFullView}
            selectedItem={selectedImage}
          />
          <Grid container spacing={1}>
            {reviewDetails.images.map((item, index) => (
              <Grid
                item
                key={uuidv4()}
                xs={2}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setShowImageFullView(true);
                  setSelectedImage(index);
                }}
              >
                <img
                  src={item}
                  width="100%"
                  height={isMobile ? "70px" : "95px"}
                  alt="uploaded-img"
                  style={{ objectFit: "cover", borderRadius: "5px" }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container alignItems="center">
          {likeByUser ? (
            <ThumbUpIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              color="brandColor"
              onClick={() => {
                handleLikeAction("true");
              }}
            />
          ) : (
            <ThumbUpOffAltIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              color="brandColor"
              onClick={() => {
                handleLikeAction("false");
              }}
            />
          )}

          <Spacer size="xs" position="left" />
          <GreyText>
            {helpfulCount > 0 ? `${helpfulCount} person(s) find this helpful` : "Helpful?"}
          </GreyText>
        </Grid>
        <Spacer size="s" position="top" />
        <SeperateLine />
      </Grid>
    </Grid>
  );
}

ReviewCard.defaultProps = {
  reviewDetails: PropTypes.shape({
    id: null,
    username: "",
    profileImg: "",
    rating: 5,
    comment: "",
    createdAt: "",
    images: [],
    likeByCurrentUser: false,
    helpfulCount: 0,
  }),
};

ReviewCard.propTypes = {
  reviewDetails: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    profileImg: PropTypes.string,
    rating: PropTypes.number,
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    likeByCurrentUser: PropTypes.bool,
    helpfulCount: PropTypes.number,
  }),
};

export default ReviewCard;
