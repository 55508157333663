import React from "react";
import { Helmet } from "react-helmet";

function GoogleTag() {
  const gtmSrc = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`;

  return (
    <Helmet>
      <script async src={gtmSrc} />
      <script>
        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.REACT_APP_GTM_ID}');`}
      </script>
    </Helmet>
  );
}

export default GoogleTag;
