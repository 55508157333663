import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function BuyNowPhoneNumberTextField({ name, defaultValue, width, isTransparent, errorName }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldTouched, touched } = useFormikContext();
  const showError =
    touched.gift &&
    touched.gift.recipient &&
    touched.gift?.recipient[errorName] &&
    errors.gift &&
    errors.gift.recipient &&
    typeof errors.gift?.recipient[errorName] === "string";

  return (
    <Box
      sx={{
        ".react-tel-input .form-control": {
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.secondary,
        },
      }}
    >
      <PhoneInput
        specialLabel=""
        value={defaultValue}
        country="my"
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, e)}
        inputStyle={{
          width,
          borderRadius: theme.shape.borderRadius[0],
          borderColor: showError && theme.palette.colors.ui.error,
        }}
      />
      {showError && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.error,
            margin: "3px 14px 0px 14px",
          }}
        >
          {errors.gift?.recipient[errorName]}
        </FormHelperText>
      )}
    </Box>
  );
}

BuyNowPhoneNumberTextField.defaultProps = {
  width: "80%",
  defaultValue: "",
  isTransparent: false,
};

BuyNowPhoneNumberTextField.propTypes = {
  errorName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
};

export default BuyNowPhoneNumberTextField;
