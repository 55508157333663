import { Box, Checkbox, IconButton, styled, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const SpaceBetweenRowBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const QuantityBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: "transparent",
  padding: "0px",
  "& .MuiTextField-root": {
    padding: "0px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "45px",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.secondary,
      },
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.brand.primary,
}));

export default function CartAddOnsItem({ item }) {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const isChecked = values.addOns.some(
    (addOns) => (isLogin() ? addOns.experienceAddOnId : addOns.id) === item.id,
  );
  const index = values.addOns.findIndex(
    (addOns) => (isLogin() ? addOns.experienceAddOnId : addOns.id) === item.id,
  );

  const getPrice = () => {
    if (isChecked) {
      if (Number.isNaN(parseFloat(values.addOns[index].quantity))) {
        return item.price;
      }
      return (
        parseFloat(values.addOns[index].quantity) * parseFloat(item.price.replace(",", ""))
      ).toFixed(2);
    }
    return item.price;
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      setFieldValue(
        "addOns",
        values.addOns.filter(
          (addOns) => (isLogin() ? addOns.experienceAddOnId : addOns.id) !== item.id,
        ),
      );
    } else {
      setFieldValue("addOns", values.addOns.concat({ ...item, experienceAddOnId: item.id }));
    }
  };

  const onAddButtonPressed = () => {
    if (Number.isNaN(parseFloat(values.addOns[index].quantity))) {
      setFieldValue(`addOns[${index}].quantity`, 1);
      return;
    }
    setFieldValue(`addOns[${index}].quantity`, parseFloat(values.addOns[index].quantity) + 1);
  };

  const onDeductButtonPressed = () => {
    if (
      Number.isNaN(parseFloat(values.addOns[index].quantity)) ||
      parseFloat(values.addOns[index].quantity) === 1
    ) {
      setFieldValue(`addOns[${index}].quantity`, 1);
      return;
    }
    setFieldValue(`addOns[${index}].quantity`, parseFloat(values.addOns[index].quantity) - 1);
  };

  const onBlurHandle = () => {
    if (values.addOns[index].quantity === "") {
      setFieldValue(`addOns[${index}].quantity`, 1);
    }
  };

  return (
    <SpaceBetweenRowBox>
      <CenterRowBox>
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          size="medium"
          sx={{
            color: theme.palette.colors.brand.primary,
            "&.Mui-checked": {
              color: theme.palette.colors.brand.primary,
            },
          }}
        />

        <Box>
          <GreyText>{item.label}</GreyText>
          <Spacer position="top" size="xs" />
          <GreyText variant="body2">{item.sublabel}</GreyText>
          <Spacer position="top" size="xs" />
          {isChecked && (
            <CenterRowBox>
              <GreyText variant="body2">Quantity</GreyText>
              <Spacer position="left" size="xs" />
              <StyledIconButton onClick={onDeductButtonPressed}>
                <RemoveIcon sx={{ fontSize: "16px" }} />
              </StyledIconButton>
              <Spacer position="left" size="xs" />
              <QuantityBox>
                <TextField
                  onBlur={onBlurHandle}
                  variant="outlined"
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: theme.fonts.fontSizes.size14,
                      padding: "2px",
                    },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  placeholder="Qty"
                  onChange={(e) => {
                    if (Number.isNaN(parseFloat(e.target.value))) {
                      setFieldValue(`addOns[${index}].quantity`, "");
                      return;
                    }
                    setFieldValue(`addOns[${index}].quantity`, parseFloat(e.target.value));
                  }}
                  value={values.addOns[index].quantity}
                />
              </QuantityBox>
              <Spacer position="left" size="xs" />
              <StyledIconButton onClick={onAddButtonPressed}>
                <AddIcon sx={{ fontSize: "16px" }} />
              </StyledIconButton>
            </CenterRowBox>
          )}
        </Box>
      </CenterRowBox>
      {isChecked ? (
        <BrandColorText
          sx={{ minWidth: "100px", textAlign: "end" }}
        >{`+RM ${getPrice()}`}</BrandColorText>
      ) : (
        <GreyText sx={{ minWidth: "100px", textAlign: "end" }}>{`+RM ${getPrice()}`}</GreyText>
      )}
    </SpaceBetweenRowBox>
  );
}

CartAddOnsItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    price: PropTypes.string,
    sublabel: PropTypes.string,
  }).isRequired,
};
