import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { orderSelector } from "../../../services/order/order-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import routes from "../../../components/navigation/routes";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

function OrderList() {
  const { getOrdersObj } = useSelector(orderSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderItemList = () => {
    if (getOrdersObj.status === "succeeded") {
      if (!getOrdersObj.data.paid || getOrdersObj.data.paid.length === 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xxxl" position="top" />
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.colors.brand.secondary,
                  fontWeight: theme.fonts.fontWeights.regular,
                }}
              >
                No item to show.
              </Typography>
            </Grid>
          </Grid>
        );
      }
      return (
        getOrdersObj.data.paid &&
        getOrdersObj.data.paid.map((item) => (
          <Grid item xs={12} key={item.id}>
            <Link
              to={{ pathname: routes.MY_ORDER_DETAILS, search: `orderId=${item.id}` }}
              style={{ textDecoration: "none" }}
            >
              <CardContainer>
                <PaddedView multiples={isMobile ? 2 : 3}>
                  <SpaceBetweenRowBox>
                    <Box>
                      <PrimaryColorText>Paid On</PrimaryColorText>{" "}
                      <PrimaryColorText>{item.paidAt}</PrimaryColorText>
                    </Box>
                    <Box>
                      <PrimaryColorText sx={{ textAlign: "end" }}>Total Cost</PrimaryColorText>
                      <PrimaryColorText
                        sx={{ textAlign: "end" }}
                      >{`RM${item.totalCost}`}</PrimaryColorText>
                    </Box>
                  </SpaceBetweenRowBox>
                </PaddedView>
              </CardContainer>
            </Link>
          </Grid>
        ))
      );
    }
    return (
      <Grid item xs={12}>
        <CardContainer>
          <PaddedView multiples={3}>
            <SpaceBetweenRowBox>
              <PrimaryColorText>Paid On</PrimaryColorText>
              <PrimaryColorText>Total Cost</PrimaryColorText>
            </SpaceBetweenRowBox>
            <SpaceBetweenRowBox>
              <CustomSkeleton width="125px" height="25px" />
              <CustomSkeleton width="125px" height="25px" />
            </SpaceBetweenRowBox>
          </PaddedView>
        </CardContainer>
      </Grid>
    );
  };

  return renderItemList();
}

export default OrderList;
