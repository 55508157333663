import React, { useState } from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PropTypes from "prop-types";
import BackgroundButton from "../../../components/button/background-button.component";
import { isLogin } from "../../../infrastructure/utils";

const StyledIconButton = styled(IconButton)({
  height: "20px",
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

const CenterItemBox = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "100%",
  width: "100%",
});

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size14,
}));

function RightBox({
  onClickAddCart,
  backgroundButtonLoading,
  isDefaultFavourite,
  onFavouriteClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("840"));
  const [isFavourite, setIsFavourite] = useState(isDefaultFavourite);

  const favouriteOnClick = () => {
    setIsFavourite(!isFavourite);
    onFavouriteClick();
  };

  const renderFavouriteIcon = () => (
    <CenterItemBox>
      {isFavourite ? (
        <StyledIconButton disableRipple onClick={() => favouriteOnClick()}>
          <FavoriteIcon sx={{ color: theme.palette.colors.ui.favourite }} />
        </StyledIconButton>
      ) : (
        <StyledIconButton disableRipple onClick={() => favouriteOnClick()}>
          <FavoriteBorderIcon sx={{ color: theme.palette.colors.brand.secondary }} />
        </StyledIconButton>
      )}
    </CenterItemBox>
  );

  return (
    <Grid item xs={isMobile ? 12 : 2.5} paddingTop={isMobile ? "30px" : 0}>
      <Grid container sx={{ height: "100%" }}>
        {!isMobile && (
          <Grid item xs={4}>
            {renderFavouriteIcon()}
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 8}>
          <CenterItemBox>
            <Box sx={{ width: "100%" }}>
              <BackgroundButton
                isLoading={!isLogin() ? false : backgroundButtonLoading}
                handleSubmit={() => onClickAddCart()}
                width="100%"
              >
                <WhiteColorText>Add To Cart</WhiteColorText>
              </BackgroundButton>
            </Box>
          </CenterItemBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

RightBox.propTypes = {
  onClickAddCart: PropTypes.func.isRequired,
  backgroundButtonLoading: PropTypes.bool.isRequired,
  isDefaultFavourite: PropTypes.bool.isRequired,
  onFavouriteClick: PropTypes.func.isRequired,
};

export default RightBox;
