import { Box, Grid, Link, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { subscribeNewsletter } from "../../../services/marketing/newsletter/newsletter-slice.service";
import NewsletterForm from "../components/newsletter-form.component";

const CenterBox = styled(Box)({
  paddingTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const FirstParagraph = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  fontSize: isMobile ? theme.fonts.fontSizes.h5 : theme.fonts.fontSizes.h3,
  textAlign: isMobile ? "center" : "left",
  fontWeight: "bold",
}));

const SecondParagraph = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  fontSize: theme.fonts.fontSizes.size20,
  textAlign: isMobile ? "center" : "left",
  color: theme.palette.colors.text.secondary,
  fontWeight: "bold",
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function NewsletterSignUpScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showIndex, setShowIndex] = useState(0);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  let count = 0;
  const experienceArr = [
    "go-karting",
    "flying",
    "scuba-diving",
    "art-classes",
    "flyboarding",
    "paragliding",
    "animal experiences",
  ];

  const cycleArrayExperience = () => {
    count += 1;
    setShowIndex(count);

    // reset counter if we reach end of array
    if (count === experienceArr.length) {
      count = 0;
      setShowIndex(0);
    }
  };

  useEffect(() => {
    setInterval(cycleArrayExperience, 1000);
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(subscribeNewsletter(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <CenterBox>
      <Box
        sx={{
          paddingX: isMobile ? "20px" : "10px",
          paddingBottom: isMobile ? "60px" : "0px",
          height: "100%",
          width: "100%",
          maxWidth: theme.dimensions.PCMaxWidth,
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Link href={routes.HOME} underline="none">
              <img
                src="https://merchantinfo.yolo.my/wp-content/uploads/2023/04/YOLO-Planet-b07aff.png"
                alt="yolo-logo"
                width={60}
              />
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="https://merchantinfo.yolo.my/magazine/"
              underline="none"
              sx={{
                color: theme.palette.colors.brand.lightPrimary,
                fontSize: theme.fonts.fontSizes.size20,
              }}
            >
              Magazine
            </Link>
          </Grid>
        </Grid>
        <Spacer size="l" />
        <Grid container direction="row-reverse">
          <Grid item xs={isMobile ? 12 : 6}>
            <img
              src="https://merchantinfo.yolo.my/wp-content/uploads/2023/04/Floating-Phones-02-WEBP.webp"
              alt="yolo-phone-logo"
              width="100%"
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6} alignItems="center" display="flex">
            <Grid
              item
              display="grid"
              flexDirection="column"
              alignContent="space-between"
              height="80%"
              xs={12}
              paddingX={isMobile ? "20px" : "0px"}
            >
              <Grid item>
                <FirstParagraph isMobile={isMobile}>
                  Largest Experience Marketplace at your fingertips.
                </FirstParagraph>
              </Grid>
              {isMobile && <Spacer />}

              <Grid item>
                <SecondParagraph isMobile={isMobile}>
                  RM5 off {experienceArr[showIndex]}
                </SecondParagraph>
                <SecondParagraph isMobile={isMobile}>
                  for the first 10,000 people to join our mailing list.
                </SecondParagraph>
                <Spacer size="l" />
                <Grid item xs={isMobile ? 12 : 10.5}>
                  <Form
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <NewsletterForm />
                  </Form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CenterBox>
  );
}

export default NewsletterSignUpScreen;
