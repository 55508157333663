import React, { useState } from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { guestSelector } from "../../../services/guest/guest-slice.service";
import { isLogin } from "../../../infrastructure/utils";
import FlexiCheckBox from "./flexi-checkbox.component";

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflowWrap: "anywhere",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledIconButton = styled(IconButton)({
  height: "20px",
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

function LeftBox({ itemDetails, onFavouriteClick, isFlexi, setIsFlexi, setMassSlotId }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("840"));
  const { guestFavouriteList } = useSelector(guestSelector);
  const [isFavourite, setIsFavourite] = useState(
    isLogin()
      ? itemDetails.favourited
      : guestFavouriteList.some((item) => item.experienceId === itemDetails.id),
  );

  const favouriteOnClick = () => {
    setIsFavourite(!isFavourite);
    if (isFavourite) {
      onFavouriteClick(false);
    } else {
      onFavouriteClick(true);
    }
  };

  const renderFavouriteIcon = () => (
    <Typography>
      {isFavourite ? (
        <StyledIconButton disableRipple onClick={() => favouriteOnClick()}>
          <FavoriteIcon sx={{ color: theme.palette.colors.ui.favourite }} />
        </StyledIconButton>
      ) : (
        <StyledIconButton disableRipple onClick={() => favouriteOnClick()}>
          <FavoriteBorderIcon sx={{ color: theme.palette.colors.brand.secondary }} />
        </StyledIconButton>
      )}
    </Typography>
  );

  return (
    <Grid item xs={isMobile ? 12 : 5}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <img
          src={itemDetails.imgPath}
          alt="exp-img"
          style={{
            width: "180px",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        />

        <Spacer size="m" position="left" />
        <DetailsBox>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: routes.EXPERIENCESDETAILS,
              search: `experienceId=${itemDetails.id}`,
            }}
          >
            <TitleText>{itemDetails.title}</TitleText>
          </Link>
          <GreyText>{itemDetails.state}</GreyText>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <StyledRatingBox>
              <ReactStars
                activeColor={theme.palette.colors.ui.star}
                size={15}
                edit={false}
                value={parseFloat(itemDetails.ratings)}
                color={theme.palette.colors.ui.border}
                isHalf={true}
              />
              <Spacer size="xs" position="left" />
              <GreyText>{`${itemDetails.ratings} (${itemDetails.noReviews})`}</GreyText>
            </StyledRatingBox>
            {isMobile && renderFavouriteIcon()}
          </Box>
        </DetailsBox>
      </Box>
      {itemDetails.hasFlexi.status && (
        <>
          <Spacer size="m" />
          <FlexiCheckBox
            itemDetails={itemDetails}
            isFlexi={isFlexi}
            setIsFlexi={setIsFlexi}
            setMassSlotId={setMassSlotId}
          />
        </>
      )}
    </Grid>
  );
}

LeftBox.defaultProps = {
  itemDetails: {
    addOns: [],
    description: "",
    favourited: false,
    hasFlexi: {
      status: false,
    },
    id: null,
    imgPath: "",
    importantInfo: "",
    location: {
      lat: "",
      lng: "",
    },
    maxNoPax: null,
    minNoPax: null,
    noReviews: null,
    pricePerItem: "",
    quantity: null,
    ratings: null,
    state: "",
    title: "",
  },
};

LeftBox.propTypes = {
  isFlexi: PropTypes.bool.isRequired,
  setIsFlexi: PropTypes.func.isRequired,
  setMassSlotId: PropTypes.func.isRequired,
  onFavouriteClick: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    favourited: PropTypes.bool,
    hasFlexi: PropTypes.shape({
      status: PropTypes.bool,
    }),
    id: PropTypes.number,
    imgPath: PropTypes.string,
    importantInfo: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    maxNoPax: PropTypes.number,
    minNoPax: PropTypes.number,
    noReviews: PropTypes.number,
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
    ratings: PropTypes.number,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default LeftBox;
