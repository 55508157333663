import React from "react";
import { Box, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import BackgroundButton from "../../../components/button/background-button.component";

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size20,
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const StyledBorderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: isMobile ? "100%" : "90%",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

function PurchaseBoxLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
      <StyledBorderBox isMobile={isMobile}>
        <TitleText>Cart Summary</TitleText>
        <SeperateLine />

        <StyledRow>
          <DescriptionText>Items:</DescriptionText>
          <CustomSkeleton width="70px" height="40px" />
        </StyledRow>
        <Spacer size="s" position="top" />
        <StyledRow>
          <DescriptionText>Total Cost:</DescriptionText>
          <CustomSkeleton width="70px" height="40px" />
        </StyledRow>
        <Spacer size="xl" position="top" />
        <BackgroundButton width="100%" isLoading={true}>
          <Typography>Secure Checkout</Typography>
        </BackgroundButton>
      </StyledBorderBox>
    </Box>
  );
}

export default PurchaseBoxLoader;
