import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const getGiftOrderDetails = createAsyncThunk(
  "gifts/orders/order_details",
  async (payload) => {
    const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/gifts/orders/${payload}`);
    return response;
  },
);

export const getGiftOrders = createAsyncThunk("gifts/orders/order_list", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/gifts/orders?page=${payload.page}`,
  );
  return response;
});

const giftOrderSlice = createSlice({
  name: "giftOrder",
  initialState: {
    getGiftOrderDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getGiftOrdersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getGiftOrderDetails.pending]: (state) => {
      state.getGiftOrderDetailsObj.status = "pending";
    },
    [getGiftOrderDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getGiftOrderDetailsObj.status = "succeeded";
      state.getGiftOrderDetailsObj.data = data;
      state.getGiftOrderDetailsObj.successMessage = message;
    },
    [getGiftOrderDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getGiftOrderDetailsObj.status = "failed";
      state.getGiftOrderDetailsObj.errorMessage = message;
    },
    [getGiftOrders.pending]: (state) => {
      state.getGiftOrdersObj.status = "pending";
    },
    [getGiftOrders.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getGiftOrdersObj.status = "succeeded";
      state.getGiftOrdersObj.data = data;
      state.getGiftOrdersObj.successMessage = message;
    },
    [getGiftOrders.rejected]: (state, action) => {
      const { message } = action.error;

      state.getGiftOrdersObj.status = "failed";
      state.getGiftOrdersObj.errorMessage = message;
    },
  },
});

export default giftOrderSlice.reducer;

// state
export const giftOrderSelector = (state) => state.giftOrder;
