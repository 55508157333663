import React from "react";
import { Box, Link, styled, Typography, useMediaQuery, useTheme } from "@mui/material";

import Spacer from "../utils/spacer.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  lineHeight: "30px",
  textAlign: "center",
  fontSize: theme.fonts.fontSizes.size14,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  lineHeight: "30px",
  fontSize: theme.fonts.fontSizes.size14,
}));

const StyledMainBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  justifyContent: isMobile ? "center" : "space-between",
  flexDirection: isMobile ? "column" : "row",
}));

const StyledRowBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: isMobile ? "column" : "row",
}));

function TermsAndPrivacy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const copyright = `(C) ${new Date().getFullYear()} YOLO, All Rights Reserved.`;
  const privacyLink = "http://merchantinfo.yolo.my/privacy-policy/";
  const termsLink = "http://merchantinfo.yolo.my/terms-and-conditions/";
  const refundLink = "https://merchantinfo.yolo.my/payment-and-refund-policies/";

  return (
    <StyledMainBox isMobile={isMobile}>
      <StyledRowBox isMobile={isMobile}>
        <PrimaryColorText>{copyright}</PrimaryColorText>
        <StyledRowBox>
          <Spacer size="s" position="right" />
          <StyledLink href={privacyLink} target="_blank" underline="hover">
            Privacy Policy
          </StyledLink>
          <Spacer size="s" position="right" />
          <PrimaryColorText>|</PrimaryColorText>
          <Spacer size="s" position="right" />
          <StyledLink href={termsLink} target="_blank" underline="hover">
            Terms
          </StyledLink>
          <Spacer size="s" position="right" />
          <PrimaryColorText>|</PrimaryColorText>
          <Spacer size="s" position="right" />
          <StyledLink href={refundLink} target="_blank" underline="hover">
            Refund Policies
          </StyledLink>
        </StyledRowBox>
      </StyledRowBox>
    </StyledMainBox>
  );
}

export default TermsAndPrivacy;
