import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

function LeftBoxLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item xs={isMobile ? 12 : 5}>
      <Grid container>
        <Grid item xs={6}>
          <CustomSkeleton width="90%" height={isMobile ? "100px" : "150px"} />
        </Grid>
        <Grid item xs={6}>
          <DetailsBox>
            <CustomSkeleton width="70%" height="25px" />
            <CustomSkeleton width="40%" height="20px" />
            <CustomSkeleton width="40%" height="20px" />
          </DetailsBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LeftBoxLoader;
