import React, { useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import LeftBox from "./left-box.component";
import MiddleBox from "./middle-box.component";
import RightBox from "./right-box.component";
import { isLogin } from "../../../infrastructure/utils";
import { guestSelector } from "../../../services/guest/guest-slice.service";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

function ItemList({ onClickAddCart, backgroundButtonLoading, itemData, onToggleFavourite }) {
  const [quantity, setQuantity] = useState(1);
  const [isFlexi, setIsFlexi] = useState(itemData.hasFlexi.status);
  const [massSlotId, setMassSlotId] = useState(
    (itemData.hasFlexi.status && itemData.hasFlexi.massSlotId) || "",
  );
  const { guestFavouriteList } = useSelector(guestSelector);
  const onSubmit = () => {
    onClickAddCart({
      quantity,
      isFlexi,
      massSlotId,
      experienceId: itemData.id,
      experience: itemData,
    });
  };
  const onFavouriteClick = (isAddFavourite) => {
    onToggleFavourite({ experienceId: itemData.id }, isAddFavourite);
  };
  return (
    <Grid container spacing={1}>
      <LeftBox
        itemDetails={itemData}
        onFavouriteClick={onFavouriteClick}
        isFlexi={isFlexi}
        setIsFlexi={setIsFlexi}
        massSlotId={massSlotId}
        setMassSlotId={setMassSlotId}
      />
      <MiddleBox setQuantity={setQuantity} quantity={quantity} itemData={itemData} />
      <RightBox
        onClickAddCart={onSubmit}
        backgroundButtonLoading={backgroundButtonLoading}
        isDefaultFavourite={
          isLogin()
            ? itemData.favourited
            : guestFavouriteList.some((item) => item.experienceId === itemData.id)
        }
        onFavouriteClick={onFavouriteClick}
      />
      <SeperateLine />
    </Grid>
  );
}

ItemList.defaultProps = {
  itemData: {
    experience: {
      addOns: [],
      description: "",
      favourited: false,
      hasFlexi: {
        status: false,
      },
      id: null,
      imgPath: "",
      importantInfo: "",
      location: {
        lat: "",
        lng: "",
      },
      maxNoPax: null,
      minNoPax: null,
      noReviews: null,
      pricePerItem: "",
      quantity: null,
      ratings: null,
      state: "",
      title: "",
    },
  },
};

ItemList.propTypes = {
  onClickAddCart: PropTypes.func.isRequired,
  backgroundButtonLoading: PropTypes.bool.isRequired,
  itemData: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    favourited: PropTypes.bool,
    hasFlexi: PropTypes.shape({
      massSlotId: PropTypes.number,
      status: PropTypes.bool,
    }),
    id: PropTypes.number,
    imgPath: PropTypes.string,
    importantInfo: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    maxNoPax: PropTypes.number,
    minNoPax: PropTypes.number,
    noReviews: PropTypes.number,
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
    ratings: PropTypes.number,
    state: PropTypes.string,
    title: PropTypes.string,
  }),

  onToggleFavourite: PropTypes.func.isRequired,
};

export default ItemList;
