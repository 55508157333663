import React from "react";
import { Box, Grid, styled } from "@mui/material";
import LeftBox from "./left-box-loader.component";
import MiddleBox from "./middle-box-loader.component";
import RightBoxLoader from "./right-box-loader.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

function FavouritesLoader() {
  return (
    <Grid container>
      <LeftBox />
      <MiddleBox />
      <RightBoxLoader />
      <SeperateLine />
    </Grid>
  );
}

export default FavouritesLoader;
