import React from "react";
import { Box, Grid, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import FestivalItems from "./festival-items-list.component";
import { marketingSelector } from "../../../../services/marketing/marketing-slice.service";

const StyledBox = styled(Box)({
  padding: 2,
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
}));

function Festival() {
  const { getFestivalObj } = useSelector(marketingSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <StyledBox
            sx={{
              marginLeft: isMobile
                ? theme.dimensions.MobileExperiencesPadding
                : theme.dimensions.PCExperiencesPadding,
            }}
          >
            <img
              src={getFestivalObj.data.iconPath}
              alt="icon"
              style={{ height: "25px", width: "25px", marginRight: "10px" }}
            />
            <Title>{getFestivalObj.data.label}</Title>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <FestivalItems />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Festival;
