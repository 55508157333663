import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import Spacer from "../../../components/utils/spacer.component";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";
import AddOnsItem from "./add-ons-item.component";
import Form from "../../../components/forms/form.component";
import AddOnsPopUpBottomBar from "./add-ons-pop-up-bottom-bar.component";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: "90%",
  maxWidth: "750px",
  outline: "none",
  maxHeight: "90%",
  overflowY: "auto",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: "15px",
  top: "15px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const validationSchema = Yup.object().shape({
  addOns: Yup.array().of(Yup.object().shape({})),
});

function AddOnsPopUpModal({ showModal, setShowModal, setIsResetAddOns, isResetAddOns }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { experienceDetailsObj } = useSelector(experienceSelector);
  const { setFieldValue } = useFormikContext();
  const formRef = useRef();

  const handleSubmit = (values) => {
    setFieldValue("addOns", values.addOns);
    setShowModal(false);
  };

  useEffect(() => {
    if (isResetAddOns) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      setIsResetAddOns(false);
    }
  }, [isResetAddOns]);

  const processedItemList = () => {
    const items = [];
    experienceDetailsObj.data.experience.addOns.map((item) =>
      items.push({
        ...item,
        quantity: 1,
      }),
    );
    return items;
  };

  return (
    <Form
      innerRef={formRef}
      initialValues={{ addOns: [] }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalBox isMobile={isMobile}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <StyledCloseIcon />
          </CloseIconButton>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
              <TitleText variant="h5" sx={{ textAlign: "start" }}>
                Select Add-On(s)
              </TitleText>
              <Spacer position="top" size="xs" />
              <GreyText>Subject to availability. Add-ons will be refunded if unavailable.</GreyText>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            {processedItemList().map((item, index) => (
              <Grid item xs={12} key={item.id}>
                <AddOnsItem item={item} index={index} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <AddOnsPopUpBottomBar />
            </Grid>
          </Grid>
        </ModalBox>
      </Modal>
    </Form>
  );
}

AddOnsPopUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setIsResetAddOns: PropTypes.func.isRequired,
  isResetAddOns: PropTypes.bool.isRequired,
};

export default AddOnsPopUpModal;
