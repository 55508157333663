import React, { useEffect } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Link, useHistory } from "react-router-dom";
import VoucherRedemptionBg from "../../../assets/images/voucher_redemption_bg.png";
import ReviewForm from "../components/review-form.component";
import Form from "../../../components/forms/form.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";

const BackgroundImage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  backgroundImage: `url(${VoucherRedemptionBg})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: isMobile ? "300px" : "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const validationSchema = Yup.object().shape({
  starRating: Yup.number().label("Star Rating"),
  descriptions: Yup.string().label("Descriptions"),
});

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  width: "100%",
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  padding: isMobile ? "25px" : "50px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

function VoucherRedeemedScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { redeemGiftVoucherObj } = useSelector(voucherSelector);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitReview = () => {};

  if (redeemGiftVoucherObj.status !== "succeeded") {
    history.push(routes.VOUCHERREDEMPTION);
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <BackgroundImage />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container paddingX={isMobile ? 2 : 10} spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BoldPrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
                    Hi {redeemGiftVoucherObj.data.booking.guest.name},
                  </BoldPrimaryColorText>
                  {redeemGiftVoucherObj.data.booking.isFlexi ? (
                    <BoldPrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
                      Your booking is confirm.
                    </BoldPrimaryColorText>
                  ) : (
                    <BoldPrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
                      Your booking is waiting for a confirmation.
                    </BoldPrimaryColorText>
                  )}
                </Grid>
                {!redeemGiftVoucherObj.data.booking.isFlexi && (
                  <Grid item xs={12}>
                    <PrimaryColorText sx={{ textAlign: "center" }}>
                      We will send the confirmation message to{" "}
                      {redeemGiftVoucherObj.data.booking.guest.email}.
                    </PrimaryColorText>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex", flexDirection: "column" }}
            >
              <CardContainer isMobile={isMobile}>
                <img
                  src={redeemGiftVoucherObj.data.experience.imgPath}
                  alt="exp-img"
                  style={{
                    width: isMobile ? "100%" : "230px",
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                  }}
                />
                <Spacer size={isMobile ? "m" : "xl"} position={isMobile ? "top" : "left"} />
                <Box>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: routes.EXPERIENCESDETAILS,
                      search: `experienceId=${redeemGiftVoucherObj.data.experience.id}`,
                    }}
                  >
                    <BoldPrimaryColorText
                      variant="h6"
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {redeemGiftVoucherObj.data.experience.title}
                    </BoldPrimaryColorText>
                  </Link>
                  <Spacer size="l" position="top" />
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <PersonIcon sx={{ color: theme.palette.colors.brand.secondary }} />
                    <Spacer size="s" position="left" />
                    <PrimaryColorText>
                      {`group of ${redeemGiftVoucherObj.data.booking.noOfPax}`}
                    </PrimaryColorText>
                  </Box>
                  <Spacer size="s" position="top" />
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <DateRangeIcon sx={{ color: theme.palette.colors.brand.secondary }} />
                    <Spacer size="s" position="left" />
                    {redeemGiftVoucherObj.data.booking.isFlexi ? (
                      <PrimaryColorText>Flexi Booking</PrimaryColorText>
                    ) : (
                      <PrimaryColorText>
                        {redeemGiftVoucherObj.data.booking.duration}
                      </PrimaryColorText>
                    )}
                  </Box>
                </Box>
              </CardContainer>
            </Grid>

            <Grid item xs={12}>
              <Form
                onSubmit={submitReview}
                validationSchema={validationSchema}
                initialValues={{
                  starRating: 5,
                  descriptions: "",
                }}
              >
                <ReviewForm />
              </Form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VoucherRedeemedScreen;
