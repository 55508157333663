import React, { useState } from "react";
import {
  Box,
  Typography,
  styled,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  Button,
  Icon,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PropTypes from "prop-types";
import StarRateIcon from "@mui/icons-material/StarRate";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Spacer from "../../../components/utils/spacer.component";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";
import { cartSelector } from "../../../services/cart/cart-slice.service";
import FlexiCheckBox from "./flexi-checkbox.component";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import { isLogin } from "../../../infrastructure/utils";
import { orderSelector } from "../../../services/order/order-slice.service";
import { guestSelector, guestToggleFavourite } from "../../../services/guest/guest-slice.service";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledRatingBox = styled(Button)({
  padding: "0px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  textTransform: "none",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const MainBox = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  marginTop: "4px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: "25px",
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

const StyledSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  fontSize: theme.fonts.fontSizes.size14,
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size16,
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.text.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const SpacebetweenBox = styled(Box)({
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

const FavouriteButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

const StyledFavoriteBorderIcon = styled(FavoriteBorderIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  width: "20px",
  height: "20px",
}));

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
  color: theme.palette.colors.ui.favourite,
  width: "20px",
  height: "20px",
}));

const FavouriteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.ui.favourite,
}));

function PurchaseBox({ scrollToReview, onToggleFavourite, experienceId, setShowAddOnModal }) {
  const dispatch = useDispatch();
  const { guestFavouriteList } = useSelector(guestSelector);
  const theme = useTheme();
  const { values, setFieldValue, submitForm, errors } = useFormikContext();
  const { experienceDetailsObj } = useSelector(experienceSelector);
  const { previewSecureCheckoutObj } = useSelector(orderSelector);
  const { addToCartObj } = useSelector(cartSelector);

  const [isFavourite, setIsFavourite] = useState(
    isLogin()
      ? experienceDetailsObj.data.experience.favourited
      : guestFavouriteList.some((item) => item.experienceId === experienceId),
  );

  const handleChange = (event) => {
    setFieldValue("quantity", event.target.value);
  };

  const onAddToCardClicked = () => {
    setFieldValue("purchaseType", "add cart");
    submitForm();
  };

  const onBuyNowClicked = () => {
    setFieldValue("purchaseType", "buy now");
    submitForm();
  };

  const renderRating = () => (
    <StyledRatingBox disableRipple onClick={scrollToReview}>
      <StyledRatingStar />
      <Spacer size="xs" position="left" />
      <BrandColorText>{experienceDetailsObj.data.experience.ratings}</BrandColorText>
      <Spacer size="m" position="left" />
      <ReviewGreyText>{`${experienceDetailsObj.data.experience.noReviews} reviews`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const onFavouriteClick = () => {
    setIsFavourite(!isFavourite);
    if (!isLogin()) {
      dispatch(guestToggleFavourite({ experienceId, addOns: values.addOns, quantity: null }));
      return;
    }
    if (isFavourite) {
      onToggleFavourite(false);
    } else {
      onToggleFavourite(true);
    }
  };

  const renderFavouriteIcon = () => (
    <FavouriteButtonBox>
      {isFavourite ? (
        <CenterRowBox>
          <StyledIconButton disableRipple onClick={() => onFavouriteClick()}>
            <StyledFavoriteIcon />
          </StyledIconButton>
          <FavouriteText>Added to favourite</FavouriteText>
        </CenterRowBox>
      ) : (
        <CenterRowBox>
          <StyledIconButton disableRipple onClick={() => onFavouriteClick()}>
            <StyledFavoriteBorderIcon />
          </StyledIconButton>
          <Typography>Add to favourite</Typography>
        </CenterRowBox>
      )}
    </FavouriteButtonBox>
  );

  const getQuantity = () => {
    const rows = [];
    const maxNumber = experienceDetailsObj.data.experience.maxNoPax;

    for (let i = experienceDetailsObj.data.experience.minNoPax; i <= maxNumber; i += 1) {
      rows.push({ label: i.toString(), value: i.toString() });
    }

    return rows;
  };

  const renderAddOns = () => (
    <>
      <Spacer size="s" position="top" />
      <TextField
        onClick={() => setShowAddOnModal(true)}
        variant="outlined"
        placeholder="Select Add-on(s)"
        value={values.addOns.length > 0 ? `${values.addOns.length} x Add-on Selected` : ""}
        sx={{
          "&& .Mui-disabled": {
            WebkitTextFillColor: theme.palette.colors.text.primary,
          },
          "& .MuiOutlinedInput-root": {
            cursor: "pointer",

            fieldset: {
              borderColor: "#c4c4c4",
            },
            "&:hover fieldset": {
              borderColor: "#c4c4c4",
            },
            "&.Mui-focused fieldset": {
              border: "1px solid",
              borderColor: "#c4c4c4",
            },
          },
        }}
        InputProps={{
          readOnly: true,
          style: {
            height: "40px",
            fontSize: theme.fonts.fontSizes.size14,
          },
          startAdornment: (
            <>
              <Icon>
                <NoteAddIcon sx={{ color: theme.palette.colors.ui.border }} />
              </Icon>
              <Spacer position="left" size="m" />
            </>
          ),
        }}
      />
    </>
  );

  const getTotalPrice = () => {
    let total = 0;
    if (values.quantity === "" && values.addOns.length === 0) {
      return experienceDetailsObj.data.experience.price;
    }
    total +=
      values.quantity * parseFloat(experienceDetailsObj.data.experience.price.replace(",", ""));

    values.addOns.forEach((item) => {
      total += item.quantity * parseFloat(item.price);
    });
    return total.toFixed(2);
  };

  return (
    <MainBox>
      <SpacebetweenBox>
        <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size20 }}>Request to Book</StyledTitle>
        {renderRating()}
      </SpacebetweenBox>

      <Spacer size="s" position="top" />
      <SeperateLine />
      <Spacer size="s" position="top" />

      <FormControl fullWidth>
        <StyledSelect
          variant="outlined"
          value={values.quantity}
          onChange={handleChange}
          autoWidth
          startAdornment={
            <>
              <Icon>
                <AccountCircleOutlinedIcon sx={{ color: theme.palette.colors.ui.border }} />
              </Icon>
              <Spacer position="left" size="m" />
            </>
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            <Typography
              sx={{ color: theme.palette.colors.ui.border, fontSize: theme.fonts.fontSizes.size14 }}
            >
              Select Quantity
            </Typography>
          </MenuItem>
          {getQuantity().map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>

      {errors.quantity && <FormHelperText error>{errors.quantity}</FormHelperText>}
      {experienceDetailsObj.data.experience.addOns.length > 0 && renderAddOns()}
      <Spacer size="s" position="top" />
      {experienceDetailsObj.data.experience.hasFlexi.status && <FlexiCheckBox />}
      {renderFavouriteIcon()}
      <Spacer size="s" position="top" />
      <SeperateLine />
      <Spacer size="s" position="top" />

      <StyledRow sx={{ justifyContent: "space-between" }}>
        <GreyText>Experience</GreyText>
        <GreyText>
          {`${values.quantity === "" ? 1 : values.quantity} x RM${
            experienceDetailsObj.data.experience.price
          } `}
        </GreyText>
      </StyledRow>
      {values.addOns.length > 0 &&
        values.addOns.map((item) => (
          <StyledRow sx={{ justifyContent: "space-between" }} key={item.id}>
            <GreyText>{item.label}</GreyText>
            <GreyText>{`${item.quantity} x RM${item.price} `}</GreyText>
          </StyledRow>
        ))}

      <Spacer size="s" position="top" />
      <SeperateLine />
      <Spacer size="s" position="top" />

      <StyledRow sx={{ justifyContent: "space-between" }}>
        <StyledTitle>Total</StyledTitle>
        <StyledTitle>{`RM${getTotalPrice()}`}</StyledTitle>
      </StyledRow>

      <Spacer size="l" position="top" />
      <BackgroundButton
        width="100%"
        isLoading={
          addToCartObj.status === "pending" || previewSecureCheckoutObj.status === "pending"
        }
        handleSubmit={onAddToCardClicked}
      >
        <WhiteColorText>Add To Cart</WhiteColorText>
      </BackgroundButton>
      <Spacer size="m" position="top" />
      {isLogin() && (
        <BorderButton
          width="100%"
          isLoading={
            addToCartObj.status === "pending" || previewSecureCheckoutObj.status === "pending"
          }
          handleSubmit={onBuyNowClicked}
        >
          <BrandColorText>Buy Now</BrandColorText>
        </BorderButton>
      )}
    </MainBox>
  );
}

PurchaseBox.propTypes = {
  experienceId: PropTypes.number.isRequired,
  onToggleFavourite: PropTypes.func.isRequired,
  scrollToReview: PropTypes.func.isRequired,
  setShowAddOnModal: PropTypes.func.isRequired,
};

export default PurchaseBox;
