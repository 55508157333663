import React from "react";
import { Grid, Typography, styled, useTheme, useMediaQuery, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useHistory } from "react-router-dom";
import WhiteBox from "../../../../assets/images/white-box.jpeg";
import { marketingSelector } from "../../../../services/marketing/marketing-slice.service";
import ExperiencesItem from "../../../experiences-listing/component/experiences-listing/experiences-item.component";
import Carousel from "../best-seller/carousel.component";
import routes from "../../../../components/navigation/routes";

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
}));

const ImageBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.ui.border}`,
  position: "relative",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  overflow: "hidden",
}));

const AbsoluteBox = styled(Box)({
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  bottom: "0px",
  padding: "16px",
  alignItems: "center",
});

function ExploreMore({ onToggleFavourite }) {
  const { exploreObj } = useSelector(marketingSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const procceesedList = [...exploreObj.data.experiences.slice(0, 12), { id: "viewall" }];
  const history = useHistory();

  const renderViewAll = () => (
    <Button
      sx={{ padding: "0px", textTransform: "none" }}
      disableRipple
      onClick={() =>
        history.push({
          pathname: routes.EXPERIENCES_EXPLORE,
          search: "type=explore",
        })
      }
    >
      <ImageBox>
        <img src={WhiteBox} alt="WhiteBox" width="100%" />
        <AbsoluteBox>
          <Typography
            sx={{
              color: theme.palette.colors.text.primary,
              fontWeight: theme.fonts.fontWeights.bold,
            }}
          >
            {`See All (${exploreObj.data.experiences.length})`}
          </Typography>
          <ArrowForwardIosIcon
            sx={{ fontSize: "medium", color: theme.palette.colors.text.placeholder }}
          />
        </AbsoluteBox>
      </ImageBox>
    </Button>
  );

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <TitleText
            sx={{
              width: "70%",
              marginLeft: isMobile
                ? theme.dimensions.MobileExperiencesPadding
                : theme.dimensions.PCExperiencesPadding,
            }}
          >
            {exploreObj.data.label}
          </TitleText>
        </Grid>
        <Grid item xs={12}>
          {exploreObj.data.experiences.length <= 3 && !isMobile ? (
            <Grid container>
              {exploreObj.data.experiences.map((item) => (
                <Grid item xs={4} key={item.id}>
                  <ExperiencesItem
                    key={item.id}
                    experienceId={item.id}
                    data={item}
                    width="100%"
                    onToggleFavourite={onToggleFavourite}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Carousel
              totalItems={exploreObj.data.experiences.length}
              pushTo={routes.EXPERIENCES_EXPLORE}
              search="type=explore"
            >
              {procceesedList.map((item) =>
                item.id === "viewall" ? (
                  <Box
                    key={item.id}
                    sx={{
                      padding: isMobile
                        ? theme.dimensions.MobileExperiencesPadding
                        : theme.dimensions.PCExperiencesPadding,
                    }}
                  >
                    {renderViewAll()}
                  </Box>
                ) : (
                  <ExperiencesItem
                    key={item.id}
                    experienceId={item.id}
                    data={item}
                    width="100%"
                    onToggleFavourite={onToggleFavourite}
                  />
                ),
              )}
            </Carousel>
          )}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "17px" }}>
          <Link
            to={{ pathname: routes.EXPERIENCES_EXPLORE, search: "type=explore" }}
            style={{
              textTransform: "none",
              color: theme.palette.colors.text.primary,
            }}
          >
            <Typography
              sx={{
                textDecoration: "underline",
                fontWeight: theme.fonts.fontWeights.bold,
                marginLeft: isMobile
                  ? theme.dimensions.MobileExperiencesPadding
                  : theme.dimensions.PCExperiencesPadding,
              }}
            >
              {`See all ${exploreObj.data.label} (${exploreObj.data.experiences.length})`}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

ExploreMore.propTypes = {
  onToggleFavourite: PropTypes.func.isRequired,
};
export default ExploreMore;
