import React from "react";
import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const StyledBottomNavBar = styled(Box)(({ theme }) => ({
  paddingLeft: "10px",
  paddingRight: "10px",
  height: "10%",
  background: theme.palette.colors.bg.white,
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
}));

const ClearButtonBox = styled(Box)({
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const ShowResultsButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    textTransform: "none",
    borderTopLeftRadius: theme.shape.borderRadius[1],
    borderTopRightRadius: theme.shape.borderRadius[1],
    borderBottomLeftRadius: theme.shape.borderRadius[1],
    borderBottomRightRadius: theme.shape.borderRadius[1],
    border: `1px solid ${theme.palette.colors.brand.primary}`,
  },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

function MobileFilterBottomNavBar({ onClearPress, onGoPress }) {
  const theme = useTheme();

  return (
    <StyledBottomNavBar sx={{ boxShadow: 3 }}>
      <ClearButtonBox>
        <Button onClick={() => onClearPress()}>
          <ButtonText>Clear</ButtonText>
        </Button>
      </ClearButtonBox>
      <ShowResultsButtonBox>
        <Button onClick={() => onGoPress()}>
          <ButtonText sx={{ color: theme.palette.colors.brand.primary }}>Show Results</ButtonText>
        </Button>
      </ShowResultsButtonBox>
    </StyledBottomNavBar>
  );
}

MobileFilterBottomNavBar.propTypes = {
  onClearPress: PropTypes.func.isRequired,
  onGoPress: PropTypes.func.isRequired,
};

export default MobileFilterBottomNavBar;
