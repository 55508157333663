import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  experienceSelector,
  getExperienceDetails,
} from "../../../services/experiences/experience-slice.service";
import routes from "../../../components/navigation/routes";
import {
  createFavourite,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";
import { getProfile } from "../../../services/profile/profile-slice.service";
import ExperiencesDetailsComponent from "../../experiences-details/components/experiences-details.component";
import Highlights from "../../experiences-details/components/highlights.component";
import Overview from "../../experiences-details/components/overview.component";
import LocationMap from "../../experiences-details/components/location-map.component";
import Reviews from "../../experiences-details/components/reviews.component";
import DetailsLoader from "../../experiences-details/loader/details-loader.component";
import BookingBox from "../components/experience-details/booking-box.component";
import {
  bookingSelector,
  cancelBooking,
  getBookingDetails,
} from "../../../services/booking/booking-slice.service";
import CancelBookingPopUpModal from "../components/cancel-bookings-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import ExperienceImageGrid from "../../experiences-details/components/experience-image-grid.component";
import Form from "../../../components/forms/form.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const validationSchema = Yup.object().shape({
  addOns: Yup.array().of(Yup.object().shape({})),
});

function BookingExperiencesDetails(props) {
  const newProps = props;
  const { experienceId, orderId } = newProps.match.params;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { experienceDetailsObj } = useSelector(experienceSelector);
  const { getBookingDetailsObj } = useSelector(bookingSelector);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const onToggleFavourite = (isAddFavourite) => {
    if (isAddFavourite) {
      dispatch(createFavourite([{ experienceId }])).then(({ meta, error }) => {
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite({ experienceId })).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const scrollToReview = () => {
    const top = document.getElementById("reviews").offsetTop - 180;

    window.scrollTo({ top, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExperienceDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getBookingDetails(orderId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  }, [location]);

  const handleCancelBooking = () => {
    setShowCancelModal(true);
  };

  const onConfirmDelete = () => {
    dispatch(cancelBooking(orderId)).then(({ meta, error }) => {
      setShowCancelModal(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.BOOKINGEXPERIENCES);
      }
    });
  };

  const blackFunction = () => {};

  const renderContent = () => {
    if (
      experienceDetailsObj.status === "succeeded" &&
      getBookingDetailsObj.status === "succeeded"
    ) {
      return (
        <Form
          validationSchema={validationSchema}
          onSubmit={blackFunction}
          initialValues={{ addOns: getBookingDetailsObj.data.order.item.addOns }}
        >
          <CancelBookingPopUpModal
            showModal={showCancelModal}
            setShowModal={setShowCancelModal}
            itemDetails={getBookingDetailsObj.data}
            onConfirmDelete={onConfirmDelete}
          />
          <Grid
            container
            paddingY={3}
            paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
            spacing={5}
          >
            <Grid item xs={12}>
              <ExperiencesDetailsComponent
                onToggleFavourite={onToggleFavourite}
                itemData={experienceDetailsObj.data.experience}
              />
            </Grid>

            <Grid xs={isMobile ? 12 : 8} item>
              <ExperienceImageGrid itemList={experienceDetailsObj.data.experience.banners} />
            </Grid>
            <Grid xs={isMobile ? 12 : 4} item>
              <BookingBox
                orderId={orderId}
                handleCancelBooking={handleCancelBooking}
                scrollToReview={scrollToReview}
              />
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Highlights itemData={experienceDetailsObj.data.experience} />
            <Overview itemData={experienceDetailsObj.data.experience.importantInfo} />
            <LocationMap itemData={experienceDetailsObj.data.experience} />
            <Grid item xs={12} id="reviews">
              <Reviews itemData={experienceDetailsObj.data.experience} />
            </Grid>
          </Grid>
          {/* {isMobile && (
            <MobileBookingBox
              orderId={orderId}
              itemData={experienceDetailsObj.data.experience}
              orderDetails={getBookingDetailsObj.data}
              status={getBookingDetailsObj.data.status}
              handleCancelBooking={handleCancelBooking}
            />
          )} */}
        </Form>
      );
    }
    return <DetailsLoader />;
  };

  return (
    <Grid
      container
      overflow={isMobile ? "hidden" : null}
      paddingBottom={isMobile ? "180px" : "0px"}
    >
      {renderContent()}
    </Grid>
  );
}

export default BookingExperiencesDetails;
