import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useHistory } from "react-router-dom";
import ItemList from "../components/item-list.component";
import FavouritesLoader from "../loader/favourites-loader.component";
import {
  createFavourite,
  favouriteSelector,
  getFavouriteList,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";
import { addToCart } from "../../../services/cart/cart-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { getProfile } from "../../../services/profile/profile-slice.service";
import BackgroundButton from "../../../components/button/background-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import AddToCartPopUpModal from "../../experiences-details/components/add-to-cart-pop-up-modal.component";
import {
  experienceSelector,
  getExperiencesSuggestions,
  getLatestFavouriteDetails,
} from "../../../services/experiences/experience-slice.service";
import { isLogin } from "../../../infrastructure/utils";
import {
  guestAddToCart,
  guestSelector,
  guestToggleFavourite,
} from "../../../services/guest/guest-slice.service";

const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  marginTop: 50,
}));

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  height: "75px",
  width: "75px",
}));

const StyledGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const EmptyListBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

function MyFavourites() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getFavouriteListObj } = useSelector(favouriteSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const [showModal, setShowModal] = useState(false);
  const [backgroundButtonLoading, setBackgroundButtonLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const { guestFavouriteList } = useSelector(guestSelector);
  const { latestFavouriteDetailsObj } = useSelector(experienceSelector);
  const [cartItemAdded, setCartItemAdded] = useState({});
  let tempGuestFavouriteList = guestFavouriteList || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLogin()) {
      dispatch(getFavouriteList()).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
    if (!isLogin()) {
      dispatch(getLatestFavouriteDetails(guestFavouriteList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, []);

  const getSuggestions = (experienceId) => {
    dispatch(getExperiencesSuggestions(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onClickAddCart = (values) => {
    if (values.quantity === "" || values.quantity === "0") {
      createSnackBar({
        message: "Error! Quantity cannot be empty",
        type: "error",
        open: true,
      });
      return;
    }
    if (!isLogin()) {
      setCartItemAdded({ experience: values.experience, quantity: values.quantity });
      dispatch(
        guestAddToCart({
          quantity: values.quantity,
          experienceId: values.experienceId,
          isFlexi: values.isFlexi,
          uniqueId: `${values.experienceId}${values.isFlexi}`,
          massSlotId: values.massSlotId,
          addOns: [],
        }),
      );
      setShowModal(true);
      dispatch(getExperiencesSuggestions(values.experienceId)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get data! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    setBackgroundButtonLoading(true);
    dispatch(
      addToCart([
        { isFlexi: values.isFlexi, massSlotId: values.massSlotId, ...values, addOns: [] },
      ]),
    ).then(({ meta, error }) => {
      setBackgroundButtonLoading(false);
      if (meta.requestStatus === "fulfilled") {
        getSuggestions(values.experienceId);
        setShowModal(true);
        dispatch(getProfile());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onToggleFavourite = (values, isAddFavourite) => {
    if (!isLogin()) {
      const isChecked = tempGuestFavouriteList.some(
        (item) => item.experienceId === values.experienceId,
      );
      if (isChecked) {
        tempGuestFavouriteList = tempGuestFavouriteList.filter(
          (item) => item.experienceId !== values.experienceId,
        );
      } else {
        const newArr = [values];
        tempGuestFavouriteList = newArr.concat(tempGuestFavouriteList);
      }
      dispatch(getLatestFavouriteDetails(tempGuestFavouriteList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });

      dispatch(guestToggleFavourite({ addOns: [], quantity: 1, ...values }));
      return;
    }
    if (isAddFavourite) {
      dispatch(createFavourite([values])).then(({ meta, error }) => {
        dispatch(getFavouriteList());
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite(values)).then(({ meta, error }) => {
      dispatch(getFavouriteList());
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (isLogin()) {
      if (getFavouriteListObj.status === "succeeded") {
        if (getFavouriteListObj.data.length > 0) {
          return (
            <>
              <SeperateLine />
              {getFavouriteListObj.data.map((item) => (
                <ItemList
                  key={item.id}
                  onClickAddCart={onClickAddCart}
                  backgroundButtonLoading={backgroundButtonLoading}
                  itemData={item}
                  onToggleFavourite={onToggleFavourite}
                />
              ))}
            </>
          );
        }
        return (
          <EmptyListBox>
            <SeperateLine />
            <StyledFavoriteIcon />
            <Spacer size="m" position="top" />
            <StyledGreyText variant="h6">Your Favourite List is Empty</StyledGreyText>
            <Spacer size="l" position="top" />
            <BackgroundButton handleSubmit={() => history.push(routes.HOME)} width="300px">
              Continue Shopping
            </BackgroundButton>
          </EmptyListBox>
        );
      }
    }

    if (!isLogin()) {
      if (latestFavouriteDetailsObj.status === "succeeded") {
        if (latestFavouriteDetailsObj.data.length > 0) {
          return (
            <>
              <SeperateLine />
              {latestFavouriteDetailsObj.data.map((item) => (
                <ItemList
                  key={item.uniqueId}
                  onClickAddCart={onClickAddCart}
                  backgroundButtonLoading={backgroundButtonLoading}
                  itemData={item.experience}
                  onToggleFavourite={onToggleFavourite}
                />
              ))}
            </>
          );
        }
        return (
          <EmptyListBox>
            <SeperateLine />
            <StyledFavoriteIcon />
            <Spacer size="m" position="top" />
            <StyledGreyText variant="h6">Your Favourite List is Empty</StyledGreyText>
            <Spacer size="l" position="top" />
            <BackgroundButton handleSubmit={() => history.push(routes.HOME)} width="300px">
              Continue Shopping
            </BackgroundButton>
          </EmptyListBox>
        );
      }
    }

    return <FavouritesLoader />;
  };

  return (
    <>
      <Grid
        container
        paddingBottom={isMobile ? "10px" : "0px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        <AddToCartPopUpModal
          showModal={showModal}
          setShowModal={setShowModal}
          onToggleFavourite={onToggleFavourite}
          cartItemAdded={cartItemAdded}
        />
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <StyledTitle variant={isMobile ? "h4" : "h3"}>My Favourites</StyledTitle>
          </Grid>
        </Grid>
        {renderContent()}
      </Grid>
      <Spacer size="xxl" position="top" />
    </>
  );
}

export default MyFavourites;
