import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq, patchReq } from "../api.services";

export const getProfile = createAsyncThunk("users/profile", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users`);
  return response;
});

export const updateFullName = createAsyncThunk("user/update_full_name", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/full_name`, payload);
  return response;
});

export const updateGender = createAsyncThunk("user/update_gender", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/gender`, payload);
  return response;
});

export const updateDateOfBirth = createAsyncThunk("user/update_date_of_birth", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/users/date_of_birth`,
    payload,
  );
  return response;
});

export const updatePhoneNumber = createAsyncThunk("user/update_phone_number", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/users/phone_number`,
    payload,
  );
  return response;
});

export const updateEmail = createAsyncThunk("user/update_email", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/email`, payload);
  return response;
});

export const updatePassword = createAsyncThunk("user/update_password", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/password`, payload);
  return response;
});

export const uploadImage = createAsyncThunk("user/upload_image", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/users/upload_image`,
    payload,
  );
  return response;
});

export const toggleNewsletter = createAsyncThunk("user/newsletter", async () => {
  const response = await patchReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/newsletter`);
  return response;
});

export const completeProfile = createAsyncThunk("user/complete_profile", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/users/complete_profile`,
    payload,
  );
  return response;
});

const profileSlice = createSlice({
  name: "auth",
  initialState: {
    profileObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    profileImgObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateFullNameObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateGenderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateDateOfBirthObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePhoneNumberObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    toggleNewsletterObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    completeProfileObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetProfile: (state) => {
      state.profileObj.data = null;
      state.profileObj.status = "idle";
      state.profileObj.successMessage = null;
      state.profileObj.errorMessage = null;
    },
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.profileObj.status = "succeeded";
      state.profileObj.data = data;
      state.profileObj.successMessage = message;

      state.profileImgObj.status = "succeeded";
      state.profileImgObj.data = data;
      state.profileImgObj.successMessage = message;
    },
    [getProfile.rejected]: (state, action) => {
      const { message } = action.error;

      state.profileObj.status = "failed";
      state.profileObj.errorMessage = message;

      state.profileImgObj.status = "failed";
      state.profileImgObj.errorMessage = message;
    },
    [updateFullName.pending]: (state) => {
      state.updateFullNameObj.status = "pending";
    },
    [updateFullName.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateFullNameObj.status = "succeeded";
      state.updateFullNameObj.data = data;
      state.profileObj.data = data;
      state.updateFullNameObj.successMessage = message;
    },
    [updateFullName.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateFullNameObj.status = "failed";
      state.updateFullNameObj.errorMessage = message;
    },
    [updateGender.pending]: (state) => {
      state.updateGenderObj.status = "pending";
    },
    [updateGender.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateGenderObj.status = "succeeded";
      state.updateGenderObj.data = data;
      state.profileObj.data = data;
      state.updateGenderObj.successMessage = message;
    },
    [updateGender.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateGenderObj.status = "failed";
      state.updateGenderObj.errorMessage = message;
    },
    [updateDateOfBirth.pending]: (state) => {
      state.updateDateOfBirthObj.status = "pending";
    },
    [updateDateOfBirth.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateDateOfBirthObj.status = "succeeded";
      state.updateDateOfBirthObj.data = data;
      state.profileObj.data = data;
      state.updateDateOfBirthObj.successMessage = message;
    },
    [updateDateOfBirth.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateDateOfBirthObj.status = "failed";
      state.updateDateOfBirthObj.errorMessage = message;
    },
    [updatePhoneNumber.pending]: (state) => {
      state.updatePhoneNumberObj.status = "pending";
    },
    [updatePhoneNumber.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePhoneNumberObj.status = "succeeded";
      state.updatePhoneNumberObj.data = data;
      state.profileObj.data = data;
      state.updatePhoneNumberObj.successMessage = message;
    },
    [updatePhoneNumber.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePhoneNumberObj.status = "failed";
      state.updatePhoneNumberObj.errorMessage = message;
    },
    [updateEmail.pending]: (state) => {
      state.updateEmailObj.status = "pending";
    },
    [updateEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEmailObj.status = "succeeded";
      state.updateEmailObj.data = data;
      state.profileObj.data = data;
      state.updateEmailObj.successMessage = message;
    },
    [updateEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEmailObj.status = "failed";
      state.updateEmailObj.errorMessage = message;
    },
    [updatePassword.pending]: (state) => {
      state.updatePasswordObj.status = "pending";
    },
    [updatePassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePasswordObj.status = "succeeded";
      state.updatePasswordObj.data = data;
      state.profileObj.data = data;
      state.updatePasswordObj.successMessage = message;
    },
    [updatePassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePasswordObj.status = "failed";
      state.updatePasswordObj.errorMessage = message;
    },
    [uploadImage.pending]: (state) => {
      state.uploadImageObj.status = "pending";
    },
    [uploadImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadImageObj.status = "succeeded";
      state.uploadImageObj.data = data;
      state.uploadImageObj.successMessage = message;
      state.profileObj.data = data;
      state.profileImgObj.data = data;
    },
    [uploadImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadImageObj.status = "failed";
      state.uploadImageObj.errorMessage = message;

      state.profileImgObj.status = "failed";
      state.profileImgObj.errorMessage = message;
    },
    [toggleNewsletter.pending]: (state) => {
      state.toggleNewsletterObj.status = "pending";
    },
    [toggleNewsletter.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.toggleNewsletterObj.status = "succeeded";
      state.toggleNewsletterObj.data = data;
      state.toggleNewsletterObj.successMessage = message;
      state.profileObj.data = data;
    },
    [toggleNewsletter.rejected]: (state, action) => {
      const { message } = action.error;

      state.toggleNewsletterObj.status = "failed";
      state.toggleNewsletterObj.errorMessage = message;
    },
    [completeProfile.pending]: (state) => {
      state.completeProfileObj.status = "pending";
    },
    [completeProfile.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.completeProfileObj.status = "succeeded";
      state.completeProfileObj.data = data;
      state.profileObj.data = data;
      state.completeProfileObj.successMessage = message;
    },
    [completeProfile.rejected]: (state, action) => {
      const { message } = action.error;

      state.completeProfileObj.status = "failed";
      state.completeProfileObj.errorMessage = message;
    },
  },
});

export default profileSlice.reducer;

// state
export const profileSelector = (state) => state.profile;

export const { resetProfile } = profileSlice.actions;
