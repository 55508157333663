import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function CenteredRow({ children }) {
  return <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{children}</Box>;
}

CenteredRow.defaultProps = {
  children: null,
};

CenteredRow.propTypes = {
  children: PropTypes.node,
};

export default CenteredRow;
