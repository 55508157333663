import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import CreditField from "./credit-field.component";
import { pointSelector } from "../../../../services/point/points-slice.service";
import BackgroundButton from "../../../../components/button/background-button.component";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "550px",
  outline: "none",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.regular,
  padding: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: 0,
  top: "-10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
  paddingBottom: "20px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const MiddleIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.text.tertiary,
  bottom: isMobile ? "79px" : "92px",
  height: isMobile ? "32px" : "40px",
  width: isMobile ? "32px" : "40px",
  borderTopLeftRadius: isMobile ? theme.shape.borderRadius[2] : theme.shape.borderRadius[3],
  borderTopRightRadius: isMobile ? theme.shape.borderRadius[2] : theme.shape.borderRadius[3],
  borderBottomLeftRadius: isMobile ? theme.shape.borderRadius[2] : theme.shape.borderRadius[3],
  borderBottomRightRadius: isMobile ? theme.shape.borderRadius[2] : theme.shape.borderRadius[3],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MiddleIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  fontSize: "30px",
}));

function PointConvertModal({
  showModal,
  setShowModal,
  onConfirmConvertPoints,
  setPointConverModalScreen,
  pointConverModalScreen,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { convertPointsObj } = useSelector(pointSelector);
  const { profileObj } = useSelector(profileSelector);
  const { errors, touched, values } = useFormikContext();
  const showError =
    (touched.credit && typeof errors.credit === "string") ||
    (touched.points && typeof errors.points === "string");

  const renderConfirmButton = () => (
    <Grid item xs={12}>
      <BackgroundButton
        handleSubmit={onConfirmConvertPoints}
        isLoading={convertPointsObj.status === "pending"}
      >
        <WhiteColorText variant="h6">Confirm</WhiteColorText>
      </BackgroundButton>
    </Grid>
  );

  const renderFormSubmitButton = () => (
    <Grid item xs={12}>
      {showError && (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <Typography sx={{ color: theme.palette.colors.text.error }}>
            {errors.points || errors.credit}
          </Typography>
        </Box>
      )}
      <Spacer size="s" position="top" />
      <FormSubmitButton isLoading={convertPointsObj.status === "pending"}>
        <WhiteColorText variant="h6">Convert Credits To Points</WhiteColorText>
      </FormSubmitButton>
    </Grid>
  );

  const renderForm = () => (
    <ModalBox isMobile={isMobile}>
      <TopTitleBox>
        <Grid container justifyContent="center">
          <TitleText variant="h5">Convert Points</TitleText>
        </Grid>
        <CloseIconButton
          onClick={() => {
            setPointConverModalScreen("Form");
            setShowModal(false);
          }}
        >
          <StyledCloseIcon />
        </CloseIconButton>
      </TopTitleBox>
      <SeperateLine />
      <Spacer size="l" position="top" />
      <Grid container>
        <Grid item xs={12}>
          <PrimaryColorText>10 Credits = 1 Points</PrimaryColorText>
        </Grid>
      </Grid>
      <Spacer size="m" position="top" />
      <Grid
        container
        sx={{ position: "relative", justifyContent: "center", alignItems: "center" }}
        spacing={2}
      >
        <MiddleIconContainer isMobile={isMobile}>
          <MiddleIcon />
        </MiddleIconContainer>
        <Grid item xs={12}>
          <CardContainer>
            <PaddedView multiples={isMobile ? 2 : 3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CenterRowBox sx={{ flex: 2 }}>
                  <MonetizationOnIcon
                    sx={{
                      color: theme.palette.colors.brand.secondary,
                      fontSize: isMobile ? "40px" : "50px",
                    }}
                  />
                  <Spacer size="s" position="left" />
                  <Box>
                    <PrimaryColorText variant="h6">Credits </PrimaryColorText>
                    <GreyText>{`Balance: RM${profileObj.data.credits}`}</GreyText>
                  </Box>
                </CenterRowBox>
                <Box sx={{ flex: 1 }}>
                  <CreditField name="credit" />
                </Box>
              </Box>
            </PaddedView>
          </CardContainer>
        </Grid>
        <Grid item xs={12}>
          <CardContainer>
            <PaddedView multiples={isMobile ? 2 : 3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CenterRowBox sx={{ flex: 2 }}>
                  <StarsRoundedIcon
                    sx={{
                      color: theme.palette.colors.ui.star,
                      fontSize: isMobile ? "40px" : "50px",
                    }}
                  />
                  <Spacer size="s" position="left" />
                  <Box>
                    <PrimaryColorText variant="h6">Points</PrimaryColorText>
                    <GreyText>{`Balance: ${profileObj.data.points}`}</GreyText>
                  </Box>
                </CenterRowBox>
                <Box sx={{ flex: 1 }}>
                  <PrimaryColorText variant="h6" textAlign="center">
                    {Number.isNaN(parseInt(values.credit, 10) / 10) ||
                    parseInt(values.credit, 10) < 10
                      ? 0
                      : parseInt(values.credit, 10) / 10}
                  </PrimaryColorText>
                </Box>
              </Box>
            </PaddedView>
          </CardContainer>
        </Grid>
      </Grid>

      <Spacer size="xxl" position="top" />
      <Grid container>{renderFormSubmitButton()}</Grid>
    </ModalBox>
  );

  const renderConfirmation = () => (
    <ModalBox isMobile={isMobile}>
      <TopTitleBox>
        <Grid container justifyContent="center">
          <TitleText variant="h5">Confirm to convert?</TitleText>
        </Grid>
        <CloseIconButton
          onClick={() => {
            setPointConverModalScreen("Form");
            setShowModal(false);
          }}
        >
          <StyledCloseIcon />
        </CloseIconButton>
      </TopTitleBox>
      <SeperateLine />
      <Spacer size="xxl" position="top" />
      <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
        <PrimaryColorText variant="h6">{`RM${values.credit} will be convert to ${values.points} points.`}</PrimaryColorText>
      </Grid>
      <Spacer size="xxl" position="top" />

      <Grid container>{renderConfirmButton()}</Grid>
    </ModalBox>
  );

  return (
    <Modal
      onBackdropClick={() => setPointConverModalScreen("Form")}
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      {pointConverModalScreen === "Form" ? renderForm() : renderConfirmation()}
    </Modal>
  );
}

PointConvertModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onConfirmConvertPoints: PropTypes.func.isRequired,
  setPointConverModalScreen: PropTypes.func.isRequired,
  pointConverModalScreen: PropTypes.string.isRequired,
};

export default PointConvertModal;
