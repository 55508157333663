import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box, Typography } from "@mui/material";

function FormFieldTextMultiline({
  name,
  placeholder,
  width,
  height,
  isShowBgColor,
  isGiftMessage,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isShowBgColor ? theme.palette.colors.bg.secondary : "transparent",
            minHeight: height,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          },
        },
      }}
    >
      <TextField
        multiline
        error={showError}
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        inputProps={{ maxLength: isGiftMessage ? 300 : null }}
      />
      {isGiftMessage && (
        <Typography sx={{ fontSize: "13px", color: theme.palette.colors.text.secondary }}>{`${
          (values.gift?.message && values.gift?.message.length) || 0
        }/300 characters`}</Typography>
      )}
    </Box>
  );
}

FormFieldTextMultiline.defaultProps = {
  width: "100%",
  height: "200px",
  isShowBgColor: true,
  isGiftMessage: false,
};

FormFieldTextMultiline.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  isShowBgColor: PropTypes.bool,
  isGiftMessage: PropTypes.bool,
};

export default FormFieldTextMultiline;
