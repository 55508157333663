import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api.services";

export const addToCart = createAsyncThunk("carts/items", async (payload) => {
  const processedData = [];
  payload.map((item) =>
    processedData.push({
      id: parseInt(item.experienceId, 10),
      quantity: item.quantity,
      isFlexi: item.isFlexi,
      massSlotId: item.massSlotId,
      addOns: item.addOns,
    }),
  );
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/carts/items`, {
    experiences: processedData,
  });
  return response;
});

export const getCartList = createAsyncThunk("carts/carts_list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/carts`);
  return response;
});

export const updateCartItemQty = createAsyncThunk("carts/update_cart_item_qty", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/carts/items/${payload.id}`,
    payload,
  );
  return response;
});

export const getCartItemDetails = createAsyncThunk("carts/cart_item_details", async (payload) => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/carts/items/${payload}`);
  return response;
});

export const removeCartItem = createAsyncThunk("carts/remove_cart_item", async (payload) => {
  const response = putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/carts/items/${payload}/abandon`);
  return response;
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    addToCartObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    getCartListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cartsDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCartItemDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeCartItemObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [addToCart.pending]: (state) => {
      state.addToCartObj.status = "pending";
    },
    [addToCart.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addToCartObj.status = "succeeded";
      state.addToCartObj.data = data;
      state.addToCartObj.successMessage = message;
    },
    [addToCart.rejected]: (state, action) => {
      const { message } = action.error;

      state.addToCartObj.status = "failed";
      state.addToCartObj.errorMessage = message;
    },
    [getCartList.pending]: (state) => {
      state.cartsDetailsObj.status = "pending";
    },
    [getCartList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCartListObj.status = "succeeded";
      state.getCartListObj.data = data;
      state.getCartListObj.successMessage = message;

      state.cartsDetailsObj.status = "succeeded";
      state.cartsDetailsObj.data = data;
      state.cartsDetailsObj.successMessage = message;
    },
    [getCartList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCartListObj.status = "failed";
      state.getCartListObj.errorMessage = message;

      state.cartsDetailsObj.status = "failed";
      state.cartsDetailsObj.errorMessage = message;
    },
    [getCartItemDetails.pending]: (state) => {
      state.getCartItemDetailsObj.status = "pending";
    },
    [getCartItemDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCartItemDetailsObj.status = "succeeded";
      state.getCartItemDetailsObj.data = data;
      state.getCartItemDetailsObj.successMessage = message;
    },
    [getCartItemDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCartItemDetailsObj.status = "failed";
      state.getCartItemDetailsObj.errorMessage = message;
    },
    [removeCartItem.pending]: (state) => {
      state.removeCartItemObj.status = "pending";
    },
    [removeCartItem.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeCartItemObj.status = "succeeded";
      state.removeCartItemObj.data = data;
      state.removeCartItemObj.successMessage = message;
    },
    [removeCartItem.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeCartItemObj.status = "failed";
      state.removeCartItemObj.errorMessage = message;
    },
  },
});

export default cartSlice.reducer;

// state
export const cartSelector = (state) => state.cart;
