import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import BookingDetails from "./booking-details.component";
import BookingCardExperienceImage from "./booking-card-experience-image.component";

const StyledCancel = styled(CancelIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.button.cancelledBooking,
}));

const StyledEventNoteRoundedIcon = styled(EventNoteRoundedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledHourglassEmptyRoundedIcon = styled(HourglassEmptyRoundedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledStatusBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: "transparent",
  border: "1px solid",
  borderColor: theme.palette.colors.button.cancelledBooking,
  height: isMobile ? "15px" : "30px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "85px",
  padding: "10px",
}));

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const CancelledBookingTextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.button.cancelledBooking,
}));

const TopSeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
  marginTop: "25px",
}));

const BottomSeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const SpaceBetweenRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
  flex: 1,
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function CancelledBookingCard({ cardDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderExperiencesDetail = () => {
    if (isMobile) {
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <BookingCardExperienceImage imagePath={cardDetails.order.item.experience.imgPath} />
            <Spacer size="m" position="left" />
            <SpaceBetweenRow isMobile={isMobile}>
              <StyledStatusBox isMobile={isMobile}>
                <CancelledBookingTextColor variant="body2">Cancelled</CancelledBookingTextColor>
              </StyledStatusBox>
              <Spacer size="s" position="top" />
              <Box>
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: routes.EXPERIENCESDETAILS,
                    search: `experienceId=${cardDetails.order.item.experience.id}`,
                  }}
                >
                  <TitleText
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {cardDetails.order.item.experience.title}
                  </TitleText>
                </Link>
                <GreyText sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                  {cardDetails.order.item.experience.state}
                </GreyText>
              </Box>
            </SpaceBetweenRow>
          </Box>
          <Spacer size="m" position="top" />
          {cardDetails.slot.duration !== null && (
            <>
              <RowBox>
                <StyledHourglassEmptyRoundedIcon />
                <Spacer size="s" position="left" />
                <PrimaryColorText>{cardDetails.slot.duration}</PrimaryColorText>
              </RowBox>
              <Spacer size="xs" position="top" />
              <RowBox>
                <StyledEventNoteRoundedIcon />
                <Spacer size="s" position="left" />
                <Box>
                  <PrimaryColorText>
                    {`${cardDetails.slot.checkIn.date}, ${cardDetails.slot.checkIn.time} - `}
                  </PrimaryColorText>
                  <PrimaryColorText>
                    {`${cardDetails.slot.checkOut.date}, ${cardDetails.slot.checkOut.time}`}
                  </PrimaryColorText>
                </Box>
              </RowBox>
            </>
          )}
          <Spacer size="m" position="top" />
          <BookingDetails cardDetails={cardDetails} />
          <Spacer size="m" position="top" />
        </>
      );
    }
    return (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BookingCardExperienceImage imagePath={cardDetails.order.item.experience.imgPath} />
          <Spacer size="m" position="left" />
          <SpaceBetweenRow isMobile={isMobile}>
            <Box>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: routes.EXPERIENCESDETAILS,
                  search: `experienceId=${cardDetails.order.item.experience.id}`,
                }}
              >
                <TitleText
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {cardDetails.order.item.experience.title}
                </TitleText>
              </Link>
              <GreyText sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                {cardDetails.order.item.experience.state}
              </GreyText>
              <Spacer size="m" position="top" />
              {cardDetails.slot.duration !== null && (
                <RowBox>
                  <StyledHourglassEmptyRoundedIcon />
                  <Spacer size="xs" position="left" />
                  <PrimaryColorText>{cardDetails.slot.duration}</PrimaryColorText>
                </RowBox>
              )}
            </Box>
            <StyledStatusBox>
              <CancelledBookingTextColor variant="body2">Cancelled</CancelledBookingTextColor>
            </StyledStatusBox>
          </SpaceBetweenRow>
        </Box>
        <BookingDetails cardDetails={cardDetails} />
        <Spacer size="l" position="top" />
        {cardDetails.slot.duration !== null && (
          <>
            <TopSeperateLine />
            <RowBox sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <StyledEventNoteRoundedIcon />
              <Spacer size="s" position="left" />
              <Box>
                <PrimaryColorText>
                  {`${cardDetails.slot.checkIn.date}, ${cardDetails.slot.checkIn.time} -  ${cardDetails.slot.checkOut.date}, ${cardDetails.slot.checkOut.time}`}
                </PrimaryColorText>
              </Box>
            </RowBox>
          </>
        )}
      </>
    );
  };

  const renderOrderDetails = () => {
    if (isMobile) {
      return (
        <>
          <SpaceBetweenRow
            sx={{
              paddingY: "10px",
              alignItems: "center",
            }}
          >
            {!cardDetails.order.item.isGift && (
              <RowBox>
                <Link
                  to={{
                    pathname: routes.MY_ORDER_DETAILS,
                    search: `orderId=${cardDetails.order.id}`,
                  }}
                >
                  <Typography>View Order #{cardDetails.order.id} </Typography>
                </Link>
                <Spacer size="s" position="left" />
              </RowBox>
            )}
          </SpaceBetweenRow>
          <BottomSeperateLine />
          <RowBox sx={{ paddingTop: "10px" }}>
            <StyledCancel />
            <Spacer size="s" position="left" />
            <GreyText>{`Cancelled on ${cardDetails.cancelled_at}`}</GreyText>
          </RowBox>
        </>
      );
    }
    return (
      <SpaceBetweenRow
        sx={{
          paddingY: "10px",
          alignItems: "center",
        }}
      >
        <RowBox>
          <StyledCancel />
          <Spacer size="s" position="left" />
          <GreyText>{`Cancelled on ${cardDetails.cancelled_at}`}</GreyText>
        </RowBox>
        {!cardDetails.order.item.isGift && (
          <RowBox>
            <Link
              to={{ pathname: routes.MY_ORDER_DETAILS, search: `orderId=${cardDetails.order.id}` }}
            >
              <Typography>View Order #{cardDetails.order.id} </Typography>
            </Link>
          </RowBox>
        )}
      </SpaceBetweenRow>
    );
  };

  return (
    <Grid item xs={isMobile ? 12 : 11}>
      <CardContainer>
        {renderExperiencesDetail()}
        <BottomSeperateLine />
        {renderOrderDetails()}
      </CardContainer>
    </Grid>
  );
}

CancelledBookingCard.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    noOfPax: PropTypes.number,
    slot: PropTypes.shape({
      checkIn: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      checkOut: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      duration: PropTypes.string,
      isFlexi: PropTypes.shape({
        status: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
    cancelled_at: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        pricePerItem: PropTypes.string,
        quantity: PropTypes.number,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          state: PropTypes.string,
          ratings: PropTypes.number,
          noReviews: PropTypes.number,
          imgPath: PropTypes.string,
        }),
        recipient: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        addOns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            sublabel: PropTypes.string,
            quantity: PropTypes.number,
            pricePerAddOn: PropTypes.string,
            price: PropTypes.string,
          }),
        ),
        isGift: PropTypes.bool,
        gift: PropTypes.shape({ message: PropTypes.string }),
      }),
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    notes: PropTypes.string,
  }).isRequired,
};
export default CancelledBookingCard;
