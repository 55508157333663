import React from "react";
import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function TextButton({ label, handleSubmit }) {
  const theme = useTheme();

  return (
    <StyledBox>
      <Button
        disableRipple
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: 0,
        }}
      >
        <Typography
          textAlign="center"
          sx={{
            color: theme.palette.colors.text.white,
          }}
        >
          {label}
        </Typography>
      </Button>
    </StyledBox>
  );
}

TextButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default TextButton;
