import React from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Spacer from "../utils/spacer.component";
import ContactUsDetails from "./contact-us-details.component";
import TermsAndPrivacy from "./terms-and-privacy.component";
import MasterCard from "../../assets/images/master.png";
import VisaCard from "../../assets/images/visa.png";
import Boost from "../../assets/images/boost.png";
import GrabPay from "../../assets/images/grab.png";
import TouchNGo from "../../assets/images/tng.png";

const IconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imagePath",
})((props) => ({
  borderTopLeftRadius: props.theme.shape.borderRadius[0],
  borderTopRightRadius: props.theme.shape.borderRadius[0],
  borderBottomLeftRadius: props.theme.shape.borderRadius[0],
  borderBottomRightRadius: props.theme.shape.borderRadius[0],
  height: "30px",
  width: "80px",
  backgroundImage: `url(${props.imagePath})`,
  backgroundSize: "cover",
}));

const SquareIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imagePath",
})((props) => ({
  borderTopLeftRadius: props.theme.shape.borderRadius[0],
  borderTopRightRadius: props.theme.shape.borderRadius[0],
  borderBottomLeftRadius: props.theme.shape.borderRadius[0],
  borderBottomRightRadius: props.theme.shape.borderRadius[0],
  height: "40px",
  width: "40px",
  backgroundImage: `url(${props.imagePath})`,
  backgroundSize: "cover",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
  textDecoration: "underline",
  textDecorationThickness: "1px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.secondary,
}));

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const StyledListBox = styled(Box)({
  paddingTop: "10px",
  display: "flex",
  flexDirection: "column",
  "& .MuiButton-root": {
    paddingLeft: 0,
    textTransform: "none",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
  "&:hover": {
    textDecoration: "underline",
  },
}));

function ContactUs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderPaymentMethod = () => {
    if (isMobile) {
      return (
        <Grid container spacing={5} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={12}>
            <StyledTitle sx={{ textDecoration: "none", textAlign: "center" }}>
              Payment Method
            </StyledTitle>
          </Grid>

          <Grid item>
            <IconBox imagePath={MasterCard} />
          </Grid>
          <Grid item>
            <IconBox imagePath={VisaCard} />
          </Grid>
          <Grid item>
            <IconBox imagePath={Boost} />
          </Grid>
          <Grid item>
            <IconBox imagePath={GrabPay} />
          </Grid>
          <Grid item>
            <SquareIconBox imagePath={TouchNGo} />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={5} sx={{ alignItems: "center" }}>
        <Grid item>
          <StyledTitle sx={{ textDecoration: "none" }}>Payment Method</StyledTitle>
        </Grid>
        <Spacer position="left" size="xl" />
        <Grid item>
          <IconBox imagePath={MasterCard} />
        </Grid>
        <Grid item>
          <IconBox imagePath={VisaCard} />
        </Grid>
        <Grid item>
          <IconBox imagePath={Boost} />
        </Grid>
        <Grid item>
          <IconBox imagePath={GrabPay} />
        </Grid>
        <Grid item>
          <SquareIconBox imagePath={TouchNGo} />
        </Grid>
      </Grid>
    );
  };

  return (
    <MainContainer paddingY={2}>
      <Box sx={{ maxWidth: theme.dimensions.PCMaxWidth }}>
        <Grid container spacing={2} paddingX={theme.dimensions.ScreenPaddingX}>
          <Grid item xs={isMobile ? 12 : 4}>
            <StyledTitle variant="h5">Help & Support</StyledTitle>
            <StyledListBox>
              <StyledLink href="https://merchantinfo.yolo.my/yolomy-help/">
                <Button disableRipple>
                  <StyledText>Help Center</StyledText>
                </Button>
              </StyledLink>
            </StyledListBox>
            <Spacer size="xl" position="bottom" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <StyledTitle variant="h5">Merchant</StyledTitle>
            <StyledListBox>
              <StyledLink href={process.env.REACT_APP_MERCHANT}>
                <Button disableRipple>
                  <StyledText>About Merchant</StyledText>
                </Button>
              </StyledLink>
              <StyledLink href={`${process.env.REACT_APP_MERCHANT}sign_up`}>
                <Button disableRipple>
                  <StyledText>Merchant Sign Up</StyledText>
                </Button>
              </StyledLink>
              <StyledLink href={`${process.env.REACT_APP_MERCHANT}login`}>
                <Button disableRipple>
                  <StyledText>Merchant Account Login</StyledText>
                </Button>
              </StyledLink>
            </StyledListBox>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <StyledTitle variant="h5">Contact Us</StyledTitle>
            <ContactUsDetails />
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            {renderPaymentMethod()}
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <TermsAndPrivacy />
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
}

export default ContactUs;
