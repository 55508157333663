import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import FilterPriceRules from "./filter-price-rules.component";
import FilterBarMenu from "./filter-bar-menu.component";

const MainContainer = styled(Box)({
  height: "55px",
  borderRadius: "16px",
  boxShadow: "3px 3px 2px rgb(0 0 0 / 12%)",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "10px",
  paddingRight: "10px",
});

function FilterBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-start">
        <MainContainer
          sx={{
            boxShadow: 2,
            marginLeft: isMobile
              ? theme.dimensions.MobileExperiencesPadding
              : theme.dimensions.PCExperiencesPadding,
          }}
        >
          <Grid item>
            <StyledBox>
              <FilterPriceRules />
            </StyledBox>
          </Grid>
          <SeperateLine />
          <Grid item>
            <StyledBox>
              <FilterBarMenu />
            </StyledBox>
          </Grid>
        </MainContainer>
      </Grid>
    </Grid>
  );
}

export default FilterBar;
