import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import BookingDetails from "./booking-details.component";
import BookingCardExperienceImage from "./booking-card-experience-image.component";
import BackgroundButton from "../../../components/button/background-button.component";

const StyledHourglassEmptyRoundedIcon = styled(HourglassEmptyRoundedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledErrorRoundedIcon = styled(ErrorRoundedIcon)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.colors.ui.notification,
}));

const StyledStatusBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: "transparent",
  border: "1px solid",
  borderColor: theme.palette.colors.button.rescheduleBooking,
  height: isMobile ? "15px" : "30px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "85px",
  padding: "10px",
}));

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: `1px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size14,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));
const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.ui.notification,
  fontSize: theme.fonts.fontSizes.size14,
}));

const RescheduleBookingTextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.button.rescheduleBooking,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const StyledButtonBox = styled(Box)({
  "& .MuiButton-root": {
    textTransform: "none",
    padding: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
    textDecoration: "none",
  },
});

const SpaceBetweenRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
  flex: 1,
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledBorderButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: theme.palette.colors.brand.secondary,
    padding: "10px",
    height: "30px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function RescheduleBookingCard({ cardDetails, handleCancelBooking }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const renderExperiencesDetail = () => {
    if (isMobile) {
      return (
        <SpaceBetweenRow isMobile={isMobile}>
          <StyledStatusBox isMobile={isMobile}>
            <RescheduleBookingTextColor variant="body2">Reschedule</RescheduleBookingTextColor>
          </StyledStatusBox>
          <Spacer size="s" position="top" />
          <Box>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: `${routes.BOOKINGEXPERIENCESDETAILS}orderId=${cardDetails.id}&experienceId=${cardDetails.order.item.experience.id}`,
              }}
            >
              <TitleText
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {cardDetails.order.item.experience.title}
              </TitleText>
            </Link>
            <GreyText>{cardDetails.order.item.experience.state}</GreyText>
          </Box>
        </SpaceBetweenRow>
      );
    }
    return (
      <SpaceBetweenRow isMobile={isMobile}>
        <Box>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `${routes.BOOKINGEXPERIENCESDETAILS}orderId=${cardDetails.id}&experienceId=${cardDetails.order.item.experience.id}`,
            }}
          >
            <TitleText
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {cardDetails.order.item.experience.title}
            </TitleText>
          </Link>
          <GreyText>{cardDetails.order.item.experience.state}</GreyText>
          <Spacer size="m" position="top" />
          {cardDetails.slot.duration && (
            <RowBox>
              <StyledHourglassEmptyRoundedIcon />
              <Spacer size="s" position="left" />
              <PrimaryColorText>{cardDetails.slot.duration}</PrimaryColorText>
            </RowBox>
          )}
        </Box>
        <StyledStatusBox>
          <RescheduleBookingTextColor variant="body2">Reschedule</RescheduleBookingTextColor>
        </StyledStatusBox>
      </SpaceBetweenRow>
    );
  };

  const renderOrderDetails = () => {
    if (isMobile) {
      return (
        <>
          <SpaceBetweenRow
            sx={{
              paddingY: "10px",
              alignItems: "center",
            }}
          >
            {!cardDetails.order.item.isGift && (
              <RowBox>
                <Link
                  to={{
                    pathname: routes.MY_ORDER_DETAILS,
                    search: `orderId=${cardDetails.order.id}`,
                  }}
                >
                  <Typography>View Order #{cardDetails.order.id} </Typography>
                </Link>
                <Spacer size="s" position="left" />
              </RowBox>
            )}
          </SpaceBetweenRow>
          {cardDetails.slot.checkIn.date && (
            <>
              <SeperateLine />
              <RowBox sx={{ paddingY: "10px" }}>
                <WarningText>Initial Selected Date & Time: </WarningText>
                <WarningText>{`${cardDetails.slot.checkIn.date}, ${cardDetails.slot.checkIn.time}`}</WarningText>
              </RowBox>
            </>
          )}
          <StyledBorderButtonBox>
            <Button onClick={() => handleCancelBooking(cardDetails)} sx={{ width: "100%" }}>
              <PrimaryColorText>Cancel</PrimaryColorText>
            </Button>
          </StyledBorderButtonBox>
        </>
      );
    }
    return (
      <>
        {cardDetails.slot.checkIn.date && (
          <>
            <RowBox sx={{ paddingY: "10px" }}>
              <StyledErrorRoundedIcon />
              <Spacer size="s" position="left" />
              <Box sx={{ flex: 1, flexWrap: "wrap", display: "flex" }}>
                <WarningText>Initial Selected Date & Time: </WarningText>
                <WarningText>{`${cardDetails.slot.checkIn.date}, ${cardDetails.slot.checkIn.time}`}</WarningText>
              </Box>
            </RowBox>
            <SeperateLine />
          </>
        )}
        <SpaceBetweenRow
          sx={{
            paddingTop: "10px",
            alignItems: "center",
          }}
        >
          <RowBox />
          <RowBox>
            {!cardDetails.order.item.isGift && (
              <Link
                to={{
                  pathname: routes.MY_ORDER_DETAILS,
                  search: `orderId=${cardDetails.order.id}`,
                }}
              >
                <Typography>View Order #{cardDetails.order.id} </Typography>
              </Link>
            )}
            <Spacer size="s" position="left" />
            <StyledBorderButtonBox>
              <Button onClick={() => handleCancelBooking(cardDetails)}>
                <PrimaryColorText>Cancel</PrimaryColorText>
              </Button>
            </StyledBorderButtonBox>
          </RowBox>
        </SpaceBetweenRow>
      </>
    );
  };

  return (
    <Grid item xs={isMobile ? 12 : 11}>
      <CardContainer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BookingCardExperienceImage imagePath={cardDetails.order.item.experience.imgPath} />
          <Spacer size="m" position="left" />
          {renderExperiencesDetail()}
        </Box>

        {isMobile && cardDetails.slot.duration && (
          <RowBox sx={{ paddingY: "10px" }}>
            <StyledHourglassEmptyRoundedIcon />
            <Spacer size="s" position="left" />
            <PrimaryColorText>{cardDetails.slot.duration}</PrimaryColorText>
          </RowBox>
        )}
        <BookingDetails cardDetails={cardDetails} />

        <Spacer position="top" size="m" />
        <SeperateLine />
        <StyledButtonBox sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Grid item xs={isMobile ? 12 : 3}>
            <BackgroundButton
              handleSubmit={() => history.push(routes.DATETIMESELECT + cardDetails.id)}
            >
              <WhiteColorText>Select Date & Time</WhiteColorText>
            </BackgroundButton>
          </Grid>
        </StyledButtonBox>
        <SeperateLine />
        {renderOrderDetails()}
      </CardContainer>
    </Grid>
  );
}

RescheduleBookingCard.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    noOfPax: PropTypes.number,
    slot: PropTypes.shape({
      checkIn: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      checkOut: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
      }),
      duration: PropTypes.string,
      isFlexi: PropTypes.shape({
        status: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
    cancelled_at: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        pricePerItem: PropTypes.string,
        quantity: PropTypes.number,
        experience: PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          state: PropTypes.string,
          ratings: PropTypes.number,
          noReviews: PropTypes.number,
          imgPath: PropTypes.string,
        }),
        recipient: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        addOns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            sublabel: PropTypes.string,
            quantity: PropTypes.number,
            pricePerAddOn: PropTypes.string,
            price: PropTypes.string,
          }),
        ),
        isGift: PropTypes.bool,
        gift: PropTypes.shape({ message: PropTypes.string }),
      }),
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    notes: PropTypes.string,
  }).isRequired,
  handleCancelBooking: PropTypes.func.isRequired,
};
export default RescheduleBookingCard;
