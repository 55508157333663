import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import OrderSummary from "../components/order-summary.component";
import ItemList from "../components/checkout-item-list.component";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/utils/spacer.component";
import PaymentMethodCheckbox from "../components/payment-method-checkbox.component";
import SecureCheckoutLoader from "../loader/secure-checkout-loader.component";
import ConfirmationModal from "../components/confirmation-modal.component";
import {
  createOrder,
  resetPreviewRedeemPromoCode,
} from "../../../services/order/order-slice.service";
import { getProfile, profileSelector } from "../../../services/profile/profile-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { cartSelector, getCartList } from "../../../services/cart/cart-slice.service";

const BackButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  width: "100%",
  color: theme.palette.colors.text.secondary,
  marginTop: 50,
  marginBottom: 50,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().label("Payment Method"),
  orders: Yup.array().of(
    Yup.object().shape({
      cartItemId: Yup.number(),
      purchasedAsGift: Yup.boolean(),
      promoCode: Yup.string(),
      recipient: Yup.object().shape({
        buyAsGift: Yup.boolean(),
        lastName: Yup.string().label("Last Name").when("buyAsGift", {
          is: true,
          then: Yup.string().required(),
        }),
        firstName: Yup.string().label("First Name").when("buyAsGift", {
          is: true,
          then: Yup.string().required(),
        }),
        email: Yup.string().email().label("Email").when("buyAsGift", {
          is: true,
          then: Yup.string().required(),
        }),
        phone: Yup.string()
          .when({
            is: (value) => value?.length,
            then: (rule) => rule.min(10),
          })
          .when("buyAsGift", {
            is: true,
            then: Yup.string().required(),
          })
          .nullable()
          .label("Phone Number"),
      }),
      giftDetails: Yup.object().shape({
        to: Yup.string().label("To"),
        from: Yup.string().label("From"),
        message: Yup.string().label("Message"),
      }),
    }),
  ),
});

function SecureCheckoutScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("940"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { getCartListObj } = useSelector(cartSelector);
  const { profileObj } = useSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCodeRedeems, setPromoCodeRedeems] = useState([{}]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [submitValue, setSubmitValue] = useState(null);
  const createSnackBar = useContext(SnackbarContext);
  const [updatedCart, setUpdateCart] = useState(false);

  const accountCreditPurchase = () => {
    setShowConfirmModal(!showConfirmModal);
    setIsLoading(true);
    dispatch(createOrder(submitValue)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        history.push(routes.BILLSUCCESS);
      }
    });
  };

  const pushToTopUp = () => {
    history.push(routes.ACCOUNTCREDITS);
  };

  const formSubmitHandle = (values) => {
    const orderTotalPrice = promoCodeRedeems.reduce((accumulator, object) => {
      if (object.discount) {
        return accumulator - parseFloat(object.discount.replace(/,/g, ""));
      }
      return accumulator;
    }, parseFloat(getCartListObj.data.totalCost.replace(/,/g, "")));
    setSubmitValue({ ...values });
    if (values.paymentMethod === "credits") {
      if (parseFloat(profileObj.data.credits.replace(/,/g, "")) < orderTotalPrice) {
        setShowTopUpModal(true);
        return;
      }
      setShowConfirmModal(true);
      return;
    }

    setIsLoading(true);
    dispatch(createOrder({ ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      }
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        if (orderTotalPrice === 0) {
          history.push(routes.BILLSUCCESS);
        } else {
          window.open(payload.data.orderBill.remote_url, "_self");
        }
      }
    });
  };

  const renderContent = () => {
    if (getCartListObj.status === "succeeded" && profileObj.status === "succeeded" && updatedCart) {
      const tempModifiedCartOrderDetailsObj = getCartListObj.data.items.map((items) => {
        const modifiedCartObj = {
          ...items,
          purchasedAsGift: false,
          cartItemId: items.id,
          promoCode: "",
          recipient: {
            buyAsGift: false,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          },
          giftDetails: {
            to: "",
            from: "",
            message: "",
          },
        };
        return modifiedCartObj;
      });

      return (
        <Form
          initialValues={{
            orders: tempModifiedCartOrderDetailsObj,
            paymentMethod: "others",
          }}
          onSubmit={formSubmitHandle}
          validationSchema={validationSchema}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <BackButton
                to={{
                  pathname: routes.MYCART,
                }}
              >
                {"< Back"}
              </BackButton>
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 12 : 9}>
            <StyledBox isMobile={isMobile}>
              <Grid item xs={12}>
                <StyledTitle variant="h5">Order Details</StyledTitle>
                <Box sx={{ paddingBottom: "50px" }}>
                  {tempModifiedCartOrderDetailsObj.map((item, index) => (
                    <ItemList
                      itemData={item}
                      key={item.cartItemId}
                      index={index}
                      setIsLoading={setIsLoading}
                      promoCodeRedeems={promoCodeRedeems}
                      setPromoCodeRedeems={setPromoCodeRedeems}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} paddingBottom="50px">
                <StyledTitle variant="h5">Payment Method</StyledTitle>
                <Spacer size="m" position="top" />
                <PaymentMethodCheckbox />
              </Grid>
            </StyledBox>
          </Grid>
          {isMobile && <SeperateLine />}
          <Grid item xs={isMobile ? 12 : 3}>
            <OrderSummary promoCodeRedeems={promoCodeRedeems} />
          </Grid>
        </Form>
      );
    }
    return <SecureCheckoutLoader />;
  };

  useEffect(() => {
    dispatch(resetPreviewRedeemPromoCode());
    window.scrollTo(0, 0);
    dispatch(getCartList()).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setUpdateCart(true);
        setPromoCodeRedeems(Array(payload.data.items.length).fill({}));
      }
    });
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Grid
        container
        paddingBottom={isMobile ? "30px" : "100px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        {renderContent()}
        <ConfirmationModal
          setShowModal={setShowConfirmModal}
          showModal={showConfirmModal}
          onSubmit={accountCreditPurchase}
          message="Confirm to proceed with the Account Credits? Total amount will be deducted from your Account Credits."
          buttonText="Confirm"
        />
        <ConfirmationModal
          setShowModal={setShowTopUpModal}
          showModal={showTopUpModal}
          onSubmit={pushToTopUp}
          message="Sorry, you do not have enough balance in your account credits for this payment. Please choose another payment method or top up your credits to continue."
          buttonText="Top Up"
        />
        <Spacer size="xxl" position="top" />
      </Grid>
    </>
  );
}

export default SecureCheckoutScreen;
