import React from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Spacer from "../../../../components/utils/spacer.component";
import routes from "../../../../components/navigation/routes";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const DetailsBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const PrimaryColorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflowWrap: "anywhere",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size15,
}));

const PaddingGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

function ItemDetailsBox({ itemDetails }) {
  const theme = useTheme();

  const getAddOns = () => {
    const addOnsString = [];
    itemDetails.addOns.map((item) =>
      addOnsString.push(
        `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
      ),
    );

    return addOnsString;
  };

  const getTotalCost = () => {
    let totalCost = 0;

    itemDetails.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(itemDetails.price.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  const getSubtotalCost = () => {
    let totalCost = 0;

    itemDetails.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(itemDetails.subtotal.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <img
          src={itemDetails.experience.imgPath}
          alt="exp-img"
          style={{
            width: "180px",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        />
        <Spacer size="m" position="left" />
        <DetailsBox>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: routes.EXPERIENCESDETAILS,
              search: `experienceId=${itemDetails.experience.id}`,
            }}
          >
            <PrimaryColorTitle>{itemDetails.experience.title}</PrimaryColorTitle>
          </Link>

          <SpaceBetweenBox>
            <PaddingGreyText>{itemDetails.experience.state}</PaddingGreyText>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <PrimaryColorText>{`${itemDetails.quantity}x`}</PrimaryColorText>
              <Spacer position="left" size="xs" />
              <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                {`RM${itemDetails.pricePerItem}`}
              </PrimaryColorText>
            </Box>
          </SpaceBetweenBox>

          <PrimaryColorText>{`Qty: ${itemDetails.quantity}`}</PrimaryColorText>
        </DetailsBox>
      </Box>
      {getAddOns().length > 0 && (
        <>
          <Spacer position="top" size="s" />
          <GreyText variant="body2">Add On(s)</GreyText>
          {getAddOns().map((item) => (
            <GreyText variant="body2" key={item}>
              {item}
            </GreyText>
          ))}
        </>
      )}
      <Spacer position="top" size="m" />
      <SpaceBetweenBox>
        <PrimaryColorText>Item Subtotal :</PrimaryColorText>
        <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
          {`RM${getSubtotalCost()}`}
        </PrimaryColorText>
      </SpaceBetweenBox>
      {itemDetails.discount && (
        <>
          <SpaceBetweenBox>
            <PrimaryColorText>Item Discount :</PrimaryColorText>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              {`- RM${itemDetails.discount}`}
            </PrimaryColorText>
          </SpaceBetweenBox>
          <SpaceBetweenBox>
            <PrimaryColorText>Item Total Price :</PrimaryColorText>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              {`RM${getTotalCost()}`}
            </PrimaryColorText>
          </SpaceBetweenBox>
        </>
      )}
    </Grid>
  );
}

ItemDetailsBox.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    quantity: null,
    subtotalPrice: "",
    experience: PropTypes.shape({
      id: null,
      title: "",
      state: "",
      ratings: "",
      noReviews: null,
      imgPath: "",
    }),
  }),
};

ItemDetailsBox.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    subtotal: PropTypes.string,
    discount: PropTypes.string,
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    experience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      state: PropTypes.string,
      ratings: PropTypes.number,
      noReviews: PropTypes.number,
      imgPath: PropTypes.string,
    }),
  }),
};

export default ItemDetailsBox;
