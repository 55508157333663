import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import OpenBookingCard from "./open-booking-card.component";
import { bookingSelector } from "../../../services/booking/booking-slice.service";
import PendingBookingCard from "./pending-booking-card.component";
import ConfirmedBookingCard from "./confirmed-booking-card.component";
import BorderButtonWithBg from "../../../components/button/border-button-with-bg.component";
import { profileSelector } from "../../../services/profile/profile-slice.service";

const RedDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: -8,
  right: -5,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.colors.text.white,
}));
function UpcomingBookings({
  onBookingStatusChange,
  handleCancelBooking,
  currentSubTab,
  setCurrentSubTab,
  setUniqueCode,
  setShowUniqueCodeModal,
}) {
  const theme = useTheme();
  const { getBookingsObj } = useSelector(bookingSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { profileObj } = useSelector(profileSelector);

  const renderEmptyList = () => (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "250px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.colors.brand.secondary,
          fontWeight: theme.fonts.fontWeights.regular,
        }}
      >
        No item to show.
      </Typography>
    </Grid>
  );

  const renderOpenBookings = () => (
    <Grid container>
      <Grid item xs={12} paddingTop={4} paddingBottom={2}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.colors.brand.secondary,
            fontWeight: theme.fonts.fontWeights.regular,
          }}
        >
          Open
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        {getBookingsObj.data.map((item) => (
          <OpenBookingCard
            key={item.id}
            cardDetails={item}
            handleCancelBooking={handleCancelBooking}
          />
        ))}
      </Grid>
    </Grid>
  );

  const renderPendingBookings = () => (
    <Grid container>
      <Grid item xs={12} paddingTop={4} paddingBottom={2}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.colors.brand.secondary,
            fontWeight: theme.fonts.fontWeights.regular,
          }}
        >
          Pending
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        {getBookingsObj.data.map((item) => (
          <PendingBookingCard
            key={item.id}
            cardDetails={item}
            handleCancelBooking={handleCancelBooking}
          />
        ))}
      </Grid>
    </Grid>
  );

  const renderConfirmedBookings = () => (
    <Grid container>
      <Grid item xs={12} paddingTop={4} paddingBottom={2}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.colors.brand.secondary,
            fontWeight: theme.fonts.fontWeights.regular,
          }}
        >
          Confirmed
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        {getBookingsObj.data.map((item) => (
          <ConfirmedBookingCard
            key={item.id}
            cardDetails={item}
            setShowUniqueCodeModal={setShowUniqueCodeModal}
            setUniqueCode={setUniqueCode}
            handleCancelBooking={handleCancelBooking}
          />
        ))}
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (getBookingsObj.status === "succeeded" && currentSubTab === "all") {
      if (
        !getBookingsObj.data.open &&
        !getBookingsObj.data.pending &&
        !getBookingsObj.data.confirmed
      ) {
        return renderEmptyList();
      }
      return (
        <>
          {getBookingsObj.data.open && (
            <Grid container>
              <Grid item xs={12} paddingTop={4} paddingBottom={2}>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.colors.brand.secondary,
                    fontWeight: theme.fonts.fontWeights.regular,
                  }}
                >
                  Open
                </Typography>
              </Grid>
              <Grid container spacing={4}>
                {getBookingsObj.data.open.map((item) => (
                  <OpenBookingCard
                    key={item.id}
                    cardDetails={item}
                    handleCancelBooking={handleCancelBooking}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {getBookingsObj.data.pending && (
            <Grid container>
              <Grid item xs={12} paddingTop={4} paddingBottom={2}>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.colors.brand.secondary,
                    fontWeight: theme.fonts.fontWeights.regular,
                  }}
                >
                  Pending
                </Typography>
              </Grid>
              <Grid container spacing={4}>
                {getBookingsObj.data.pending.map((item) => (
                  <PendingBookingCard
                    key={item.id}
                    cardDetails={item}
                    handleCancelBooking={handleCancelBooking}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {getBookingsObj.data.confirmed && (
            <Grid container>
              <Grid item xs={12} paddingTop={4} paddingBottom={2}>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.colors.brand.secondary,
                    fontWeight: theme.fonts.fontWeights.regular,
                  }}
                >
                  Confirmed
                </Typography>
              </Grid>
              <Grid container spacing={4}>
                {getBookingsObj.data.confirmed.map((item) => (
                  <ConfirmedBookingCard
                    key={item.id}
                    cardDetails={item}
                    setShowUniqueCodeModal={setShowUniqueCodeModal}
                    setUniqueCode={setUniqueCode}
                    handleCancelBooking={handleCancelBooking}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      currentSubTab === "open"
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return renderOpenBookings();
    }

    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      currentSubTab === "pending"
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return renderPendingBookings();
    }

    if (
      getBookingsObj.status === "succeeded" &&
      Array.isArray(getBookingsObj.data) &&
      currentSubTab === "confirmed"
    ) {
      if (getBookingsObj.data.length === 0) {
        return renderEmptyList();
      }
      return renderConfirmedBookings();
    }

    return (
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "250px",
        }}
      >
        <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
      </Grid>
    );
  };

  const getTabColor = () => {
    if (currentSubTab === "all") {
      return theme.palette.colors.brand.secondary;
    }
    if (currentSubTab === "open") {
      return theme.palette.colors.button.openBooking;
    }
    if (currentSubTab === "pending") {
      return theme.palette.colors.button.pendingBooking;
    }
    return theme.palette.colors.button.confirmedBooking;
  };

  const renderButton = () => {
    if (isMobile) {
      return (
        <Grid
          container
          justifyContent="center"
          sx={{
            "& .MuiOutlinedInput-root": {
              " &.Mui-focused fieldset": {
                borderColor: getTabColor(),
              },
            },
            "& .MuiSelect-iconOutlined": {
              color: theme.palette.colors.text.white,
            },
          }}
        >
          <FormControl>
            <Select
              variant="outlined"
              value={currentSubTab}
              sx={{
                minWidth: "150px",
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                height: "40px",
                backgroundColor: getTabColor(),
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: "5px",
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                  },
                },
              }}
              defaultValue={currentSubTab}
            >
              <MenuItem value="all">
                <BorderButtonWithBg
                  isSelected={currentSubTab === "all"}
                  tabColor={theme.palette.colors.brand.secondary}
                  label="All"
                  handleSubmit={() => {
                    onBookingStatusChange("all");
                    setCurrentSubTab("all");
                  }}
                />
              </MenuItem>
              <MenuItem value="open">
                <BorderButtonWithBg
                  isSelected={currentSubTab === "open"}
                  tabColor={theme.palette.colors.button.openBooking}
                  label={
                    <>
                      <Typography>Open</Typography>
                      {profileObj.status === "succeeded" && profileObj.data.bookingAlert.open > 0 && (
                        <RedDot>
                          <NotificationText>{profileObj.data.bookingAlert.open}</NotificationText>
                        </RedDot>
                      )}
                    </>
                  }
                  handleSubmit={() => {
                    onBookingStatusChange("open");
                    setCurrentSubTab("open");
                  }}
                />
              </MenuItem>
              <MenuItem value="pending">
                <BorderButtonWithBg
                  isSelected={currentSubTab === "pending"}
                  tabColor={theme.palette.colors.button.pendingBooking}
                  handleSubmit={() => {
                    onBookingStatusChange("pending");
                    setCurrentSubTab("pending");
                  }}
                  label="Pending"
                />
              </MenuItem>
              <MenuItem value="confirmed">
                <BorderButtonWithBg
                  isSelected={currentSubTab === "confirmed"}
                  tabColor={theme.palette.colors.button.confirmedBooking}
                  handleSubmit={() => {
                    onBookingStatusChange("confirmed");
                    setCurrentSubTab("confirmed");
                  }}
                  label={
                    <>
                      <Typography>Confirmed</Typography>
                      {profileObj.status === "succeeded" &&
                        profileObj.data.bookingAlert.upcoming > 0 && (
                          <RedDot>
                            <NotificationText>
                              {profileObj.data.bookingAlert.upcoming}
                            </NotificationText>
                          </RedDot>
                        )}
                    </>
                  }
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item>
          <BorderButtonWithBg
            isSelected={currentSubTab === "all"}
            tabColor={theme.palette.colors.brand.secondary}
            handleSubmit={() => {
              onBookingStatusChange("all");
              setCurrentSubTab("all");
            }}
            label="All"
          />
        </Grid>
        <Grid item>
          <BorderButtonWithBg
            isSelected={currentSubTab === "open"}
            tabColor={theme.palette.colors.button.openBooking}
            handleSubmit={() => {
              onBookingStatusChange("open");
              setCurrentSubTab("open");
            }}
            label={
              <>
                <Typography>Open</Typography>
                {profileObj.status === "succeeded" && profileObj.data.bookingAlert.open > 0 && (
                  <RedDot>
                    <NotificationText>{profileObj.data.bookingAlert.open}</NotificationText>
                  </RedDot>
                )}
              </>
            }
          />
        </Grid>
        <Grid item>
          <BorderButtonWithBg
            isSelected={currentSubTab === "pending"}
            tabColor={theme.palette.colors.button.pendingBooking}
            handleSubmit={() => {
              onBookingStatusChange("pending");
              setCurrentSubTab("pending");
            }}
            label="Pending"
          />
        </Grid>
        <Grid item>
          <BorderButtonWithBg
            isSelected={currentSubTab === "confirmed"}
            tabColor={theme.palette.colors.button.confirmedBooking}
            handleSubmit={() => {
              onBookingStatusChange("confirmed");
              setCurrentSubTab("confirmed");
            }}
            label={
              <>
                <Typography>Confirmed</Typography>
                {profileObj.status === "succeeded" && profileObj.data.bookingAlert.upcoming > 0 && (
                  <RedDot>
                    <NotificationText>{profileObj.data.bookingAlert.upcoming}</NotificationText>
                  </RedDot>
                )}
              </>
            }
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {renderButton()}
      {renderContent()}
    </Grid>
  );
}

UpcomingBookings.propTypes = {
  onBookingStatusChange: PropTypes.func.isRequired,
  handleCancelBooking: PropTypes.func.isRequired,
  currentSubTab: PropTypes.string.isRequired,
  setCurrentSubTab: PropTypes.func.isRequired,
  setShowUniqueCodeModal: PropTypes.func.isRequired,
  setUniqueCode: PropTypes.func.isRequired,
};

export default UpcomingBookings;
