import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

import CustomSkeleton from "../../../../components/utils/skeleton.component";

function MarketingBannerLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item xs={12}>
      <CustomSkeleton
        width="100%"
        height={!isMobile ? "340px" : "220px"}
        borderRadius={theme.shape.borderRadius[0]}
      />
    </Grid>
  );
}

export default MarketingBannerLoader;
