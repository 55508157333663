import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import CurrencyBalanceLoader from "./current-balance-loader.component";

const StyledTitleBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

function AccountCreditsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={isMobile ? 12 : 7}>
      <Grid container sx={{ paddingLeft: isMobile ? "35px" : "10px" }}>
        <Grid item xs={12} paddingTop={4}>
          <StyledTitleBox>
            <Typography variant="h4" fontWeight={theme.fonts.fontWeights.bold}>
              Account Credits
            </Typography>
            <CustomSkeleton width="120px" height="35px" borderRadius={4} />
          </StyledTitleBox>
        </Grid>

        <Grid
          container
          sx={{
            paddingY: 3,
          }}
        >
          <CurrencyBalanceLoader />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: 3,
          }}
        >
          <Grid item>
            <CustomSkeleton width="110px" height="30px" borderRadius={4} />
          </Grid>
          <Grid item>
            <CustomSkeleton width="110px" height="30px" borderRadius={4} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: 3,
          }}
        >
          <Grid item>
            <CustomSkeleton width="110px" height="30px" borderRadius={4} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccountCreditsLoader;
