import React from "react";
import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Spacer from "../../../../components/utils/spacer.component";
import { isLogin } from "../../../utils";
import routes from "../../../../components/navigation/routes";

const StyledMainBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ButtonBorderBox = styled(Button)(({ theme }) => ({
  display: "flex",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${theme.palette.colors.brand.secondary}`,
  textTransform: "none",
  padding: "0px",
  boxShadow: 0,
  "&:hover": {
    backgroundColor: theme.palette.colors.brand.white,
  },
  "& .MuiButton-root": {},
}));

const CenterRowBox = styled(Box)({
  flexDirection: "row",
  paddingLeft: "5px",
  paddingRight: "5px",
  display: "flex",
  alignItems: "center",
});

function LeftButtonView({ amount }) {
  const theme = useTheme();

  return (
    <StyledMainBox>
      <Link style={{ textDecoration: "none" }} to={{ pathname: routes.VOUCHERREDEMPTION }}>
        <ButtonBorderBox>
          <CenterRowBox>
            <Typography
              sx={{
                color: theme.palette.colors.text.primary,
                fontSize: theme.fonts.fontSizes.size14,
              }}
            >
              Use A Voucher
            </Typography>
          </CenterRowBox>
        </ButtonBorderBox>
      </Link>
      <Spacer size="m" position="left" />
      {isLogin() && (
        <Link style={{ textDecoration: "none" }} to={{ pathname: routes.ACCOUNTCREDITS }}>
          <ButtonBorderBox>
            <CenterRowBox>
              <PaidIcon
                sx={{
                  color: theme.palette.colors.brand.secondary,
                  fontSize: theme.fonts.fontSizes.size14,
                }}
              />
              <Typography
                sx={{
                  color: theme.palette.colors.text.primary,
                  fontWeight: theme.fonts.fontWeights.bold,
                  fontSize: theme.fonts.fontSizes.size14,
                  paddingX: "5px",
                }}
              >
                {`RM${amount}`}
              </Typography>
            </CenterRowBox>
          </ButtonBorderBox>
        </Link>
      )}
    </StyledMainBox>
  );
}

LeftButtonView.defaultProps = {
  amount: "0.00",
};

LeftButtonView.propTypes = {
  amount: PropTypes.string,
};

export default LeftButtonView;
