import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CarouselItemList from "../../dashboard/components/carousel-item-list.component";
import ListingItemsLoader from "../../../components/listing-items/listing-items-loader.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
}));

function YouMightAlsoLikeLoader() {
  const arr = Array.apply("", Array(3));

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledTitle variant="h4">You might also like...</StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <CarouselItemList>
            {arr.map(() => (
              <ListingItemsLoader key={uuidv4()} />
            ))}
          </CarouselItemList>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default YouMightAlsoLikeLoader;
