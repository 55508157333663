import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";

import LeftBox from "./left-box.component";
import { isLogin } from "../../../infrastructure/utils";
import Spacer from "../../../components/utils/spacer.component";
import NumberUpDownArrowField from "../../../components/forms/quantity-field.component";
import CenteredRow from "../../../components/utils/centered-row.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "50px",
}));

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
});

function ItemList({
  itemData,
  onToggleFavourite,
  onToggleDelete,
  onQuantityUpdate,
  setShowAddOnModal,
}) {
  const { setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("740"));
  const [quantity, setQuantity] = useState(
    isLogin() ? itemData.quantity : itemData.experience.quantity,
  );

  const onFavourtieClick = (isAddFavourite) => {
    onToggleFavourite(
      {
        experienceId: itemData.experience.id,
        addOns: itemData.experience.addOns,
        quantity: null,
      },
      isAddFavourite,
    );
  };

  const getAddOns = () => {
    const addOnsString = [];
    if (!isLogin()) {
      itemData.experience.addOns.map((item) =>
        addOnsString.push(
          `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${(
            parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity
          ).toFixed(2)}`,
        ),
      );
    } else {
      itemData.addOns.map((item) =>
        addOnsString.push(
          `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
        ),
      );
    }

    return addOnsString;
  };

  const getPrice = () => {
    if (isLogin()) {
      let price = 0;
      itemData.addOns.forEach((item) => {
        price += parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity;
      });
      price += parseFloat(
        Number.isNaN(quantity)
          ? parseFloat(itemData.pricePerItem.replace(",", ""))
          : parseFloat(quantity * itemData.pricePerItem.replace(",", "")).toFixed(2),
      );
      return price.toFixed(2);
    }
    let price = 0;
    itemData.experience.addOns.forEach((item) => {
      price += parseFloat(item.pricePerAddOn.replace(",", "")) * item.quantity;
    });
    price += parseFloat(
      Number.isNaN(quantity)
        ? parseFloat(itemData.experience.pricePerItem.replace(",", ""))
        : parseFloat(quantity * itemData.experience.pricePerItem.replace(",", "")).toFixed(2),
    );
    return price.toFixed(2);
  };

  const onDeleteClick = () => {
    if (isLogin()) {
      onToggleDelete(itemData.id);
      return;
    }
    onToggleDelete({
      uniqueId: itemData.uniqueId,
      experience: itemData.experience,
      quantity,
      isFlexi: itemData.experience.isFlexi,
    });
  };

  const onQuantityChange = (values) => {
    setQuantity(values);
    if (Number.isNaN(values)) {
      return;
    }

    if (!isLogin()) {
      onQuantityUpdate({
        experienceId: itemData.experience.id,
        quantity: values,
        isFlexi: itemData.experience.hasFlexi.status,
        uniqueId: `${itemData.experience.id}${itemData.experience.hasFlexi.status}`,
      });
      return;
    }
    onQuantityUpdate({ id: itemData.id, quantity: values, addOns: itemData.addOns });
  };

  const onAddOnsEditClicked = () => {
    if (!isLogin()) {
      setFieldValue("addOns", itemData.experience.addOns);
      setFieldValue("isFlexi", itemData.uniqueId.includes("true"));
      setFieldValue("uniqueId", itemData.uniqueId);
      setFieldValue("massSlotId", itemData.experience.hasFlexi.massSlotId);
    } else {
      setFieldValue("addOns", itemData.addOns);
      setFieldValue("isFlexi", itemData.isFlexi);
      setFieldValue("massSlotId", itemData.experience.hasFlexi.massSlotId);
    }
    setFieldValue("quantity", quantity);
    setFieldValue("experienceId", itemData.experience.id);
    setShowAddOnModal(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={isMobile ? 12 : 6}>
        <LeftBox
          itemDetails={itemData}
          onFavourtieClick={onFavourtieClick}
          onDeleteClick={onDeleteClick}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 5.5} paddingTop={isMobile ? "30px" : 0}>
        <MainBox>
          <DetailsBox>
            <GreyText textAlign="start">Per Ticket</GreyText>
            <Spacer size="s" position="top" />
            <PrimaryColorText>{`RM${
              isLogin() ? itemData.pricePerItem : itemData.experience.pricePerItem
            }`}</PrimaryColorText>
          </DetailsBox>
          <DetailsBox>
            <GreyText textAlign="center">Qty</GreyText>
            <Spacer size="s" position="top" />
            <NumberUpDownArrowField
              maxNoPax={itemData.experience.maxNoPax}
              minNoPax={itemData.experience.minNoPax}
              setValue={onQuantityChange}
              value={quantity}
            />
          </DetailsBox>
          <DetailsBox sx={{ width: "100px" }}>
            <GreyText textAlign="end">Total</GreyText>
            <Spacer size="s" position="top" />
            <PrimaryColorText textAlign="end">{`RM${getPrice()}`}</PrimaryColorText>
          </DetailsBox>
        </MainBox>
      </Grid>
      {!isMobile && (
        <Grid item xs={0.5}>
          <CenteredBox>
            <CloseIconButton onClick={() => onDeleteClick()}>
              <CloseIcon />
            </CloseIconButton>
          </CenteredBox>
        </Grid>
      )}

      <Grid item xs={12}>
        {isLogin() ? (
          <Typography fontSize={theme.fonts.fontSizes.size14}>
            Booking Type: {itemData.isFlexi ? "Flexi" : "Standard"}
          </Typography>
        ) : (
          <Typography fontSize={theme.fonts.fontSizes.size14}>
            Booking Type: {itemData.experience.isFlexi ? "Flexi" : "Standard"}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={onAddOnsEditClicked}
          sx={{ padding: "0px", textTransform: "none" }}
          disableRipple
        >
          <CenteredRow>
            <GreyText>Add On(s) </GreyText>
            <Spacer size="s" position="left" />

            <EditIcon
              sx={{
                fontSize: theme.fonts.fontSizes.size16,
                color: theme.palette.colors.text.secondary,
              }}
            />
          </CenteredRow>
        </Button>
        {getAddOns().map((item) => (
          <GreyText key={item}>{item}</GreyText>
        ))}
      </Grid>

      <SeperateLine />
    </Grid>
  );
}

ItemList.defaultProps = {
  itemData: {
    id: null,
    uniqueId: "",
    experience: {
      addOns: [],
      description: "",
      favourited: false,
      hasFlexi: {
        status: false,
      },
      isFlexi: false,
      id: null,
      imgPath: "",
      importantInfo: "",
      location: {
        lat: "",
        lng: "",
      },
      maxNoPax: null,
      minNoPax: null,
      noReviews: null,
      pricePerItem: "",
      quantity: null,
      ratings: null,
      state: "",
      title: "",
    },
    isFlexi: false,
    pricePerItem: "",
    price: "",
    addOns: [],
    quantity: 0,
  },
};

ItemList.propTypes = {
  itemData: PropTypes.shape({
    uniqueId: PropTypes.string,
    id: PropTypes.number,
    experience: PropTypes.shape({
      addOns: PropTypes.arrayOf(PropTypes.shape({})),
      description: PropTypes.string,
      favourited: PropTypes.bool,
      hasFlexi: PropTypes.shape({
        status: PropTypes.bool,
        massSlotId: PropTypes.number,
      }),
      isFlexi: PropTypes.bool,
      id: PropTypes.number,
      imgPath: PropTypes.string,
      importantInfo: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
      }),
      maxNoPax: PropTypes.number,
      minNoPax: PropTypes.number,
      noReviews: PropTypes.number,
      pricePerItem: PropTypes.string,
      quantity: PropTypes.number,
      ratings: PropTypes.number,
      state: PropTypes.string,
      title: PropTypes.string,
    }),
    isFlexi: PropTypes.bool,
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    pricePerItem: PropTypes.string,
    quantity: PropTypes.number,
  }),
  onToggleFavourite: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
  onQuantityUpdate: PropTypes.func.isRequired,
  setShowAddOnModal: PropTypes.func.isRequired,
};

export default ItemList;
