import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  styled,
  Typography,
  Icon,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
  Link,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import { cartSelector } from "../../../services/cart/cart-slice.service";
import AddToCartModalLoader from "../loader/add-to-cart-modal-loader.component";
import BackgroundButton from "../../../components/button/background-button.component";
import routes from "../../../components/navigation/routes";
import BorderButton from "../../../components/button/border-button.component";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";
import YouMightAlsoLikeCartCardLoader from "../loader/you-might-also-like-cart-card-loader.component";
import { isLogin } from "../../../infrastructure/utils";
import { profileSelector } from "../../../services/profile/profile-slice.service";
import ExperiencesItem from "../../experiences-listing/component/experiences-listing/experiences-item.component";

const GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size12,
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "350px" : "980px",
  outline: "none",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const SuccessIcon = styled(Icon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.ui.success,
  marginTop: "-5px",
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size12,
}));

const DetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
}));

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
});

const RowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "100%",
  flexDirection: "row",
});

const Minorbox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingRight: "0px",
});

const SuccessTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  height: "25px",
  width: "25px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

function AddToCartPopUpModal({ showModal, setShowModal, cartItemAdded }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1000"));
  const { addToCartObj } = useSelector(cartSelector);
  const { experiencesSuggestionsObj } = useSelector(experienceSelector);
  const { profileObj } = useSelector(profileSelector);
  const history = useHistory();

  useEffect(() => {
    setShowModal(false);
  }, []);

  const renderContent = () => {
    if (isLogin()) {
      if (addToCartObj.status === "succeeded") {
        if (isMobile) {
          return (
            <>
              <Grid item xs={12}>
                <img
                  src={addToCartObj.data[0].experience.imgPath}
                  alt="experience-cover"
                  width="100%"
                  style={{ width: "100%", borderRadius: theme.shape.borderRadius[0] }}
                />
              </Grid>
              <Grid item xs={12}>
                <DetailsBox isMobile={isMobile}>
                  <TitleText>{addToCartObj.data[0].experience.title}</TitleText>
                  <Minorbox>
                    <StyledRatingBox>
                      <ReactStars
                        activeColor={theme.palette.colors.ui.star}
                        size={15}
                        edit={false}
                        value={parseFloat(addToCartObj.data[0].experience.ratings)}
                        color={theme.palette.colors.ui.border}
                        isHalf={true}
                      />
                      <Spacer size="xs" position="left" />
                      <GreyText>{`${addToCartObj.data[0].experience.ratings} (${addToCartObj.data[0].experience.noReviews})`}</GreyText>
                    </StyledRatingBox>
                    <BoldPrimaryColorText>{`Qty: ${addToCartObj.data[0].quantity}`}</BoldPrimaryColorText>
                  </Minorbox>
                  {addToCartObj.data[0].addOns.length > 0 && (
                    <>
                      <Spacer size="s" position="top" />
                      <PrimaryColorText variant="body2">Add On(s)</PrimaryColorText>
                      {addToCartObj.data[0].addOns.map((item) => (
                        <SpaceBetweenRowBox key={item.id}>
                          <GreyColorText>{item.label}</GreyColorText>
                          <PrimaryColorText>{`${item.quantity} x RM ${item.pricePerAddOn}`}</PrimaryColorText>
                        </SpaceBetweenRowBox>
                      ))}
                    </>
                  )}
                </DetailsBox>
              </Grid>
              <Grid item xs={12}>
                <SeperateLine />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <ButtonBox>
                      <Spacer size="m" position={isMobile ? "top" : "left"} />
                      <Box sx={{ width: "100%", minWidth: "200px" }}>
                        <BorderButton
                          handleSubmit={() => {
                            setShowModal(false);
                          }}
                        >
                          <BrandColorText>Continue Shopping</BrandColorText>
                        </BorderButton>
                      </Box>
                    </ButtonBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBox>
                      <Spacer size="m" position={isMobile ? "top" : "left"} />

                      <BackgroundButton
                        isLoading={!profileObj.status === "succeeded"}
                        handleSubmit={() => {
                          if (profileObj.data.firstName) {
                            if (profileObj.data.emailVerified) {
                              history.push(routes.SECURECHECKOUT);
                            } else {
                              history.push(routes.VERIFICATIONCODE, {
                                email: profileObj.data.email,
                              });
                            }
                          } else {
                            history.push(routes.COMPLETEPROFILE);
                          }
                        }}
                      >
                        <WhiteColorText>Secure Checkout</WhiteColorText>
                      </BackgroundButton>
                    </ButtonBox>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }

        return (
          <Grid item xs={12}>
            <Grid container>
              <Grid item width="15%">
                <img
                  src={addToCartObj.data[0].experience.imgPath}
                  alt="experience-cover"
                  width="100%"
                  style={{ width: "100%", borderRadius: theme.shape.borderRadius[0] }}
                />
              </Grid>
              <Grid item width="85%">
                <DetailsBox>
                  <SpaceBetweenRowBox>
                    <Box>
                      <TitleText>{addToCartObj.data[0].experience.title}</TitleText>
                      <StyledRatingBox>
                        <ReactStars
                          activeColor={theme.palette.colors.ui.star}
                          size={15}
                          edit={false}
                          value={parseFloat(addToCartObj.data[0].experience.ratings)}
                          color={theme.palette.colors.ui.border}
                          isHalf={true}
                        />
                        <Spacer size="xs" position="left" />
                        <GreyText>{`${addToCartObj.data[0].experience.ratings} (${addToCartObj.data[0].experience.noReviews})`}</GreyText>
                      </StyledRatingBox>
                      <BoldPrimaryColorText>{`Qty: ${addToCartObj.data[0].quantity}`}</BoldPrimaryColorText>
                      {addToCartObj.data[0].addOns.length > 0 && (
                        <>
                          <Spacer size="s" position="top" />
                          <PrimaryColorText variant="body2">Add On(s)</PrimaryColorText>
                          {addToCartObj.data[0].addOns.map((item) => (
                            <SpaceBetweenRowBox key={item.id}>
                              <GreyColorText sx={{ width: "200px" }}>{item.label}</GreyColorText>
                              <Spacer size="l" position="left" />
                              <PrimaryColorText>{`${item.quantity} x RM ${item.pricePerAddOn}`}</PrimaryColorText>
                            </SpaceBetweenRowBox>
                          ))}
                        </>
                      )}
                    </Box>

                    <RowBox>
                      <Spacer size="m" position="left" />
                      <Box sx={{ width: "100%", minWidth: "200px" }}>
                        <BorderButton
                          handleSubmit={() => {
                            setShowModal(false);
                          }}
                        >
                          <BrandColorText>Continue Shopping</BrandColorText>
                        </BorderButton>
                      </Box>
                      <Spacer size="m" position="left" />

                      <BackgroundButton
                        width="180px"
                        isLoading={!profileObj.status === "succeeded"}
                        handleSubmit={() => {
                          if (profileObj.data.firstName) {
                            if (profileObj.data.emailVerified) {
                              history.push(routes.SECURECHECKOUT);
                            } else {
                              history.push(routes.VERIFICATIONCODE, {
                                email: profileObj.data.email,
                              });
                            }
                          } else {
                            history.push(routes.COMPLETEPROFILE);
                          }
                        }}
                      >
                        <WhiteColorText>Secure Checkout</WhiteColorText>
                      </BackgroundButton>
                    </RowBox>
                  </SpaceBetweenRowBox>
                </DetailsBox>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      return <AddToCartModalLoader />;
    }

    if (cartItemAdded.experience) {
      if (isMobile) {
        return (
          <>
            <Grid item xs={12}>
              <img
                src={cartItemAdded.experience.imgPath}
                alt="experience-cover"
                width="100%"
                style={{ width: "100%", borderRadius: theme.shape.borderRadius[0] }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailsBox isMobile={isMobile}>
                <TitleText>{cartItemAdded.experience.title}</TitleText>
                <Minorbox>
                  <StyledRatingBox>
                    <ReactStars
                      activeColor={theme.palette.colors.ui.star}
                      size={15}
                      edit={false}
                      value={parseFloat(cartItemAdded.experience.ratings)}
                      color={theme.palette.colors.ui.border}
                      isHalf={true}
                    />
                    <Spacer size="xs" position="left" />
                    <GreyText>{`${cartItemAdded.experience.ratings} (${cartItemAdded.experience.noReviews})`}</GreyText>
                  </StyledRatingBox>
                  <BoldPrimaryColorText>{`Qty: ${cartItemAdded.quantity}`}</BoldPrimaryColorText>
                </Minorbox>
                {cartItemAdded.experience.addOns.length > 0 && (
                  <>
                    <Spacer size="s" position="top" />
                    <PrimaryColorText variant="body2">Add On(s)</PrimaryColorText>
                    {cartItemAdded.experience.addOns.map((item) => (
                      <SpaceBetweenRowBox key={item.id}>
                        <GreyColorText>{item.label}</GreyColorText>
                        <PrimaryColorText>{`${item.quantity} x RM ${item.price}`}</PrimaryColorText>
                      </SpaceBetweenRowBox>
                    ))}
                  </>
                )}
              </DetailsBox>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12}>
                  <ButtonBox>
                    <Spacer size="m" position={isMobile ? "top" : "left"} />
                    <Box sx={{ width: "100%", minWidth: "200px" }}>
                      <BorderButton
                        handleSubmit={() => {
                          setShowModal(false);
                        }}
                      >
                        <BrandColorText>Continue Shopping</BrandColorText>
                      </BorderButton>
                    </Box>
                  </ButtonBox>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBox>
                    <Spacer size="m" position={isMobile ? "top" : "left"} />
                    <Link style={{ textDecoration: "none" }} href={routes.AUTH}>
                      <BackgroundButton>
                        <WhiteColorText>Secure Checkout</WhiteColorText>
                      </BackgroundButton>
                    </Link>
                  </ButtonBox>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      }
      return (
        <Grid item xs={12}>
          <Grid container>
            <Grid item width="20%">
              <img
                src={cartItemAdded.experience.imgPath}
                alt="experience-cover"
                width="100%"
                style={{ width: "100%", borderRadius: theme.shape.borderRadius[0] }}
              />
            </Grid>
            <Grid item width="80%">
              <DetailsBox>
                <SpaceBetweenRowBox>
                  <Box>
                    <TitleText>{cartItemAdded.experience.title}</TitleText>
                    <StyledRatingBox>
                      <ReactStars
                        activeColor={theme.palette.colors.ui.star}
                        size={15}
                        edit={false}
                        value={parseFloat(cartItemAdded.experience.ratings)}
                        color={theme.palette.colors.ui.border}
                        isHalf={true}
                      />
                      <Spacer size="xs" position="left" />
                      <GreyText>{`${cartItemAdded.experience.ratings} (${cartItemAdded.experience.noReviews})`}</GreyText>
                    </StyledRatingBox>
                    <BoldPrimaryColorText>{`Qty: ${cartItemAdded.quantity}`}</BoldPrimaryColorText>
                    {cartItemAdded.experience.addOns.length > 0 && (
                      <>
                        <Spacer size="s" position="top" />
                        <PrimaryColorText variant="body2">Add On(s)</PrimaryColorText>
                        {cartItemAdded.experience.addOns.map((item) => (
                          <SpaceBetweenRowBox key={item.id}>
                            <GreyColorText>{item.label}</GreyColorText>
                            <PrimaryColorText>{`${item.quantity} x RM ${item.price}`}</PrimaryColorText>
                          </SpaceBetweenRowBox>
                        ))}
                      </>
                    )}
                  </Box>

                  <RowBox>
                    <Spacer size="m" position="left" />
                    <Box sx={{ width: "100%", minWidth: "200px" }}>
                      <BorderButton
                        handleSubmit={() => {
                          setShowModal(false);
                        }}
                      >
                        <BrandColorText>Continue Shopping</BrandColorText>
                      </BorderButton>
                    </Box>
                    <Spacer size="m" position="left" />
                    <Link style={{ textDecoration: "none" }} href={routes.AUTH}>
                      <BackgroundButton width="180px">
                        <WhiteColorText>Secure Checkout</WhiteColorText>
                      </BackgroundButton>
                    </Link>
                  </RowBox>
                </SpaceBetweenRowBox>
              </DetailsBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return <AddToCartModalLoader />;
  };

  const renderYouMightAlsoLike = () => {
    if (experiencesSuggestionsObj.status === "succeeded") {
      return (
        experiencesSuggestionsObj.data.length > 0 && (
          <Grid item xs={12}>
            <TitleText>Other Similar Options</TitleText>

            <Grid container spacing={2}>
              {experiencesSuggestionsObj.data.map((item) => (
                <Grid item xs={4} key={item.experienceId}>
                  <ExperiencesItem
                    isShowFavourite={false}
                    data={item}
                    experienceId={item.experienceId}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
      );
    }
    return (
      <Grid item xs={12}>
        <TitleText>You might also like</TitleText>
        <Spacer position="top" size="m" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <YouMightAlsoLikeCartCardLoader />
          </Grid>
          <Grid item xs={4}>
            <YouMightAlsoLikeCartCardLoader />
          </Grid>
          <Grid item xs={4}>
            <YouMightAlsoLikeCartCardLoader />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopTitleBox>
              <SuccessTitleBox>
                <SuccessIcon>
                  <StyledCheckCircleIcon />
                </SuccessIcon>
                <Spacer size="s" position="left" />
                <TitleText
                  sx={{
                    fontSize: theme.fonts.fontSizes.size24,
                    fontWeight: theme.fonts.fontWeights.bold,
                  }}
                >
                  Added to cart
                </TitleText>
              </SuccessTitleBox>
              <CloseIconButton onClick={() => setShowModal(false)}>
                <CloseIcon sx={{ height: "25px", width: "25px" }} />
              </CloseIconButton>
            </TopTitleBox>
          </Grid>
          {renderContent()}
          {!isMobile && (
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
          )}

          {!isMobile && renderYouMightAlsoLike()}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddToCartPopUpModal.defaultProps = {
  cartItemAdded: {
    experience: {
      experienceId: null,
      imgPath: "",
      noReviews: null,
      price: "",
      ratings: 0,
      state: "",
      title: "",
      type: "",
    },
    quantity: 0,
  },
};

AddToCartPopUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  cartItemAdded: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    experience: PropTypes.shape({
      experienceId: PropTypes.number,
      imgPath: PropTypes.string,
      noReviews: PropTypes.number,
      price: PropTypes.string,
      ratings: PropTypes.number,
      state: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      addOns: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    quantity: PropTypes.number,
  }),
};

export default AddToCartPopUpModal;
