import React, { useContext, useState } from "react";
import { Box, Button, Drawer, Grid, styled, Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import YoloLogo from "../../../assets/images/yolo_logo";
import Form from "../../../components/forms/form.component";
import FormDateTime from "./date-time-form.component";
import Spacer from "../../../components/utils/spacer.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";
import { isLogin } from "../../../infrastructure/utils";
import { emailCheck } from "../../../services/auth/auth-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import FormDebounceFieldText from "../../auth/components/sign_up/form-debounce-field-text.component";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.colors.bg.white,
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const ButtonText = styled(Button)(({ theme }) => ({
  marginTop: "-3px",
  height: "13px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
  },
}));

const BottomBar = styled(Box)(({ theme }) => ({
  background: theme.palette.colors.bg.white,
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

const validationSchema = Yup.object().shape({
  date: Yup.string().required().label("Date").nullable(),
  slotId: Yup.string().required().label("Time"),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function ExperienceDetailMobileDrawer({ openDrawer, setOpenDrawer, currentStep, setCurrentStep }) {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState("");
  const { setFieldValue } = useFormikContext();
  const { giftVoucherPreviewObj } = useSelector(voucherSelector);
  const [errorMessage, setErrorMessage] = useState("");
  const createSnackBar = useContext(SnackbarContext);

  const getAddOns = () => {
    const addOnsString = [];
    giftVoucherPreviewObj.data.orderItem.addOns.map((item) =>
      addOnsString.push(`${item.label}: ${item.quantity} x RM ${item.price}`),
    );
    return addOnsString;
  };

  const onSubmitDateTime = (value) => {
    setFieldValue("slotId", value.slotId);
    setCurrentStep(3);
  };

  const onChangeEmail = (email) => {
    setErrorMessage("");
    dispatch(emailCheck(email)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue("email", email.email);
      }
      if (meta.requestStatus === "rejected") {
        setErrorMessage(error.message);
        setFieldValue("email", "");
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderSignUpForm = () => (
    <>
      <Grid item xs={12}>
        <GreyText>First Name</GreyText>
        <FormFieldText name="firstName" placeholder="Enter First Name" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Last Name</GreyText>
        <FormFieldText name="lastName" placeholder="Enter Last Name" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Email</GreyText>
        <Form
          validationSchema={emailValidationSchema}
          initialValues={{ email: "" }}
          onSubmit={onChangeEmail}
        >
          <FormDebounceFieldText
            name="email"
            placeholder="Enter Your Email Address"
            errorMessage={errorMessage}
          />
        </Form>
      </Grid>
      <Grid item xs={12}>
        <GreyText>Password</GreyText>
        <FormFieldText name="password" placeholder="Enter Your Password" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Confirm Password</GreyText>
        <FormFieldText type="password" name="confirmPassword" placeholder="Confirm Your Password" />
      </Grid>
    </>
  );

  const renderStepTwo = () => (
    <>
      <Grid container padding={2} spacing={5}>
        <Grid item xs={12}>
          <ButtonText
            onClick={() => {
              setOpenDrawer(false);
              setCurrentStep(1);
            }}
          >
            <GreyText>{"< Back"}</GreyText>
          </ButtonText>
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
            Set the date and time.
          </PrimaryColorText>
        </Grid>
      </Grid>
      <Spacer position="top" size="xl" />
      <Form
        onSubmit={onSubmitDateTime}
        initialValues={{ date: null, slotId: "" }}
        validationSchema={validationSchema}
      >
        <FormDateTime setSelectedTime={setSelectedTime} />
      </Form>
    </>
  );

  const renderStepThree = () => (
    <>
      <Grid container padding={2} spacing={3} paddingBottom="150px">
        <Grid item xs={12}>
          <ButtonText
            onClick={() => {
              if (giftVoucherPreviewObj.data.gift.isFlexi) {
                setOpenDrawer(false);
                setCurrentStep(1);
              } else {
                setCurrentStep(2);
              }
            }}
          >
            <GreyText>{"< Back"}</GreyText>
          </ButtonText>
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
            Booking Details
          </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText>{giftVoucherPreviewObj.data.experience.title}</PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        {giftVoucherPreviewObj.data.gift.isFlexi ? (
          <Grid item xs={12}>
            <PrimaryColorText textAlign="justify">
              This experience is a flexi booking experience, you can redeem anytime. Please show the
              unique code to the merchant to verify your booking.
            </PrimaryColorText>
            <Spacer size="m" position="top" />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <PrimaryColorText>{selectedTime}</PrimaryColorText>
            <ButtonText variant="contained" onClick={() => setCurrentStep(2)}>
              <BrandColorText>Change</BrandColorText>
            </ButtonText>
          </Grid>
        )}

        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        {!isLogin() && (
          <>
            <Grid item xs={12}>
              <PrimaryColorText>Sign up to place the booking</PrimaryColorText>
            </Grid>
            {renderSignUpForm()}
          </>
        )}
        <Grid item xs={12}>
          <PrimaryColorText>Do you have any questions for the merchant?</PrimaryColorText>
          <Spacer size="s" position="top" />
          <FormFieldTextMultiline name="notes" placeholder="Your Question" />
        </Grid>
      </Grid>
      <BottomBar sx={{ boxShadow: 3 }}>
        <FormSubmitButton>
          <WhiteColorText>{!isLogin() && "Sign Up &"} Place Booking</WhiteColorText>
        </FormSubmitButton>
      </BottomBar>
    </>
  );

  const renderRefund = () => (
    <>
      <Grid container padding={2} spacing={3} paddingBottom="150px">
        <Grid item xs={12}>
          <ButtonText
            onClick={() => {
              setOpenDrawer(false);
              setCurrentStep(1);
            }}
          >
            <GreyText>{"< Back"}</GreyText>
          </ButtonText>
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText variant="h5" sx={{ textAlign: "center" }}>
            Booking Details
          </PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText>{giftVoucherPreviewObj.data.experience.title}</PrimaryColorText>
        </Grid>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <PrimaryColorText textAlign="justify">
            The exchanged credits will be reflected in your account credits.
          </PrimaryColorText>
          <Spacer />
          <PrimaryColorText textAlign="justify" fontWeight="bold">
            {giftVoucherPreviewObj.data.orderItem.quantity} x RM{" "}
            {giftVoucherPreviewObj.data.orderItem.pricePerItem}
          </PrimaryColorText>
          {getAddOns().length > 0 && (
            <>
              <Spacer size="s" position="top" />
              <PrimaryColorText>Add On(s)</PrimaryColorText>
              {getAddOns().map((item) => (
                <GreyText variant="body2" key={item}>
                  {item}
                </GreyText>
              ))}
            </>
          )}
          <Spacer size="s" position="top" />
          <Grid container justifyContent="space-between">
            <PrimaryColorText textAlign="justify">Subtotal:</PrimaryColorText>
            <PrimaryColorText textAlign="justify">
              RM {giftVoucherPreviewObj.data.orderItem.subtotal}
            </PrimaryColorText>
          </Grid>

          {giftVoucherPreviewObj.data.orderItem.discount &&
            giftVoucherPreviewObj.data.orderItem.discount !== "0.00" && (
              <Grid container justifyContent="space-between">
                <PrimaryColorText textAlign="justify"> Discount: </PrimaryColorText>
                <PrimaryColorText textAlign="justify">
                  - RM {giftVoucherPreviewObj.data.orderItem.discount}
                </PrimaryColorText>
              </Grid>
            )}
          <Grid container justifyContent="space-between">
            <PrimaryColorText textAlign="justify">Total Exchanged Credits:</PrimaryColorText>{" "}
            <PrimaryColorText textAlign="justify">
              RM {giftVoucherPreviewObj.data.orderItem.total}
            </PrimaryColorText>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        {!isLogin() && (
          <>
            <Grid item xs={12}>
              <PrimaryColorText>Sign up to exchange the booking</PrimaryColorText>
            </Grid>
            {renderSignUpForm()}
          </>
        )}
        <Grid item xs={12}>
          <PrimaryColorText>Do you have any questions for the merchant?</PrimaryColorText>
          <Spacer size="s" position="top" />
          <FormFieldTextMultiline name="notes" placeholder="Your Question" />
        </Grid>
      </Grid>
      <BottomBar sx={{ boxShadow: 3 }}>
        <FormSubmitButton>
          <WhiteColorText>{!isLogin() && "Sign Up &"} Exchange to Credits</WhiteColorText>
        </FormSubmitButton>
      </BottomBar>
    </>
  );

  return (
    <StyledDrawer
      anchor="right"
      variant="temporary"
      open={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
        setCurrentStep(1);
      }}
    >
      <Toolbar>
        <Box sx={{ marginLeft: -1 }}>
          <YoloLogo />
        </Box>
      </Toolbar>
      {currentStep === 2 && renderStepTwo()}
      {currentStep === 3 && renderStepThree()}
      {currentStep === "refund" && renderRefund()}
    </StyledDrawer>
  );
}

ExperienceDetailMobileDrawer.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default ExperienceDetailMobileDrawer;
