import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  width: "100%",
});

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});

function MiddleBox() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={isMobile ? 12 : 4} paddingTop={isMobile ? "30px" : 0}>
      <MainBox>
        <DetailsBox>
          <CustomSkeleton width="90%" height="25px" />
          <Spacer size="s" position="top" />
          <CustomSkeleton width="90%" height="25px" />
        </DetailsBox>
        <DetailsBox>
          <CustomSkeleton width="90%" height="25px" />
          <Spacer size="s" position="top" />
          <CustomSkeleton width="90%" height="25px" />
        </DetailsBox>
        <DetailsBox>
          <CustomSkeleton width="90%" height="25px" />
          <Spacer size="s" position="top" />
          <CustomSkeleton width="90%" height="25px" />
        </DetailsBox>
      </MainBox>
    </Grid>
  );
}

export default MiddleBox;
