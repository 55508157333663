import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  giftVoucherPreview,
  redeemGiftVoucher,
  refundGiftVoucher,
  voucherSelector,
} from "../../../services/voucher/voucher-slice.service";
import Highlights from "../../experiences-details/components/highlights.component";
import Overview from "../../experiences-details/components/overview.component";
import LocationMap from "../../experiences-details/components/location-map.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import ExperienceDetailLoader from "../loader/experience-detail-loader.component";
import ExperienceDetailSideBox from "../components/experience-detail-side-box.component";
import Form from "../../../components/forms/form.component";
import BackgroundButton from "../../../components/button/background-button.component";
import ExperienceDetailMobileDrawer from "../components/experience-detail-mobile-drawer.component";
import routes from "../../../components/navigation/routes";
import Loading from "../../../components/notification/backdrop-loading.component";
import ConfirmationModal from "../../purchasing/components/confirmation-modal.component";
import { isLogin } from "../../../infrastructure/utils";
import ExperienceImageGrid from "../../experiences-details/components/experience-image-grid.component";
import ExperienceImageCarousel from "../../experiences-details/components/experience-image-carousel.component";
import { register } from "../../../services/auth/auth-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import BorderButton from "../../../components/button/border-button.component";

const BottomBar = styled(Box)(({ theme }) => ({
  background: theme.palette.colors.bg.white,
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
  fontWeight: theme.fonts.fontWeights.bold,
  overflowWrap: "anywhere",
  lineheight: "20px",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size20,
}));

const guestValidationSchema = Yup.object().shape({
  slotId: Yup.string().nullable().label("Time"),
  giftVoucher: Yup.string().required().label("Gift Voucher"),
  isRefund: Yup.bool().required().label("Is Refund"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
  notes: Yup.string().label("Question"),
});

const validationSchema = Yup.object().shape({
  slotId: Yup.string().nullable().label("Time"),
  giftVoucher: Yup.string().required().label("Gift Voucher"),
  isRefund: Yup.bool().required().label("Is Refund"),
  notes: Yup.string().label("Question"),
});

function VoucherExperienceDetailScreen(props) {
  const newProps = props;
  const { giftVoucher } = newProps.history.location.state;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down("990"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { giftVoucherPreviewObj } = useSelector(voucherSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    if (giftVoucher === "" || giftVoucher === undefined) {
      history.push(routes.VOUCHERREDEMPTION);
    }

    dispatch(giftVoucherPreview({ voucherCode: giftVoucher })).then(({ meta }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Gift voucher code invalid",
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onSubmitForm = (values) => {
    if (values.isRefund) {
      setShowRefundModal(true);
    } else {
      setShowModal(true);
    }
    setSubmitValues(values);
  };

  const commenceBooking = () => {
    formRef.current.setFieldValue("isRefund", false);
    formRef.current.setFieldValue("email", "");
    if (giftVoucherPreviewObj.data.gift.isFlexi) {
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  };

  const onConfirmRedeemVoucher = () => {
    setShowModal(false);
    setIsLoading(true);
    dispatch(redeemGiftVoucher({ voucherCode: submitValues.giftVoucher, ...submitValues })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          history.push(routes.VOUCHERREDEEMED);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onConfirmRefundVoucher = () => {
    setShowRefundModal(false);
    setIsLoading(true);
    dispatch(refundGiftVoucher({ voucherCode: submitValues.giftVoucher })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          history.push(routes.ACCOUNTCREDITS);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const signUp = () => {
    setShowModal(false);
    setShowRefundModal(false);
    setIsLoading(true);
    dispatch(register(submitValues)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        if (submitValues.isRefund) {
          onConfirmRefundVoucher();
        } else {
          onConfirmRedeemVoucher();
        }
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderExperienceDetail = () => {
    if (giftVoucherPreviewObj.status === "succeeded") {
      return (
        <>
          {isMobile && (
            <Grid item xs={12}>
              <StyledTitle>{giftVoucherPreviewObj.data.experience.title}</StyledTitle>
              <GreyText>{`In ${giftVoucherPreviewObj.data.experience.state}`}</GreyText>
            </Grid>
          )}
          <Grid sx={{ width: isTablet ? "100%" : null, flex: 1 }} item>
            {isMobile ? (
              <ExperienceImageCarousel itemList={giftVoucherPreviewObj.data.experience.banners} />
            ) : (
              <>
                <StyledTitle>{giftVoucherPreviewObj.data.experience.title}</StyledTitle>
                <GreyText>{`In ${giftVoucherPreviewObj.data.experience.state}`}</GreyText>
                <ExperienceImageGrid itemList={giftVoucherPreviewObj.data.experience.banners} />
              </>
            )}
          </Grid>

          {!isMobile && (
            <Grid sx={{ width: isTablet ? "100%" : "380px" }} item>
              <ExperienceDetailSideBox />
            </Grid>
          )}
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Highlights itemData={giftVoucherPreviewObj.data.experience.overview} />
          <Overview itemData={giftVoucherPreviewObj.data.experience.importantInfo} />
          <LocationMap itemData={giftVoucherPreviewObj.data.experience} />
        </>
      );
    }
    return <ExperienceDetailLoader />;
  };

  return (
    <Form
      innerRef={formRef}
      validationSchema={isLogin() ? validationSchema : guestValidationSchema}
      onSubmit={onSubmitForm}
      initialValues={{
        slotId: "",
        giftVoucher,
        isRefund: false,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        notes: "",
        type: "experience",
      }}
    >
      <ConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmit={isLogin() ? onConfirmRedeemVoucher : signUp}
        message="Please confirm your booking details are correct."
        buttonText="Confirm"
      />
      <ConfirmationModal
        showModal={showRefundModal}
        setShowModal={setShowRefundModal}
        onSubmit={isLogin() ? onConfirmRefundVoucher : signUp}
        message="Are you confirm to exchange this gift experience voucher to credits?"
        buttonText="Confirm"
      />
      <Loading isLoading={isLoading} />
      <Grid
        container
        overflow={isMobile ? "hidden" : null}
        paddingBottom={isMobile ? "180px" : "0px"}
        padding={2}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
        rowSpacing={5}
        columnSpacing={2}
      >
        {renderExperienceDetail()}
      </Grid>
      {isMobile && giftVoucherPreviewObj.status === "succeeded" && (
        <>
          <BottomBar sx={{ boxShadow: 3 }}>
            <BackgroundButton
              handleSubmit={() => {
                setOpenDrawer(true);
                commenceBooking();
              }}
            >
              Commence Booking
            </BackgroundButton>
            <Spacer />
            <BorderButton
              handleSubmit={() => {
                setOpenDrawer(true);
                formRef.current.setFieldValue("isRefund", true);
                formRef.current.setFieldValue("email", "");
                setCurrentStep("refund");
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                Exchange to Credits
              </Typography>
            </BorderButton>
          </BottomBar>
          <ExperienceDetailMobileDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </>
      )}
    </Form>
  );
}

export default VoucherExperienceDetailScreen;
