import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  addToCart,
  cartSelector,
  getCartItemDetails,
  getCartList,
  removeCartItem,
  updateCartItemQty,
} from "../../../services/cart/cart-slice.service";
import PurchaseBox from "../components/purchase-box.component";
import PurchaseBoxLoader from "../loader/purchase-box-loader.component";
import ItemList from "../components/item-list.component";
import CartItemsLoader from "../loader/cart-items-loader.component";
import RemoveItemPopUpModal from "../components/remove-item-modal/remove-item-pop-up-modal.component";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { getProfile } from "../../../services/profile/profile-slice.service";
import BackgroundButton from "../../../components/button/background-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { isLogin } from "../../../infrastructure/utils";
import {
  experienceSelector,
  getLatestCartDetails,
} from "../../../services/experiences/experience-slice.service";
import {
  guestAddToCart,
  guestSelector,
  guestToggleFavourite,
  onCartAmountChanged,
  onRemoveCartItems,
} from "../../../services/guest/guest-slice.service";
import {
  createFavourite,
  removeFavourite,
} from "../../../services/favourite/favourites-slice.service";
import Form from "../../../components/forms/form.component";
import CartAddOnsModal from "../components/cart-add-ons-modal.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  marginTop: 50,
}));

const StyledShoppingIcon = styled(ShoppingCartIcon)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  height: "75px",
  width: "75px",
}));

const StyledGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const EmptyListBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

const TransparentBox = styled(Box)({
  width: "100%",
  marginTop: 50,
});

const validationSchema = Yup.object().shape({
  addOns: Yup.array().of(Yup.object().shape({})),
});

function MyCart() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("970"));
  const [showModal, setShowModal] = useState(false);
  const { getCartListObj } = useSelector(cartSelector);
  const { latestCartDetailsObj } = useSelector(experienceSelector);
  const [countDown, setCountDown] = useState(0);
  const [updateValues, setUpdateValues] = useState();
  const [changingValue, setChangingValue] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const { guestCartList } = useSelector(guestSelector);
  const [removeCartItemDetails, setRemoveCartItemDetails] = useState({});
  let tempGuestCartList = guestCartList || [];
  const [showAddOnModal, setShowAddOnModal] = useState(false);

  const loadCartList = () => {
    dispatch(getCartList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const updateItemQuantity = () => {
    dispatch(updateCartItemQty(updateValues)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        loadCartList();
        setChangingValue(false);
        dispatch(getProfile());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onQuantityUpdate = (values) => {
    if (!isLogin()) {
      dispatch(onCartAmountChanged(values));
      return;
    }
    setChangingValue(true);
    setCountDown(3);
    setUpdateValues(values);
  };

  useEffect(() => {
    let interval = 0;
    let timer = 0;
    if (countDown > 0) {
      timer = setTimeout(() => {
        interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 1) {
              updateItemQuantity();
              clearInterval(interval);
            }
            return prev - 1;
          });
        });
      }, 300);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countDown]);

  const getAddOnsLength = () => {
    let total = 0;
    guestCartList.forEach((item) => {
      total += item.addOns.length;
    });
    return total;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLogin()) {
      loadCartList();
    }
    if (!isLogin()) {
      dispatch(getLatestCartDetails(guestCartList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, [getAddOnsLength()]);

  const onToggleFavourite = (values, isAddFavourite) => {
    if (!isLogin()) {
      dispatch(guestToggleFavourite({ addOns: [], quantity: 1, ...values }));
      return;
    }
    if (isAddFavourite) {
      dispatch(createFavourite([{ experienceId: values.experienceId }])).then(({ meta, error }) => {
        dispatch(getProfile());
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeFavourite({ experienceId: values.experienceId })).then(({ meta, error }) => {
      dispatch(getProfile());
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onToggleDelete = (values) => {
    if (!isLogin()) {
      setRemoveCartItemDetails(values);
      setShowModal(true);
      return;
    }
    dispatch(getCartItemDetails(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowModal(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onConfirmDelete = (values) => {
    if (!isLogin()) {
      dispatch(
        onRemoveCartItems({
          experienceId: values,
          uniqueId: removeCartItemDetails.uniqueId,
        }),
      );
      tempGuestCartList = tempGuestCartList.filter(
        (item) => item.uniqueId !== `${values}${removeCartItemDetails.isFlexi}`,
      );
      setShowModal(false);
      dispatch(getLatestCartDetails(tempGuestCartList)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
      return;
    }
    dispatch(removeCartItem(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowModal(false);
        loadCartList();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        dispatch(getProfile());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const updateCart = (values) => {
    if (!isLogin()) {
      dispatch(
        guestAddToCart({
          experienceId: values.experienceId,
          quantity: parseInt(values.quantity, 10),
          uniqueId: `${values.experienceId}${values.isFlexi}`,
          isFlexi: values.isFlexi,
          massSlotId: values.massSlotId,
          addOns: values.addOns,
          isCart: true,
        }),
      );
      setShowAddOnModal(false);
      return;
    }
    const addOns = [];
    values.addOns.map((item) => addOns.push({ ...item, id: item.experienceAddOnId || item.id }));
    dispatch(addToCart([{ ...values, addOns }])).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowAddOnModal(false);
        dispatch(getProfile());
        dispatch(getCartList());
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: `Failed to get data! ${error.message}`,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (isLogin() && getCartListObj.status === "succeeded") {
      if (getCartListObj.data.items.length > 0) {
        return (
          <>
            <Grid item xs={isMobile ? 12 : 9}>
              <SeperateLine />
              {getCartListObj.data.items.map((item) => (
                <ItemList
                  setShowAddOnModal={setShowAddOnModal}
                  key={item.id}
                  itemData={item}
                  onToggleFavourite={onToggleFavourite}
                  onToggleDelete={onToggleDelete}
                  onQuantityUpdate={onQuantityUpdate}
                />
              ))}
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <TransparentBox />
              <PurchaseBox changingValue={changingValue} />
            </Grid>
          </>
        );
      }
      return (
        <EmptyListBox>
          <SeperateLine />
          <StyledShoppingIcon />
          <Spacer size="m" position="top" />
          <StyledGreyText variant="h6">Your Cart is Empty</StyledGreyText>
          <Spacer size="l" position="top" />
          <BackgroundButton handleSubmit={() => history.push(routes.HOME)} width="300px">
            Continue Shopping
          </BackgroundButton>
        </EmptyListBox>
      );
    }

    if (!isLogin() && latestCartDetailsObj.status === "succeeded") {
      if (latestCartDetailsObj.data.length > 0) {
        return (
          <>
            <Grid item xs={isMobile ? 12 : 9}>
              <SeperateLine />
              {latestCartDetailsObj.data.map((item) => (
                <ItemList
                  setShowAddOnModal={setShowAddOnModal}
                  key={item.uniqueId}
                  itemData={item}
                  onToggleFavourite={onToggleFavourite}
                  onToggleDelete={onToggleDelete}
                  onQuantityUpdate={onQuantityUpdate}
                />
              ))}
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <TransparentBox />
              <PurchaseBox changingValue={changingValue} />
            </Grid>
          </>
        );
      }
      return (
        <EmptyListBox>
          <SeperateLine />
          <StyledShoppingIcon />
          <Spacer size="m" position="top" />
          <StyledGreyText variant="h6">Your Cart is Empty</StyledGreyText>
          <Spacer size="l" position="top" />
          <BackgroundButton handleSubmit={() => history.push(routes.HOME)} width="300px">
            Continue Shopping
          </BackgroundButton>
        </EmptyListBox>
      );
    }

    return (
      <>
        <Grid item xs={isMobile ? 12 : 9}>
          <SeperateLine />
          <CartItemsLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TransparentBox />
          <PurchaseBoxLoader />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        paddingBottom={isMobile ? "10px" : "0px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <StyledTitle variant={isMobile ? "h4" : "h3"}>My Cart</StyledTitle>
          </Grid>
        </Grid>
        <Form
          initialValues={{ addOns: [] }}
          onSubmit={updateCart}
          validationSchema={validationSchema}
        >
          {renderContent()}
          {showAddOnModal && (
            <CartAddOnsModal showModal={showAddOnModal} setShowModal={setShowAddOnModal} />
          )}
        </Form>
      </Grid>
      <RemoveItemPopUpModal
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirmDelete={onConfirmDelete}
        removeCartItemDetails={removeCartItemDetails}
      />
      <Spacer size="xxl" position="top" />
    </>
  );
}

export default MyCart;
