import React, { useContext, useEffect } from "react";
import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../../services/profile/profile-slice.service";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import { getOrders } from "../../../services/order/order-slice.service";
import OrderList from "../components/order-list.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

function MyOrderScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getOrders()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        paddingBottom: 5,
        backgroundColor: theme.palette.colors.bg.white,
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
      }}
    >
      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      <Grid item xs={isMobile ? 12 : 8}>
        <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
          <Grid item xs={12} paddingTop={4}>
            <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold}>
              Purchase History
            </PrimaryColorText>
          </Grid>

          <Grid
            spacing={4}
            container
            sx={{
              paddingY: 3,
            }}
          >
            <OrderList />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MyOrderScreen;
