import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Box, useTheme } from "@mui/material";
import Loader from "react-js-loader";

function FormSubmitButton({ children, width, disabled, isLoading }) {
  const theme = useTheme();
  const { handleSubmit } = useFormikContext();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          textTransform: "none",
          padding: 1,
          "&:hover": {
            backgroundColor: theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      {isLoading ? (
        <Button
          variant="contained"
          disabled
          sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
        >
          <Box
            sx={{ height: "25px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.white} size={30} />
          </Box>
        </Button>
      ) : (
        <Button
          variant="contained"
          disabled={disabled}
          onClick={handleSubmit}
          sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
        >
          {children}
        </Button>
      )}
    </Box>
  );
}

FormSubmitButton.defaultProps = {
  width: "100%",
  disabled: false,
  isLoading: false,
};

FormSubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FormSubmitButton;
