import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export default function BookingCardExperienceImage({ imagePath }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <img
      alt="exp-img"
      src={imagePath}
      style={{
        width: isMobile ? "130px" : "150px",
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        marginBottom: "5px",
      }}
    />
  );
}

BookingCardExperienceImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
};
