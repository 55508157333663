import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    width: "100%",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
    },
  },
}));

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

export default function DialogBox({
  isShow,
  title,
  message,
  setIsShowAlert,
  isConfirmHandle,
  buttonText,
  disabled,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setIsShowAlert(false);
  };

  const handleConfirm = () => {
    isConfirmHandle();
  };

  return (
    <Modal open={isShow} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </Grid>
          <Grid item xs={12} paddingTop="20px" paddingBottom="10px">
            <Typography variant="h5" textAlign="center">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: theme.palette.colors.text.secondary, textAlign: "center" }}>
              {message}
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop="100px">
            <StyledButtonBox>
              <Button variant="contain" disabled={disabled} onClick={handleConfirm}>
                <Typography
                  sx={{
                    color: theme.palette.colors.brand.white,
                    fontWeight: theme.fonts.fontWeights.bold,
                  }}
                  variant="h6"
                >
                  {disabled ? "Loading" : buttonText}
                </Typography>
              </Button>
            </StyledButtonBox>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

DialogBox.defaultProps = {
  title: "",
  message: "",
  buttonText: "",
  disabled: false,
};

DialogBox.propTypes = {
  isShow: PropTypes.bool.isRequired,
  setIsShowAlert: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  isConfirmHandle: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
};
