import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import YoloLogo from "../../../assets/images/yolo_logo";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import CompleteProfileForm from "../components/complete-profile-form.component";

function CompleteProfileScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        height: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YoloLogo />
              </Grid>
              <Spacer size="m" />
              <CompleteProfileForm />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default CompleteProfileScreen;
