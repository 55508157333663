import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isLogin } from "../../infrastructure/utils";
import { getReq, postReq } from "../api.services";

export const giftVoucherPreview = createAsyncThunk("users/voucher/preview", async (payload) => {
  const response = postReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
    }/gifts/preview`,
    payload,
  );
  return response;
});

export const redeemGiftVoucher = createAsyncThunk("users/voucher/redeem", async (payload) => {
  const { slotId, voucherCode, notes } = payload;
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/gifts/redeem`, {
    slotId,
    voucherCode,
    notes,
  });
  return response;
});

export const refundGiftVoucher = createAsyncThunk("users/voucher/refund", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/gifts/refund`, {
    voucherCode: payload.voucherCode,
  });
  return response;
});

export const getCreditGiftList = createAsyncThunk("users/voucher/credit/list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/gifts/options?type=credit`);
  return response;
});

export const creditGiftPreview = createAsyncThunk(
  "users/voucher/credit/preview",
  async (payload) => {
    const response = postReq(
      `${
        isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
      }/gifts/orders/preview`,
      payload,
    );
    return response;
  },
);

export const createCreditGiftVoucherBill = createAsyncThunk(
  "users/voucher/credit/bill/create",
  async (payload) => {
    const response = postReq(
      `${
        isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
      }/gifts/orders`,
      payload,
    );
    return response;
  },
);

export const creditGiftVoucherBillCheck = createAsyncThunk(
  "users/voucher/credit/bill/check",
  async (remoteBillId) => {
    const response = getReq(
      `${
        isLogin() ? process.env.REACT_APP_API_PRIVATE_V1 : process.env.REACT_APP_API_PUBLIC_V1
      }/gifts/orders/bills/remote/${remoteBillId}`,
    );
    return response;
  },
);

const voucherSlice = createSlice({
  name: "voucher",
  initialState: {
    giftVoucherPreviewObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    redeemGiftVoucherObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCreditGiftListObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    creditGiftPreviewObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    createCreditGiftVoucherBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    creditGiftVoucherBillCheckObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [giftVoucherPreview.pending]: (state) => {
      state.giftVoucherPreviewObj.status = "pending";
    },
    [giftVoucherPreview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.giftVoucherPreviewObj.status = "succeeded";
      state.giftVoucherPreviewObj.data = data;
      state.giftVoucherPreviewObj.successMessage = message;
    },
    [giftVoucherPreview.rejected]: (state, action) => {
      const { message } = action.error;

      state.giftVoucherPreviewObj.status = "failed";
      state.giftVoucherPreviewObj.errorMessage = message;
    },
    [redeemGiftVoucher.pending]: (state) => {
      state.redeemGiftVoucherObj.status = "pending";
    },
    [redeemGiftVoucher.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.redeemGiftVoucherObj.status = "succeeded";
      state.redeemGiftVoucherObj.data = data;
      state.redeemGiftVoucherObj.successMessage = message;
    },
    [redeemGiftVoucher.rejected]: (state, action) => {
      const { message } = action.error;

      state.redeemGiftVoucherObj.status = "failed";
      state.redeemGiftVoucherObj.errorMessage = message;
    },
    [getCreditGiftList.pending]: (state) => {
      state.getCreditGiftListObj.status = "pending";
    },
    [getCreditGiftList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCreditGiftListObj.status = "succeeded";
      state.getCreditGiftListObj.data = data;
      state.getCreditGiftListObj.successMessage = message;
    },
    [getCreditGiftList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCreditGiftListObj.status = "failed";
      state.getCreditGiftListObj.errorMessage = message;
    },
    [creditGiftPreview.pending]: (state) => {
      state.creditGiftPreviewObj.status = "pending";
    },
    [creditGiftPreview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.creditGiftPreviewObj.status = "succeeded";
      state.creditGiftPreviewObj.data = data;
      state.creditGiftPreviewObj.successMessage = message;
    },
    [creditGiftPreview.rejected]: (state, action) => {
      const { message } = action.error;

      state.creditGiftPreviewObj.status = "failed";
      state.creditGiftPreviewObj.errorMessage = message;
    },
    [createCreditGiftVoucherBill.pending]: (state) => {
      state.createCreditGiftVoucherBillObj.status = "pending";
    },
    [createCreditGiftVoucherBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createCreditGiftVoucherBillObj.status = "succeeded";
      state.createCreditGiftVoucherBillObj.data = data;
      state.createCreditGiftVoucherBillObj.successMessage = message;
    },
    [createCreditGiftVoucherBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.createCreditGiftVoucherBillObj.status = "failed";
      state.createCreditGiftVoucherBillObj.errorMessage = message;
    },
    [creditGiftVoucherBillCheck.pending]: (state) => {
      state.creditGiftVoucherBillCheckObj.status = "pending";
    },
    [creditGiftVoucherBillCheck.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.creditGiftVoucherBillCheckObj.status = "succeeded";
      state.creditGiftVoucherBillCheckObj.data = data;
      state.creditGiftVoucherBillCheckObj.successMessage = message;
    },
    [creditGiftVoucherBillCheck.rejected]: (state, action) => {
      const { message } = action.error;

      state.creditGiftVoucherBillCheckObj.status = "failed";
      state.creditGiftVoucherBillCheckObj.errorMessage = message;
    },
  },
});

export default voucherSlice.reducer;

// state
export const voucherSelector = (state) => state.voucher;
