import React, { useContext, useState } from "react";
import { Box, Typography, styled, Button, useTheme, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import BackgroundButton from "../../../components/button/background-button.component";
import { voucherSelector } from "../../../services/voucher/voucher-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import FormDateTime from "./date-time-form.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import { isLogin } from "../../../infrastructure/utils";
import { emailCheck } from "../../../services/auth/auth-slice.service";
import FormDebounceFieldText from "../../auth/components/sign_up/form-debounce-field-text.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import BorderButton from "../../../components/button/border-button.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledBorderBox = styled(Box)(({ theme }) => ({
  width: "95%",
  backgroundColor: "white",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const ButtonText = styled(Button)(({ theme }) => ({
  marginTop: "-3px",
  height: "13px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
  },
}));

const MainContainer = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-end",
  marginTop: "4px",
});

const validationSchema = Yup.object().shape({
  date: Yup.string().required().label("Date").nullable(),
  slotId: Yup.string().required().label("Time"),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function ExperienceDetailSideBox() {
  const dispatch = useDispatch();
  const { giftVoucherPreviewObj } = useSelector(voucherSelector);
  const [currentStep, setCurrentStep] = useState(1);
  const { setFieldValue, values } = useFormikContext();
  const [selectedTime, setSelectedTime] = useState("");
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState("");
  const createSnackBar = useContext(SnackbarContext);

  const commenceBooking = () => {
    setFieldValue("isRefund", false);
    setFieldValue("email", "");
    if (giftVoucherPreviewObj.data.gift.isFlexi) {
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  };

  const getAddOns = () => {
    const addOnsString = [];
    giftVoucherPreviewObj.data.orderItem.addOns.map((item) =>
      addOnsString.push(`${item.label}: ${item.quantity} x RM ${item.price}`),
    );
    return addOnsString;
  };

  const onSubmitDateTime = (value) => {
    setFieldValue("slotId", value.slotId);
    setCurrentStep(3);
  };

  const onChangeEmail = (email) => {
    setErrorMessage("");
    dispatch(emailCheck(email)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue("email", email.email);
      }
      if (meta.requestStatus === "rejected") {
        setErrorMessage(error.message);
        setFieldValue("email", "");
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderSignUpForm = () => (
    <>
      <GreyText>First Name</GreyText>
      <FormFieldText name="firstName" placeholder="Enter First Name" />

      <Spacer size="m" position="top" />
      <GreyText>Last Name</GreyText>
      <FormFieldText name="lastName" placeholder="Enter Last Name" />

      <Spacer size="m" position="top" />
      <GreyText>Email</GreyText>
      <Form
        validationSchema={emailValidationSchema}
        initialValues={{ email: values.email }}
        onSubmit={onChangeEmail}
      >
        <FormDebounceFieldText
          name="email"
          placeholder="Enter Your Email Address"
          errorMessage={errorMessage}
        />
      </Form>

      <Spacer size="m" position="top" />
      <GreyText>Password</GreyText>
      <FormFieldText name="password" placeholder="Enter Your Password" />

      <Spacer size="m" position="top" />
      <GreyText>Confirm Password</GreyText>
      <FormFieldText type="password" name="confirmPassword" placeholder="Confirm Your Password" />

      <Spacer size="m" position="top" />
    </>
  );

  if (currentStep === 2) {
    return (
      <MainContainer>
        <StyledBorderBox>
          <PrimaryColorText
            sx={{
              fontSize: theme.fonts.fontSizes.size20,
              fontWeight: theme.fonts.fontWeights.bold,
            }}
          >
            Select the date and time
          </PrimaryColorText>

          <Spacer size="s" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          <Form
            onSubmit={onSubmitDateTime}
            initialValues={{ date: null, slotId: "" }}
            validationSchema={validationSchema}
          >
            <FormDateTime setSelectedTime={setSelectedTime} />
          </Form>
        </StyledBorderBox>
        <Spacer size="m" position="top" />
        <Box sx={{ width: "95%", justifyContent: "flex-start" }}>
          <ButtonText variant="contained" onClick={() => setCurrentStep(1)}>
            <GreyText>{"< Back"}</GreyText>
          </ButtonText>
        </Box>
      </MainContainer>
    );
  }

  if (currentStep === 3) {
    return (
      <MainContainer>
        <StyledBorderBox>
          <PrimaryColorText
            sx={{
              fontSize: theme.fonts.fontSizes.size20,
              fontWeight: theme.fonts.fontWeights.bold,
            }}
          >
            Booking Details
          </PrimaryColorText>

          <Spacer size="s" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />

          {giftVoucherPreviewObj.data.gift.isFlexi ? (
            <>
              <PrimaryColorText textAlign="justify">
                This experience is a flexi booking experience, you can redeem anytime. Please show
                the unique code to the merchant to verify your booking.
              </PrimaryColorText>
              <Spacer size="m" position="top" />
            </>
          ) : (
            <>
              <PrimaryColorText>{selectedTime}</PrimaryColorText>
              <ButtonText variant="contained" onClick={() => commenceBooking()}>
                <BrandColorText>Change</BrandColorText>
              </ButtonText>
            </>
          )}

          <Spacer size="m" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          {!isLogin() && (
            <>
              <Spacer size="m" position="top" />
              <PrimaryColorText>Sign up to place the booking</PrimaryColorText>

              <Spacer size="m" position="top" />
              {renderSignUpForm()}
            </>
          )}

          <PrimaryColorText>Do you have any questions for the merchant?</PrimaryColorText>
          <Spacer size="s" position="top" />
          <FormFieldTextMultiline name="notes" placeholder="Your Question" />

          <Spacer size="m" position="top" />
          <FormSubmitButton>
            <WhiteColorText>{!isLogin() && "Sign Up &"} Place Booking</WhiteColorText>
          </FormSubmitButton>

          <Spacer size="m" position="top" />
          <Box sx={{ width: "95%", justifyContent: "flex-start" }}>
            <ButtonText
              variant="contained"
              onClick={() => {
                if (giftVoucherPreviewObj.data.gift.isFlexi) {
                  setCurrentStep(1);
                } else {
                  setCurrentStep(2);
                }
              }}
            >
              <GreyText>{"< Back"}</GreyText>
            </ButtonText>
          </Box>
        </StyledBorderBox>
      </MainContainer>
    );
  }

  if (currentStep === "refund") {
    return (
      <MainContainer>
        <StyledBorderBox>
          <PrimaryColorText
            sx={{
              fontSize: theme.fonts.fontSizes.size20,
              fontWeight: theme.fonts.fontWeights.bold,
            }}
          >
            Booking Details
          </PrimaryColorText>

          <Spacer size="s" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          <PrimaryColorText>
            The exchanged credits will be reflected in your account credits.
          </PrimaryColorText>
          <Spacer size="s" position="top" />

          <PrimaryColorText textAlign="justify" fontWeight="bold">
            {giftVoucherPreviewObj.data.orderItem.quantity} x RM{" "}
            {giftVoucherPreviewObj.data.orderItem.pricePerItem}
          </PrimaryColorText>
          {getAddOns().length > 0 && (
            <>
              <Spacer size="s" position="top" />
              <PrimaryColorText>Add On(s)</PrimaryColorText>
              {getAddOns().map((item) => (
                <GreyText variant="body2" key={item}>
                  {item}
                </GreyText>
              ))}
            </>
          )}
          <Spacer size="s" position="top" />
          <Grid container justifyContent="space-between">
            <PrimaryColorText textAlign="justify">Subtotal:</PrimaryColorText>
            <PrimaryColorText textAlign="justify">
              RM {giftVoucherPreviewObj.data.orderItem.subtotal}
            </PrimaryColorText>
          </Grid>

          {giftVoucherPreviewObj.data.orderItem.discount &&
            giftVoucherPreviewObj.data.orderItem.discount !== "0.00" && (
              <Grid container justifyContent="space-between">
                <PrimaryColorText textAlign="justify"> Discount: </PrimaryColorText>
                <PrimaryColorText textAlign="justify">
                  - RM {giftVoucherPreviewObj.data.orderItem.discount}
                </PrimaryColorText>
              </Grid>
            )}
          <Grid container justifyContent="space-between">
            <PrimaryColorText textAlign="justify">Total Exchanged Credits:</PrimaryColorText>{" "}
            <PrimaryColorText textAlign="justify">
              RM {giftVoucherPreviewObj.data.orderItem.total}
            </PrimaryColorText>
          </Grid>

          <Spacer size="m" position="top" />
          <SeperateLine />
          <Spacer size="m" position="top" />
          {!isLogin() && (
            <>
              <Spacer size="m" position="top" />
              <PrimaryColorText>Sign up to exchange the booking</PrimaryColorText>

              <Spacer size="m" position="top" />
              {renderSignUpForm()}
            </>
          )}

          <Spacer size="m" position="top" />
          <FormSubmitButton>
            <WhiteColorText>{!isLogin() && "Sign Up &"} Exchange to Credits</WhiteColorText>
          </FormSubmitButton>

          <Spacer size="m" position="top" />
          <Box sx={{ width: "95%", justifyContent: "flex-start" }}>
            <ButtonText variant="contained" onClick={() => setCurrentStep(1)}>
              <GreyText>{"< Back"}</GreyText>
            </ButtonText>
          </Box>
        </StyledBorderBox>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <StyledBorderBox>
        <PrimaryColorText
          sx={{
            fontSize: theme.fonts.fontSizes.size20,
            fontWeight: theme.fonts.fontWeights.bold,
          }}
        >
          Making a booking
        </PrimaryColorText>

        <Spacer size="s" position="top" />
        <SeperateLine />

        <Spacer size="m" position="top" />
        <GreyText>{giftVoucherPreviewObj.data.gift.voucherCode}</GreyText>
        <Spacer size="s" position="top" />
        <GreyText>{`Expiry: ${giftVoucherPreviewObj.data.gift.expireAt}`}</GreyText>
        <Spacer size="s" position="top" />
        <PrimaryColorText>{`Pax: ${giftVoucherPreviewObj.data.gift.noOfPax}`}</PrimaryColorText>
        <Spacer size="s" position="top" />
        <BackgroundButton width="100%" handleSubmit={() => commenceBooking()}>
          <WhiteColorText>Commence Booking</WhiteColorText>
        </BackgroundButton>
        <Spacer size="s" position="top" />
        <BorderButton
          width="100%"
          handleSubmit={() => {
            setFieldValue("isRefund", true);
            setFieldValue("email", "");
            setCurrentStep("refund");
          }}
        >
          <BrandColorText>Exchange to Credits</BrandColorText>
        </BorderButton>
      </StyledBorderBox>
    </MainContainer>
  );
}

export default ExperienceDetailSideBox;
