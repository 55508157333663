import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  styled,
  useTheme,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import FPXLogo from "../../../assets/images/fpx_logo";

import { profileSelector } from "../../../services/profile/profile-slice.service";

const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
});

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "lighter",
  marginLeft: "15px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  "&.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

function PaymentMethodCheckbox() {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(values.paymentMethod);
  const theme = useTheme();
  const { profileObj } = useSelector(profileSelector);

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue("paymentMethod", event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        <FormControlLabel
          value="others"
          control={<StyledRadio />}
          label={
            <LabelContainer>
              <FPXLogo />
              <LabelText>Online Banking / e-Wallet</LabelText>
            </LabelContainer>
          }
        />
        <SeperateLine />
        <FormControlLabel
          value="credits"
          control={<StyledRadio />}
          label={
            <LabelContainer>
              <AccountBalanceWalletIcon
                sx={{ color: theme.palette.colors.text.secondary, width: "60px", height: "30px" }}
              />
              <LabelText>{`Account Credit (RM ${profileObj.data.credits})`}</LabelText>
            </LabelContainer>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentMethodCheckbox;
