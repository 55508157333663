import React from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

const StyledArrowIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({ theme }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "18px",
    stroke: theme.palette.colors.brand.white,
    strokeWidth: 2,
  },
  color: theme.palette.colors.text.white,
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius[4],
  height: "35px",
  width: "35px",
  top: "45%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
  "&:hover": {
    color: theme.palette.colors.text.white,
    backgroundColor: isMobile ? "rgba(0,0,0,0.5)" : "rgba(255,255,255,0.5)",
  },
  "&:active": {
    color: theme.palette.colors.text.white,
    backgroundColor: isMobile ? "rgba(0,0,0,0.5)" : "rgba(255,255,255,0.5)",
  },
}));

const DotsBox = styled(Box)(({ theme }) => ({
  "&&.slick-dots li.slick-active button:before": {
    color: theme.palette.colors.brand.white,
    backgroundColor: theme.palette.colors.brand.white,
    opacity: 1,
    fontSize: "7px",
    lineHeight: "7px",
    width: "7px",
    height: "7px",
  },
  "&&.slick-dots li button::before": {
    border: `1px solid ${theme.palette.colors.brand.white}`,
    color: "transparent",
    opacity: 1,
    fontSize: "7px",
    lineHeight: "7px",
    borderRadius: "7px",
    width: "7px",
    height: "7px",
  },
  bottom: 0,
  left: "-20px",
  right: 0,
}));

const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  ":hover": {
    ".hover-arrow": {
      opacity: 1,
    },
  },
  ".hover-arrow": {
    opacity: isMobile ? 1 : 0,
  },
}));

function PrevArrow(arrowProps) {
  const { onClick } = arrowProps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledArrowIconBox
      onClick={onClick}
      sx={{ left: 5 }}
      className="hover-arrow"
      isMobile={isMobile}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

function NextArrow(arrowProps) {
  const { onClick } = arrowProps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledArrowIconBox
      onClick={onClick}
      sx={{ right: 5 }}
      className="hover-arrow"
      isMobile={isMobile}
    >
      <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

const settings = {
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  className: "react__slick__slider__parent",
  appendDots: (dots) => (
    <DotsBox>
      <ul>{dots}</ul>
    </DotsBox>
  ),
};

export default function ExperienceImageCarousel({ imageList }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <MainContainer isMobile={isMobile}>
      <Slider {...settings} dots={true} arrows={true}>
        {imageList.map((item) => (
          <Box
            key={uuidv4()}
            sx={{
              backgroundColor: theme.palette.colors.loading.imageBg,
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              overflow: "hidden",
            }}
          >
            <img width="100%" alt="exp-img" src={item} />
          </Box>
        ))}
      </Slider>
    </MainContainer>
  );
}

ExperienceImageCarousel.propTypes = {
  imageList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
