import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Grid, styled, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import UpdateNameForm from "../components/update-profile-form/update-name-form.component";
import UpdateGenderForm from "../components/update-profile-form/update-gender-form.component";
import UpdateDOBForm from "../components/update-profile-form/update-dateOfBirth-form.component";
import UpdatePhoneForm from "../components/update-profile-form/update-phone-form.component";
import {
  profileSelector,
  toggleNewsletter,
  updateDateOfBirth,
  updateEmail,
  updateFullName,
  updateGender,
  updatePassword,
  updatePhoneNumber,
  uploadImage,
} from "../../../services/profile/profile-slice.service";
import UpdateEmailForm from "../components/update-profile-form/update-email-form.component";
import UpdatePasswordForm from "../components/update-profile-form/update-password-form.component";
import SideMenuAvatar from "../../../components/profile-side-menu/side-menu-avatar.component";
import UpdateFormLoader from "../components/update-profile-form/update-form-loader.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));
function UserDetails({ userData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const [isEditing, setIsEditing] = useState(false);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isGenderEdit, setIsGenderEdit] = useState(false);
  const [isDOBEdit, setIsDOBEdit] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const { profileObj, toggleNewsletterObj } = useSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleUpdateName = (values) => {
    setIsLoading(true);
    dispatch(updateFullName(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setIsNameEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateGender = (values) => {
    setIsLoading(true);
    dispatch(updateGender(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setIsGenderEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateDOB = (values) => {
    setIsLoading(true);
    dispatch(updateDateOfBirth(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setIsDOBEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateEmail = (values) => {
    setIsLoading(true);
    dispatch(updateEmail(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setIsEditing(false);
        setIsEmailEdit(false);
        history.push(routes.VERIFICATIONCODE, { email: values.email });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdatePhoneNumber = (values) => {
    setIsLoading(true);
    dispatch(updatePhoneNumber(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setIsPhoneEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdatePassword = (values) => {
    setIsLoading(true);
    dispatch(updatePassword(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setIsPasswordEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleNewsletterPreference = () => {
    dispatch(toggleNewsletter()).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (profileObj.status === "succeeded") {
      return (
        <Grid item xs={isMobile ? 12 : 8}>
          <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
            <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold} paddingTop={4}>
              Profile
            </PrimaryColorText>
            {isMobile && (
              <Grid container sx={{ paddingTop: 3 }}>
                <SideMenuAvatar handleUploadImage={handleUploadImage} userData={userData} />
              </Grid>
            )}
            <UpdateNameForm
              firstName={userData.firstName}
              lastName={userData.lastName}
              handleSubmit={handleUpdateName}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isNameEdit={isNameEdit}
              setIsNameEdit={setIsNameEdit}
              isLoading={isLoading}
            />
            <UpdateGenderForm
              gender={userData.gender}
              handleSubmit={handleUpdateGender}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isGenderEdit={isGenderEdit}
              setIsGenderEdit={setIsGenderEdit}
              isLoading={isLoading}
            />
            <UpdateDOBForm
              dateOfBirth={userData.dateOfBirth}
              handleSubmit={handleUpdateDOB}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isDOBEdit={isDOBEdit}
              setIsDOBEdit={setIsDOBEdit}
              isLoading={isLoading}
            />
            <UpdateEmailForm
              email={userData.email}
              handleSubmit={handleUpdateEmail}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isEmailEdit={isEmailEdit}
              setIsEmailEdit={setIsEmailEdit}
              isLoading={isLoading}
            />
            <UpdatePhoneForm
              phoneNumber={userData.phoneNumber}
              handleSubmit={handleUpdatePhoneNumber}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isPhoneEdit={isPhoneEdit}
              setIsPhoneEdit={setIsPhoneEdit}
              isLoading={isLoading}
            />
            <UpdatePasswordForm
              handleSubmit={handleUpdatePassword}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isPasswordEdit={isPasswordEdit}
              setIsPasswordEdit={setIsPasswordEdit}
              isLoading={isLoading}
            />
            <Grid
              container
              spacing={1}
              sx={{ paddingY: 3, paddingBottom: "50px" }}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Grid item xs={12}>
                  <Typography variant="label">Newsletter</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Receive special offers, promotion and other information from YOLO via email.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Switch
                  disabled={toggleNewsletterObj.status === "pending"}
                  checked={profileObj.data.subscribedNewsletter}
                  onChange={handleNewsletterPreference}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return <UpdateFormLoader />;
  };

  return renderContent();
}

UserDetails.defaultProps = {
  userData: PropTypes.shape({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    phoneNumber: "",
  }),
};

UserDetails.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

export default UserDetails;
