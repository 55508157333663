import React from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

function UpdateNameForm({
  firstName,
  lastName,
  setIsEditing,
  isEditing,
  handleSubmit,
  isNameEdit,
  setIsNameEdit,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().min(2).label("First Name"),
    lastName: Yup.string().required().min(2).label("Last Name"),
  });

  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsNameEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsNameEdit(false);
  };

  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isNameEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isNameEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                firstName,
                lastName,
              }}
              onSubmit={handleFormSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                      First Name
                    </Typography>
                    <FormFieldText width="60%" name="firstName" placeholder="First Name" />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                      Last Name
                    </Typography>
                    <FormFieldText width="60%" name="lastName" placeholder="Last Name" />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          ) : (
            <Typography color={theme.palette.colors.text.secondary}>
              {firstName === "" ? "Not Provided" : `${firstName} ${lastName}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdateNameForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isNameEdit: PropTypes.bool.isRequired,
  setIsNameEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdateNameForm;
