import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const creatorLinkPreview = createAsyncThunk("creator/", async (payload) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PUBLIC_V1}/creator/${payload.creatorLink}?ip=${payload.ip}`,
    );
    return response;
});

const creatorSlice = createSlice({
    name: "creator",
    initialState: {
        creatorLinkPreviewObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
    },
    reducers: {},
    extraReducers: {
        [creatorLinkPreview.pending]: (state) => {
            state.creatorLinkPreviewObj.status = "pending";
        },
        [creatorLinkPreview.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.creatorLinkPreviewObj.status = "succeeded";
            state.creatorLinkPreviewObj.data = data;
            state.creatorLinkPreviewObj.successMessage = message;
        },
        [creatorLinkPreview.rejected]: (state, action) => {
            const { message } = action.error;

            state.creatorLinkPreviewObj.status = "failed";
            state.creatorLinkPreviewObj.errorMessage = message;
        },
    },
});

export default creatorSlice.reducer;

// state
export const creatorSelector = (state) => state.creator;
