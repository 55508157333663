import React from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";

function UpdateGenderForm({
  gender,
  setIsEditing,
  isEditing,
  handleSubmit,
  isGenderEdit,
  setIsGenderEdit,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .nullable()
      .notRequired()
      .when({
        is: (value) => value?.length,
        then: (rule) => rule.oneOf(["others", "male", "female"]),
      })
      .label("Gender"),
  });
  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsGenderEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsGenderEdit(false);
  };

  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Gender</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isGenderEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isGenderEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                gender,
              }}
              onSubmit={handleFormSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <OutlinedSelectTextField
                      width="30%"
                      name="gender"
                      label="Gender"
                      selected={gender || ""}
                      selections={[
                        { type: "Male", value: "male" },
                        { type: "Female", value: "female" },
                        { type: "Others", value: "others" },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          ) : (
            <Typography color={theme.palette.colors.text.secondary}>
              {gender === "" ? "Not Provided" : gender.charAt(0).toUpperCase() + gender.slice(1)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdateGenderForm.propTypes = {
  gender: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isGenderEdit: PropTypes.bool.isRequired,
  setIsGenderEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdateGenderForm;
