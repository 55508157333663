import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import {
  cancelOrder,
  getOrderDetails,
  orderSelector,
} from "../../../services/order/order-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import AwaitingPaymentSummary from "../components/pending-payment/awaiting-payment-summary.component";
import ItemList from "../components/pending-payment/item-list.component";
import OrderDetailsLoader from "../loader/order-details-loader.component";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const BackButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  width: "100%",
  color: theme.palette.colors.text.secondary,
  marginTop: 50,
  marginBottom: 50,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 50,
  marginBottom: 50,
}));

function OrderDetailsScreen() {
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { getOrderDetailsObj } = useSelector(orderSelector);
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleCancelOrder = (value) => {
    setIsLoading(true);
    dispatch(cancelOrder(value)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        history.push(routes.PENDINGPAYMENT);
      }
    });
  };

  const renderContent = () => {
    if (getOrderDetailsObj.status === "succeeded") {
      return (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <BackButton
                to={{
                  pathname: routes.PENDINGPAYMENT,
                }}
              >
                {"< Back"}
              </BackButton>
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 12 : 9}>
            <StyledBox isMobile={isMobile}>
              <Grid item xs={12}>
                <StyledTitle variant="h5">Order Details</StyledTitle>
                <Box sx={{ paddingBottom: "50px" }}>
                  {getOrderDetailsObj.data.orderItems.map((item) => (
                    <ItemList itemData={item} key={item.id} />
                  ))}
                </Box>
              </Grid>
            </StyledBox>
          </Grid>
          {isMobile && <SeperateLine />}
          <Grid item xs={isMobile ? 12 : 3}>
            <AwaitingPaymentSummary
              setIsLoading={setIsLoading}
              handleCancelOrder={handleCancelOrder}
            />
          </Grid>
        </>
      );
    }
    return <OrderDetailsLoader />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOrderDetails(orderId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Grid
        container
        paddingBottom={isMobile ? "30px" : "100px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
      >
        {renderContent()}

        <Spacer size="xxl" position="top" />
      </Grid>
    </>
  );
}

export default OrderDetailsScreen;
