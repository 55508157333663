import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const getBookings = createAsyncThunk("booking/get_bookings", async (payload) => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/bookings?status=${payload}`);
  return response;
});

export const cancelBooking = createAsyncThunk("booking/cancel_booking", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/bookings/${payload}/cancel`);
  return response;
});

export const getBookingDetails = createAsyncThunk("booking/booking_details", async (payload) => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/bookings/${payload}`);
  return response;
});

export const scheduleBooking = createAsyncThunk(
  "booking/schedule_booking",
  async ({ orderId, slotId }) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/bookings/${orderId}/schedule`,
      slotId,
    );
    return response;
  },
);

export const rateBooking = createAsyncThunk("booking/rate", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/bookings/${payload.bookingId}/reviews`,
    payload,
  );
  return response;
});

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    getBookingsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    scheduleBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    rateBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBookings.pending]: (state) => {
      state.getBookingsObj.status = "pending";
    },
    [getBookings.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingsObj.status = "succeeded";
      state.getBookingsObj.data = data;
      state.getBookingsObj.successMessage = message;
    },
    [getBookings.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingsObj.status = "failed";
      state.getBookingsObj.errorMessage = message;
    },
    [cancelBooking.pending]: (state) => {
      state.cancelBookingObj.status = "pending";
    },
    [cancelBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelBookingObj.status = "succeeded";
      state.cancelBookingObj.data = data;
      state.cancelBookingObj.successMessage = message;
    },
    [cancelBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelBookingObj.status = "failed";
      state.cancelBookingObj.errorMessage = message;
    },
    [getBookingDetails.pending]: (state) => {
      state.getBookingDetailsObj.status = "pending";
    },
    [getBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingDetailsObj.status = "succeeded";
      state.getBookingDetailsObj.data = data;
      state.getBookingDetailsObj.successMessage = message;
    },
    [getBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingDetailsObj.status = "failed";
      state.getBookingDetailsObj.errorMessage = message;
    },
    [scheduleBooking.pending]: (state) => {
      state.scheduleBookingObj.status = "pending";
    },
    [scheduleBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.scheduleBookingObj.status = "succeeded";
      state.scheduleBookingObj.data = data;
      state.scheduleBookingObj.successMessage = message;
    },
    [scheduleBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.scheduleBookingObj.status = "failed";
      state.scheduleBookingObj.errorMessage = message;
    },
    [rateBooking.pending]: (state) => {
      state.rateBookingObj.status = "pending";
    },
    [rateBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.rateBookingObj.status = "succeeded";
      state.rateBookingObj.data = data;
      state.rateBookingObj.successMessage = message;
    },
    [rateBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.rateBookingObj.status = "failed";
      state.rateBookingObj.errorMessage = message;
    },
  },
});

export default bookingSlice.reducer;

// state
export const bookingSelector = (state) => state.booking;
