import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import Form from "../../../../components/forms/form.component";
import TextButton from "../../../../components/button/text-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import Spacer from "../../../../components/utils/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

function UpdatePasswordForm({
  setIsEditing,
  isEditing,
  handleSubmit,
  isPasswordEdit,
  setIsPasswordEdit,
  isLoading,
}) {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().min(4).label("Password"),
    newPassword: Yup.string().required().min(4).label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Password and confirm password must be same.")
      .label("Password confirmation"),
  });
  const theme = useTheme();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsPasswordEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsPasswordEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography variant="label">Password</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isPasswordEdit ? (
                <TextButton label="Cancel" handleSubmit={handleCancelPress} />
              ) : (
                <TextButton label="Edit" handleSubmit={handleEditPress} isDisabled={isEditing} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isPasswordEdit && (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                      Current Password
                    </Typography>
                    <FormFieldText
                      width="75%"
                      type="password"
                      name="currentPassword"
                      placeholder="Current Password"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                      New Password
                    </Typography>
                    <FormFieldText
                      width="75%"
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                      Confirm Password
                    </Typography>
                    <FormFieldText
                      width="75%"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading} width="10%">
                Save
              </FormSubmitButton>
            </Form>
          )}
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }} />
    </>
  );
}

UpdatePasswordForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isPasswordEdit: PropTypes.bool.isRequired,
  setIsPasswordEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UpdatePasswordForm;
