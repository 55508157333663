import { Box, Grid, styled } from "@mui/material";
import React from "react";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function ReviewsLoader() {
  return (
    <Grid item xs={12}>
      <RowBox>
        <Box sx={{ width: "100%" }}>
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton width="100%" height="30px" />
          <RowBox>
            <CustomSkeleton height="100px" width="100px" />
            <Spacer size="s" position="left" />
            <CustomSkeleton height="100px" width="100px" />
          </RowBox>
          <Spacer size="m" position="top" />
          <CustomSkeleton width="100%" height="30px" />
          <CustomSkeleton height="100px" width="100px" />
        </Box>
      </RowBox>
    </Grid>
  );
}

export default ReviewsLoader;
