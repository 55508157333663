import React from "react";
import { styled, Grid, Typography, Box } from "@mui/material";
import CarouselItemList from "../carousel-item-list.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size24,
}));

function ReviewsLoader() {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title sx={{ textAlign: "center" }}>What Our Customers Say</Title>
        </Grid>
        <Grid item xs={12}>
          <CarouselItemList isHomePageReview>
            <Box sx={{ padding: "10px" }}>
              <CustomSkeleton width="100%" height="200px" />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <CustomSkeleton width="100%" height="200px" />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <CustomSkeleton width="100%" height="200px" />
            </Box>
          </CarouselItemList>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReviewsLoader;
