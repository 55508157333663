import React, { useState } from "react";
import { Box, Button, Grid, Menu, styled, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Spacer from "../../../../components/utils/spacer.component";
import FilterPriceRuleButton from "./filter-price-rule-button.component";

const StyledMenu = styled((props) => <Menu {...props} />)({
  marginTop: "45px",
  marginLeft: "-10px",
  "& .MuiPaper-root": {
    width: "200px",
    borderRadius: 1,
    marginTop: "5px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "10px 0",
    },
  },
});

const PlaceHolderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const StyledBox = styled(Box)({
  padding: "10px",
  "& .MuiButton-root": {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledFilterButton = styled(Button)({
  textTransform: "none",
  alignItems: "center",
  display: "flex",
  ":hover": { backgroundColor: "transparent" },
});

const StyledButton = styled(Button)({
  padding: "2px",
  width: "30px",
  textTransform: "none",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
});

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "5px",
});

function FilterPriceRules() {
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };
  const theme = useTheme();
  const { values, handleSubmit, setFieldValue } = useFormikContext();
  const handleCloseMenu = () => {
    handleSubmit();
    setOpenMenu(null);
  };

  const getLabel = () => {
    if (values.prule === "price-high-low") {
      return "High - Low";
    }
    if (values.prule === "price-low-high") {
      return "Low - High";
    }
    return "Default";
  };

  const onClearHandle = () => {
    setFieldValue("prule", "");
    handleSubmit();
    setOpenMenu(null);
  };

  return (
    <>
      <StyledFilterButton onClick={handleOpenMenu} disableRipple>
        <PlaceHolderText>Price: {getLabel()}</PlaceHolderText>
      </StyledFilterButton>
      <StyledMenu
        elevation={0}
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        <StyledBox sx={{ padding: "10px" }}>
          <Spacer size="s" position="top" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FilterPriceRuleButton id="" label="Price : Default" />
            </Grid>
            <Grid item xs={12}>
              <FilterPriceRuleButton id="price-high-low" label="Price : High - Low" />
            </Grid>
            <Grid item xs={12}>
              <FilterPriceRuleButton id="price-low-high" label="Price : Low - High" />
            </Grid>
            <Grid item xs={12}>
              <SpaceBetweenRowBox>
                <StyledButton disableRipple onClick={onClearHandle}>
                  <Typography
                    sx={{
                      fontSize: theme.fonts.fontSizes.size14,
                      color: theme.palette.colors.text.primary,
                    }}
                  >
                    Clear
                  </Typography>
                </StyledButton>

                <StyledButton
                  disableRipple
                  onClick={handleCloseMenu}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.fonts.fontSizes.size14,
                      color: theme.palette.colors.brand.primary,
                    }}
                  >
                    Save
                  </Typography>
                </StyledButton>
              </SpaceBetweenRowBox>
            </Grid>
          </Grid>
        </StyledBox>
      </StyledMenu>
    </>
  );
}

export default FilterPriceRules;
