import React, { useContext, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme, styled } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "react-js-loader";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { creditGiftVoucherBillCheck } from "../../../services/voucher/voucher-slice.service";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

export default function GiftVoucherPaymentCheckScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const createSnackBar = useContext(SnackbarContext);

  const loadDataFromNet = () => {
    if (new URLSearchParams(location.search).get("status") === "CANCELLED") {
      history.push(routes.BILLFAILED);
      return;
    }
    dispatch(creditGiftVoucherBillCheck(new URLSearchParams(location.search).get("orderId"))).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.bill.remoteState === "paid") {
            history.push({
              pathname: routes.GIFT_VOUCHER_BILL_SUCCESS,
              search: `remoteBillId=${payload.data.bill.remoteBillId}`,
            });
          } else {
            setTimeout(() => {
              loadDataFromNet();
            }, 3000);
          }
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  useEffect(() => {
    loadDataFromNet();
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xxxl" />
      <Loader
        type="bubble-loop"
        bgColor={theme.palette.colors.brand.secondary}
        color="red"
        size={100}
      />
      <Spacer size="xxxl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Processing</StyledTitle>
      <Spacer size="m" />
      <GreyText>
        Your payment successful but your bank is still processing the transaction.
      </GreyText>
      <Spacer size="m" />
      <GreyText>Please do not close or refresh the page.</GreyText>
      <Spacer size="xxxl" />
      <Spacer size="xxl" />
    </StyledContainer>
  );
}
