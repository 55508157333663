import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

export default function GiftVoucherPreviewLoader() {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PrimaryColorText
          sx={{
            fontSize: theme.fonts.fontSizes.size20,
          }}
        >
          Voucher Details
        </PrimaryColorText>
      </Grid>
      <Grid item xs={12}>
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Voucher Code</GreyText>
        <CustomSkeleton width="100%" height="30px" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Amount</GreyText>
        <CustomSkeleton width="100%" height="30px" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Purchased Date</GreyText>
        <CustomSkeleton width="100%" height="30px" />
      </Grid>
      <Grid item xs={12}>
        <GreyText>Purchased By</GreyText>
        <CustomSkeleton width="100%" height="30px" />
      </Grid>
    </Grid>
  );
}
