import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Spacer from "../../../components/utils/spacer.component";

function BuyNowFormFieldText({
  name,
  placeholder,
  type,
  width,
  showIcon,
  isTransparent,
  errorName,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError =
    touched.gift &&
    touched.gift.recipient &&
    touched.gift?.recipient[errorName] &&
    errors.gift &&
    errors.gift.recipient &&
    typeof errors.gift?.recipient[errorName] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.secondary,
          },
        },
      }}
    >
      <TextField
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        helperText={showError ? errors.gift?.recipient[errorName] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={type || name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        InputProps={{
          startAdornment: name === "email" && showIcon && (
            <InputAdornment position="start">
              <PersonIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
        }}
        value={values.gift?.recipient[errorName]}
      />
    </Box>
  );
}

BuyNowFormFieldText.defaultProps = {
  type: null,
  width: "100%",
  showIcon: true,
  isTransparent: false,
};

BuyNowFormFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  showIcon: PropTypes.bool,
  isTransparent: PropTypes.bool,
  errorName: PropTypes.string.isRequired,
};

export default BuyNowFormFieldText;
