import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useTheme, Toolbar, Box, useMediaQuery, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import YoloLogo from "../../assets/images/yolo_logo";
import PCViewAppBar from "./components/pc-view-app-bar-components/pc-view-appbar.component";
import MobileViewAppBar from "./components/mobile-view-app-bar-components/mobile-drawer-app-bar.component";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import {
  getProfile,
  profileSelector,
  resetProfile,
} from "../../services/profile/profile-slice.service";
import { isLogin } from "../utils/index";
import routes from "../../components/navigation/routes";

import MobileBottomNavBar from "./components/mobile-view-app-bar-components/mobile-bottom-nav-bar.component";
import ContactUs from "../../components/contact-us/contact-us.component";
import NewsletterSignUp from "../../components/newsletter/newsletter-sign-up.component";
import {
  experienceSelector,
  getExperiencesCategoryList,
  getLocationCategoryList,
} from "../../services/experiences/experience-slice.service";

const AppBarContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingLeft: 0,
  paddingRight: 0,
}));

const MaxWidthBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  minHeight: "89vh",
}));

const OverflowBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "30px",
});

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.white,
}));

const PCViewCenterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
}));

const VoucherSubHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontWeight: theme.fonts.fontWeights.bold,
  paddingTop: "15px",
  paddingBottom: "15px",
}));

function CustomizedAppBar({ children }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profileObj } = useSelector(profileSelector);
  const { locationCategoryListObj, experiencesCategoryListObj } = useSelector(experienceSelector);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("1000"));
  const voucherSubHeaderText =
    location.pathname === routes.VOUCHERDETAILS
      ? "You're booking this experience."
      : "You've redeemed this voucher.";

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logout()).then(({ meta }) => {
      setIsLoading(false);
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.AUTH);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        history.push(routes.AUTH);
      }
    });
  };

  const handleSearch = (q, type) => {
    history.push({
      pathname: routes.EXPERIENCESLISTING,
      search: `type=${type}&q=${encodeURIComponent(q)}`,
    });
  };

  const getPaddingBottom = () => {
    if (isMobile && location.pathname.includes("experiences")) {
      return "190px";
    }
    if (isMobile) {
      return "70px";
    }
    return 0;
  };

  useEffect(() => {
    if (isLogin()) dispatch(getProfile());
    dispatch(getLocationCategoryList());
    dispatch(getExperiencesCategoryList());
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: theme.palette.colors.bg.white }}
        elevation={0}
      >
        {isMobile ? (
          <>
            <Toolbar
              disableGutters={!isMobile}
              sx={{ borderBottom: `1px solid ${theme.palette.colors.ui.border}` }}
            >
              <AppBarContainer>
                <Box sx={{ marginLeft: -1 }}>
                  <YoloLogo />
                </Box>
                {!location.pathname.includes("voucher/") && (
                  <MobileViewAppBar
                    isLoading={isLoading}
                    handleSearch={handleSearch}
                    handleLogout={handleLogout}
                    locationList={locationCategoryListObj.data}
                    experienceList={experiencesCategoryListObj.data}
                  />
                )}
              </AppBarContainer>
            </Toolbar>
            {(location.pathname === routes.VOUCHERDETAILS ||
              location.pathname === routes.GIFT_VOUCHER_PREVIEW) && (
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.colors.brand.primary,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <VoucherSubHeaderText>{voucherSubHeaderText}</VoucherSubHeaderText>
              </Box>
            )}
          </>
        ) : (
          <PCViewCenterBox>
            <PCViewAppBar
              handleSearch={handleSearch}
              handleLogout={handleLogout}
              userData={profileObj.data}
              locationList={locationCategoryListObj.data}
              experienceList={experiencesCategoryListObj.data}
            />
          </PCViewCenterBox>
        )}
      </AppBar>
      {/* ====== Content Section ====== */}
      <ContentBox sx={{ paddingBottom: getPaddingBottom() }}>
        <OverflowBox sx={{ overflowX: isMobile ? "hidden" : null }}>
          <MaxWidthBox
            sx={{
              maxWidth:
                location.pathname === routes.VOUCHERREDEMPTION ||
                location.pathname === routes.VOUCHERREDEEMED ||
                location.pathname === routes.HOME
                  ? null
                  : theme.dimensions.PCMaxWidth,
            }}
          >
            {children}
          </MaxWidthBox>
        </OverflowBox>
        {!location.pathname.includes("voucher/") && <NewsletterSignUp />}
        {!location.pathname.includes("voucher/") && <ContactUs />}
      </ContentBox>
      {!location.pathname.includes("voucher/") && isMobile && (
        <MobileBottomNavBar userData={profileObj.data} />
      )}
    </>
  );
}

CustomizedAppBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomizedAppBar;
