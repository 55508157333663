import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? "25px" : "50px",
  width: isMobile ? "325px" : "650px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

function ConfirmationModal({ showModal, setShowModal, onSubmit, message, buttonText }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <CloseIconButton onClick={() => setShowModal(false)}>
              <CloseIcon />
            </CloseIconButton>
          </Grid>
          <Spacer size="xxl" position="top" />
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center">
              {message}
            </Typography>
          </Grid>

          <Grid item xs={12} paddingTop="50px">
            <BackgroundButton handleSubmit={onSubmit}>
              <Typography variant="h5">{buttonText}</Typography>
            </BackgroundButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default ConfirmationModal;
