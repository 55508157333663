import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme, styled } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import routes from "../../../components/navigation/routes";
import CircleTick from "../../../assets/images/circle_tick";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

export default function GiftVoucherPaymentSuccessScreen() {
  const location = useLocation();
  const billId = new URLSearchParams(location.search).get("remoteBillId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xxxl" />
      <CircleTick />
      <Spacer size="xl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Congratulations!</StyledTitle>
      <Spacer size="m" />
      <GreyText>Your payment has been made successfully.</GreyText>
      <Spacer size="l" />
      <GreyText>{`REF ID: ${billId}`}</GreyText>
      <Spacer size="xxxl" />
      <BackgroundButton handleSubmit={() => history.push(routes.GIFT_VOUCHER)} width="325px">
        <Typography>OK</Typography>
      </BackgroundButton>
    </StyledContainer>
  );
}
