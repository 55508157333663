import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import routes from "../../../components/navigation/routes";
import { cartSelector } from "../../../services/cart/cart-slice.service";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import { isLogin } from "../../../infrastructure/utils";
import { guestSelector } from "../../../services/guest/guest-slice.service";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";
import { profileSelector } from "../../../services/profile/profile-slice.service";

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size20,
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
}));

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size20,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size20,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "50px",
  paddingBottom: "50px",
  backgroundColor: "white",
  boxShadow: `0px 0px 2px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: isMobile ? "100%" : "90%",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

function PurchaseBox({ changingValue }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const { cartsDetailsObj } = useSelector(cartSelector);
  const history = useHistory();
  const { guestCartQuantity, guestCartList } = useSelector(guestSelector);
  const { latestCartDetailsObj } = useSelector(experienceSelector);
  const { profileObj } = useSelector(profileSelector);

  const getTotalCost = () => {
    let totalCost = 0;
    latestCartDetailsObj.data.map((item) =>
      guestCartList.map((item2) => {
        if (item.uniqueId === item2.uniqueId) {
          item.experience.addOns.forEach((addOns) => {
            totalCost +=
              parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
          });
          totalCost =
            parseFloat(totalCost) +
            parseFloat(item.experience.pricePerItem.replace(",", "")) * parseFloat(item2.quantity);
        }
        return totalCost;
      }),
    );
    return parseFloat(totalCost).toFixed(2);
  };

  if (isLogin()) {
    return (
      <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
        <StyledBorderBox isMobile={isMobile}>
          <TitleText>Cart Summary</TitleText>
          <SeperateLine />

          <StyledRow>
            <DescriptionText>Items:</DescriptionText>
            {cartsDetailsObj.status === "succeeded" && !changingValue ? (
              <GreyText>{`${cartsDetailsObj.data.totalItems} items`}</GreyText>
            ) : (
              <CustomSkeleton width="50px" />
            )}
          </StyledRow>
          <Spacer size="s" position="top" />
          <StyledRow>
            <DescriptionText>Total Cost:</DescriptionText>
            {cartsDetailsObj.status === "succeeded" && !changingValue ? (
              <BoldPrimaryColorText>{`RM${cartsDetailsObj.data.totalCost}`}</BoldPrimaryColorText>
            ) : (
              <CustomSkeleton width="70px" />
            )}
          </StyledRow>
          <Spacer size="xxxl" position="top" />

          <BackgroundButton
            isLoading={
              !(
                cartsDetailsObj.status === "succeeded" &&
                !changingValue &&
                profileObj.status === "succeeded"
              )
            }
            handleSubmit={() => {
              if (profileObj.data.firstName) {
                if (profileObj.data.emailVerified) {
                  history.push(routes.SECURECHECKOUT);
                } else {
                  history.push(routes.VERIFICATIONCODE, { email: profileObj.data.email });
                }
              } else {
                history.push(routes.COMPLETEPROFILE);
              }
            }}
          >
            <WhiteColorText>Secure Checkout</WhiteColorText>
          </BackgroundButton>
        </StyledBorderBox>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}>
      <StyledBorderBox isMobile={isMobile}>
        <TitleText>Cart Summary</TitleText>
        <SeperateLine />

        <StyledRow>
          <DescriptionText>Items:</DescriptionText>

          <GreyText>{`${guestCartQuantity} items`}</GreyText>
        </StyledRow>
        <Spacer size="s" position="top" />
        <StyledRow>
          <DescriptionText>Total Cost:</DescriptionText>

          {latestCartDetailsObj.status === "succeeded" ? (
            <BoldPrimaryColorText>{`RM${getTotalCost()}`}</BoldPrimaryColorText>
          ) : (
            <CustomSkeleton width="70px" />
          )}
        </StyledRow>
        <Spacer size="xxxl" position="top" />

        <BackgroundButton handleSubmit={() => history.push(routes.AUTH)}>
          <WhiteColorText>Secure Checkout</WhiteColorText>
        </BackgroundButton>
      </StyledBorderBox>
    </Box>
  );
}

PurchaseBox.propTypes = {
  changingValue: PropTypes.bool.isRequired,
};

export default PurchaseBox;
