import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import GiftBanner from "../../../assets/images/gift_voucher.png";
import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import AmountField from "../components/amount-field.component";
import {
  creditGiftPreview,
  getCreditGiftList,
  voucherSelector,
} from "../../../services/voucher/voucher-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CreditVoucherPreviewModal from "../components/credit-voucher-preview-modal.component";

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
  fontSize: theme.fonts.fontSizes.size32,
  lineHeight: theme.fonts.fontSizes.size32,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const validationSchema = Yup.object().shape({
  amount: Yup.number().min(1).label("Amount").required(),
  quantity: Yup.number().label("Quantity"),
});

export default function GiftVoucherScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getCreditGiftListObj } = useSelector(voucherSelector);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCreditGiftList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onShowPreview = (values) => {
    setShowPreviewModal(true);
    const items = [
      { label: values.amountObj.label, value: values.amount * 100, quantity: values.quantity },
    ];
    dispatch(creditGiftPreview({ items })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Form
      initialValues={{ amount: "", quantity: 1 }}
      validationSchema={validationSchema}
      onSubmit={onShowPreview}
    >
      <CreditVoucherPreviewModal showModal={showPreviewModal} setShowModal={setShowPreviewModal} />
      <Spacer size="xl" position="top" />
      <Grid
        container
        paddingBottom={isMobile ? "10px" : "0px"}
        paddingX={isMobile ? 2 : theme.dimensions.ScreenPaddingX}
        spacing={3}
      >
        <Grid item xs={12}>
          <CenteredBox>
            <BoldPrimaryColorText>Gift Voucher</BoldPrimaryColorText>
            <GreyText>Give the Gift of Choice</GreyText>
          </CenteredBox>
        </Grid>
        <Grid item xs={12}>
          <img src={GiftBanner} alt="gift_banner" width="100%" />
        </Grid>
        <Grid item xs={12}>
          <CenteredBox>
            <PrimaryColorText>
              All gift vouchers can be used to purchase any YOLO experience or product
            </PrimaryColorText>
          </CenteredBox>
        </Grid>
        <Grid item xs={12}>
          {getCreditGiftListObj.status === "succeeded" ? (
            <AmountField />
          ) : (
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item>
                <CustomSkeleton width="130px" height="40px" />
              </Grid>
              <Grid item>
                <CustomSkeleton width="130px" height="40px" />
              </Grid>
              <Grid item>
                <CustomSkeleton width="130px" height="40px" />
              </Grid>
              <Grid item>
                <CustomSkeleton width="130px" height="40px" />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <CenteredBox>
            <FormSubmitButton isLoading={getCreditGiftListObj.status !== "succeeded"} width="300px">
              <Typography>Buy as a voucher</Typography>
            </FormSubmitButton>
          </CenteredBox>
        </Grid>
      </Grid>
      <Spacer size="xxl" position="top" />
    </Form>
  );
}
