import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const DetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  paddingLeft: isMobile ? "0px" : "15px",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
}));

const Minorbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "row" : "row",
  alignItems: isMobile ? "center" : "flex-start",
  justifyContent: isMobile ? "space-between" : "space-between",
}));

function RemoveItemModalLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid item xs={12} paddingTop={3}>
        <GreyText lineHeight="20px">
          Are you sure you want to remove the following product from the cart?
        </GreyText>
      </Grid>

      <Grid container>
        <Grid item xs={isMobile ? 12 : 5} paddingTop={3}>
          <CustomSkeleton width="100%" height={isMobile ? "170px" : "150px"} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 7} paddingTop={3}>
          <DetailsBox isMobile={isMobile}>
            <CustomSkeleton width="100px" />
            <Spacer size="s" position="top" />
            <Minorbox isMobile={isMobile}>
              <CustomSkeleton width="100px" />
              <CustomSkeleton width="100px" />
            </Minorbox>
          </DetailsBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RemoveItemModalLoader;
