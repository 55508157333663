import React from "react";
import { Box, Grid, styled, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { experienceSelector } from "../../../services/experiences/experience-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import CategoryReview from "./category-review.component";
import CategoryImageGrid from "./category-image-grid.component";

const BoldPrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const SecondaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const ColumnFlexBox = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

export default function CategoryIntro() {
  const { experiencesListObj } = useSelector(experienceSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));

  return (
    <Grid container>
      <Grid item xs={12}>
        <BoldPrimaryColorText sx={{ fontSize: theme.fonts.fontSizes.size32 }}>
          {experiencesListObj.data.category.label}
        </BoldPrimaryColorText>
      </Grid>
      {experiencesListObj.data.category.imagePath.length > 0 && (
        <Grid item xs={12}>
          <CategoryImageGrid itemList={experiencesListObj.data.category.imagePath} />
        </Grid>
      )}
      {experiencesListObj.data.category.description && (
        <Grid item xs={12}>
          <Spacer position="top" size="m" />
          <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
            <ColumnFlexBox>
              <SecondaryColorText>About</SecondaryColorText>
              <PrimaryColorText>{experiencesListObj.data.category.description}</PrimaryColorText>
            </ColumnFlexBox>
            <Spacer size="m" position={isMobile ? "top" : "left"} />
            <CategoryReview reviews={experiencesListObj.data.reviews} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
