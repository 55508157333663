import { Grid, Pagination, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import {
  getGiftOrders,
  giftOrderSelector,
} from "../../../services/gift-order/gift-order-slice.service";
import { uploadImage } from "../../../services/profile/profile-slice.service";
import OrderList from "../components/order-list.component";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

function GiftCardOrderScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const { getGiftOrdersObj } = useSelector(giftOrderSelector);

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    dispatch(getGiftOrders({ page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onPageChange(null, 1);
  }, []);

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        paddingBottom: 5,
        backgroundColor: theme.palette.colors.bg.white,
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
      }}
    >
      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      <Grid item xs={isMobile ? 12 : 8}>
        <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
          <Grid item xs={12} paddingTop={4}>
            <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold}>
              Gift Card Orders
            </PrimaryColorText>
          </Grid>

          <Grid
            spacing={4}
            container
            sx={{
              paddingY: 3,
            }}
          >
            <OrderList />
          </Grid>
          {getGiftOrdersObj.status === "succeeded" && (
            <Grid container justifyContent="center" paddingTop="10px">
              <Pagination
                page={page}
                onChange={onPageChange}
                count={getGiftOrdersObj.data.pagination.totalPages}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GiftCardOrderScreen;
