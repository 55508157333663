import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CarouselItemList from "../carousel-item-list.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

const StyledBox = styled(Box)({
  padding: 2,
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
});

function FestivalLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const arr = Array.apply("", Array(4));
  return (
    <Grid item xs={12} paddingY={2}>
      <StyledBox container>
        <CustomSkeleton width="300px" height="50px" borderRadius={theme.shape.borderRadius[0]} />
      </StyledBox>
      <Box
        sx={{
          paddingLeft: isMobile ? "15px" : 0,
          paddingTop: "15px",
        }}
      >
        <CarouselItemList>
          {arr.map(() => (
            <Box key={uuidv4()} sx={{ padding: "5px" }}>
              <CustomSkeleton width="100%" height="200px" />
            </Box>
          ))}
        </CarouselItemList>
      </Box>
    </Grid>
  );
}

export default FestivalLoader;
