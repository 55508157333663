import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormLabel from "../../../../components/forms/form-label.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { emailCheck } from "../../../../services/auth/auth-slice.service";
import BoxTitle from "../box-title.component";
import FormDebounceFieldText from "./form-debounce-field-text.component";
import MiniCheckBox from "./sign-up-mini-checkbox.component";

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function SignUpForm({ optForNewsletter, setOptForNewsletter, isLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const createSnackBar = useContext(SnackbarContext);

  const onChangeEmail = (values) => {
    setErrorMessage("");
    dispatch(emailCheck(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue("email", values.email);
      }
      if (meta.requestStatus === "rejected") {
        setErrorMessage(error.message);
        setFieldValue("email", "");
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[0]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <BoxTitle title="Sign Up" />
        <Grid item textAlign="center" paddingTop="20px">
          <Typography variant="h5" fontWeight="600">
            Sign up in 30 seconds!
          </Typography>
          <Typography>Get registered | Instant RM5 just for joining</Typography>
        </Grid>
        <PaddedView multiples={isMobile ? 3 : 4}>
          <FormLabel>First Name</FormLabel>
          <Spacer size="s" />
          <FormFieldText name="firstName" placeholder="Enter Your First Name" />

          <Spacer size="m" />
          <FormLabel>Last Name</FormLabel>
          <Spacer size="s" />
          <FormFieldText name="lastName" placeholder="Enter Your Last Name" />

          <Spacer size="m" />
          <FormLabel>Email</FormLabel>
          <Spacer size="s" />
          <Form
            validationSchema={emailValidationSchema}
            initialValues={{ email: "" }}
            onSubmit={onChangeEmail}
          >
            <FormDebounceFieldText
              name="email"
              placeholder="Enter Your Email Address"
              errorMessage={errorMessage}
            />
          </Form>

          <Spacer size="m" />
          <FormLabel>Password</FormLabel>
          <Spacer size="s" />
          <FormFieldText name="password" placeholder="Enter Your Password" />

          <Spacer size="m" />
          <FormLabel>Confirm Password</FormLabel>
          <Spacer size="s" />
          <FormFieldText
            type="password"
            name="confirmPassword"
            placeholder="Confirm Your Password"
          />

          <Grid container justifyContent="space-between" sx={{ paddingTop: 1.5 }}>
            <MiniCheckBox
              optForNewsletter={optForNewsletter}
              setOptForNewsletter={setOptForNewsletter}
              label="Yes! I would like to receive special offers, promotion and other information from YOLO."
            />
          </Grid>

          <Spacer size="l" />

          <Spacer size="s" />
          <FormSubmitButton isLoading={isLoading}>
            <Typography>Continue</Typography>
          </FormSubmitButton>
        </PaddedView>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  setOptForNewsletter: PropTypes.func.isRequired,
  optForNewsletter: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SignUpForm;
