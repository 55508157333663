import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react";
import Spacer from "../../../components/utils/spacer.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

function AnyReactComponent(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            // window.open(`https://www.google.com/maps/search/${text}/${lat},${lng}`, "_blank")
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

const StyledTableCell = styled(TableCell)({
  padding: "0px",
  border: "0px",
});

const StyledHeaderTableCell = styled(TableCell)({
  backgroundColor: "transparent",
  border: "0px",
  padding: "0px",
});

function LocationMap({ itemData }) {
  const title = "Where You'll Meet";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));
  const defaultProps = {
    center: {
      lat: parseFloat(itemData.location.lat),
      lng: parseFloat(itemData.location.lng),
    },
    zoom: 17,
  };

  if (!isMobile) {
    return (
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: theme.dimensions.headerHeight }}>
            <TableRow>
              <StyledHeaderTableCell sx={{ width: "40%" }}>
                <StyledTitle sx={{ height: "15px", fontSize: theme.fonts.fontSizes.size32 }}>
                  {title}
                </StyledTitle>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: "0px" }}>
              <StyledTableCell />
              <StyledTableCell>
                <Box
                  sx={{ width: isMobile ? "100%" : "95%", height: isMobile ? "200px" : "400px" }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    draggable={false}
                  >
                    <AnyReactComponent
                      lat={parseFloat(itemData.location.lat)}
                      lng={parseFloat(itemData.location.lng)}
                      text={itemData.experience?.title || itemData.title}
                    />
                  </GoogleMapReact>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Spacer size="xl" position="top" />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size24 }}>{title}</StyledTitle>
      <Spacer size="m" position="top" />
      <Box sx={{ width: isMobile ? "100%" : "95%", height: isMobile ? "200px" : "400px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          draggable={false}
        >
          <AnyReactComponent
            lat={parseFloat(itemData.location.lat)}
            lng={parseFloat(itemData.location.lng)}
            text={itemData.experience?.title || itemData.title}
          />
        </GoogleMapReact>
      </Box>
    </Grid>
  );
}

LocationMap.defaultProps = {
  itemData: PropTypes.shape({
    title: "",
    location: PropTypes.shape({
      lat: "",
      lng: "",
    }),
    experience: PropTypes.shape({
      title: "",
    }),
  }),
};

LocationMap.propTypes = {
  itemData: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    experience: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default LocationMap;
