import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";
import BackgroundButton from "../../../components/button/background-button.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const StyledBorderBox = styled(Box)({
  width: "95%",
  paddingTop: "20px",
  paddingBottom: "20px",
  backgroundColor: "white",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

const StyledRow = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

function PurchaseBoxLoader() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}
    >
      <SeperateLine />
      <StyledBorderBox>
        <StyledRow>
          <CustomSkeleton width="150px" height="30px" />
        </StyledRow>
        <Spacer size="s" position="top" />
        <StyledRow>
          <CustomSkeleton width="150px" height="30px" />
        </StyledRow>
        <Spacer size="xl" position="top" />
        <BackgroundButton isLoading={true}>
          <WhiteColorText>Add To Cart</WhiteColorText>
        </BackgroundButton>
      </StyledBorderBox>
      <SeperateLine />
    </Box>
  );
}

export default PurchaseBoxLoader;
