import React, { useContext, useEffect } from "react";
import { Box, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useLocation } from "react-router-dom";
import { uploadImage } from "../../../services/profile/profile-slice.service";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import { getOrderDetails, orderSelector } from "../../../services/order/order-slice.service";
import ExperiencesCard from "../components/experiences-card.component";
import MyOrderDetailsLoader from "../loader/my-order-details-loader.component";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const PaymentDetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const SpaceBetweenRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledBackIcon = styled(IconButton)(({ theme }) => ({
  marginTop: "5px",
  color: theme.palette.colors.brand.secondary,
  paddingLeft: "0px",
}));

function MyOrderDetailScreen() {
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const { getOrderDetailsObj } = useSelector(orderSelector);
  const createSnackBar = useContext(SnackbarContext);
  const discount =
    getOrderDetailsObj.status === "succeeded"
      ? parseFloat(getOrderDetailsObj.data.subtotal.replace(/,/g, "")) -
        parseFloat(getOrderDetailsObj.data.totalCost.replace(/,/g, ""))
      : 0;

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOrderDetails(orderId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderContent = () => {
    if (getOrderDetailsObj.status === "succeeded") {
      return (
        <Grid
          spacing={4}
          container
          sx={{
            paddingY: 3,
          }}
        >
          <Grid item xs={12}>
            <PaymentDetailsBox isMobile={isMobile}>
              <PrimaryColorText>{`Paid on: ${getOrderDetailsObj.data.paidAt}`}</PrimaryColorText>
              <PrimaryColorText>{`Payment Method: ${getOrderDetailsObj.data.paymentMethod}`}</PrimaryColorText>
            </PaymentDetailsBox>
          </Grid>

          {getOrderDetailsObj.data.orderItems.map((item) => (
            <Grid item xs={12} key={item.id}>
              <ExperiencesCard cardDetails={item} />
            </Grid>
          ))}

          <Grid item xs={12}>
            <SeperateLine />
          </Grid>

          <Grid item xs={12}>
            <SpaceBetweenRow>
              <GreyText>Items:</GreyText>
              <GreyText>{`${getOrderDetailsObj.data.totalItems} item`}</GreyText>
            </SpaceBetweenRow>
            <SpaceBetweenRow>
              <GreyText>Subtotal:</GreyText>
              <GreyText>{`RM${getOrderDetailsObj.data.subtotal}`}</GreyText>
            </SpaceBetweenRow>
            {discount !== 0 && (
              <SpaceBetweenRow>
                <GreyText>Discount:</GreyText>
                <GreyText>{`-RM${discount.toFixed(2)}`}</GreyText>
              </SpaceBetweenRow>
            )}
          </Grid>

          <Grid item xs={12}>
            <SpaceBetweenRow>
              <PrimaryColorText>Total:</PrimaryColorText>
              <PrimaryColorText
                sx={{ fontWeight: theme.fonts.fontWeights.bold }}
              >{`RM${getOrderDetailsObj.data.totalCost}`}</PrimaryColorText>
            </SpaceBetweenRow>
          </Grid>
        </Grid>
      );
    }
    return <MyOrderDetailsLoader />;
  };

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        paddingBottom: 5,
        backgroundColor: theme.palette.colors.bg.white,
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
      }}
    >
      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      <Grid item xs={isMobile ? 12 : 8}>
        <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
          <Grid item xs={12} paddingTop={4}>
            <RowBox>
              <Link to={{ pathname: routes.MYORDERS }}>
                <StyledBackIcon>
                  <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
                </StyledBackIcon>
              </Link>
              {!isMobile && <Spacer size="xs" position="left" />}
              <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold}>
                Order Details
              </PrimaryColorText>
            </RowBox>
          </Grid>

          {renderContent()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MyOrderDetailScreen;
