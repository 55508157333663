import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as Yup from "yup";
import {
  getProfile,
  profileSelector,
  uploadImage,
} from "../../../services/profile/profile-slice.service";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import Spacer from "../../../components/utils/spacer.component";
import CurrentBalance from "../components/current-balance.component";
import TopUpModal from "../components/top-up/topup-modal.component";
import {
  createTopUpBill,
  getCreditTransactions,
  getCreditTransactionDetails,
  resetCreditTransactionDetail,
} from "../../../services/credits/credit-slice.service";
import Loading from "../../../components/notification/backdrop-loading.component";
import PointConvertModal from "../components/points-conversion/points-conversion-modal.component";
import {
  convertPoints,
  getConvertiblePoints,
  getPointsTransactions,
  pointSelector,
} from "../../../services/point/points-slice.service";
import AccountCreditsLoader from "../loader/account-credits-loader.component";
import Form from "../../../components/forms/form.component";
import BorderButtonWithBg from "../../../components/button/border-button-with-bg.component";
import TransactionsFilter from "../components/transactions-filter.component";
import TransactionsList from "../components/transactions-list.component";
import TransactionDetails from "../components/transaction-details-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const StyledTopUpButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "120px",
  height: "35px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
  boxShadow: 0,
  "&:hover": {
    backgroundColor: "none",
  },
}));

const StyledTitleBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const validationSchema = Yup.object().shape({
  credit: Yup.number().required().label("Credit"),
  points: Yup.number()
    .min(1, "Credit must be greater or equal to 10.")
    .integer("Credit must be closet to 10.")
    .required(),
});

function AccountCreditsScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  const dispatch = useDispatch();
  const { profileObj } = useSelector(profileSelector);
  const { getConvertiblePointsObj } = useSelector(pointSelector);
  const [showPointConvertModal, setShowPointConvertModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creditToConvert, setCreditToConvert] = useState(0);
  const [pointConverModalScreen, setPointConverModalScreen] = useState("Form");
  const [currentTab, setCurrentTab] = useState("credits");
  const [currentFilter, setCurrentFilter] = useState("all");
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleUploadImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const resetTransactionsList = () => {
    setCurrentTab("credits");
    setCurrentFilter("all");
    dispatch(getCreditTransactions("all")).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onChangeCurrentTab = (tab) => {
    setCurrentTab(tab);
    setCurrentFilter("all");
    if (tab === "credits") {
      dispatch(getCreditTransactions("all")).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
    if (tab === "points") {
      dispatch(getPointsTransactions("all")).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const onChangeFilter = (filter) => {
    if (currentTab === "credits") {
      dispatch(getCreditTransactions(filter)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
    if (currentTab === "points") {
      dispatch(getPointsTransactions(filter)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const openTransactionDetails = (id) => {
    dispatch(resetCreditTransactionDetail());
    setShowTransactionDetails(true);
    dispatch(getCreditTransactionDetails(id)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onConfirmTopUp = (values) => {
    setIsLoading(true);
    dispatch(createTopUpBill(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      }
      if (meta.requestStatus === "fulfilled") {
        window.open(payload.data.remote_url, "_self");
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfile()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getConvertiblePoints()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getCreditTransactions("all")).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const submitConvertPoints = (values) => {
    setPointConverModalScreen("Confirmation");
    setCreditToConvert(values.credit * 100);
  };

  const onConfirmConvertPoints = () => {
    setIsLoading(true);
    dispatch(convertPoints({ credit: creditToConvert })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        resetTransactionsList();
        dispatch(getProfile());
        setPointConverModalScreen("Form");
        setShowPointConvertModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (profileObj.status === "succeeded" && getConvertiblePointsObj.status === "succeeded") {
      return (
        <>
          <Form
            validationSchema={validationSchema}
            initialValues={{ credit: 10, points: 1 }}
            onSubmit={submitConvertPoints}
          >
            <PointConvertModal
              onConfirmConvertPoints={onConfirmConvertPoints}
              showModal={showPointConvertModal}
              setShowModal={setShowPointConvertModal}
              setPointConverModalScreen={setPointConverModalScreen}
              pointConverModalScreen={pointConverModalScreen}
            />
          </Form>
          <TopUpModal
            showModal={showTopUpModal}
            setShowModal={setShowTopUpModal}
            onConfirmTopUp={onConfirmTopUp}
          />
          <TransactionDetails
            showModal={showTransactionDetails}
            setShowModal={setShowTransactionDetails}
          />
          <Grid item xs={isMobile ? 12 : 8}>
            <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
              <Grid item xs={12} paddingTop={4}>
                <StyledTitleBox>
                  <PrimaryColorText variant="h4" fontWeight={theme.fonts.fontWeights.bold}>
                    Account Credits
                  </PrimaryColorText>
                  <StyledTopUpButton onClick={() => setShowTopUpModal(true)}>
                    <AddCircleIcon
                      sx={{ color: theme.palette.colors.text.white, fontSize: "18px" }}
                    />
                    <Spacer size="xs" position="left" />
                    <Typography sx={{ color: theme.palette.colors.text.white }}>Top Up</Typography>
                  </StyledTopUpButton>
                </StyledTitleBox>
              </Grid>

              <Grid
                container
                sx={{
                  paddingY: 3,
                }}
              >
                <CurrentBalance setShowPointConvertModal={setShowPointConvertModal} />
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  paddingY: 3,
                }}
              >
                <Grid item>
                  <BorderButtonWithBg
                    isSelected={currentTab === "credits"}
                    tabColor={theme.palette.colors.brand.secondary}
                    label="Credits"
                    handleSubmit={() => {
                      onChangeCurrentTab("credits");
                    }}
                  />
                </Grid>
                {/* <Grid item>
                  <BorderButtonWithBg
                    isSelected={currentTab === "points"}
                    tabColor={theme.palette.colors.brand.secondary}
                    label="Points"
                    handleSubmit={() => {
                      onChangeCurrentTab("points");
                    }}
                  />
                </Grid> */}
              </Grid>
              <Grid container>
                <TransactionsFilter
                  currentFilter={currentFilter}
                  setCurrentFilter={setCurrentFilter}
                  onChangeFilter={onChangeFilter}
                  currentTab={currentTab}
                />
              </Grid>
              <TransactionsList
                currentTab={currentTab}
                openTransactionDetails={openTransactionDetails}
              />
            </Grid>
          </Grid>
        </>
      );
    }
    return <AccountCreditsLoader />;
  };

  return (
    <Grid
      spacing={1}
      container
      flexDirection="row"
      sx={{
        minWidth: isMobile ? null : "900px",
        paddingX: theme.dimensions.ScreenPaddingX,
        paddingBottom: 5,
        backgroundColor: theme.palette.colors.bg.white,
      }}
    >
      <Loading isLoading={isLoading} />

      {!isMobile && <SideMenu handleUploadImage={handleUploadImage} />}
      {renderContent()}
    </Grid>
  );
}

export default AccountCreditsScreen;
