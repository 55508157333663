import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  emailVerification,
  logout,
  resendVerificationCode,
} from "../../../services/auth/auth-slice.service";
import YoloLogo from "../../../assets/images/yolo_logo";
import VerificationForm from "../components/sign_up/verification-code-form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

function VerificationScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const newProps = props;
  const userEmail = newProps.history.location.state.email;
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmitVerificationCodes = (values) => {
    values.email = userEmail;
    setIsLoading(true);
    dispatch(emailVerification(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.HOME);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitResendCodes = () => {
    const values = {};
    values.email = userEmail;
    values.type = "register";
    dispatch(resendVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.AUTH);
      }
    });
  };

  useEffect(() => {
    if (!newProps.history.location.state?.fromRegister) {
      handleSubmitResendCodes();
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        height: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          height: "100vh",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid
            item
            sx={{
              height: `calc(105vh - ${isMobile ? "45px - 16px - 16px" : "60px - 32px - 32px"})`,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YoloLogo />
              </Grid>
              <Spacer size="m" />
              <VerificationForm
                handleSubmit={handleSubmitVerificationCodes}
                handleSubmitResendCodes={handleSubmitResendCodes}
                email={userEmail}
                handleLogout={handleLogout}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default VerificationScreen;
