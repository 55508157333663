import React from "react";
import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const StyledArrowIconBox = styled(Box)(({ theme, disabled }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "12px",
  },
  "&:hover": {
    color: disabled ? theme.palette.colors.text.primary : theme.palette.colors.brand.primary,
  },
  color: theme.palette.colors.text.primary,
  backgroundColor: theme.palette.colors.brand.white,
  opacity: disabled ? 0.5 : 1,
  borderRadius: theme.shape.borderRadius[4],
  height: "30px",
  width: "30px",
  top: "-30px",
  boxShadow: "1px 2px 3px 1px #9E9E9E",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
}));

function PrevArrow(arrowProps) {
  const { onClick, currentSlide } = arrowProps;
  return (
    <StyledArrowIconBox onClick={onClick} disabled={currentSlide === 0} sx={{ right: "55px" }}>
      <ArrowBackIosNewIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

function NextArrow(arrowProps) {
  const { onClick, className } = arrowProps;
  return (
    <StyledArrowIconBox
      onClick={onClick}
      disabled={className.includes("slick-disabled")}
      sx={{ right: "15px" }}
      className="test"
    >
      <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
    </StyledArrowIconBox>
  );
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};
function Carousel({ children, totalItems, pushTo, search }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", right: isMobile ? "0px" : "100px", top: "-25px" }}>
        <Link
          to={{ pathname: pushTo, search }}
          style={{ textTransform: "none", textDecorationColor: theme.palette.colors.text.primary }}
        >
          <LinkText>{`See All (${totalItems})`}</LinkText>
        </Link>
      </Box>
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
}

Carousel.propTypes = {
  pushTo: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  search: PropTypes.string.isRequired,
};

export default Carousel;
