import React from "react";
import { Box, Grid, styled, TextField, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Spacer from "../../../../components/utils/spacer.component";
import ItemDetailsBox from "./item-details-box.component";

const BottomSeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const TopSeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 25,
  marginBottom: 15,
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function ItemList({ itemData }) {
  const theme = useTheme();
  const renderMessageBox = () => (
    <>
      <TopSeperateLine />
      <Grid item xs={12}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={4}>
            <PrimaryColorText>Message Merchant: </PrimaryColorText>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
            >
              <TextField
                sx={{ textAlign: "center" }}
                multiline
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    style: { textAlign: "right", color: theme.palette.colors.text.primary },
                  },
                }}
                disabled
                value={itemData.notes === null ? "No Message" : itemData.notes}
              />
            </Box>
          </Grid>
          <BottomSeperateLine />
          <Grid item xs={12}>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              Recipient Info{" "}
            </PrimaryColorText>
          </Grid>
          <Grid item xs={12}>
            <RowBox>
              <PrimaryColorText sx={{ width: "100px" }}>First Name</PrimaryColorText>
              <Spacer position="left" size="m" />
              <GreyText>{itemData.recipient.firstName}</GreyText>
            </RowBox>
            <RowBox>
              <PrimaryColorText sx={{ width: "100px" }}>Last Name</PrimaryColorText>
              <Spacer position="left" size="m" />
              <GreyText>{itemData.recipient.lastName}</GreyText>
            </RowBox>
            <RowBox>
              <PrimaryColorText sx={{ width: "100px" }}>Email</PrimaryColorText>
              <Spacer position="left" size="m" />
              <GreyText>{itemData.recipient.email}</GreyText>
            </RowBox>
          </Grid>
          <BottomSeperateLine />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Spacer position="bottom" size="l" />
      <Grid container>
        <ItemDetailsBox itemDetails={itemData} />
        {renderMessageBox()}
      </Grid>
    </>
  );
}

ItemList.defaultProps = {
  itemData: PropTypes.shape({
    price: "0.00",
    quantity: 0,
    notes: "No Message",
    recipient: PropTypes.shape({
      email: "",
      firstName: "",
      lastName: "",
    }),
  }),
};

ItemList.propTypes = {
  itemData: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    quantity: PropTypes.number,
    notes: PropTypes.string,
    recipient: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

export default ItemList;
