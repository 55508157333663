import React from "react";
import PropTypes from "prop-types";
import { Typography, FormGroup, FormControlLabel, useTheme } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";

function MiniCheckBox({ label, optForNewsletter, setOptForNewsletter }) {
  const theme = useTheme();
  const handleOnChange = (event) => {
    setOptForNewsletter(event.target.checked);
  };
  return (
    <FormGroup sx={{ marginTop: -0.7 }}>
      <FormControlLabel
        control={
          <CheckBox
            checked={optForNewsletter}
            onChange={handleOnChange}
            size="small"
            sx={{
              color: theme.palette.colors.brand.primary,
              "&.Mui-checked": {
                color: theme.palette.colors.brand.primary,
              },
            }}
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.colors.brand.primary,
            }}
          >
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
}

MiniCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  optForNewsletter: PropTypes.bool.isRequired,
  setOptForNewsletter: PropTypes.func.isRequired,
};

export default MiniCheckBox;
