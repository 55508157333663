import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import GiftCardLoader from "./gift-card-loader.component";

const PaymentDetailsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const SpaceBetweenRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
});

function GiftCardOrderDetailsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      spacing={4}
      container
      sx={{
        paddingY: 3,
      }}
    >
      <Grid item xs={12}>
        <PaymentDetailsBox isMobile={isMobile}>
          <CustomSkeleton width="125px" height="25px" />
          <CustomSkeleton width="125px" height="25px" />
        </PaymentDetailsBox>
      </Grid>

      <Grid item xs={12}>
        <GiftCardLoader />
      </Grid>

      <Grid item xs={12}>
        <SeperateLine />
      </Grid>

      <Grid item xs={12}>
        <SpaceBetweenRow>
          <CustomSkeleton width="125px" height="25px" />
          <CustomSkeleton width="125px" height="25px" />
        </SpaceBetweenRow>
        <SpaceBetweenRow>
          <CustomSkeleton width="125px" height="25px" />
          <CustomSkeleton width="125px" height="25px" />
        </SpaceBetweenRow>
      </Grid>

      <Grid item xs={12}>
        <SpaceBetweenRow>
          <CustomSkeleton width="125px" height="25px" />
          <CustomSkeleton width="125px" height="30px" />
        </SpaceBetweenRow>
      </Grid>
    </Grid>
  );
}

export default GiftCardOrderDetailsLoader;
