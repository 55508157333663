import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import TextButton from "./text-button.component";
import PhotoUpload from "./photo-upload.component";
import routes from "../navigation/routes";

function SideMenuAvatar({ userData, handleUploadImage }) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));

  const pushToVerifyCode = () => {
    history.push(routes.VERIFICATIONCODE, { email: userData.email });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "20px",
        backgroundColor: isMobile
          ? theme.palette.colors.brand.white
          : theme.palette.colors.text.inputField,
        height: "100%",
      }}
    >
      <Grid item sx={{ width: "35%" }}>
        <PhotoUpload handleSubmit={handleUploadImage} imagePath={userData.imagePath} name="photo" />
      </Grid>
      <Grid item sx={{ width: "65%" }}>
        <Typography variant="h5">Hi, {userData.firstName || "User"}!</Typography>
        <TextButton
          handleSubmit={pushToVerifyCode}
          isDisabled={userData.emailVerified}
          label={userData.emailVerified ? "Account Verified" : `  Verify Your Account > `}
        />
      </Grid>
    </Grid>
  );
}

SideMenuAvatar.defaultProps = {
  userData: PropTypes.shape({
    imagePath: "",
    firstName: "",
    emailVerified: false,
    email: "",
  }),
};

SideMenuAvatar.propTypes = {
  userData: PropTypes.shape({
    imagePath: PropTypes.string,
    firstName: PropTypes.string,
    emailVerified: PropTypes.bool,
    email: PropTypes.string,
  }),
  handleUploadImage: PropTypes.func.isRequired,
};

export default SideMenuAvatar;
