import React from "react";
import {
  Box,
  Grid,
  styled,
  Typography,
  useTheme,
  Switch,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Link } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import routes from "../../../components/navigation/routes";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import BuyNowFormFieldText from "./buy-now-form-field-text.component";
import BuyNowPhoneNumberTextField from "./buy-now-phone-number-field.component";

const DetailsBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  "& .MuiButton-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "0px",
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const StyledRowCenterBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  marginLeft: "-5px",
}));

const PrimaryColorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size16,
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size15,
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const GiftSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.colors.text.white,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.colors.button.switch,
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.colors.ui.notification,
}));

function BuyNowExperience({ itemData, promoRedeem }) {
  const { setFieldValue, values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      setFieldValue(`purchasedAsGift`, true);
      setFieldValue(`gift.recipient.purchasedAsGift`, true);
      setFieldValue(`gift.recipient.firstName`, "");
      setFieldValue(`gift.recipient.lastName`, "");
      setFieldValue(`gift.recipient.email`, "");
      setFieldValue(`gift.recipient.phone`, "");
      setFieldValue(`gift.message`, "");
    } else {
      setFieldValue(`gift.recipient.purchasedAsGift`, false);
      setFieldValue(`purchasedAsGift`, false);
    }
  };

  const getAddOns = () => {
    const addOnsString = [];
    itemData.addOns.map((item) =>
      addOnsString.push(
        `${item.label} (RM${item.pricePerAddOn}) x ${item.quantity}: RM ${item.price}`,
      ),
    );
    return addOnsString;
  };

  const getSubtotalCost = () => {
    let totalCost = 0;

    itemData.addOns.forEach((addOns) => {
      totalCost += parseFloat(addOns.quantity) * parseFloat(addOns.pricePerAddOn.replace(",", ""));
    });
    totalCost = parseFloat(totalCost) + parseFloat(itemData.price.replace(",", ""));

    return parseFloat(totalCost).toFixed(2);
  };

  const getTotalCost = () => {
    let result = getSubtotalCost();
    result -= parseFloat(promoRedeem.discount.replace(",", ""));

    return parseFloat(result).toFixed(2);
  };

  const renderMessageBox = () => (
    <Grid item xs={12}>
      <Spacer size="l" position="top" />
      <Grid container sx={{ alignItems: "center" }} spacing={2}>
        <Grid item xs={12}>
          <SeperateLine />
        </Grid>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <PrimaryColorText>Purchase as a gift</PrimaryColorText>
            <GiftSwitch onChange={handleSwitchChange} />
          </SpaceBetweenBox>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={values.purchasedAsGift} unmountOnExit timeout="auto">
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    First Name
                  </Typography>
                  <BuyNowFormFieldText
                    errorName="firstName"
                    name="gift.recipient.firstName"
                    placeholder="First Name"
                    isTransparent
                  />
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Last Name
                  </Typography>
                  <BuyNowFormFieldText
                    errorName="lastName"
                    name="gift.recipient.lastName"
                    placeholder="Last Name"
                    isTransparent
                  />
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Email
                  </Typography>
                  <BuyNowFormFieldText
                    errorName="email"
                    name="gift.recipient.email"
                    placeholder="Email Address"
                    isTransparent
                  />
                  <Spacer position="top" size="xs" />
                  <RowBox>
                    <WarningAmberRoundedIcon />
                    <Spacer position="left" size="xs" />
                    <Typography variant="body2">
                      Please ensure the email address is correct.
                    </Typography>
                  </RowBox>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Phone Number
                  </Typography>
                  <BuyNowPhoneNumberTextField
                    errorName="phone"
                    name="gift.recipient.phone"
                    placeholder="Phone Number"
                    width="100%"
                    isTransparent
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Spacer position="top" size="m" />
                <Typography>Add a gift message?</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" flexDirection="column">
                  <Typography color={theme.palette.colors.text.secondary} sx={{ padding: "5px" }}>
                    Message (Optional)
                  </Typography>
                  <FormFieldTextMultiline
                    isShowBgColor={false}
                    name="gift.message"
                    placeholder="Message"
                    isGiftMessage={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Spacer size="l" position="top" />
            <SeperateLine />
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Spacer position="bottom" size="l" />
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: isMobile ? "100px" : "180px" }}>
              <img
                src={itemData.experience.imgPath}
                alt="ExperienceImage"
                style={{
                  width: isMobile ? "100px" : "180px",
                  borderRadius: theme.shape.borderRadius[0],
                }}
              />
            </Box>
            <Spacer size="m" position="left" />
            <DetailsBox>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: routes.EXPERIENCESDETAILS,
                  search: `experienceId=${itemData.experience.id}`,
                }}
              >
                <PrimaryColorTitle
                  sx={{
                    fontSize: theme.fonts.fontSizes.size16,
                    textAlign: "start",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflowWrap: "anywhere",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {itemData.experience.title}
                </PrimaryColorTitle>
              </Link>
              <Spacer position="top" size="s" />

              <SpaceBetweenBox>
                <GreyText sx={{ fontSize: theme.fonts.fontSizes.size15 }}>
                  {itemData.experience.area}
                </GreyText>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <PrimaryColorText>{`${itemData.quantity}x`}</PrimaryColorText>
                  <Spacer position="left" size="xs" />
                  <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                    {`RM${itemData.pricePerItem}`}
                  </PrimaryColorText>
                </Box>
              </SpaceBetweenBox>

              <StyledRowCenterBox>
                <StyledPersonIcon />
                <Spacer size="xs" position="left" />
                <PrimaryColorText>{`group of ${itemData.quantity}`}</PrimaryColorText>
              </StyledRowCenterBox>
            </DetailsBox>
          </Box>
        </Grid>

        {getAddOns().length > 0 && (
          <Grid item xs={12}>
            <Spacer position="top" size="xs" />
            <GreyText variant="body2">Add On(s)</GreyText>
            {getAddOns().map((item) => (
              <GreyText variant="body2" key={item}>
                {item}
              </GreyText>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Spacer position="top" size="m" />
          <SpaceBetweenBox>
            <PrimaryColorText>Item Subtotal :</PrimaryColorText>
            <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              {`RM${getSubtotalCost()}`}
            </PrimaryColorText>
          </SpaceBetweenBox>
          {promoRedeem && (
            <>
              <SpaceBetweenBox>
                <PrimaryColorText>Item Discount :</PrimaryColorText>
                <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  {`- RM${promoRedeem.discount}`}
                </PrimaryColorText>
              </SpaceBetweenBox>
              <SpaceBetweenBox>
                <PrimaryColorText>Item Total Price :</PrimaryColorText>
                <PrimaryColorText sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  {`RM${getTotalCost()}`}
                </PrimaryColorText>
              </SpaceBetweenBox>
            </>
          )}
        </Grid>

        {renderMessageBox()}
      </Grid>
    </>
  );
}

BuyNowExperience.defaultProps = {
  itemData: {
    experience: {
      area: "",
      id: null,
      imgPath: "",
      title: "",
      type: "",
    },
    pricePerItem: "",
    isFlexi: false,
    quantity: "",
    subtotalPrice: "",
    price: "",
  },
  promoRedeem: {
    subtotal: null,
    discount: null,
    total: null,
  },
};

BuyNowExperience.propTypes = {
  itemData: PropTypes.shape({
    experience: PropTypes.shape({
      area: PropTypes.string,
      id: PropTypes.number,
      imgPath: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
    }),
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
    pricePerItem: PropTypes.string,
    price: PropTypes.string,
    isFlexi: PropTypes.bool,
    quantity: PropTypes.string,
    subtotalPrice: PropTypes.string,
  }),
  promoRedeem: PropTypes.shape({
    subtotal: PropTypes.string,
    discount: PropTypes.string,
    total: PropTypes.string,
  }),
};

export default BuyNowExperience;
